import React, { useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { searchEvent } from 'services/GoogleAnalyticsService'

const searchStyles = makeStyles(theme => ({
  inputSearch: {
    height: '100%',
    width: '100%',
  },
  searchForm: {
    display: 'flex',
  },
  cancelIcon: {
    color: theme.palette.background.gray,
    cursor: 'pointer',
    marginRight: '8px',
    width: '16px',
  },
}))

const CustomSearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  const classes = searchStyles()
  const [refineTimeoutId, setRefineTimeoutId] = useState('')
  const [text, setText] = useState('')
  const timeSleep = 1500
  const handleOnchange = e => {
    const {
      target: { value: searchValue },
    } = e
    clearTimeout(refineTimeoutId)
    setText(searchValue)
    setRefineTimeoutId(
      setTimeout(() => {
        searchEvent(searchValue)
        refine(searchValue)
      }, timeSleep)
    )
  }

  return (
    <div className={classes.searchForm}>
      <TextField
        id="inputSearch"
        className={classes.inputSearch}
        placeholder="What do you want to build?"
        size="small"
        type="text"
        variant="outlined"
        onChange={handleOnchange}
        value={text}
        InputProps={{
          endAdornment: currentRefinement && (
            <CancelIcon
              className={classes.cancelIcon}
              onClick={() => {
                refine('')
                searchEvent('')
                setText('')
              }}
            />
          ),
        }}
      />
    </div>
  )
})

export default CustomSearchBox
