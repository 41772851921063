import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { CommonButton } from 'components'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.background.white,
    borderRadius: '10px',
    maxWidth: '420px',
    minWidth: '290px',
    paddingBottom: '3rem',
    [theme.breakpoints.down(768)]: {
      maxWidth: '260px',
      minWidth: '260px',
      paddingBottom: '22px',
      top: '50%',
      left: '50%',
    },
  },
  content: {
    color: '#000',
    padding: '20px',
    textAlign: 'justify',
  },
  title: {
    color: '#000',
    padding: '10px 20px 0px 20px',
    margin: '0px',
    '& h2': {
      padding: '10px 0px 0px 0px',
      margin: '0px',
    },
  },
  footer: {
    margin: '0px 20px',
    '& * + *': {
      marginLeft: '0.5rem',
    },
  },
  left: {
    float: 'left',
    [theme.breakpoints.down(768)]: {
      float: 'none',
      '& button': {
        marginBottom: '2px',
        marginLeft: '0px',
        width: '100%',
      },
    },
  },
  right: {
    float: 'right',
    [theme.breakpoints.down(768)]: {
      float: 'none',
      marginLeft: '0px',
      '& button': {
        marginBottom: '2px',
        marginLeft: '0px',
        width: '100%',
      },
    },
  },
}))

const TooltipTutorial = ({
  backProps,
  closeProps,
  continuous,
  index,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
}) => {
  const classes = useStyles()
  const { content, title } = step

  return (
    <div {...tooltipProps} className={classes.tooltip}>
      {title && (
        <div className={classes.title}>
          <h2>{title}</h2>
        </div>
      )}
      <div className={classes.content}>{content}</div>
      <div className={classes.footer}>
        {index >= 0 && (
          <div className={classes.left}>
            <CommonButton
              {...skipProps}
              buttonStyle={BUTTON_STYLE.CANCEL}
              fullWidth={false}
              id="skip"
              label="Skip tutorial"
              variant={BUTTON_VARIANT.OUTLINED}
            />
          </div>
        )}
        <div className={classes.right}>
          {index > 0 && (
            <CommonButton
              {...backProps}
              buttonStyle={BUTTON_STYLE.ACCENT}
              fullWidth={false}
              id="back"
              label="Back"
              variant={BUTTON_VARIANT.OUTLINED}
            />
          )}
          {continuous && (
            <CommonButton
              {...primaryProps}
              buttonStyle={BUTTON_STYLE.PRIMARY}
              fullWidth={false}
              id="next"
              label="Next"
              variant={BUTTON_VARIANT.OUTLINED}
            />
          )}
          {!continuous && (
            <CommonButton
              {...closeProps}
              fullWidth={false}
              id="close"
              label="End tutorial"
            />
          )}
        </div>
      </div>
    </div>
  )
}

TooltipTutorial.propTypes = {
  backProps: PropTypes.object.isRequired,
  closeProps: PropTypes.object.isRequired,
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  primaryProps: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  tooltipProps: PropTypes.object,
}

export default TooltipTutorial
