import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { CircularProgress, Grid, Hidden, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SendIcon from '@material-ui/icons/Send'
import { CommonButton, ErrorMessage } from 'components'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import { LISTING_STATUS } from 'constants/listingConstants'
import { removeContactInformationFromMessage } from 'constants/utils'
import { getListingById } from 'services/ListingService'

const useStyles = makeStyles(theme => ({
  buttonStyles: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 'bold',
    height: '100%',
    opacity: 0.8,
    textTransform: 'none',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
    '&:disabled': {
      opacity: 0.8,
      color: theme.palette.text.white,
    },
  },
  disabled: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    pointerEvents: 'none',
    opacity: 0.4,
  },
  height: {
    height: '40px',
  },
  noPadding: {
    height: 'inherit',
    '& .MuiButtonBase-root': {
      paddingLeft: '4px',
      paddingRight: '4px',
      paddingBottom: '0px',
      paddingTop: '0px',
    },
    '& .MuiButtonBase-root .MuiButton-label': {
      height: '40px',
    },
  },
  spinner: {
    color: theme.palette.text.white,
    margin: 'auto',
  },
  sendIcon: {
    cursor: 'pointer',
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '20px',
    width: '20px',
  },
}))

const SendMessage = ({
  listingStatus,
  pathname,
  onClickSend,
  onMessageUpdate,
}) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')
  const [messageText, setMessageText] = useState('')
  const [disableButton, setDisableButton] = useState(true)
  const [publishingMessage, setPublishingMessage] = useState(false)

  const updateMessageText = event => {
    const {
      target: { value },
    } = event
    const message = removeContactInformationFromMessage(value)
    setMessageText(message)
    onMessageUpdate(message)
    setDisableButton(message.length === 0)
  }

  const sendMessage = async () => {
    const listingUid = pathname.split('/').pop()
    const listing = await getListingById(listingUid)

    if (messageText.length > 0 && listing.status !== LISTING_STATUS.DELIVERED) {
      setDisableButton(true)
      setPublishingMessage(true)
      await onClickSend(messageText)
      setPublishingMessage(false)
      setMessageText('')
    } else {
      setErrorMessage(
        'Error adding message because the listing has been delivered.'
      )
    }
  }

  return (
    <Grid container item xs={12}>
      {listingStatus !== LISTING_STATUS.DELIVERED && (
        <>
          <ErrorMessage message={errorMessage} />
          <Grid item xs={11} sm={10}>
            <TextField
              fullWidth
              disabled={publishingMessage}
              id="newMessage"
              helperText="Phone numbers and emails are not allowed, they will be removed"
              label="New Message"
              placeholder="New Message"
              size="small"
              type="text"
              value={messageText}
              variant="outlined"
              onChange={updateMessageText}
            />
          </Grid>
          <Grid item xs={1} sm={2} className={classes.height}>
            <Hidden smUp>
              <div
                className={clsx(classes.buttonStyles, {
                  [classes.disabled]: disableButton,
                })}
              >
                {publishingMessage ? (
                  <CircularProgress className={classes.spinner} size={20} />
                ) : (
                  <SendIcon
                    className={classes.sendIcon}
                    onClick={sendMessage}
                  />
                )}
              </div>
            </Hidden>
            <Hidden xsDown>
              {publishingMessage ? (
                <div
                  className={clsx(classes.buttonStyles, {
                    [classes.disabled]: disableButton,
                  })}
                >
                  <CircularProgress className={classes.spinner} size={20} />
                </div>
              ) : (
                <div className={classes.noPadding}>
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.PRIMARY}
                    fullWidth={true}
                    disabled={disableButton}
                    label="Send Message"
                    size={BUTTON_SIZE.SMALL}
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={sendMessage}
                  />
                </div>
              )}
            </Hidden>
          </Grid>
        </>
      )}
    </Grid>
  )
}

SendMessage.propTypes = {
  listingStatus: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  onClickSend: PropTypes.func.isRequired,
  onMessageUpdate: PropTypes.func,
}

SendMessage.defaultProps = {
  onMessageUpdate: () => {},
}

export default SendMessage
