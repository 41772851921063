import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { round, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  CommonButton,
  OfferTooltip,
  ShippingRateList,
  StripeComponent,
} from 'components'
import { OFFER_ACCEPTED, OFFER_REJECTED } from 'constants/alertConstants'
import { LISTINGS_DETAILS_ENDPOINT } from 'constants/apiUrls'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import { LISTING_STATUS } from 'constants/listingConstants'
import { ERROR_ACCEPTING_OFFERS } from 'constants/offerConstants'
import {
  calculateWeightByDimensions,
  getCountryState,
  UNITS,
} from 'constants/utils'

import { createAlert } from 'services/AlertService'
import { getListingById, updateListing } from 'services/ListingService'
import { sendNotification } from 'services/MailService'
import {
  acceptOffer,
  rejectOffers,
  updateShippingRateOffer,
} from 'services/OfferService'
import { getShippingRateFees } from 'services/ShipEngineService'
import { getUserById } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
  btnActions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  btns: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      minWidth: '460px',
      height: '420px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  label: {
    marginBottom: '20px',
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
  step: {
    '& .MuiStepIcon-text': {
      fill: theme.palette.text.white,
    },
  },
}))

const defaultAmountState = { amount: 0, currency: 'usd' }

const defaultMakerState = {
  isFetched: false,
  maker: null,
}

const parseShippingRateList = rateFeesList => {
  const carriers = rateFeesList.map(item => item.carrierId)
  const uniqueCarriers = new Set(carriers)
  let ratesByCarrier = []
  for (let carrier of uniqueCarriers) {
    const rates = rateFeesList.filter(
      item => item.carrierId === carrier && item.shippingAmount
    )
    ratesByCarrier = [...ratesByCarrier, rates]
  }
  return ratesByCarrier
}

const OfferConfirmationStepper = ({
  disabled,
  height,
  length,
  material,
  modelId,
  modelName,
  offer,
  ownedBy,
  quantity,
  shipToCity,
  shipToCountry,
  shipToState,
  shipToZipCode,
  storeOffer,
  width,
  disableAllOffers,
  handleAcceptOffer,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const loggedUser = useSelector(state => state.userState.user)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [makerState, setMakerState] = useState(defaultMakerState)
  const [shippingRateChosen, setShippingRateChosen] = useState(null)
  const [fetchingShippingRate, setFetchingShippingRate] = useState(false)
  const [shippingRateList, setShippingRateList] = useState(null)
  const [updatingShippingFee, setUpdatingShippingFee] = useState(false)
  const steps = ['Choose the delivery type', 'Make payment']

  useEffect(() => {
    const retrieveMakerState = async () => {
      try {
        const {
          username: { uid: makerId },
        } = offer
        const {
          email,
          first_name,
          isMaker,
          last_name,
          shipping_info: shippingInfo,
        } = await getUserById(makerId)

        setMakerState({
          isFetched: true,
          maker: {
            email,
            first_name,
            isMaker,
            last_name,
            shippingInfo,
          },
        })
      } catch (error) {
        setError(
          'An error ocurred while fetching shipping info data, please try again later'
        )
      }
    }

    if (!makerState.isFetched) {
      retrieveMakerState()
    }
  }, [makerState, offer])

  useEffect(() => {
    const fetchShippingRateFees = async () => {
      try {
        setFetchingShippingRate(true)
        const dimensions = {
          height: round(height / 10, 2),
          length: round(length / 10, 2),
          width: round(width / 10, 2),
          unit: UNITS.CENTIMETER,
        }
        const weightValue = calculateWeightByDimensions(
          material,
          length,
          height,
          width
        )
        const weight = {
          unit: UNITS.GRAM,
          value: weightValue,
        }

        const {
          maker: {
            shippingInfo: {
              city: shipFromCity,
              country: shipFromCountry,
              state: shipFromState,
              zip_code: shipFromZipCode,
            },
          },
        } = makerState

        const {
          countryCode: shipFromCountryCode,
          stateCode: shipFromStateCode,
        } = getCountryState(shipFromCountry, shipFromState)
        const {
          countryCode: shipToCountryCode,
          stateCode: shipToStateCode,
        } = getCountryState(shipToCountry, shipToState)

        const shippingRateFees = await getShippingRateFees(
          dimensions,
          shipFromCountryCode,
          shipFromZipCode,
          shipFromCity,
          shipFromStateCode,
          shipToCountryCode,
          shipToZipCode,
          shipToCity,
          shipToStateCode,
          weight
        )

        const shippingRateFeesWithTotalAmount = shippingRateFees.map(item => {
          const {
            confirmationAmount = { defaultAmountState },
            insuranceAmount = { defaultAmountState },
            otherAmount = { defaultAmountState },
            shippingAmount = { defaultAmountState },
          } = item
          const totalAmount = round(
            confirmationAmount.amount +
              insuranceAmount.amount +
              otherAmount.amount +
              shippingAmount.amount,
            2
          )
          return {
            ...item,
            confirmationAmount,
            insuranceAmount,
            otherAmount,
            shippingAmount,
            totalAmount,
          }
        })

        setShippingRateList(
          parseShippingRateList(shippingRateFeesWithTotalAmount)
        )
      } catch (error) {
        setError(
          'An error ocurred while fetching shipping info data, please try again later'
        )
      }
      setFetchingShippingRate(false)
    }
    if (makerState.isFetched && !shippingRateList) {
      fetchShippingRateFees()
    }
  }, [
    height,
    length,
    makerState,
    material,
    shippingRateList,
    shipToCity,
    shipToCountry,
    shipToState,
    shipToZipCode,
    width,
  ])

  const onSelect = shippingRate => {
    setShippingRateChosen(shippingRate)
  }

  const getStepContent = stepIndex => {
    if (stepIndex === 0) {
      let shippingRateListSorted = []
      if (shippingRateList) {
        shippingRateListSorted = shippingRateList.map(currentShippingRate => {
          currentShippingRate = sortBy(currentShippingRate, 'totalAmount')
          return currentShippingRate
        })
      }

      return (
        <ShippingRateList
          shippingRateList={shippingRateList ? shippingRateListSorted : []}
          fetchingShippingRate={fetchingShippingRate}
          onSelect={onSelect}
        />
      )
    } else {
      const newTotalFee = round(
        offer.design_fee +
          offer.platform_fee +
          offer.offer +
          shippingRateChosen.totalAmount,
        2
      )
      return (
        <>
          {error && <div className={classes.errorMessage}>{error}</div>}
          {(loading || updatingShippingFee) && (
            <CircularProgress className={classes.progress} />
          )}
          <Typography className={classes.label}>
            If you confirm that you want to accept the offer by
            {` ${offer.username.first_name} ${offer.username.last_name} `}
            for ${newTotalFee}.
            {storeOffer && (
              <OfferTooltip
                designFee={`${offer.design_fee}`}
                makerOffer={`${offer.offer}`}
                platformFee={`${offer.platform_fee}`}
                shippingFee={`${shippingRateChosen.totalAmount}`}
                total={newTotalFee}
              />
            )}
            You need to submit payment before continuing
            {!storeOffer && '(All other offers will be rejected).'}
          </Typography>
          <StripeComponent
            listingId={offer.listingId}
            listingPrice={offer.total_fee - offer.shipping_fee}
            listingTotalPrice={newTotalFee}
            modelId={modelId}
            modelName={modelName}
            offerId={offer.id}
            quantity={quantity}
            shippingFee={shippingRateChosen.totalAmount}
            onSuccess={handleAccept}
            setLoading={setLoading}
          />
        </>
      )
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const notifyMakerFromListingOwner = async (alertStatus, createdFor) => {
    const listing = await getListingById(offer.listingId)

    const createdBy = {
      first_name: listing.user_info.first_name,
      last_name: listing.user_info.last_name,
      uid: listing.owned_by,
    }
    const listingInfo = {
      modelName: listing.model_name,
      uid: offer.listingId,
    }
    try {
      await createAlert(createdBy, createdFor, alertStatus, listingInfo)
    } catch (error) {
      setError(ERROR_ACCEPTING_OFFERS)
    }
  }

  const sendAcceptNofificationMail = async () => {
    const { listingId } = offer
    try {
      const listingURL = `${process.env.GATSBY_API_URL}/listings/${listingId}`
      const {
        maker: { email, first_name, last_name },
      } = makerState
      const makerName = `${first_name} ${last_name}`
      const message = 'Your offer was accepted for this listing'

      await sendNotification(email, makerName, message, modelName, listingURL)
    } catch (error) {
      setError('There was an error sending the notification.')
    }
  }

  const handleAccept = async paymentIntentId => {
    setError('')
    setLoading(true)
    try {
      await acceptOffer(offer)
      await sendAcceptNofificationMail()
      const rejectedUsers = await rejectOffers(offer.id, offer.listingId)
      const {
        carrierId,
        carrierCode,
        confirmationAmount,
        deliveryDays,
        insuranceAmount,
        otherAmount,
        rateType,
        serviceCode,
        serviceType,
        shippingAmount,
        totalAmount,
        trackable,
      } = shippingRateChosen
      await updateListing(offer.listingId, {
        status: LISTING_STATUS.OFFER_ACCEPTED,
        stripe_payment_intent_id: paymentIntentId,
        shipping_rate_details: {
          carrier_id: carrierId,
          carrier_code: carrierCode,
          confirmation_amount: confirmationAmount,
          delivery_days: deliveryDays,
          insurance_amount: insuranceAmount,
          other_amount: otherAmount,
          rate_type: rateType,
          service_code: serviceCode,
          service_type: serviceType,
          shipping_amount: shippingAmount,
          total_shipping_fee: totalAmount,
          trackable,
        },
      })
      await notifyMakerFromListingOwner(OFFER_ACCEPTED, {
        first_name: offer.username.first_name,
        last_name: offer.username.last_name,
        uid: offer.username.uid,
      })
      await rejectedUsers.map(async user => {
        const createdFor = {
          first_name: user.first_name,
          last_name: user.last_name,
          uid: user.uid,
        }
        await notifyMakerFromListingOwner(OFFER_REJECTED, createdFor)
      })
      handleAcceptOffer()
      if (!storeOffer) {
        disableAllOffers()
      }
    } catch (error) {
      setError(ERROR_ACCEPTING_OFFERS)
    }
    setLoading(false)
    setOpen(false)
    navigate(LISTINGS_DETAILS_ENDPOINT(offer.listingId))
  }

  const handleClose = () => {
    setOpen(false)
    setShippingRateChosen(null)
    setActiveStep(0)
  }

  const nextStep = async () => {
    try {
      setLoading(true)
      setUpdatingShippingFee(true)
      const {
        carrierId,
        carrierCode,
        confirmationAmount,
        deliveryDays,
        insuranceAmount,
        otherAmount,
        rateType,
        serviceCode,
        serviceType,
        shippingAmount,
        totalAmount,
        trackable,
      } = shippingRateChosen
      const {
        id: offerId,
        offer: makerFee,
        platform_fee: platformFee,
        design_fee: designFee,
      } = offer
      const newTotalFee = round(
        makerFee + platformFee + designFee + totalAmount,
        2
      )
      await updateShippingRateOffer(offerId, {
        carrierId,
        carrierCode,
        confirmationAmount,
        deliveryDays,
        insuranceAmount,
        newTotalFee,
        otherAmount,
        rateType,
        serviceCode,
        serviceType,
        shippingAmount,
        totalAmount,
        trackable,
      })
      setActiveStep(1)
    } catch (error) {
      setError(
        'Something went wrong when saving the shipping rate fee, please try again later'
      )
    }
    setUpdatingShippingFee(false)
    setLoading(false)
  }

  const previousStep = () => {
    setActiveStep(0)
  }

  const disableBackButton =
    !shippingRateChosen || updatingShippingFee || loading

  return (
    <>
      <CommonButton
        buttonStyle={BUTTON_STYLE.PRIMARY}
        disabled={disabled || loggedUser.uid !== ownedBy}
        fullWidth={false}
        label="Accept Offer"
        variant={BUTTON_VARIANT.OUTLINED}
        onClick={handleOpen}
      />
      <Dialog className={classes.dialog} open={open} onClose={handleClose}>
        <DialogTitle>Buy this Item</DialogTitle>
        <DialogContent>
          {error && <div className={classes.errorMessage}>{error}</div>}
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel className={classes.step}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <div>{getStepContent(activeStep)}</div>
          </div>
        </DialogContent>
        <DialogActions>
          {
            <div className={classes.btnActions}>
              <Grid container>
                <Grid className={classes.btns} item xs={12} sm={6}>
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.CANCEL}
                    disabled={loading}
                    fullWidth={true}
                    label="Cancel"
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={handleClose}
                  />
                </Grid>
                {activeStep === 0 && (
                  <Grid className={classes.btns} item xs={12} sm={6}>
                    <CommonButton
                      buttonStyle={BUTTON_STYLE.PRIMARY}
                      disabled={disableBackButton}
                      fullWidth={true}
                      label="Next"
                      variant={BUTTON_VARIANT.OUTLINED}
                      onClick={nextStep}
                    />
                  </Grid>
                )}
                {activeStep === 1 && (
                  <Grid className={classes.btns} item xs={12} sm={6}>
                    <CommonButton
                      buttonStyle={BUTTON_STYLE.ACCENT}
                      disabled={disableBackButton}
                      fullWidth={true}
                      label="Back"
                      variant={BUTTON_VARIANT.OUTLINED}
                      onClick={previousStep}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          }
        </DialogActions>
      </Dialog>
    </>
  )
}

OfferConfirmationStepper.propTypes = {
  disabled: PropTypes.bool,
  height: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  material: PropTypes.string.isRequired,
  modelId: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  shipToCity: PropTypes.string.isRequired,
  shipToCountry: PropTypes.string.isRequired,
  shipToState: PropTypes.string.isRequired,
  shipToZipCode: PropTypes.string.isRequired,
  storeOffer: PropTypes.bool,
  width: PropTypes.string.isRequired,
  disableAllOffers: PropTypes.func,
  handleAcceptOffer: PropTypes.func,
  setLoading: PropTypes.func,
}

OfferConfirmationStepper.defaultProps = {
  disabled: false,
  storeOffer: false,
  disableAllOffers: () => {},
  handleAcceptOffer: () => {},
}

export default OfferConfirmationStepper
