import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { isEmpty } from 'lodash'
import * as emailValidator from 'email-validator'

import {
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Link,
  TextField,
} from '@material-ui/core'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { CommonButton, ErrorMessage, GoogleButton, formStyle } from 'components'
import { TERMS_OF_SERVICE_ENDPOINT } from 'constants/apiUrls'
import {
  EMAIL,
  ERROR_CREATING_ACCOUNT,
  ERROR_EMAIL_ALREADY_USED,
  INVALID_EMAIL,
  PASSWORD,
} from 'constants/userConstants'
import { validatePassword, validateRepeatPasswords } from 'constants/utils'
import { signUpWithEmailPassword } from 'services/UserService'
import { BUTTON_STYLE, BUTTON_TYPE } from 'constants/buttonConstants'
import './styles.css'

const SignUp = () => {
  const classes = formStyle()

  const initialState = {
    email: '',
    password: '',
    repeatedPassword: '',
  }

  const defaultValidations = {
    error: false,
    helperText: '',
  }

  const [emailValidation, setEmailValidation] = useState(defaultValidations)
  const [errorMessage, setErrorMessage] = useState('')
  const [fields, setFields] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [passwordValidation, setPasswordValidation] = useState(
    defaultValidations
  )
  const [repeatedPasswordValidation, setRepeatedPasswordValidation] = useState(
    defaultValidations
  )

  const checkEmail = value => {
    setEmailValidation(defaultValidations)
    if (!isEmpty(value) && !emailValidator.validate(value)) {
      setEmailValidation({
        error: true,
        helperText: INVALID_EMAIL,
      })
    }
  }
  const checkPassword = value => {
    const validated = validatePassword(value)
    setPasswordValidation(validated)
  }

  const checkRepeatedPassword = (password, value) => {
    const validatedPasswords = validateRepeatPasswords(password, value)
    setRepeatedPasswordValidation(validatedPasswords)
  }

  const handleChange = prop => event => {
    const { value } = event.target
    switch (prop) {
      case EMAIL: {
        checkEmail(value)
        break
      }
      case PASSWORD: {
        checkPassword(value)
        checkRepeatedPassword(fields.password, fields.repeatedPassword)
        break
      }
      default:
        checkRepeatedPassword(fields.password, value)
        break
    }
  }

  const handleCreateAccount = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      if (
        !emailValidation.error &&
        !passwordValidation.error &&
        !repeatedPasswordValidation.error
      ) {
        await signUpWithEmailPassword(fields)
      }
    } catch (error) {
      if (error.message !== ERROR_EMAIL_ALREADY_USED) {
        error.message = ERROR_CREATING_ACCOUNT
      }
      setErrorMessage(error.message)
    }
    setLoading(false)
  }

  const updateField = prop => event => {
    setFields({ ...fields, [prop]: event.target.value })
  }

  return (
    <Container component="main" maxWidth="xs">
      <form className="signup-form" onSubmit={handleCreateAccount}>
        {loading && <CircularProgress className={classes.progress} />}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorMessage message={errorMessage} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="email"
              className={classes.styledField}
              disabled={loading}
              error={emailValidation.error}
              fullWidth
              helperText={emailValidation.helperText}
              id="email"
              label="Email Address"
              required
              size="small"
              type="email"
              value={fields.email}
              variant="outlined"
              onBlur={handleChange('email')}
              onChange={updateField('email')}
              InputProps={{
                endAdornment: <EmailOutlinedIcon className="field-icons" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.styledField}
              error={passwordValidation.error}
              disabled={loading}
              fullWidth
              helperText={passwordValidation.helperText}
              id="password"
              label="Password"
              required
              size="small"
              type="password"
              value={fields.password}
              variant="outlined"
              onBlur={handleChange('password')}
              onChange={updateField('password')}
              InputProps={{
                endAdornment: <LockOutlinedIcon className="field-icons" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.styledField}
              error={repeatedPasswordValidation.error}
              disabled={loading}
              fullWidth
              helperText={repeatedPasswordValidation.helperText}
              id="repeatedPassword"
              label="Repeat Password"
              required
              size="small"
              type="password"
              value={fields.repeatedPassword}
              variant="outlined"
              onBlur={handleChange('repeatedPassword')}
              onChange={updateField('repeatedPassword')}
              InputProps={{
                endAdornment: <LockOutlinedIcon className="field-icons" />,
              }}
            />
          </Grid>
        </Grid>
        <Grid className={classes.checkboxContainer} item xs={12}>
          <Checkbox disabled={loading} />
          <span>I Accept The </span>
          <Link
            href="#"
            onClick={() => navigate(TERMS_OF_SERVICE_ENDPOINT)}
            underline="always"
          >
            Terms of Service
          </Link>
        </Grid>
        <Grid className={classes.createAccountBtnContainer} item xs={12}>
          <CommonButton
            buttonStyle={BUTTON_STYLE.PRIMARY}
            disabled={loading}
            fullWidth={true}
            label="Create account"
            type={BUTTON_TYPE.SUBMIT}
          />
        </Grid>
        <Grid item xs={12}>
          <GoogleButton
            disabled={loading}
            setDisabled={setLoading}
            title="Sign up with Google"
          />
        </Grid>
      </form>
    </Container>
  )
}

export default SignUp
