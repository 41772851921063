import React, { useState } from 'react'
import clsx from 'clsx'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  MODEL_DETAILS_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from 'constants/apiUrls'

const useStyles = makeStyles(theme => ({
  card: {
    height: '256px',
    maxWidth: '335px',
    minWidth: '335px',
    width: '100%',
  },
  cardActions: {
    padding: '0',
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'inherit !important',
    },
  },
  cardFooter: {
    display: 'grid',
  },
  description: {
    alignItems: 'center',
    color: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  hoverDescription: {
    background: 'rgba(63, 48, 44, 0.1)',
    fontWeight: 'bold',
    height: '100%',
    lineHeight: '1.7',
    padding: '24px',
    position: 'absolute',
    textAlign: 'left',
    width: '100%',
  },
  hoverImage: {
    height: '192px',
    opacity: '0.2',
    pointerEvents: 'none',
    position: 'relative',
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    padding: 0,
    textTransform: 'none',
  },
  media: {
    height: '192px',
    pointerEvents: 'none',
    objectFit: 'contain',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const HitCard = ({ hit }) => {
  const classes = useStyles()
  const [hover, setHover] = useState(false)
  const model = hit
  let { description, formatted_name, images, name, ownedBy } = hit
  let imageThumb = images[0]
  const {
    first_name: firstName,
    last_name: lastName,
    photo_url: photoUrl,
    uid: userId,
  } = ownedBy
  if (hit.thumbnail) {
    imageThumb = hit.thumbnail
  }
  const redirectToModel = () => {
    hover &&
      navigate(MODEL_DETAILS_ENDPOINT(formatted_name), { state: { model } })
  }

  return (
    <Card className={classes.card}>
      <CardActionArea
        onClick={redirectToModel}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {hover ? (
          <CardContent className={clsx(classes.description, classes.media)}>
            <img className={classes.hoverImage} src={imageThumb} alt="" />
            <span className={classes.hoverDescription}>
              {description.slice(0, 150)}
              {description.length > 150 && <span>...</span>}
            </span>
          </CardContent>
        ) : (
          <CardMedia
            className={classes.media}
            component="img"
            image={imageThumb}
            title={name}
          />
        )}
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <Box onClick={() => navigate(USER_PROFILE_ENDPOINT(userId))}>
          <IconButton>
            <Avatar src={photoUrl} />
          </IconButton>
        </Box>
        <div className={classes.cardFooter}>
          <div
            className={clsx(classes.label, classes.title)}
            size="small"
            underline="none"
          >
            {name}
          </div>
          <Link
            className={classes.label}
            href="#"
            onClick={() => navigate(USER_PROFILE_ENDPOINT(userId))}
            size="small"
            underline="none"
          >
            {firstName} {lastName}
          </Link>
        </div>
      </CardActions>
    </Card>
  )
}

HitCard.propTypes = {
  hit: PropTypes.object.isRequired,
}

export default HitCard
