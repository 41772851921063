import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import * as linkify from 'linkifyjs'
import { findPhoneNumbersInText } from 'libphonenumber-js'
import { DropzoneArea } from 'material-ui-dropzone'
import { size } from 'lodash'
import {
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CommonButton, CustomDialogTitle, ErrorMessage } from 'components'

import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import { LISTING_STATUS } from 'constants/listingConstants'
import {
  FILE_TYPES_TO_UPLOAD,
  renameFilesWithUniqueName,
} from 'constants/utils'
import { getListingById, updateProductionFiles } from 'services/ListingService'
import { uploadFile } from 'services/StorageService'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    display: 'contents',
    margin: 0,
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
  dialog: {
    color: 'blue',
    '& .MuiDialog-paperScrollPaper': {
      minHeight: '440px',
    },
    '& .MuiDropzoneArea-root': {
      minHeight: '140px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .MuiDropzoneArea-text': {
      margin: '8px 0',
      fontSize: '0.875rem',
    },
    '& .MuiDropzonePreviewList-imageContainer > .MuiChip-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '381px',
    },
    '& .MuiDropzoneArea-icon': {
      height: '20px',
    },
  },
  label: {
    cursor: 'default',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    padding: '0px',
    textSizeAdjust: '100%',
    top: '0px',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '47%',
  },
}))

const UploadModal = ({
  listing,
  updateProdImages,
  updateStatus,
  edit = false,
}) => {
  const { user: loggedUser } = useSelector(state => state.userState)
  const classes = useStyles()
  const { production_files: prodFiles = { images: [], notes: '' } } = listing
  const [errorMessage, setErrorMessage] = useState('')
  const [allowToUpload, setAllowToUpload] = useState(true)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [productionFiles, setProductionFiles] = useState({
    imageFiles: [],
    notes: '',
  })
  const previousImages = prodFiles.images.map(image => image.image)
  const handleImageChange = images => {
    setProductionFiles({ ...productionFiles, imageFiles: images })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = async () => {
    try {
      const { id } = listing
      const currentListing = await getListingById(id)
      const { status } = currentListing

      const allowedStates = [
        LISTING_STATUS.IN_PROGRESS,
        LISTING_STATUS.AWAITING_APPROVAL,
      ]
      if (allowedStates.includes(status)) {
        setOpen(true)
      }

      const responseStates = [LISTING_STATUS.APPROVED, LISTING_STATUS.REJECTED]
      if (responseStates.includes(status)) {
        setAllowToUpload(false)
        setErrorMessage(
          `The listing has been ${status}, it is not allowed to upload more pictures.`
        )
      }
    } catch (error) {
      setErrorMessage(
        'An error occurred while fetching the listing, please try again later.'
      )
    }
  }

  const cleanNotes = note => {
    let finalNote = note

    const cleanFromPhoneNumbers = findPhoneNumbersInText(finalNote)
    cleanFromPhoneNumbers.forEach(phoneNumber => {
      const phone = note.slice(phoneNumber.startsAt, phoneNumber.endsAt)
      finalNote = finalNote.replace(phone, '')
    })

    const linksFound = linkify.find(finalNote)
    linksFound.forEach(linkFound => {
      if (linkFound.type === 'url' || linkFound.type === 'email') {
        finalNote = finalNote.replace(linkFound.value, '')
      }
    })
    return finalNote
  }

  const handleSubmit = async () => {
    setLoading(true)
    let { imageFiles, notes } = productionFiles
    const finalNotes = cleanNotes(notes)

    imageFiles = renameFilesWithUniqueName(
      imageFiles,
      FILE_TYPES_TO_UPLOAD.IMAGE
    )

    //  Uploading production images
    const images = await Promise.all(
      imageFiles.map(async file => {
        if (file.path) {
          return uploadFile(
            `users/${loggedUser.uid}/designs/${listing.model}/images/model-files/listing/${listing.id}/images`,
            file.formattedName,
            file
          )
        } else {
          const imageUrl = prodFiles.images.filter(image =>
            image.image.includes(file.name)
          )
          return imageUrl[0]
        }
      })
    )

    const prodImages = []
    images.forEach(image => {
      if (image.submission_date) {
        prodImages.push(image)
      } else {
        prodImages.push({
          image: image,
          submission_date: new Date(),
        })
      }
    })

    await updateProductionFiles(listing.id, {
      images: prodImages,
      notes: finalNotes,
    })
    await updateStatus(LISTING_STATUS.AWAITING_APPROVAL, true)
    updateProdImages({
      images: prodImages,
      notes: finalNotes,
    })
    setLoading(false)
    handleClose()
  }

  const handleNotesChange = event => {
    const {
      target: { value },
    } = event
    setProductionFiles({ ...productionFiles, notes: value })
  }

  return (
    <Container className={classes.boldTitle}>
      <CommonButton
        buttonStyle={BUTTON_STYLE.PRIMARY}
        disabled={!allowToUpload}
        fullWidth={false}
        label="Upload Pictures"
        variant={BUTTON_VARIANT.OUTLINED}
        onClick={handleOpen}
      />
      <ErrorMessage message={errorMessage} />
      <Dialog
        className={classes.dialog}
        maxWidth={'xs'}
        open={open}
        onClose={handleClose}
      >
        <CustomDialogTitle onClose={handleClose}>
          Upload Pictures
        </CustomDialogTitle>
        <DialogContent>
          {loading && (
            <CircularProgress size={48} className={classes.progress} />
          )}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className={classes.label}>Upload Pictures *</div>
              <DropzoneArea
                acceptedFiles={['image/jpeg', 'image/png']}
                alertSnackbarProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                }}
                filesLimit={10}
                initialFiles={previousImages}
                showPreviews={true}
                showPreviewsInDropzone={false}
                previewChipProps={{
                  classes: { root: classes.previewChip },
                }}
                previewGridProps={{
                  container: { spacing: 1, direction: 'row' },
                }}
                previewText=""
                useChipsForPreview
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={12}>
              <span className={classes.label}>
                {`Drop a note for ${listing.user_info.first_name}
                ${listing.user_info.last_name}`}
              </span>
              <TextField
                autoComplete="printNotes"
                className={classes.styledField}
                fullWidth
                multiline
                rows={4}
                size="small"
                type="text"
                variant="outlined"
                value={productionFiles.notes}
                onChange={handleNotesChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CommonButton
            buttonStyle={BUTTON_STYLE.CANCEL}
            fullWidth={false}
            label="Cancel"
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={handleClose}
          />
          <CommonButton
            buttonStyle={BUTTON_STYLE.PRIMARY}
            disabled={!size(productionFiles.imageFiles) > 0 || loading}
            fullWidth={false}
            label="Submit"
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={handleSubmit}
          />
        </DialogActions>
      </Dialog>
    </Container>
  )
}

UploadModal.propTypes = {
  listing: PropTypes.object.isRequired,
}

export default UploadModal
