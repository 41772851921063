import React from 'react'
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  aboutSection: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '40px',
    },
  },
  avatar: {
    marginLeft: '12px',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '20px',
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    [theme.breakpoints.up(1525)]: {
      maxWidth: '1400px',
    },
  },
  description: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '20px',
    },
  },
  dimensions: {
    display: 'grid',
  },
  owner: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  techSpecs: {
    marginTop: '20px',
  },
  techSpecsSection: {
    justifyContent: 'center',
  },
}))

const SkeletonAboutTechSpecs = () => {
  const classes = useStyles()

  return (
    <Grid container item xs={11} className={classes.container}>
      <Grid container item xs={12} className={classes.aboutSection}>
        <Grid item xs={12} className={classes.owner}>
          <Skeleton animation="wave" variant="rect" width="100%" height={40} />
        </Grid>
        <Grid item xs={12} className={classes.techSpecs}>
          <Skeleton animation="wave" variant="rect" width="100%" height={260} />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Skeleton
            className={classes.button}
            animation="wave"
            variant="rect"
            width="100%"
            height={40}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SkeletonAboutTechSpecs
