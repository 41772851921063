import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import * as emailValidator from 'email-validator'
import {
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Link,
  TextField,
} from '@material-ui/core'
import { INVALID_EMAIL } from 'constants/userConstants'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {
  CommonButton,
  ErrorMessage,
  FeatureFlag,
  GoogleButton,
  formStyle,
} from 'components'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'

import {
  loginWithEmailPassword,
  sendPasswordResetEmailToUser,
} from 'services/UserService'

const Login = () => {
  const classes = formStyle()
  const defaultValidations = {
    error: false,
    helperText: '',
  }
  const initialState = {
    email: '',
    password: '',
  }

  const [emailValidation, setEmailValidation] = useState(defaultValidations)
  const [errorMessage, setErrorMessage] = useState('')
  const [fields, setFields] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [showRecoveryPasswordForm, setShowRecoveryPasswordForm] = useState(
    false
  )

  const checkEmail = () => {
    setEmailValidation(defaultValidations)
    if (!isEmpty(fields.email) && !emailValidator.validate(fields.email)) {
      setEmailValidation({
        error: true,
        helperText: INVALID_EMAIL,
      })
    }
  }

  const login = async e => {
    e.preventDefault()
    try {
      setLoading(true)
      await loginWithEmailPassword(fields)
    } catch (error) {
      setErrorMessage(error.message)
    }
    setLoading(false)
  }

  const updateField = prop => event => {
    setFields({ ...fields, [prop]: event.target.value })
  }

  const handleChangeSection = () => {
    setShowRecoveryPasswordForm(!showRecoveryPasswordForm)
    setErrorMessage('')
  }

  const sendMailToResetPassword = async () => {
    try {
      setLoading(true)
      if (!emailValidation.error && fields.email) {
        const hasBeenSent = await sendPasswordResetEmailToUser(fields.email)
        setErrorMessage(
          'Please check your email address and click on the link to change your password.'
        )
        if (!hasBeenSent) {
          setErrorMessage(
            'You are not allowed to perform this action, check your credentials please.'
          )
        }
      }
    } catch (error) {
      setErrorMessage(error.message)
    }
    setLoading(false)
    setFields(initialState)
  }

  return (
    <Container component="main" maxWidth="xs">
      {!showRecoveryPasswordForm ? (
        <form className="login-form" onSubmit={login}>
          {loading && <CircularProgress className={classes.progress} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ErrorMessage message={errorMessage} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="email"
                className={classes.styledField}
                disabled={loading}
                fullWidth
                id="email"
                label="Email Address"
                required
                size="small"
                type="email"
                value={fields.email}
                variant="outlined"
                onChange={updateField('email')}
                InputProps={{
                  endAdornment: <EmailOutlinedIcon className="field-icons" />,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.styledField}
                disabled={loading}
                fullWidth
                id="password"
                label="Password"
                required
                size="small"
                type="password"
                value={fields.password}
                variant="outlined"
                onChange={updateField('password')}
                InputProps={{
                  endAdornment: <LockOutlinedIcon className="field-icons" />,
                }}
              />
              <FeatureFlag
                activeComponent={<></>}
                inactiveComponent={
                  <Link
                    className={classes.right}
                    href="#"
                    onClick={handleChangeSection}
                  >
                    Forgot password
                  </Link>
                }
                flag="forgot_password"
              />
            </Grid>
          </Grid>
          <Grid className={classes.checkboxContainer} item xs={12}>
            <Checkbox disabled={loading} />
            Remember Me
          </Grid>
          <Grid className={classes.createAccountBtnContainer} item xs={12}>
            <CommonButton
              buttonStyle={BUTTON_STYLE.PRIMARY}
              disabled={loading}
              fullWidth={true}
              label="Login"
              type={BUTTON_TYPE.SUBMIT}
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleButton
              disabled={loading}
              setDisabled={setLoading}
              title="Sign in with Google"
            />
          </Grid>
        </form>
      ) : (
        <form className="signup-form">
          {loading && <CircularProgress className={classes.progressRecovery} />}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ErrorMessage message={errorMessage} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="email"
                className={classes.styledField}
                disabled={loading}
                error={emailValidation.error}
                fullWidth
                helperText={emailValidation.helperText}
                id="recovery_password_email"
                label="Email Address"
                required
                size="small"
                type="email"
                value={fields.email}
                variant="outlined"
                onBlur={checkEmail}
                onChange={updateField('email')}
                InputProps={{
                  endAdornment: <EmailOutlinedIcon className="field-icons" />,
                }}
              />
              <Link
                className={classes.right}
                href="#"
                onClick={handleChangeSection}
              >
                Login
              </Link>
              <Grid item xs={12}>
                <CommonButton
                  buttonStyle={BUTTON_STYLE.PRIMARY}
                  disabled={loading}
                  fullWidth={true}
                  label={'Reset password'}
                  size={BUTTON_SIZE.MEDIUM}
                  type={BUTTON_TYPE.SUBMIT}
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={sendMailToResetPassword}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Container>
  )
}

export default Login
