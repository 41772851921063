import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import { CommonButton, ErrorMessage, ImageMedia } from 'components'

import { BUTTON_STYLE } from 'constants/buttonConstants'
import { LISTING_STATUS } from 'constants/listingConstants'
import { formatToDateString } from 'constants/utils'

import { getListingById, updateProductionFiles } from 'services/ListingService'

const useStyles = makeStyles(theme => ({
  content: {
    width: 'calc(100% - 60px)',
    display: 'flex',
    justifyContent: 'space-between',
    height: '100px',
    [theme.breakpoints.down('xs')]: {
      alignContent: 'center',
      flexWrap: 'wrap',
    },
  },
  header: {
    color: theme.palette.text.gray,
    fontWeight: 'bold',
    paddingBottom: '8px',
    paddingTop: '8',
    fontSize: '1rem',
  },
  information: {
    color: theme.palette.text.gray,
    display: 'block',
    margin: 'auto',
  },
  infoContainer: {
    padding: 0,
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  media: {
    display: 'flex',
    margin: '4px 0px',
  },
  sectionImage: {
    '& .MuiGrid-root': {
      alignItems: 'center',
      overflow: 'unset',
    },
  },
  title: {
    display: 'block',
    fontWeight: 'bold',
  },
}))

const ProductionPictures = ({
  isMaker,
  listing,
  uploadedBy,
  updateProdImages,
}) => {
  const classes = useStyles()
  const { production_files: productionFiles, status: listingStatus } = listing
  const { first_name: firstName, last_name: lastName } = uploadedBy
  const [images, setImages] = useState(productionFiles.images)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setImages(productionFiles.images)
  }, [productionFiles.images])

  const removeImage = async image => {
    const { id } = listing
    const currentListing = await getListingById(id)
    const { status } = currentListing

    const allowedStates = [
      LISTING_STATUS.IN_PROGRESS,
      LISTING_STATUS.AWAITING_APPROVAL,
    ]
    if (allowedStates.includes(status)) {
      setLoading(true)
      const newImages = images.filter(img => img.image !== image)
      await updateProductionFiles(listing.id, {
        ...productionFiles,
        images: newImages,
      })
      updateProdImages({ ...productionFiles, images: newImages })
      setImages(newImages)
      setLoading(false)
    } else {
      setErrorMessage(
        `The listing has been ${status}, it is not allowed to remove pictures.`
      )
    }
  }

  return (
    <>
      <ErrorMessage message={errorMessage} />
      <span className={classes.header}>Production Pictures</span>
      {images.map((image, index) => (
        <Card className={classes.media} key={index}>
          <div className={classes.sectionImage}>
            <ImageMedia image={image.image} thumbnails={true} />
          </div>
          <CardContent className={classes.content}>
            <p className={classes.infoContainer}>
              <span className={classes.title}>
                Picture {(index + 10).toString(36).toUpperCase()}
              </span>
              <span className={classes.information}>
                Uploaded on&nbsp;
                {image.submission_date && image.submission_date.seconds
                  ? formatToDateString(image.submission_date)
                  : `${moment().format('MMM')} ${moment().year()}`}
              </span>
            </p>
            <span className={classes.information}>
              {`Uploaded by ${firstName} ${lastName}`}
            </span>
            {isMaker && listingStatus === LISTING_STATUS.AWAITING_APPROVAL && (
              <CommonButton
                buttonStyle={BUTTON_STYLE.CANCEL}
                disabled={loading}
                label="Remove"
                onClick={() => {
                  removeImage(image.image)
                }}
              />
            )}
          </CardContent>
        </Card>
      ))}
    </>
  )
}

ProductionPictures.propTypes = {
  isMaker: PropTypes.bool,
  listing: PropTypes.object.isRequired,
  uploadedBy: PropTypes.object,
}

ProductionPictures.defaultProps = {
  isMaker: false,
  uploadedBy: {
    first_name: '',
    last_name: '',
  },
}

export default ProductionPictures
