import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { Grid } from '@material-ui/core'
import { CommonButton } from 'components'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import { CREATE_PAYMENT_INTENT_EDNPOINT } from 'constants/stripeEndpointsConstants'
import {
  ERROR_SRIPE_OFFER,
  ERROR_STRIPE_PAYMENT,
} from 'constants/offerConstants'
import { purchaseEvent } from 'services/GoogleAnalyticsService'

const StripeCheckoutForm = ({
  listingId,
  listingPrice,
  listingTotalPrice,
  modelId,
  modelName,
  offerId,
  quantity,
  shippingFee,
  userEmail,
  userId,
  onSuccess,
  setLoading,
}) => {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [clientSecret, setClientSecret] = useState('')
  const [totalAmount, setTotalAmount] = useState(listingTotalPrice)
  const stripe = useStripe()
  const elements = useElements()
  useEffect(() => {
    const generateAttemptPayment = async () => {
      try {
        const response = await axios.post(CREATE_PAYMENT_INTENT_EDNPOINT, {
          listingId,
          modelName,
          offerId,
          userId,
        })
        setClientSecret(response.data.clientSecret)
        setTotalAmount(response.data.totalFee)
      } catch (error) {
        setError(ERROR_SRIPE_OFFER)
      }
    }
    if (clientSecret === '') {
      generateAttemptPayment()
    }
  }, [clientSecret, modelName, listingId, listingTotalPrice, offerId, userId])

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: `Raleway, 'Roboto'`,
        fontSmoothing: 'antialiased',
        fontSize: '1.25rem',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }

  const handleChange = async event => {
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    setProcessing(true)
    try {
      setLoading(true)
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
        receipt_email: userEmail,
      })
      if (payload.error) {
        setError(ERROR_STRIPE_PAYMENT)
        setProcessing(false)
      } else {
        setError(null)
        setProcessing(false)
        setSucceeded(true)
        purchaseEvent(
          payload.paymentIntent.id,
          listingPrice,
          shippingFee,
          listingId,
          modelName,
          modelId,
          quantity
        )
        await onSuccess(payload.paymentIntent.id)
      }
    } catch (error) {
      setError(ERROR_STRIPE_PAYMENT)
      setProcessing(false)
    }
    setProcessing(false)
    setLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CardElement
            className="Card-Element-Container"
            id="card-element"
            options={cardStyle}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CommonButton
            id="submit"
            buttonStyle={BUTTON_STYLE.PRIMARY}
            disabled={processing || disabled || succeeded}
            fullWidth={true}
            label={`Pay $${totalAmount}`}
            size={BUTTON_SIZE.MEDIUM}
            type={BUTTON_TYPE.SUBMIT}
            variant={BUTTON_VARIANT.OUTLINED}
          />
        </Grid>
      </Grid>
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
    </form>
  )
}

StripeCheckoutForm.propTypes = {
  listingId: PropTypes.string.isRequired,
  listingPrice: PropTypes.number.isRequired,
  listingTotalPrice: PropTypes.number.isRequired,
  modelId: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  shippingFee: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  setLoading: PropTypes.func,
}

StripeCheckoutForm.defaultProops = {
  onSuccess: () => {},
}

export default StripeCheckoutForm
