import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  alignCenter: {
    margin: 'auto',
  },
  cellPadding: {
    padding: '2px',
  },
  flex: {
    display: 'flex',
  },
  styledField: {
    color: '#000',
  },
  tableBody: {
    maxWidth: '350px',
    width: '100%',
    margin: 'auto',
  },
})

const EstimationInputs = ({
  estimatedMaterial,
  estimatedPrintTime,
  materials,
  handleChangeEstimatedDays,
  handleChangeInput,
}) => {
  const classes = useStyles()

  const getEstimatedMaterial = material =>
    estimatedMaterial[material] ? estimatedMaterial[material] : ''
  const getEstimatedPrintTime = material =>
    estimatedPrintTime[material] ? estimatedPrintTime[material] : ''

  return (
    <Grid className={classes.flex} container item xs={12} sm={6}>
      <Grid
        className={classes.tableBody}
        container
        id="estimated-info"
        item
        xs={12}
      >
        <Grid container item xs={12}>
          <Grid item xs={4} />
          <Grid className={classes.cellPadding} item xs={4}>
            Estimated Print Time (Days)
          </Grid>
          <Grid className={classes.cellPadding} item xs={4}>
            Estimated Material (mm<sup>3</sup>)
          </Grid>
        </Grid>
        {materials.map((item, index) => (
          <Grid
            className={clsx(classes.cellPadding, classes.flex)}
            container
            item
            key={index}
            xs={12}
          >
            <Grid className={classes.alignCenter} item xs={4}>
              {item.name}
            </Grid>
            <Grid className={classes.cellPadding} item xs={4}>
              <TextField
                className={classes.styledField}
                fullWidth
                label={item.name}
                placeholder="Days"
                size="small"
                type="number"
                value={getEstimatedPrintTime(item.name)}
                variant="outlined"
                onChange={handleChangeEstimatedDays(item.name)}
                InputProps={{
                  inputProps: { min: '0' },
                }}
              />
            </Grid>
            <Grid className={classes.cellPadding} item xs={4}>
              <TextField
                className={classes.styledField}
                fullWidth
                label={item.name}
                placeholder={'mm^3'}
                size="small"
                type="number"
                value={getEstimatedMaterial(item.name)}
                variant="outlined"
                onChange={handleChangeInput(item.name, 'material')}
                InputProps={{
                  inputProps: { min: '0' },
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

EstimationInputs.propTypes = {
  estimatedMaterial: PropTypes.object,
  estimatedPrintTime: PropTypes.object,
  materials: PropTypes.arrayOf(PropTypes.object),
  handleChangeEstimatedDays: PropTypes.func.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
}

EstimationInputs.defaultProps = {
  estimatedMaterial: {},
  estimatedPrintTime: {},
  materials: [],
}

export default EstimationInputs
