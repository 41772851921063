import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { formStyle } from 'components'
import { ERROR_FETCHING_CATALOG } from 'constants/catalogConstants'

const initialOtherOption = {
  printTechnology: false,
  material: false,
  manufacturer: false,
}

const TechDetailsForm = ({ makerInfo, setMakerInfo }) => {
  const classes = formStyle()
  const colors = ['Black', 'White', 'Gray']
  const [otherOption, setOtherOption] = useState(initialOtherOption)
  const { catalog, error: catalogError } = useSelector(
    state => state.catalogState
  )

  const newCatalog = {
    manufacturers: [{ name: 'Other' }].concat(catalog.manufacturers),
    materials: [{ name: 'Other' }].concat(catalog.materials),
  }
  const { materials = [], manufacturers = [] } = newCatalog

  const updateTechDetails = prop => event => {
    const {
      target: { value },
    } = event
    if (prop !== 'model' && value === 'Other') {
      setOtherOption({ ...otherOption, [prop]: true })
      setMakerInfo({ ...makerInfo, [prop]: '' })
    } else {
      setMakerInfo({ ...makerInfo, [prop]: value })
    }
  }

  const handleColorChange = event => {
    const {
      target: { name: color },
    } = event
    const newColors = [...makerInfo.colors]
    const foundIndex = newColors.indexOf(color)
    if (foundIndex >= 0) {
      newColors.splice(foundIndex, 1)
    } else {
      newColors.push(color)
    }
    setMakerInfo({ ...makerInfo, colors: newColors })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {catalogError && <div>{ERROR_FETCHING_CATALOG}</div>}
        <TextField
          className={classes.styledField}
          fullWidth
          id="manufacturer"
          label="Manufacturer"
          placeholder="Manufacturer"
          required={true}
          select={!otherOption.manufacturer}
          size="small"
          value={makerInfo.manufacturer}
          variant="outlined"
          onChange={updateTechDetails('manufacturer')}
        >
          {manufacturers.map(manufacture => (
            <MenuItem key={manufacture.name} value={manufacture.name}>
              {manufacture.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.styledField}
          fullWidth
          id="model"
          label="Model"
          placeholder="Model"
          required={true}
          size="small"
          value={makerInfo.model}
          variant="outlined"
          onChange={updateTechDetails('model')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel component="legend">Colors Supported *</FormLabel>
        <FormGroup>
          {colors.map(color => (
            <FormControlLabel
              key={color}
              control={
                <Checkbox
                  checked={makerInfo.colors.includes(color)}
                  onChange={handleColorChange}
                  name={color}
                />
              }
              label={color}
            />
          ))}
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.styledField}
          fullWidth
          id="primaryMaterial"
          label="Primary Material"
          placeholder="Primary Material"
          required={true}
          select={!otherOption.primaryMaterial}
          size="small"
          value={makerInfo.primaryMaterial}
          variant="outlined"
          onChange={updateTechDetails('primaryMaterial')}
        >
          {materials.map(material => (
            <MenuItem key={material.name} value={material.name}>
              {material.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

TechDetailsForm.propTypes = {
  makerInfo: PropTypes.instanceOf(Object).isRequired,
  setMakerInfo: PropTypes.func.isRequired,
}

export default TechDetailsForm
