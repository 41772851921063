import React from 'react'
import PropTypes from 'prop-types'
import { round } from 'lodash'
import { IconButton, Grid, Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    fontWeight: 'bold',
    margin: '8px 0px',
  },
  offerTool: {
    width: '132px',
    textAlign: 'center',
    fontSize: '0.75rem',
  },
}))

const OfferTooltip = ({
  cheapestShippingOption,
  designFee,
  makerOffer,
  platformFee,
  shippingFee,
  showShippingFee,
  total,
}) => {
  const classes = useStyles()
  const formatPrice = price => {
    return parseFloat(price).toFixed(2)
  }

  const totalWithoutShippingFee = round(
    round(designFee, 2) + round(makerOffer, 2) + round(platformFee, 2),
    2
  )

  return (
    <Tooltip
      placement="bottom-start"
      title={
        <Grid container className={classes.offerTool}>
          <Grid className={classes.boldTitle} item xs={12}>
            Fee detail
          </Grid>
          <Grid item xs={8}>
            Maker's Offer:
          </Grid>
          <Grid item xs={4}>
            ${formatPrice(makerOffer)}
          </Grid>
          <Grid item xs={8}>
            Design Fee:
          </Grid>
          <Grid item xs={4}>
            ${formatPrice(designFee)}
          </Grid>
          <Grid item xs={8}>
            Platform Fee:
          </Grid>
          <Grid item xs={4}>
            ${formatPrice(platformFee)}
          </Grid>
          {cheapestShippingOption && (
            <>
              <Grid item xs={8}>
                Shipping:
              </Grid>
              <Grid item xs={4}>
                ${formatPrice(cheapestShippingOption.totalAmount)}
              </Grid>
            </>
          )}
          {showShippingFee && (
            <>
              <Grid item xs={8}>
                Shipping Fee:
              </Grid>
              <Grid item xs={4}>
                ${formatPrice(shippingFee)}
              </Grid>
            </>
          )}
          <Grid className={classes.boldTitle} item xs={8}>
            Total Price:
          </Grid>
          {cheapestShippingOption ? (
            <Grid className={classes.boldTitle} item xs={4}>
              {formatPrice(
                totalWithoutShippingFee + cheapestShippingOption.totalAmount
              )}
            </Grid>
          ) : (
            <Grid className={classes.boldTitle} item xs={4}>
              {formatPrice(total)}
            </Grid>
          )}
        </Grid>
      }
    >
      <IconButton>
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}

OfferTooltip.propTypes = {
  designFee: PropTypes.string,
  makerOffer: PropTypes.string,
  platformFee: PropTypes.string,
  shippingFee: PropTypes.number,
  showShippingFee: PropTypes.bool,
  total: PropTypes.string,
}

OfferTooltip.defaultProps = {
  designFee: 0,
  makerOffer: 0,
  platformFee: 0,
  shippingFee: 0,
  showShippingFee: true,
  total: 0,
}

export default OfferTooltip
