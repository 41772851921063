import * as Firebase from 'firebase'
import getFirebase from './firebase'
import moment from 'moment'
import { size } from 'lodash'
import { OFFER_STATUS, SLANT_3D_STATUS } from 'constants/offerConstants'

const firebaseApp = getFirebase()

export const getOffers = (listingId, limit = 10, startAfter) => {
  return new Promise((resolve, reject) => {
    let query = firebaseApp
      .firestore()
      .collection('offers')
      .where('listingId', '==', listingId)
      .orderBy('date', 'desc')
      .limit(limit)
    if (startAfter) {
      query = query.startAfter(startAfter)
    }
    query
      .get()
      .then(snapshot => {
        const lastElement = snapshot.docs[snapshot.docs.length - 1]
        const offers = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
        resolve({ offers, lastElement })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getOffersByMaker = (
  makerId,
  limit = 10,
  startAfter,
  statusIn = []
) => {
  return new Promise((resolve, reject) => {
    let query = firebaseApp
      .firestore()
      .collection('offers')
      .where('username.uid', '==', makerId)
      .orderBy('date', 'desc')
      .limit(limit)
    if (startAfter) {
      query = query.startAfter(startAfter)
    }
    if (size(statusIn)) {
      query = query.where('status', 'in', statusIn)
    }
    query
      .get()
      .then(snapshot => {
        const lastElement = snapshot.docs[snapshot.docs.length - 1]
        const offers = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
        resolve({ offers, lastElement })
      })
      .catch(err => {
        console.log(err.message)
        reject(err)
      })
  })
}

export const getOfferByListingAndUser = (listingId, userId) => {
  return new Promise((resolve, reject) => {
    let query = firebaseApp
      .firestore()
      .collection('offers')
      .where('listingId', '==', listingId)
      .where('username.uid', '==', userId)
      .limit(1)
    query
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0]
          if (doc.exists) {
            resolve({ id: doc.id, ...doc.data() })
          } else {
            resolve({})
          }
        } else {
          resolve({})
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getAcceptedOfferByListingId = listingId => {
  return new Promise((resolve, reject) => {
    let query = firebaseApp
      .firestore()
      .collection('offers')
      .where('listingId', '==', listingId)
      .where('status', '==', 'ACCEPTED')
      .limit(1)
    query
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0]
          if (doc.exists) {
            resolve({ id: doc.id, ...doc.data() })
          } else {
            resolve({})
          }
        } else {
          resolve({})
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const acceptOffer = offer => {
  const params = {
    status: OFFER_STATUS.ACCEPTED,
  }

  if (offer.is_from_slant3d) {
    params['slant_3d_status'] = SLANT_3D_STATUS.ACCEPTED
  }

  return new Promise((resolve, reject) => {
    firebaseApp
      .firestore()
      .collection('offers')
      .doc(offer.id)
      .update({
        accepted_on: Firebase.firestore.FieldValue.serverTimestamp(),
        delivery_date: new Date(moment().add(offer.production_days, 'days')),
        ...params,
      })
      .then(() => {
        resolve(offer.id)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const rejectOffers = (offerId, listingId) => {
  return new Promise((resolve, reject) => {
    let rejectedMakers = []
    let batch = firebaseApp.firestore().batch()
    let rejectedOffers = firebaseApp
      .firestore()
      .collection('offers')
      .where('listingId', '==', listingId)

    rejectedOffers
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          if (doc.id !== offerId) {
            rejectedMakers.push(doc.data().username)
            const offer = firebaseApp
              .firestore()
              .collection('offers')
              .doc(doc.id)
            batch.update(offer, {
              status: OFFER_STATUS.REJECTED,
            })
          }
        })
        batch.commit().then(resolve(rejectedMakers))
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const createOffer = (
  currentUser,
  fromStore = false,
  listingId,
  offer
) => {
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      const {
        designFee,
        notes,
        newOffer,
        platformFee,
        productionDays,
        shippingFee,
        status,
        totalFee,
      } = offer
      const {
        uid,
        first_name: firstName,
        last_name: lastName,
        photo_url: photoUrl,
      } = currentUser
      firebaseApp
        .firestore()
        .collection('offers')
        .add({
          date: Firebase.firestore.FieldValue.serverTimestamp(),
          design_fee: designFee,
          listingId: listingId,
          notes: notes,
          offer: newOffer,
          platform_fee: platformFee,
          production_days: productionDays,
          shipping_fee: shippingFee,
          status: status,
          total_fee: totalFee,
          from_store: fromStore,
          username: {
            uid: uid,
            first_name: firstName,
            last_name: lastName,
            photo_url: photoUrl,
          },
        })
        .then(docRef => {
          resolve(docRef.id)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const getOffersByMakerId = makerId => {
  return new Promise((resolve, reject) => {
    let query = firebaseApp
      .firestore()
      .collection('offers')
      .where('username.uid', '==', makerId)

    query
      .get()
      .then(snapshot => {
        const offers = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
        resolve(offers)
      })
      .catch(err => {
        console.log(err.message)
        reject(err)
      })
  })
}

export const updateOfferStatus = (offerId, status) => {
  return new Promise((resolve, reject) => {
    firebaseApp
      .firestore()
      .collection('offers')
      .doc(offerId)
      .update({
        status: status,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(offerId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateFieldsOffer = (offerId, fieldsToUpdate) => {
  return new Promise((resolve, reject) => {
    firebaseApp
      .firestore()
      .collection('offers')
      .doc(offerId)
      .update({
        ...fieldsToUpdate,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(offerId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateOffer = (offerId, offer, status) => {
  const { notes, productionDays, shippingFee, totalFee } = offer
  return new Promise((resolve, reject) => {
    firebaseApp
      .firestore()
      .collection('offers')
      .doc(offerId)
      .update({
        notes: notes,
        production_days: productionDays,
        shipping_fee: shippingFee,
        total_fee: totalFee,
        status: status,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(offerId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateShippingRateOffer = (offerId, shippingRate) => {
  const {
    carrierId,
    carrierCode,
    confirmationAmount,
    deliveryDays,
    insuranceAmount,
    otherAmount,
    rateType,
    serviceCode,
    serviceType,
    shippingAmount,
    totalAmount,
    trackable,
    newTotalFee,
  } = shippingRate
  return new Promise((resolve, reject) => {
    firebaseApp
      .firestore()
      .collection('offers')
      .doc(offerId)
      .update({
        shipping_rate: {
          carrier_id: carrierId,
          carrier_code: carrierCode,
          confirmation_amount: confirmationAmount,
          delivery_days: deliveryDays,
          insurance_amount: insuranceAmount,
          other_amount: otherAmount,
          rate_type: rateType,
          service_code: serviceCode,
          service_type: serviceType,
          shipping_amount: shippingAmount,
          total_amount: totalAmount,
          trackable,
        },
        shipping_fee: totalAmount,
        total_fee: newTotalFee,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(offerId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const getAcceptedCompletedOfferByListingId = listingId => {
  return new Promise((resolve, reject) => {
    let query = firebaseApp
      .firestore()
      .collection('offers')
      .where('listingId', '==', listingId)
      .where('status', 'in', ['ACCEPTED', 'LISTING_DELIVERED'])
      .limit(1)
    query
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0]
          if (doc.exists) {
            resolve({ id: doc.id, ...doc.data() })
          } else {
            resolve({})
          }
        } else {
          resolve({})
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}
