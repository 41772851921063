import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingTop: '50px',
    paddingLeft: '100px',
    paddingRight: '100px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '20px',
      paddingLeft: '32px',
      paddingRight: '32px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '20px',
      paddingLeft: '44px',
      paddingRight: '44px',
    },
  },
}))

const ListingPageSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.mainContainer}>
      <Skeleton
        animation="wave"
        variant="rect"
        height={'4rem'}
        width={'350px'}
      />
      <Skeleton animation="wave" variant="rect" height={'500px'} />
    </div>
  )
}

export default ListingPageSkeleton
