import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core'
import { isNil, size } from 'lodash'
import { CommonButton, StripeSignup, TechDetailsForm } from 'components'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import { updateUserTechDetails } from 'services/UserService'

import {
  generateStripeAccount,
  generateStripeAccountSignupLink,
} from 'services/StripeService'
import { updateStripeUserInfo } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  btnActions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  btns: {
    width: '40%',
    marginRight: '20px',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      minHeight: '420px',
      width: '500px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
  step: {
    '& .MuiStepIcon-text': {
      fill: theme.palette.text.white,
    },
  },
}))

const initialMakerInfo = {
  colors: [],
  manufacturer: '',
  model: '',
  primaryMaterial: '',
}

const BecomeMakerModal = ({ loggedUser }) => {
  const classes = useStyles()
  const initialTechDetails = !isNil(loggedUser.technical_details)
    ? {
        colors: [...loggedUser.technical_details.colors],
        manufacturer: loggedUser.technical_details.manufacturer,
        model: loggedUser.technical_details.model,
        primaryMaterial: loggedUser.technical_details.primary_material,
      }
    : initialMakerInfo
  const currentStep = !isNil(loggedUser.technical_details) ? 1 : 0
  const [activeStep, setActiveStep] = useState(currentStep)
  const [error, setError] = useState('')
  const [makerInfo, setMakerInfo] = useState(initialTechDetails)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const steps = ['Tech Details']

  const handleNext = async () => {
    setError('')
    setIsLoading(false)
    if (activeStep === 0) {
      try {
        setIsLoading(true)
        await updateUserTechDetails(loggedUser.uid, makerInfo, loggedUser)
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      } catch (error) {
        setError(error.message)
      }
      setIsLoading(false)
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <TechDetailsForm makerInfo={makerInfo} setMakerInfo={setMakerInfo} />
        )
      case 1:
        return <StripeSignup />
      default:
        return 'Unknown stepIndex'
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setActiveStep(currentStep)
    setMakerInfo({ ...initialTechDetails })
  }

  const handleCopmplete = async () => {
    let stripeAccountId
    setIsLoading(true)
    try {
      if (!loggedUser.stripe_account_id) {
        const stripeAccount = await generateStripeAccount(loggedUser.uid)
        stripeAccountId = stripeAccount.data.accountId
        await updateStripeUserInfo(loggedUser.uid, {
          stripe_account_id: stripeAccountId,
          stripe_onboarding_status: 'IN_PROGRESS',
        })
      } else {
        stripeAccountId = loggedUser.stripe_account_id
      }
      const stripeSignup = await generateStripeAccountSignupLink(
        stripeAccountId,
        loggedUser.uid
      )
      if (stripeSignup) {
        window.location.replace(stripeSignup.data.url)
      } else {
        throw new Error('Error when generating url link')
      }
    } catch (error) {
      setError(
        'Something went wrong when trying to setup the stripe account, please try again later'
      )
    } finally {
      setIsLoading(false)
    }
  }

  const validateFirstStep = () => {
    const manufacturer = size(makerInfo.manufacturer) > 0
    const model = size(makerInfo.model) > 0
    const primaryMaterial = size(makerInfo.primaryMaterial) > 0
    const colors = size(makerInfo.colors) > 0

    return manufacturer && model && primaryMaterial && colors
  }

  return (
    <div id="applyToBeMaker">
      <CommonButton
        buttonStyle={BUTTON_STYLE.PRIMARY}
        fullWidth={true}
        label={
          loggedUser.technical_details
            ? 'Continue Application'
            : 'Apply To Be A Maker'
        }
        size={BUTTON_SIZE.SMALL}
        type={BUTTON_TYPE.SUBMIT}
        variant={BUTTON_VARIANT.OUTLINED}
        onClick={handleOpen}
      />
      <Dialog
        className={classes.dialog}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Become a Maker</DialogTitle>
        <DialogContent>
          {error && <div className={classes.errorMessage}>{error}</div>}
          {isLoading && <CircularProgress className={classes.progress} />}
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel className={classes.step}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <StripeSignup />
            ) : (
              <div>{getStepContent(activeStep)}</div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {activeStep === steps.length ? (
            <div className={classes.btnActions}>
              <div className={classes.btns}>
                <CommonButton
                  buttonStyle={BUTTON_STYLE.CANCEL}
                  className={classes.backButton}
                  disabled={activeStep === 0}
                  fullWidth={true}
                  label="Back"
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={handleBack}
                />
              </div>
              <div className={classes.btns}>
                <CommonButton
                  buttonStyle={BUTTON_STYLE.PRIMARY}
                  disabled={isLoading}
                  fullWidth={true}
                  label="Save and Continue"
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={handleCopmplete}
                />
              </div>
            </div>
          ) : (
            <div className={classes.btnActions}>
              {activeStep === 0 ? (
                <div className={classes.btns}>
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.CANCEL}
                    fullWidth={true}
                    label="Cancel"
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={handleClose}
                  />
                </div>
              ) : (
                <div className={classes.btns}>
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.CANCEL}
                    className={classes.backButton}
                    disabled={activeStep === 0}
                    fullWidth={true}
                    label="Back"
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={handleBack}
                  />
                </div>
              )}
              <div className={classes.btns}>
                <CommonButton
                  buttonStyle={BUTTON_STYLE.PRIMARY}
                  disabled={!validateFirstStep()}
                  fullWidth={true}
                  label={'Next'}
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={handleNext}
                />
              </div>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

BecomeMakerModal.propTypes = {
  loggedUser: PropTypes.object.isRequired,
}

export default BecomeMakerModal
