import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { SkeletonRow } from 'components'

const SkeletonListing = ({ columns }) => {
  return (
    <Fragment>
      <SkeletonRow columns={columns} />
      <SkeletonRow columns={columns} />
      <SkeletonRow columns={columns} />
      <SkeletonRow columns={columns} />
      <SkeletonRow columns={columns} />
      <SkeletonRow columns={columns} />
      <SkeletonRow columns={columns} />
      <SkeletonRow columns={columns} />
      <SkeletonRow columns={columns} />
      <SkeletonRow columns={columns} />
    </Fragment>
  )
}

SkeletonListing.propTypes = {
  columns: PropTypes.number,
}

SkeletonListing.defaultProps = {
  columns: 6,
}

export default SkeletonListing
