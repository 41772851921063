import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: theme.palette.error.main,
  },
}))

const ErrorMessage = ({ message }) => {
  const classes = useStyles()

  return <>{message && <div className={classes.errorMessage}>{message}</div>}</>
}

export default ErrorMessage
