export const DEFAULT_LISTING_LIMITS = [10, 25, 50]
export const ERROR_FETCHING_LISTINGS =
  'An error occurred while fetching the listings, please try again later.'
export const ERROR_FETCHING_SHIP_INFO =
  'Something went wrong when trying to get the shipping information. Please try again later'
export const ERROR_SAVING_LISTING =
  'An error occurred while saving the listing, please try again later.'
export const ERROR_UPDATING_LISTING =
  'An error occurred while updating the listing, please try again later.'
export const LISTING_ACTIONS = {
  APPROVE: 'APPROVE',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  RECEIVED: 'RECEIVED',
  REJECT: 'REJECT',
  REJECT_OFFER: 'REJECT_OFFER',
  SEND_SHIPMENT: 'SEND_SHIPMENT',
  START_PRODUCTION: 'START_PRODUCTION',
}
export const LISTING_STATUS = {
  APPROVED: 'APPROVED',
  AWAITING: 'AWAITING_PAYMENT',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  BIDDING: 'BIDDING',
  CANCELLED: 'CANCELLED',
  DELIVERED: 'DELIVERED',
  EXPIRED: 'EXPIRED',
  FULFILLED: 'FULFILLED',
  IN_PROGRESS: 'IN_PROGRESS',
  OFFER_ACCEPTED: 'OFFER_ACCEPTED',
  OFFER_PENDING: 'OFFER_PENDING',
  OFFER_COMPLETED: 'OFFER_COMPLETED',
  REJECTED: 'REJECTED',
  SHIPPED: 'SHIPPED',
  SUBMITTED: 'SUBMITTED',
}

export const LISTING_INTERMEDIATE_ACTIONS = {
  AWAITING_CHANGE: 'AWAITING_CHANGE',
}

export const LISTING_INTERMEDIATE_STATUS = {
  AWAITING_CHANGE: 'AWAITING_CHANGE',
}
