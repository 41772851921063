import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { round } from 'lodash'
import { Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MaterialEstimationsProvidedBySlicer } from 'components'
import { PLATFORM_FEE } from 'constants/platformFeeConstant'

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: theme.palette.error.main,
  },
  label: {
    cursor: 'default',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    padding: '0px',
    textSizeAdjust: '100%',
    top: '0px',
  },
  margin: {
    marginBottom: '4px',
    marginTop: '4px',
  },
  marginTop: {
    marginTop: '40px',
  },
  title: {
    display: 'block',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    textAlign: 'center',
    [theme.breakpoints.down(281)]: {
      fontSize: '0.7rem',
    },
  },
}))

const OfferForm = ({
  estimatedMaterialPerFile,
  isOrder,
  listingOwner,
  offer,
  setOffer,
}) => {
  const classes = useStyles()

  const catalogError = useSelector(state => state.catalogState.error)

  const updateMoneyValues = prop => event => {
    const {
      target: { value },
    } = event
    const newValue = Number.parseFloat(value)
    const regex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/

    if ((newValue >= 0 && regex.test(newValue)) || value === '') {
      if (prop === 'newOffer') {
        const platformFee = round(newValue * (PLATFORM_FEE / 100), 2)
        setOffer({
          ...offer,
          newOffer: value === '' ? '' : newValue.toString(),
          platformFee: newValue >= 0 ? platformFee : 0,
        })
      } else {
        setOffer({
          ...offer,
          shippingFee: value === '' ? '' : newValue.toString(),
        })
      }
    }
  }

  const updateOfferNotes = event => {
    const {
      target: { value },
    } = event
    setOffer({ ...offer, notes: value })
  }

  const updateProductionDays = event => {
    const {
      target: { value },
    } = event
    const newValue = Number.parseInt(value)
    if (newValue >= 0 || value === '') {
      setOffer({
        ...offer,
        productionDays: value === '' ? '' : newValue.toString(),
      })
    }
  }

  return (
    <>
      <Grid container className={classes.marginTop} spacing={4}>
        {catalogError && (
          <div className={classes.errorMessage}>{catalogError}</div>
        )}

        <MaterialEstimationsProvidedBySlicer
          estimatedMaterialPerFile={estimatedMaterialPerFile}
        />

        <Grid id="offer-estimate" container spacing={2} item xs={12}>
          <Grid item xs={12} sm={6}>
            <div className={classes.label}>How much is this offer? *</div>
            <TextField
              fullWidth={true}
              disabled={isOrder}
              size="small"
              type="number"
              value={offer.newOffer}
              onChange={updateMoneyValues('newOffer')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.label}>Production Days *</div>
            <TextField
              fullWidth={true}
              size="small"
              type="number"
              value={offer.productionDays}
              onChange={updateProductionDays}
            />
          </Grid>
          <Grid item xs={12}>
            <span className={classes.label}>
              Drop a note for {listingOwner.first_name} {listingOwner.last_name}
            </span>
            <TextField
              autoComplete="printNotes"
              className={classes.styledField}
              fullWidth
              multiline
              rows={4}
              size="small"
              type="text"
              value={offer.notes}
              variant="outlined"
              onChange={updateOfferNotes}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

OfferForm.propTypes = {
  estimatedMaterial: PropTypes.object,
  estimatedMaterialPerFile: PropTypes.array,
  estimatedPrintTime: PropTypes.object,
  isOrder: PropTypes.bool,
  listingOwner: PropTypes.object.isRequired,
  offer: PropTypes.instanceOf(Object).isRequired,
  setOffer: PropTypes.func.isRequired,
}

OfferForm.defaultProps = {
  estimatedMaterial: {},
  estimatedMaterialPerFile: [],
  estimatedPrintTime: {},
  isOrder: false,
}

export default OfferForm
