import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { Header } from 'components'
import { isBrowser } from 'constants/utils'
import './styles.css'

const Footer = lazy(() => import('./Footer/Footer'))

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="layout">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="content">
        <main className="main">{children}</main>
      </div>
      {isBrowser() && (
        <Suspense fallback={<div />}>
          <Footer />
        </Suspense>
      )}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
