import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  contianer: {
    paddingTop: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '20px',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  skeleton: {
    marginBottom: '20px',
  },
}))

const DashboardSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.contianer}>
      <Skeleton
        animation="wave"
        className={classes.skeleton}
        variant="rect"
        height={'4rem'}
        width={'200px'}
      />
      <Skeleton
        animation="wave"
        className={classes.skeleton}
        variant="rect"
        height={'4rem'}
        width={'200px'}
      />
      <Skeleton animation="wave" variant="rect" height={'500px'} />
    </div>
  )
}

export default DashboardSkeleton
