import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'

import { StripeCheckoutForm } from 'components'
import { getStripe } from 'services/StripeService'

const StripeComponent = ({
  listingId,
  listingPrice,
  listingTotalPrice,
  modelId,
  modelName,
  offerId,
  quantity,
  shippingFee,
  onSuccess,
  setLoading,
}) => {
  const loggedUser = useSelector(state => state.userState.user)

  return (
    <Elements stripe={getStripe()}>
      <StripeCheckoutForm
        listingId={listingId}
        listingPrice={listingPrice}
        listingTotalPrice={listingTotalPrice}
        modelId={modelId}
        modelName={modelName}
        offerId={offerId}
        quantity={quantity}
        shippingFee={shippingFee}
        userEmail={loggedUser.email}
        userId={loggedUser.uid}
        onSuccess={onSuccess}
        setLoading={setLoading}
      />
    </Elements>
  )
}

StripeComponent.propTypes = {
  listingId: PropTypes.string.isRequired,
  listingPrice: PropTypes.number.isRequired,
  listingTotalPrice: PropTypes.number.isRequired,
  modelId: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  shippingFee: PropTypes.number.isRequired,
  onSuccess: PropTypes.func,
  setLoading: PropTypes.func,
}

StripeComponent.defaultProps = {
  onSuccess: () => {},
}

export default StripeComponent
