import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Typography, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey,
  },
}))

const CustomDialogTitle = ({ children, onClose }) => {
  const classes = useStyles()

  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <Close />
      </IconButton>
    </DialogTitle>
  )
}

CustomDialogTitle.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default CustomDialogTitle
