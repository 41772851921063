import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  inputStyle: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0',
    },
    align: 'center',
    textAlign: 'center',
    '& input': {
      textAlign: 'center',
    },
    width: '60px',
  },
}))

const CustomTextFieldNumber = ({ number, fieldName, onBlur, onChange }) => {
  const classes = useStyles()

  const handleChange = event => {
    event.preventDefault()
    const {
      target: { value },
    } = event

    if (value >= 0) {
      onChange(fieldName, value)
    }
  }

  return (
    <TextField
      InputProps={{
        classes: { input: classes.inputStyle },
        disableUnderline: true,
      }}
      size="small"
      type="number"
      value={number}
      onBlur={() => onBlur(fieldName, number)}
      onChange={handleChange}
    />
  )
}

CustomTextFieldNumber.propTypes = {
  fieldName: PropTypes.string,
  number: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default CustomTextFieldNumber
