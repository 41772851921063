import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { IssueModal } from 'components'
import { LISTING_STATUS } from 'constants/listingConstants'
import { downloadDesignFiles } from 'constants/utils'

const useStyles = makeStyles(() => ({
  padding: { padding: 0 },
}))

const ListingMenu = ({ listingId, modelId, ownedBy, status }) => {
  const classes = useStyles()
  const [menu, openMenu] = useState(null)
  const [openIssueModal, setOpenIssueModal] = useState(false)

  const handleOpen = event => {
    openMenu(event.currentTarget)
  }
  const handleClose = () => openMenu(null)

  const handleOpenIssueModal = () => {
    setOpenIssueModal(true)
    openMenu(null)
  }

  const handleCancelIssueModal = () => {
    setOpenIssueModal(false)
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        MenuListProps={{
          classes: { padding: classes.padding },
        }}
      >
        <MenuItem onClick={handleOpenIssueModal}>Report Issue</MenuItem>
        {status === LISTING_STATUS.IN_PROGRESS && (
          <MenuItem onClick={() => downloadDesignFiles(modelId)}>
            Download design files
          </MenuItem>
        )}
      </Menu>
      <IssueModal
        listingId={listingId}
        modelId={modelId}
        ownedBy={ownedBy}
        open={openIssueModal}
        handleCancelIssueModal={handleCancelIssueModal}
      />
    </>
  )
}

ListingMenu.propTypes = {
  listingId: PropTypes.string.isRequired,
  modelId: PropTypes.string.isRequired,
  ownedBy: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
}

export default ListingMenu
