import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CommonButton, CustomDialogTitle, ErrorMessage } from 'components'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
    '& .MuiDialogTitle-root': {
      padding: '10px 0px',
    },
  },
  btn: {
    display: 'flex',
    margin: '0 5px',
    textAlign: 'center',
    width: '100%',
  },
  container: {
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      zIndex: '3',
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      width: '500px',
      padding: '20px',
    },
  },
  footer: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '20px',
    margin: '0 auto',
  },
  footerContent: {
    display: 'flex',
    width: '100%',
  },
}))

const ConfirmationDialog = ({
  cancelLabel,
  confirmLabel,
  content,
  open,
  title,
  onAccept,
  onCancel,
  onClose,
}) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [disableAcceptButton, setDisableAcceptButton] = useState(false)

  const handleCloseDialog = () => {
    if (onClose) {
      onClose()
    } else {
      onCancel()
      setErrorMessage('')
    }
  }

  const handleClose = () => {
    onCancel()
    setErrorMessage('')
  }

  const handleConfirmation = async () => {
    try {
      setDisableAcceptButton(true)
      setLoading(true)
      await onAccept()
      setLoading(false)
    } catch (error) {
      setErrorMessage('There was an error, please try again later.')
    } finally {
      onCancel()
      setLoading(false)
      setDisableAcceptButton(false)
    }
  }

  return (
    <>
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleCloseDialog}
      >
        <div>
          <div className={classes.boldTitle}>
            <ErrorMessage message={errorMessage} />
            <CustomDialogTitle onClose={handleCloseDialog}>
              {title}
            </CustomDialogTitle>
            <DialogContent>{content}</DialogContent>
          </div>
          <DialogActions>
            <div className={classes.footer}>
              <div className={classes.footerContent}>
                <div className={classes.btn}>
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.CANCEL}
                    disabled={disableAcceptButton}
                    fullWidth={true}
                    label={cancelLabel}
                    size={BUTTON_SIZE.LARGE}
                    type={BUTTON_TYPE.SUBMIT}
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={handleClose}
                  />
                </div>
                <div className={classes.btn}>
                  <div className={classes.container}>
                    {loading && <CircularProgress />}
                    <CommonButton
                      buttonStyle={BUTTON_STYLE.PRIMARY}
                      disabled={disableAcceptButton}
                      fullWidth={true}
                      label={confirmLabel}
                      size={BUTTON_SIZE.LARGE}
                      type={BUTTON_TYPE.SUBMIT}
                      variant={BUTTON_VARIANT.OUTLINED}
                      onClick={handleConfirmation}
                    />
                  </div>
                </div>
              </div>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </>
  )
}

ConfirmationDialog.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  content: PropTypes.object,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

ConfirmationDialog.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Yes',
  title: 'Confirmation Dialog',
  content: null,
}

export default ConfirmationDialog
