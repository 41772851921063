import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  CreateListing,
  FeatureFlag,
  MeasurementChange,
  MaterialEstimationsProvidedBySlicer,
} from 'components'

import { USER_PROFILE_ENDPOINT } from 'constants/apiUrls'
import {
  INCH_TO_MM,
  INCHES_UNIT,
  MILLIMETER_UNIT,
} from 'constants/modelConstants'
import { formatToDateString } from 'constants/utils'

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'none',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
    [theme.breakpoints.up(1525)]: {
      maxWidth: '1400px',
    },
  },
  description: {
    marginTop: '20px',
    fontSize: '1.125rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
  },
  dimensions: {
    display: 'grid',
    borderBottom: '3px solid black',
    width: '100%',
    minHeight: '60px',
  },
  label: {
    borderBottom: '3px solid black',
    width: '72px',
    height: '100%',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  descriptionField: {
    borderBottom: '3px solid black',
    width: '100%',
    height: '100%',
  },
  alignBlock: {
    display: 'inline-block',
  },
  owner: {
    cursor: 'pointer',
    display: 'inline',
    padding: '0px 3px',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    fontWeight: 'bold',
  },
  subtitle: {
    alignSelf: 'center',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    paddingBottom: '20px',
  },
  techSpecs: {
    fontSize: '1rem',
    marginTop: '20px',
  },
  techSpecsSection: {
    justifyContent: 'center',
  },
  title: {
    fontSize: '2.125rem',
    fontWeight: 'bold',
    margin: '20px 0px 0px 20px',
    lineHeight: '1.8',
    [theme.breakpoints.up(1525)]: {
      paddingLeft: 'calc(50% - 700px)',
    },
  },
}))

const AboutTechSpecs = ({ createListing, model }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [unit, setUnit] = useState(MILLIMETER_UNIT)
  const { category, ownedBy = {}, submission_date, tech_specs } = model
  const { first_name: firstName = '', last_name: lastName = '' } = ownedBy
  const { depth, height, material, print_notes: printNotes, width } = tech_specs

  const [depthMeasure, setDepthMeasure] = useState(depth)
  const [heightMeasure, setHeightMeasure] = useState(height)
  const [widthMeasure, setWidthMeasure] = useState(width)
  const [showMeasures, setShowMeasures] = useState(true)

  const ownerName = `${firstName} ${lastName[0]}.`
  const submissionDate = formatToDateString({
    seconds: submission_date.seconds,
  })

  useEffect(() => {
    if (!depth || !height || !width) {
      setShowMeasures(false)
    } else {
      setDepthMeasure(depth)
      setHeightMeasure(height)
      setWidthMeasure(width)
    }
  }, [depth, height, width])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangeMeasurements = () => {
    const currentUnit = unit === MILLIMETER_UNIT ? INCHES_UNIT : MILLIMETER_UNIT
    setUnit(currentUnit)
    const condition =
      currentUnit === MILLIMETER_UNIT ? INCH_TO_MM : 1 / INCH_TO_MM

    setDepthMeasure(depthMeasure * condition)
    setHeightMeasure(heightMeasure * condition)
    setWidthMeasure(widthMeasure * condition)
  }

  const formatMeasure = measure => parseFloat(measure).toFixed(3)

  return (
    <Grid container item xs={11} className={classes.container}>
      <Grid container id="design-info" item xs={12}>
        <Grid className={classes.title} item xs={12}>
          {model.name}
        </Grid>
        <Grid item xs={12}>
          Uploaded by
          <Typography
            className={classes.owner}
            variant="caption"
            onClick={() => navigate(USER_PROFILE_ENDPOINT(ownedBy.uid))}
          >
            {ownerName}
          </Typography>
          on: {submissionDate}
        </Grid>
        <Grid item xs={12} className={classes.description}>
          {printNotes}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid
          container
          id="design-dimensions"
          item
          xs={12}
          className={classes.techSpecs}
          spacing={3}
        >
          <Grid item xs={5}>
            <div className={classes.label}>Category</div>
          </Grid>
          <Grid item xs={7}>
            <div className={classes.descriptionField}>{category}</div>
          </Grid>
          <Grid item xs={5}>
            <div className={clsx(classes.label, classes.alignBlock)}>
              <span>Size</span>
              <MeasurementChange
                groupSize="small"
                unit={unit}
                onHandleChangeMeasurements={handleChangeMeasurements}
              />
            </div>
          </Grid>

          <Grid item xs={7}>
            <div className={classes.dimensions}>
              {showMeasures && (
                <>
                  <span>
                    Width: {formatMeasure(widthMeasure)} {unit}
                  </span>
                  <span>
                    Height: {formatMeasure(heightMeasure)} {unit}
                  </span>
                  <span>
                    Depth: {formatMeasure(depthMeasure)} {unit}
                  </span>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.label}>Material</div>
          </Grid>
          <Grid item xs={7}>
            <div className={classes.descriptionField}>{material}</div>
          </Grid>
          <FeatureFlag
            activeComponent={<></>}
            inactiveComponent={
              <Grid item xs={12}>
                <MaterialEstimationsProvidedBySlicer
                  estimatedMaterialPerFile={model.material_estimations}
                  showEstimatedMaterial={false}
                />
              </Grid>
            }
            flag="material_estimations"
          />
        </Grid>
        {createListing && (
          <Grid id="build-design" item sm={12} xs={12}>
            <CreateListing
              model={model}
              open={open}
              handleClose={handleClose}
              handleOpen={handleOpen}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

AboutTechSpecs.propTypes = {
  createListing: PropTypes.bool,
  model: PropTypes.object.isRequired,
}

AboutTechSpecs.defaultProps = {
  createListing: true,
}

export default AboutTechSpecs
