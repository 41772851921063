import axios from 'axios'
import {
  ESTIMATE_SHIPPING_FEES,
  PRINT_LABEL,
  VALIDATE_ADDRESS,
  TRACKING_BRANDED,
  TRACKING_LABEL,
} from 'constants/shipEngineEndpointsConstants'

export const validateAddress = async (
  address,
  city,
  country,
  state,
  zipCode
) => {
  try {
    const response = await axios.post(VALIDATE_ADDRESS, {
      address_line1: address,
      city_locality: city,
      country_code: country,
      postal_code: zipCode,
      state_province: state,
    })
    const {
      data: { status, message, matchedAddress = {} },
    } = response
    const {
      address_line1: verifiedAddress,
      city_locality: verifiedCity,
      country_code: verifiedCountry,
      postal_code: verifiedZipCode,
      state_province: verifiedState,
    } = matchedAddress
    if (status === 'verified') {
      return {
        isValid: true,
        matchedAddress: {
          address: verifiedAddress,
          city: verifiedCity,
          country: verifiedCountry,
          zipCode: verifiedZipCode.split('-')[0],
          state: verifiedState,
        },
      }
    } else {
      console.log('message: ', message)
      return {
        isValid: false,
        message: 'Please check your address, city, state and zipCode',
      }
    }
  } catch (error) {
    if (error.message.includes('404')) {
      return {
        isValid: false,
        message: 'Please check your address, city, state and zipCode',
      }
    }
    throw new Error('Something went wrong, please try again later.')
  }
}

export const getShippingRateFees = async (
  dimensions,
  fromCountryCode,
  fromPostalCode,
  fromCityLocality,
  fromStateProvince,
  toCountryCode,
  toPostalCode,
  toCityLocality,
  toStateProvince,
  weight
) => {
  try {
    const response = await axios.post(ESTIMATE_SHIPPING_FEES, {
      dimensions,
      from_country_code: fromCountryCode,
      from_postal_code: fromPostalCode,
      from_city_locality: fromCityLocality,
      from_state_province: fromStateProvince,
      to_country_code: toCountryCode,
      to_postal_code: toPostalCode,
      to_city_locality: toCityLocality,
      to_state_province: toStateProvince,
      weight,
    })
    const { data } = response
    return data
  } catch (error) {
    console.log('error: ', error)
    throw new Error('Something went wrong, please try again later.')
  }
}

export const printLabel = async (listingId, userId) => {
  try {
    const { data } = await axios.post(PRINT_LABEL, {
      listingId,
      makerId: userId,
    })
    return data
  } catch (error) {
    if (error.message && error.message.includes('404')) {
      return {
        isValid: false,
        message: 'The request to print the labels is not correct',
      }
    }
    throw new Error('Something went wrong, please try again later.')
  }
}

export const getShipmentTracking = async labelId => {
  try {
    const { data } = await axios.post(TRACKING_LABEL, {
      labelId,
    })
    return data
  } catch (error) {
    if (error.message && error.message.includes('404')) {
      return {
        isValid: false,
        message: 'The request to shipment tracking not found',
      }
    }
    throw new Error('Something went wrong, please try again later.')
  }
}

export const getTrackingInformationPage = async (
  carrierCode,
  shipper,
  trackingId
) => {
  try {
    const { data } = await axios.post(TRACKING_BRANDED, {
      carrierCode,
      serviceCode: shipper,
      trackingNumber: trackingId,
    })
    return data.tracking_pages[data.tracking_pages.length - 1]
  } catch (error) {
    if (error.message && error.message.includes('404')) {
      return {
        isValid: false,
        message: 'The request to shipment tracking not found',
      }
    }
    throw new Error('Something went wrong, please try again later.')
  }
}
