import React from 'react'
import Rating from '@material-ui/lab/Rating'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: theme.palette.error.main,
  },
  label: {
    cursor: 'default',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    padding: '0px',
    textSizeAdjust: '100%',
    top: '0px',
  },
  title: {
    display: 'block',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    textAlign: 'center',
  },
  tableRow: {
    border: 'hidden',
  },
}))

const MakerReviewForm = ({ makerReview, setMakerReview }) => {
  const classes = useStyles()
  const addReview = prop => (event, newValue) => {
    if (prop === 'notes') {
      const {
        target: { value },
      } = event
      setMakerReview({ ...makerReview, [prop]: value })
    } else {
      setMakerReview({
        ...makerReview,
        [prop]: newValue !== null ? newValue : 0,
      })
    }
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <div className={classes.label}>Quality and Accuracy</div>
          <Rating
            name="quality-stars"
            value={makerReview.qualityAccuracy}
            onChange={addReview('qualityAccuracy')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.label}>Profesionalism</div>
          <Rating
            name="profesionalism-stars"
            value={makerReview.profesionalism}
            onChange={addReview('profesionalism')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.label}>Price</div>
          <Rating
            name="price-stars"
            value={makerReview.price}
            onChange={addReview('price')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <span className={classes.label}>Promptness</span>
          <Rating
            name="promptness-stars"
            value={makerReview.promptness}
            onChange={addReview('promptness')}
          />
        </Grid>
        <Grid item xs={12}>
          <span className={classes.label}>Review</span>
          <TextField
            autoComplete="printNotes"
            className={classes.styledField}
            fullWidth
            inputProps={{
              maxLength: 500,
            }}
            multiline
            rows={4}
            size="small"
            type="text"
            value={makerReview.notes}
            variant="outlined"
            onChange={addReview('notes')}
          />
        </Grid>
      </Grid>
    </>
  )
}

MakerReviewForm.propTypes = {
  makerReview: PropTypes.instanceOf(Object).isRequired,
  setMakerReview: PropTypes.func.isRequired,
}

export default MakerReviewForm
