import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Grid, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  AcceptOrderModal,
  OfferTooltip,
  CommonButton,
  ConfirmationDialog,
  ErrorMessage,
  FeatureFlag,
  ListingMenu,
  MakeOfferModal,
  OfferConfirmDialog,
  OffersModal,
  ShipmentTrackingModal,
  TrackingModal,
  UploadModal,
} from 'components'
import { USER_PROFILE_ENDPOINT } from 'constants/apiUrls'
import {
  LISTING_ACTIONS,
  LISTING_INTERMEDIATE_STATUS,
  LISTING_STATUS,
} from 'constants/listingConstants'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'

import { CHANGE_OFFER_ACCEPTED } from 'constants/alertConstants'
import { OFFER_STATUS, SLANT_3D_STATUS } from 'constants/offerConstants'
import { shipperLink } from 'constants/shipperLink'
import { ERROR_REJECTING_ORDER } from 'constants/storeConstants'
import { downloadDesignFiles } from 'constants/utils'
import { createAlert } from 'services/AlertService'
import { updateListing, updateListingStatus } from 'services/ListingService'
import { sendNofificationMail } from 'services/MailService'
import {
  getOffers,
  getOfferByListingAndUser,
  updateFieldsOffer,
  updateOfferStatus,
} from 'services/OfferService'

const useStyles = makeStyles(theme => ({
  ctaContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& .MuiButtonBase-root.MuiButton-root.MuiButton-outlined': {
      height: '44px',
    },
  },
  trackingId: {
    width: '100%',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  link: {
    fontWeight: 'bold',
    color: theme.palette.text.link,
    paddingLeft: '10px',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.text.linkHover,
    },
  },
  flexRow: {
    display: 'flex',
  },
  listingStatus: {
    backgroundColor: theme.palette.background.gray,
    borderRadius: '4px',
    color: theme.palette.text.white,
    fontSize: '0.875rem',
    fontWeight: 'bold',
    marginLeft: '12px',
    padding: '6px',
    paddingLeft: '16px',
    paddingRight: '16px',
    maxHeight: '32px',
  },
}))

const ListingStatus = ({
  isUpdating,
  listingState,
  loggedUserIsTheChosenMaker,
  offerState,
  shippingInfoState,
  setListingState,
  setOfferState,
  setOpenReviews,
  updateProdImages,
  updateStatus,
}) => {
  const classes = useStyles()
  const loggedUser = useSelector(state => state.userState.user)
  const [confirmationDialogState, setConfirmationDialogState] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [openChangeOfferDialog, setOpenChangeOfferDialog] = useState(false)
  const [content, setContent] = useState({})
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [openTrackingModal, setOpenTrackingDialog] = useState(false)

  const {
    model_name: modelName,
    owned_by: ownedBy,
    shipping_tracking,
    status,
    intermediate_state: intermediateState,
    user_info: userInfo,
  } = listingState.listing

  const onOfferSubmitted = async () => {
    try {
      const offer = await getOfferByListingAndUser(
        listingState.listing.id,
        loggedUser.uid
      )
      setOfferState({
        offer,
        isFetched: true,
      })
    } catch (error) {
      setErrorMessage(error.message)
    }
  }

  const handleConfirmationDialog = dialogState => {
    if (dialogState === LISTING_ACTIONS.APPROVE) {
      setConfirmationDialogState({
        state: LISTING_ACTIONS.APPROVE,
        title: 'Are you sure you want to Approve it?',
      })
    }
    if (dialogState === LISTING_ACTIONS.REJECT) {
      setConfirmationDialogState({
        state: LISTING_ACTIONS.REJECT,
        title: 'Are you sure you want to Reject it?',
      })
    }

    setOpenConfirmationDialog(true)
  }

  const handleDialogCancel = () => {
    setOpenConfirmationDialog(false)
  }

  const handleDialogAccept = async () => {
    const { state } = confirmationDialogState
    await updateStatus(state, false)
  }

  const changeOfferStatus = async (offerId, status, stat3DStatus) => {
    const paramsUpdate = status

    const offer = offerState
    if (offer.is_from_slant3d) {
      paramsUpdate['slant_3d_status'] = stat3DStatus
    }

    await updateFieldsOffer(offerId, paramsUpdate)
  }

  const handleAcceptOfferExchange = async () => {
    try {
      const { listing } = listingState
      const { offers } = await getOffers(listing.id)

      const acceptedOffer = offers.find(
        offer => offer.status === OFFER_STATUS.ACCEPTED
      )
      await changeOfferStatus(
        acceptedOffer.id,
        { status: OFFER_STATUS.REJECTED },
        SLANT_3D_STATUS.REJECTED
      )

      const { pending_offer_id } = intermediateState
      const newOfferSelected = offers.find(
        offer => offer.id === pending_offer_id
      )

      await changeOfferStatus(
        pending_offer_id,
        {
          status: OFFER_STATUS.ACCEPTED,
          shipping_rate: offerState.offer.shipping_rate,
        },
        SLANT_3D_STATUS.ACCEPTED
      )
      const {
        username: { first_name, last_name, uid },
      } = newOfferSelected
      const createdFor = { first_name, last_name, uid }
      const msg = `Change of offer was made, old offer was ${acceptedOffer.id}, now the offer that will take over the listing is: ${intermediateState.pending_offer_id}`

      await sendNotificationToUser(
        createdFor,
        listing,
        CHANGE_OFFER_ACCEPTED,
        msg,
        newOfferSelected.is_from_slant3d
      )
      await setDefaultIntermediateState(listing.id)

      setListingState({
        listing: {
          ...listing,
          intermediate_state: {
            pending_offer_id: '',
            status: '',
            operator_id: '',
          },
        },
        isFetched: true,
      })
      setOpenChangeOfferDialog(false)
    } catch (error) {
      setErrorMessage('Error while changing offer, please try again later')
    }
  }

  const sendNotificationToUser = async (
    createdFor,
    listing,
    labelNotification,
    notificationMailMsg,
    isFromSlant3d
  ) => {
    try {
      const listingAlert = {
        modelName: listing.model_name,
        uid: listing.id,
      }
      await createAlert(null, createdFor, labelNotification, listingAlert)
      const ccEmails = process.env.GATSBY_EMAILS_TO_REPORT
      await sendNofificationMail(
        createdFor.uid,
        listing,
        notificationMailMsg,
        isFromSlant3d,
        ccEmails
      )
    } catch (error) {
      setErrorMessage('Error sending a notification, please try again later')
    }
  }

  const handleRejectOfferExchange = async () => {
    try {
      setListingState({
        listing: {
          ...listingState.listing,
          intermediate_state: { pending_offer_id: '', status: '' },
        },
        isFetched: true,
      })
      await setDefaultIntermediateState(listingState.listing.id)
      setOpenChangeOfferDialog(false)
    } catch (error) {
      setErrorMessage(
        'Error rejecting the change of offer, please try again later'
      )
    }
  }

  const handleCloseOfferExchange = async () => {
    setOpenChangeOfferDialog(false)
  }

  const setDefaultIntermediateState = async listingId => {
    await updateListing(listingId, {
      intermediate_state: {
        pending_offer_id: '',
        status: '',
      },
    })
  }

  const handleRejectOffer = async fromMaker => {
    setErrorMessage('')
    try {
      await updateOfferStatus(offerState.offer.id, OFFER_STATUS.REJECTED)
      await updateListingStatus(
        offerState.offer.listingId,
        LISTING_STATUS.REJECTED
      )
      await updateStatus(LISTING_ACTIONS.REJECT_OFFER, fromMaker)
    } catch (error) {
      setErrorMessage(ERROR_REJECTING_ORDER)
    }
  }

  const handleStatusChange = newStatus => {
    setListingState({
      listing: {
        ...listingState.listing,
        status: newStatus,
      },
      isFetched: true,
    })
  }

  const handleOpenTrackingModal = () => {
    setOpenTrackingDialog(true)
  }

  const goToProfile = userUid => {
    if (userUid) {
      navigate(USER_PROFILE_ENDPOINT(userUid))
    }
  }

  const shouldShowShippingFee = offerStatus =>
    offerStatus !== OFFER_STATUS.SUBMITTED

  const openDialogToChangeOffer = async () => {
    const { offers } = await getOffers(listingState.listing.id)
    const offerSelected = offers.find(
      offer => offer.id === intermediateState.pending_offer_id
    )
    const priceDifference = (
      offerSelected.total_fee - offerState.offer.total_fee
    ).toFixed(2)

    const {
      username: { uid, first_name, last_name },
    } = offerSelected
    const dialogContent = (
      <span>
        <div className={classes.flexRow}>
          <div>New maker: </div>
          <Typography className={classes.link} onClick={() => goToProfile(uid)}>
            {first_name} {last_name}
          </Typography>
        </div>
        <div>
          The price of current offer : ${offerState.offer.total_fee.toFixed(2)}
          <OfferTooltip
            designFee={`${offerState.offer.design_fee}`}
            makerOffer={`${offerState.offer.offer}`}
            platformFee={`${offerState.offer.platform_fee}`}
            shippingFee={offerState.offer.shipping_fee}
            showShippingFee={shouldShowShippingFee(offerState.offer.status)}
            total={`${offerState.offer.total_fee}`}
            offer={offerState.offer}
          />
        </div>
        The new offer has a difference of {`$${priceDifference} in price`}
        <OfferTooltip
          designFee={`${offerSelected.design_fee}`}
          makerOffer={`${offerSelected.offer}`}
          platformFee={`${offerSelected.platform_fee}`}
          shippingFee={offerSelected.shipping_fee}
          showShippingFee={shouldShowShippingFee(offerSelected.status)}
          total={`${offerSelected.total_fee}`}
          offer={offerSelected}
        />
        and has a production time of {offerSelected.production_days} days. Would
        you like to accept the new offer ?
      </span>
    )
    setContent(dialogContent)
    setOpenChangeOfferDialog(true)
  }

  const loggedUserIsMaker = loggedUser && loggedUser.isMaker
  const loggedUserIsOwner = loggedUser && ownedBy && loggedUser.uid === ownedBy
  const isIntermediateState =
    intermediateState &&
    intermediateState.status === LISTING_INTERMEDIATE_STATUS.AWAITING_CHANGE

  const showMakeOfferModal =
    !isIntermediateState &&
    isEmpty(offerState.offer) &&
    loggedUserIsMaker &&
    !loggedUserIsOwner &&
    status === LISTING_STATUS.SUBMITTED

  const notOfferFromSlant3d =
    offerState.isFetched && !offerState.offer.is_from_slant3d

  const showIntermediateState = loggedUserIsOwner && isIntermediateState

  const showApproveRejectButtons =
    !isIntermediateState &&
    loggedUserIsOwner &&
    status === LISTING_STATUS.AWAITING_APPROVAL &&
    notOfferFromSlant3d

  const showReceivedShipmentButton =
    !isIntermediateState &&
    loggedUserIsOwner &&
    (listingState.listing.status === LISTING_STATUS.SHIPPED ||
      (offerState.isFetched &&
        offerState.offer.is_from_slant3d &&
        listingState.listing.status === LISTING_STATUS.OFFER_ACCEPTED))

  const showShipmentTrackingModalButton =
    !isIntermediateState &&
    loggedUserIsMaker &&
    loggedUserIsTheChosenMaker &&
    listingState.listing.status === LISTING_STATUS.APPROVED &&
    notOfferFromSlant3d

  const showStartProductionButton =
    !isIntermediateState &&
    loggedUserIsMaker &&
    status === LISTING_STATUS.OFFER_ACCEPTED &&
    loggedUserIsTheChosenMaker &&
    notOfferFromSlant3d &&
    offerState.offer.status === OFFER_STATUS.ACCEPTED

  const productionStopped =
    isIntermediateState &&
    loggedUserIsMaker &&
    status === LISTING_STATUS.OFFER_ACCEPTED &&
    loggedUserIsTheChosenMaker &&
    notOfferFromSlant3d &&
    offerState.offer.status === OFFER_STATUS.ACCEPTED

  const showUploadPictureButton =
    !isIntermediateState &&
    loggedUserIsMaker &&
    loggedUserIsTheChosenMaker &&
    listingState.listing.status === LISTING_STATUS.IN_PROGRESS &&
    notOfferFromSlant3d

  return (
    <>
      <ErrorMessage message={errorMessage} />
      <Grid className={classes.ctaContainer} item xs={12} sm={4}>
        {status === LISTING_STATUS.SUBMITTED && loggedUserIsOwner && (
          <OffersModal
            listingId={listingState.listing.id}
            modelId={listingState.listing.model}
            modelName={listingState.listing.model_name}
            quantity={listingState.listing.tech_specs.quantity}
            onOfferAccepted={() =>
              handleStatusChange(LISTING_STATUS.OFFER_ACCEPTED)
            }
          />
        )}
        {showMakeOfferModal && (
          <MakeOfferModal
            estimatedMaterial={listingState.listing.estimated_material}
            estimatedMaterialPerFile={
              listingState.listing.estimated_material_per_file
            }
            estimatedPrintTime={listingState.listing.estimated_print_time}
            listingId={listingState.listing.id}
            listingOwner={{ ...userInfo, uid: ownedBy }}
            modelName={modelName}
            changeStatus={onOfferSubmitted}
            zipCode={listingState.listing.zip_code}
          />
        )}
        {showIntermediateState && (
          <>
            <CommonButton
              buttonStyle={BUTTON_STYLE.PRIMARY}
              disabled={false}
              label="Change Offer"
              size={BUTTON_SIZE.MEDIUM}
              type={BUTTON_TYPE.SUBMIT}
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={openDialogToChangeOffer}
            />

            <ConfirmationDialog
              cancelLabel="No"
              confirmLabel="Yes"
              open={openChangeOfferDialog}
              content={content}
              title="Are you sure change the offer of your listing?"
              onAccept={handleAcceptOfferExchange}
              onCancel={handleRejectOfferExchange}
              onClose={handleCloseOfferExchange}
            />
          </>
        )}
        {showStartProductionButton && (
          <CommonButton
            buttonStyle={BUTTON_STYLE.PRIMARY}
            disabled={isUpdating}
            label="Start Production"
            size={BUTTON_SIZE.MEDIUM}
            type={BUTTON_TYPE.SUBMIT}
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={() => {
              updateStatus(LISTING_ACTIONS.START_PRODUCTION, true)
              downloadDesignFiles(listingState.listing.model)
            }}
          />
        )}
        {productionStopped && (
          <Typography className={classes.listingStatus} variant="caption">
            {'PAUSED LISTING'}
          </Typography>
        )}
        {showApproveRejectButtons && (
          <>
            <CommonButton
              buttonStyle={BUTTON_STYLE.PRIMARY}
              disabled={isUpdating}
              label="Approve"
              size={BUTTON_SIZE.MEDIUM}
              type={BUTTON_TYPE.SUBMIT}
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={() => handleConfirmationDialog(LISTING_ACTIONS.APPROVE)}
            />
            <CommonButton
              buttonStyle={BUTTON_STYLE.CANCEL}
              disabled={isUpdating}
              label="Reject"
              size={BUTTON_SIZE.MEDIUM}
              type={BUTTON_TYPE.SUBMIT}
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={() => handleConfirmationDialog(LISTING_ACTIONS.REJECT)}
            />
            <ConfirmationDialog
              open={openConfirmationDialog}
              title={confirmationDialogState.title}
              onAccept={handleDialogAccept}
              onCancel={handleDialogCancel}
            />
          </>
        )}
        {showUploadPictureButton && (
          <>
            <UploadModal
              listing={listingState.listing}
              updateProdImages={updateProdImages}
              updateStatus={updateStatus}
            />
            <ListingMenu
              listingId={listingState.listing.id}
              modelId={listingState.listing.model}
              status={listingState.listing.status}
              ownedBy={listingState.listing.owned_by}
            />
          </>
        )}
        {showShipmentTrackingModalButton && (
          <ShipmentTrackingModal
            listingId={listingState.listing.id}
            makerFee={offerState.offer.offer}
            offerId={offerState.offer.id}
            shippingTracking={listingState.listing.shipping_tracking}
            userId={offerState.offer.username.uid}
            updateStatus={updateStatus}
          />
        )}
        {showReceivedShipmentButton && (
          <>
            <CommonButton
              buttonStyle={BUTTON_STYLE.PRIMARY}
              disabled={isUpdating}
              fullWidth={false}
              label="Received Shipment"
              size={BUTTON_SIZE.MEDIUM}
              type={BUTTON_TYPE.SUBMIT}
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={() => {
                setOpenReviews(true)
                updateStatus(LISTING_ACTIONS.RECEIVED, false)
              }}
            />
            {listingState.listing.shipping_tracking && (
              <div className={classes.trackingId}>
                Tracking ID:&nbsp;
                {listingState.listing.shipping_tracking.shipper !== 'OTHER' ? (
                  <Link
                    href={shipperLink(
                      listingState.listing.shipping_tracking.carrier_code,
                      listingState.listing.shipping_tracking.tracking_id
                    )}
                    rel="noopener"
                    target="_blank"
                  >
                    {listingState.listing.shipping_tracking.shipper}
                  </Link>
                ) : (
                  `${listingState.listing.shipping_tracking.tracking_id},
                        ${listingState.listing.shipping_tracking.shipper}`
                )}
              </div>
            )}
          </>
        )}
        {!isEmpty(offerState.offer) &&
          loggedUserIsMaker &&
          loggedUserIsTheChosenMaker &&
          listingState.listing.status === LISTING_STATUS.OFFER_PENDING && (
            <>
              <AcceptOrderModal
                estimatedMaterial={listingState.listing.estimated_material}
                estimatedMaterialPerFile={
                  listingState.listing.estimated_material_per_file
                }
                estimatedPrintTime={listingState.listing.estimated_print_time}
                initialOffer={offerState.offer.offer}
                listingId={offerState.offer.listingId}
                listingOwner={{
                  ...listingState.listing.user_info,
                  uid: listingState.listing.owned_by,
                }}
                modelName={listingState.listing.model_name}
                offerId={offerState.offer.id}
                zipCode={listingState.listing.zip_code}
                handleFetchOffers={() =>
                  handleStatusChange(LISTING_STATUS.OFFER_COMPLETED)
                }
              />
              <CommonButton
                buttonStyle={BUTTON_STYLE.CANCEL}
                disabled={isUpdating}
                label="Reject"
                size={BUTTON_SIZE.MEDIUM}
                type={BUTTON_TYPE.SUBMIT}
                variant={BUTTON_VARIANT.OUTLINED}
                onClick={() => handleRejectOffer(true)}
              />
            </>
          )}
        {/* This is for the My Store Flow */}
        {!isEmpty(offerState.offer) &&
          loggedUserIsOwner &&
          status === LISTING_STATUS.OFFER_COMPLETED && (
            <>
              <OfferConfirmDialog
                height={listingState.listing.tech_specs.height}
                length={listingState.listing.tech_specs.length}
                material={listingState.listing.tech_specs.material}
                offer={offerState.offer}
                modelId={listingState.listing.model}
                modelName={modelName}
                ownedBy={listingState.listing.owned_by}
                quantity={listingState.listing.tech_specs.quantity}
                shipToCity={shippingInfoState.shippingInfo.city}
                shipToCountry={listingState.listing.country}
                shipToState={shippingInfoState.shippingInfo.state}
                shipToZipCode={listingState.listing.zip_code}
                storeOffer={true}
                width={listingState.listing.tech_specs.width}
                handleAcceptOffer={() =>
                  handleStatusChange(LISTING_STATUS.OFFER_ACCEPTED)
                }
              />
              <CommonButton
                buttonStyle={BUTTON_STYLE.CANCEL}
                disabled={isUpdating}
                label="Reject"
                size={BUTTON_SIZE.MEDIUM}
                type={BUTTON_TYPE.SUBMIT}
                variant={BUTTON_VARIANT.OUTLINED}
                onClick={() => handleRejectOffer(false)}
              />
              <ConfirmationDialog
                open={openConfirmationDialog}
                title={confirmationDialogState.title}
                onAccept={handleDialogAccept}
                onCancel={handleDialogCancel}
              />
            </>
          )}
        {loggedUserIsOwner &&
          shipping_tracking &&
          listingState.listing.status === LISTING_STATUS.DELIVERED && (
            <>
              <FeatureFlag
                activeComponent={<></>}
                inactiveComponent={
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.CANCEL}
                    disabled={isUpdating}
                    label="Shipping Information"
                    size={BUTTON_SIZE.MEDIUM}
                    type={BUTTON_TYPE.SUBMIT}
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={handleOpenTrackingModal}
                  />
                }
                flag="tracking_shipping"
              />
              <TrackingModal
                labelId={shipping_tracking.label_id}
                open={openTrackingModal}
                setOpenTrackDialog={setOpenTrackingDialog}
                carrierCode={shipping_tracking.carrier_code}
                shipper={shipping_tracking.shipper}
                trackingId={shipping_tracking.tracking_id}
              />
            </>
          )}
        {((loggedUserIsMaker && loggedUserIsTheChosenMaker) ||
          loggedUserIsOwner) &&
          listingState.listing.status !== LISTING_STATUS.IN_PROGRESS && (
            <ListingMenu
              listingId={listingState.listing.id}
              modelId={listingState.listing.model}
              status={listingState.listing.status}
              ownedBy={listingState.listing.owned_by}
            />
          )}
      </Grid>
    </>
  )
}

ListingStatus.propTypes = {
  isUpdating: PropTypes.bool,
  listingState: PropTypes.object,
  loggedUserIsTheChosenMaker: PropTypes.bool,
  offerState: PropTypes.object,
  shippingInfoState: PropTypes.object,
  setListingState: PropTypes.func,
  setOfferState: PropTypes.func,
  setOpenReviews: PropTypes.func,
  updateProdImages: PropTypes.func,
  updateStatus: PropTypes.func,
}

ListingStatus.defaultProps = {
  listingState: {},
  offerState: {},
  shippingInfoState: {},
  setListingState: () => {},
  setOfferState: () => {},
  setOpenReviews: () => {},
  updateProdImages: () => {},
  updateStatus: () => {},
}

export default ListingStatus
