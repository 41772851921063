import React from 'react'
import PropTypes from 'prop-types'

import { OfferConfirmationStepper } from 'components'

const OfferConfirmDialog = ({
  disabled,
  height,
  length,
  material,
  modelId,
  modelName,
  offer,
  quantity,
  shipToCity,
  ownedBy,
  shipToCountry,
  shipToState,
  shipToZipCode,
  storeOffer,
  width,
  disableAllOffers,
  handleAcceptOffer,
}) => {
  return (
    <OfferConfirmationStepper
      disabled={disabled}
      height={height}
      length={length}
      material={material}
      modelId={modelId}
      modelName={modelName}
      offer={offer}
      ownedBy={ownedBy}
      quantity={quantity}
      shipToCity={shipToCity}
      shipToCountry={shipToCountry}
      shipToState={shipToState}
      shipToZipCode={shipToZipCode}
      storeOffer={storeOffer}
      width={width}
      disableAllOffers={disableAllOffers}
      handleAcceptOffer={handleAcceptOffer}
    />
  )
}
OfferConfirmDialog.propTypes = {
  disabled: PropTypes.bool,
  height: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  material: PropTypes.string.isRequired,
  modelId: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  shipToCity: PropTypes.string.isRequired,
  shipToCountry: PropTypes.string.isRequired,
  shipToState: PropTypes.string.isRequired,
  shipToZipCode: PropTypes.string.isRequired,
  storeOffer: PropTypes.bool,
  width: PropTypes.string.isRequired,
  disableAllOffers: PropTypes.func,
  handleAcceptOffer: PropTypes.func,
}

OfferConfirmDialog.defaultProps = {
  disabled: false,
  storeOffer: false,
  disableAllOffers: () => {},
  handleAcceptOffer: () => {},
}

export default OfferConfirmDialog
