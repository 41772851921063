import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { size } from 'lodash'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core'
import {
  Carousel,
  CommonButton,
  ErrorMessage,
  ListingTechSpecs,
  ShippingInfo,
  StripeComponent,
} from 'components'
import { createAlert } from 'services/AlertService'
import { createListing } from 'services/ListingService'
import { createListingShippingInfo } from 'services/ListingShippingInfoService'
import { createOffer } from 'services/OfferService'
import { USER_PROFILE_ENDPOINT } from 'constants/apiUrls'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import {
  ERROR_SAVING_LISTING,
  LISTING_STATUS,
} from 'constants/listingConstants'
import { OFFER_STATUS } from 'constants/offerConstants'
import { ORDER_MADE } from 'constants/storeConstants'
import { updateCurrentUser } from 'actions/userActions'
import { USER_MENU } from 'constants/userConstants'

const useStyles = makeStyles(theme => ({
  btnActions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  btns: {
    width: '40%',
    marginRight: '20px',
  },
  dialog: {
    width: '100%',
    '& .MuiDialogContent-root': {
      maxWidth: '1328px',
      '@media (max-width:600px)': {
        padding: 0,
      },
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'inherit',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
    },
  },
  step: {
    '& .MuiStepIcon-text': {
      fill: theme.palette.text.white,
    },
  },
}))

const ListingStepper = ({ storeModel }) => {
  const classes = useStyles()
  const loggedUser = useSelector(state => state.userState.user)
  const { catalog } = useSelector(state => state.catalogState)
  const { category, description, images, name, ownedBy } = storeModel
  const { material_colors: materialColors = [], materials = [] } = catalog
  const [activeStep, setActiveStep] = useState(0)
  const [open, setOpen] = useState(false)
  const [valid, setValid] = useState(false)
  const [shippingInfo, setShippingInfo] = useState({})
  const [isCreatingListing, setIsCreatingListing] = useState(false)
  const [offerId, setOfferId] = useState('')
  const [listingId, setListingId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const steps = ['Update your information', 'Make payment']

  const validateShippingInfo = newShippingInfo => {
    const {
      companyAddress,
      country,
      firstName,
      lastName,
      phoneNumber,
      state,
      zipCode,
    } = newShippingInfo
    return (
      size(companyAddress) &&
      size(country) &&
      size(firstName) &&
      size(lastName) &&
      size(phoneNumber) &&
      size(state) &&
      size(zipCode)
    )
  }

  const updateShippingInfo = useCallback(newShippingInfo => {
    const isValid = validateShippingInfo(newShippingInfo)
    setShippingInfo(newShippingInfo)
    setValid(isValid)
  }, [])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setActiveStep(0)
  }

  const saveListing = async () => {
    let updateUserInfo = false
    let newUser = { ...loggedUser }
    const { email } = loggedUser
    const {
      city,
      companyAddress,
      companyName,
      country,
      phoneNumber,
      state,
      zipCode,
    } = shippingInfo
    const techSpecs = {
      ...storeModel.tech_specs,
      printNotes: storeModel.tech_specs.print_notes,
    }
    const modelDescription = {
      ...techSpecs,
      category,
      description,
      images,
      isSupportRequired: storeModel.is_support_required,
      modelId: storeModel.uid,
      name,
      materialColor: 'BLACK',
      quantity: 1,
    }
    try {
      setIsCreatingListing(true)
      const listingId = await createListing(
        loggedUser,
        ownedBy,
        modelDescription,
        zipCode,
        country,
        LISTING_STATUS.OFFER_ACCEPTED
      )
      setListingId(listingId)
      const offerId = await createOffer(storeModel.ownedBy, listingId, {
        deliveryDate: '',
        designFee: storeModel.design_fee,
        notes: '',
        newOffer: storeModel.offer,
        platformFee: storeModel.platform_fee,
        status: OFFER_STATUS.SUBMITTED,
        totalFee: storeModel.total_fee,
      })
      setOfferId(offerId)
      await createListingShippingInfo(listingId, { ...shippingInfo, email })
      let shippingInfoProvided = {
        shipping_info: {
          company_address: companyAddress,
          company_name: companyName,
          country,
          state,
          zip_code: zipCode,
        },
      }
      if (!loggedUser.shipping_info) {
        updateUserInfo = true
        newUser = {
          ...newUser,
          ...shippingInfoProvided,
        }
      }
      if (!loggedUser.phone_number) {
        updateUserInfo = true
        newUser = {
          ...newUser,
          phone_number: phoneNumber,
        }
      }
      if (loggedUser.shipping_info && !loggedUser.shipping_info.city) {
        updateUserInfo = true
        newUser = {
          ...newUser,
          shipping_info: {
            ...newUser.shipping_info,
            city,
          },
        }
      }
      if (loggedUser.shipping_info && !loggedUser.shipping_info.company_name) {
        updateUserInfo = true
        newUser = {
          ...newUser,
          shipping_info: {
            ...newUser.shipping_info,
            company_name: companyName,
          },
        }
      }
      if (updateUserInfo) {
        await updateCurrentUser(newUser)
      }
    } catch (err) {
      setErrorMessage(ERROR_SAVING_LISTING)
    } finally {
      setIsCreatingListing(false)
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleAccept = async () => {
    try {
      const listingInfo = {
        modelName: storeModel.name,
        uid: listingId,
      }
      await createAlert(loggedUser, storeModel.ownedBy, ORDER_MADE, listingInfo)
      setOpen(false)
      setActiveStep(0)
      navigate(USER_PROFILE_ENDPOINT(loggedUser.uid), {
        state: {
          collapsedListing: listingId,
          mylistings: true,
          option: USER_MENU.LIST,
        },
      })
    } catch (error) {
      setErrorMessage(ERROR_SAVING_LISTING)
    }
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid container>
              <Grid container item xs={12} sm={12} md={7} lg={7}>
                <Carousel slides={images} thumbnails={false} />
              </Grid>
              <Grid container item xs={12} sm={12} md={5} lg={5}>
                <ListingTechSpecs
                  model={storeModel}
                  materials={materials}
                  materialColors={materialColors}
                  readOnly={true}
                  updateTechSpecs={updateShippingInfo}
                />
              </Grid>
            </Grid>
            <Grid className={classes.margin} container>
              <Grid container item xs={12} sm={12} md={7} lg={7}>
                <ShippingInfo
                  loggedInUser={loggedUser}
                  updateShippingInfo={updateShippingInfo}
                />
              </Grid>
            </Grid>
          </>
        )
      case 1:
        return (
          <StripeComponent
            listingId={listingId}
            listingPrice={storeModel.total_fee}
            listingTotalPrice={storeModel.total_fee}
            modelId={storeModel.uid}
            modelName={storeModel.name}
            offerId={offerId}
            quantity={1}
            shippingFee={0}
            onSuccess={handleAccept}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  return (
    <div>
      <CommonButton
        buttonStyle={BUTTON_STYLE.PRIMARY}
        fullWidth={true}
        label="Buy"
        size={BUTTON_SIZE.SMALL}
        type={BUTTON_TYPE.SUBMIT}
        variant={BUTTON_VARIANT.OUTLINED}
        onClick={handleOpen}
      />
      <Dialog className={classes.dialog} open={open} onClose={handleClose}>
        <DialogTitle>Buy this Item</DialogTitle>
        <DialogContent>
          <ErrorMessage message={errorMessage} />
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel className={classes.step}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <div>{getStepContent(activeStep)}</div>
          </div>
        </DialogContent>
        <DialogActions>
          {
            <div className={classes.btnActions}>
              {activeStep === 0 && (
                <>
                  <div className={classes.btns}>
                    <CommonButton
                      buttonStyle={BUTTON_STYLE.CANCEL}
                      fullWidth={true}
                      label="Cancel"
                      variant={BUTTON_VARIANT.OUTLINED}
                      onClick={handleClose}
                    />
                  </div>
                  <div className={classes.btns}>
                    <CommonButton
                      buttonStyle={BUTTON_STYLE.PRIMARY}
                      disabled={!valid || isCreatingListing}
                      fullWidth={true}
                      label={'Next'}
                      variant={BUTTON_VARIANT.OUTLINED}
                      onClick={saveListing}
                    />
                  </div>
                </>
              )}
            </div>
          }
        </DialogActions>
      </Dialog>
    </div>
  )
}

ListingStepper.propTypes = {
  storeModel: PropTypes.object.isRequired,
}

export default ListingStepper
