import React from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
  listingRow: {
    width: '100%',
    borderBottom: 'white',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
  },
  tableCell: {
    padding: 0,
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const SkeletonRow = ({ columns }) => {
  const classes = useStyles()
  return (
    <TableRow className={classes.root}>
      {range(columns).map(index => (
        <TableCell key={index} className={classes.tableCell}>
          <Skeleton
            animation="wave"
            variant="rect"
            className={classes.listingRow}
            height={'52px'}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}

SkeletonRow.propTypes = {
  columns: PropTypes.number.isRequired,
}

export default SkeletonRow
