import React from 'react'
import PropTypes from 'prop-types'
import { Grid, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '248px',
    fontSize: '1rem',
    [theme.breakpoints.down(281)]: {
      marginTop: '40px',
      fontSize: '0.7rem',
    },
    [theme.breakpoints.down(361)]: {
      marginTop: '40px',
      fontSize: '0.875rem',
    },
  },
  printDropdown: {
    paddingRight: '8px',
    width: '100%',
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      width: 'inherit',
      '& .MuiSelect-root.MuiSelect-select': {
        paddingLeft: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
      },
    },
  },
  printLabel: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '8px',
  },
}))

const PrintSpeedDropDown = ({ printSpeed, handleSpeedUpdate }) => {
  const classes = useStyles()
  return (
    <Grid className={classes.formControl} container item xs={12}>
      <Grid className={classes.printLabel} item xs={9} md={8}>
        Print Speed (mm<sup>3</sup>/s):
      </Grid>
      <Grid className={classes.printDropdown} item xs={3} md={4}>
        <Select
          id="sprint-select"
          value={printSpeed}
          variant="outlined"
          onChange={handleSpeedUpdate}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={35}>35</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </Grid>
    </Grid>
  )
}

PrintSpeedDropDown.propTypes = {
  handleSpeedUpdate: PropTypes.func.isRequired,
  printSpeed: PropTypes.number.isRequired,
}

export default PrintSpeedDropDown
