import React from 'react'
import { Card, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px 0px',
    width: '100%',
    [theme.breakpoints.up(1525)]: {
      maxWidth: '1400px',
    },
  },
}))

const SkeletonCarousel = () => {
  const carouselClasses = useStyles()
  return (
    <Grid item xs={11} className={carouselClasses.container}>
      <Card>
        <Skeleton animation="wave" variant="rect" height={542} width="100%" />
      </Card>
    </Grid>
  )
}

export default SkeletonCarousel
