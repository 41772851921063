import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as linkify from 'linkifyjs'
import { navigate } from 'gatsby'
import { round, toNumber, sortBy } from 'lodash'
import { findPhoneNumbersInText } from 'libphonenumber-js'
import {
  Avatar,
  CircularProgress,
  TableCell,
  TableRow,
} from '@material-ui/core'
import {
  AcceptOrderModal,
  AverageReviewTooltip,
  CommonButton,
  OfferConfirmDialog,
  OfferTooltip,
} from 'components'
import { getShippingRateFees } from 'services/ShipEngineService'
import { makeStyles } from '@material-ui/core/styles'
import { LISTINGS_DETAILS_ENDPOINT } from 'constants/apiUrls'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import { LISTING_STATUS } from 'constants/listingConstants'
import { activeOfferLabelStatus } from 'constants/listingStatusHandler'
import { OFFERS_STATE, OFFER_STATUS } from 'constants/offerConstants'
import { ERROR_REJECTING_ORDER } from 'constants/storeConstants'
import { USER_PROFILE_ENDPOINT } from 'constants/apiUrls'
import { USER_MENU } from 'constants/userConstants'
import {
  calculateWeightByDimensions,
  getCountryState,
  UNITS,
} from 'constants/utils'

import { getListingById, updateListingStatus } from 'services/ListingService'
import { getListingShippingInfoByListingId } from 'services/ListingShippingInfoService'
import { updateOfferStatus } from 'services/OfferService'
import { getUserById } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'inline-flex',
    marginRight: '8px',
  },
  boldTitle: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.white,
  },
  boldText: {
    '& .MuiToolbar-root .MuiTypography-root, .MuiInputBase-root .MuiSelect-root': {
      fontWeight: 'bold',
    },
  },
  container: {
    height: '76vh',
    minHeight: '501px',
  },
  fullHeight: {
    height: '100%',
  },
  notesCell: {
    width: '200px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.white,
  },
  progress: {
    top: '46%',
    left: '47%',
  },
}))

const parseDate = timestamp => {
  let date = ''
  if (timestamp) {
    const getDate = timestamp.toDate().toISOString().split('T')[0]
    date = getDate.replace('-', '/').replace('-', '/')
  }
  return date
}
const MINIMUM_VALUE_DIMENSIONS_MILLIMETERS = '152.4'
const defaultMakerState = {
  isFetched: false,
  maker: null,
}

const parseShippingRateList = rateFeesList => {
  const carriers = rateFeesList.map(item => item.carrierId)
  const uniqueCarriers = new Set(carriers)
  let ratesByCarrier = []
  for (let carrier of uniqueCarriers) {
    const rates = rateFeesList.filter(
      item => item.carrierId === carrier && item.shippingAmount
    )
    ratesByCarrier = [...ratesByCarrier, rates]
  }
  return ratesByCarrier
}

const defaultAmountState = { amount: 0, currency: 'usd' }
const OfferRow = ({
  disableOnClick,
  fetchOffers,
  isMaker,
  listingId,
  modelId,
  offer,
  needLoad,
  modelName,
  offersList,
  offersState,
  quantity,
  onOfferAccepted,
  rejectOffer,
  setError,
  setParsedOffersList,
}) => {
  const classes = useStyles()
  const [disableOffer, setDisableOffer] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [shippingInfo, setShippingInfo] = useState(null)
  const [ownedBy, setOwnedBy] = useState('')
  const slant3dOffersFlag = useSelector(
    state => state.featureFlagState.flags['display_slant3d_offers']
  )
  const [makerState, setMakerState] = useState(defaultMakerState)
  const [shippingRateList, setShippingRateList] = useState(null)
  const [cheapestShippingOption, setCheapestShippingOption] = useState(null)

  useEffect(() => {
    const parseOfferList = () => {
      return offersList.filter(item => !item.is_from_slant3d)
    }
    if (!slant3dOffersFlag) {
      const newParsedOfferList = parseOfferList()
      setParsedOffersList(newParsedOfferList)
    } else {
      setParsedOffersList(offersList)
    }
  }, [setParsedOffersList, offersList, slant3dOffersFlag])

  useEffect(() => {
    const fetchShippingInfo = async () => {
      try {
        setIsLoading(true)
        let listing = await getListingById(listingId)
        const listingShippingInfo = await getListingShippingInfoByListingId(
          listingId
        )
        const { tech_specs } = listing
        let { height, length, width } = tech_specs
        if (height < MINIMUM_VALUE_DIMENSIONS_MILLIMETERS) {
          height = MINIMUM_VALUE_DIMENSIONS_MILLIMETERS
        }
        if (length < MINIMUM_VALUE_DIMENSIONS_MILLIMETERS) {
          length = MINIMUM_VALUE_DIMENSIONS_MILLIMETERS
        }
        if (width < MINIMUM_VALUE_DIMENSIONS_MILLIMETERS) {
          width = MINIMUM_VALUE_DIMENSIONS_MILLIMETERS
        }

        setShippingInfo({
          height,
          length,
          material: listing.tech_specs.material,
          shipToCity: listingShippingInfo.city,
          shipToCountry: listing.country,
          shipToState: listingShippingInfo.state,
          shipToZipCode: listing.zip_code,
          width,
        })
        setOwnedBy(listing.owned_by)
        setIsLoading(false)
      } catch (error) {
        setError(
          'An error occurred while trying to fetch the shipping info, please try again later'
        )
      }
    }

    if (listingId && !shippingInfo) {
      fetchShippingInfo()
    }
  }, [setError, listingId, shippingInfo])

  useEffect(() => {
    const retrieveMakerState = async () => {
      try {
        setIsLoading(true)
        const {
          username: { uid: makerId },
        } = offer
        const {
          email,
          first_name,
          isMaker,
          last_name,
          shipping_info: shippingInfo,
        } = await getUserById(makerId)

        setMakerState({
          isFetched: true,
          maker: {
            email,
            first_name,
            isMaker,
            last_name,
            shippingInfo,
          },
        })
        setIsLoading(false)
      } catch (error) {
        setError(
          'An error occurred while trying to retrieve maker state, please try again later'
        )
      }
    }

    if (!makerState.isFetched) {
      retrieveMakerState()
    }
  }, [setError, makerState, offer])

  useEffect(() => {
    const fetchShippingRateFees = async () => {
      try {
        const {
          height,
          length,
          width,
          material,
          shipToCountry,
          shipToState,
          shipToZipCode,
          shipToCity,
        } = shippingInfo

        const dimensions = {
          height: round(height / 10, 2),
          length: round(length / 10, 2),
          width: round(width / 10, 2),
          unit: UNITS.CENTIMETER,
        }
        const weightValue = calculateWeightByDimensions(
          material,
          length,
          height,
          width
        )
        const weight = {
          unit: UNITS.GRAM,
          value: weightValue,
        }

        const {
          maker: {
            shippingInfo: {
              city: shipFromCity,
              country: shipFromCountry,
              state: shipFromState,
              zip_code: shipFromZipCode,
            },
          },
        } = makerState

        const {
          countryCode: shipFromCountryCode,
          stateCode: shipFromStateCode,
        } = getCountryState(shipFromCountry, shipFromState)
        const {
          countryCode: shipToCountryCode,
          stateCode: shipToStateCode,
        } = getCountryState(shipToCountry, shipToState)

        const shippingRateFees = await getShippingRateFees(
          dimensions,
          shipFromCountryCode,
          shipFromZipCode,
          shipFromCity,
          shipFromStateCode,
          shipToCountryCode,
          shipToZipCode,
          shipToCity,
          shipToStateCode,
          weight
        )

        const shippingRateFeesWithTotalAmount = shippingRateFees.map(item => {
          const {
            confirmationAmount = { defaultAmountState },
            insuranceAmount = { defaultAmountState },
            otherAmount = { defaultAmountState },
            shippingAmount = { defaultAmountState },
          } = item
          const totalAmount = round(
            confirmationAmount.amount +
              insuranceAmount.amount +
              otherAmount.amount +
              shippingAmount.amount,
            2
          )
          return {
            ...item,
            confirmationAmount,
            insuranceAmount,
            otherAmount,
            shippingAmount,
            totalAmount,
          }
        })

        const parsedShippingRateList = parseShippingRateList(
          shippingRateFeesWithTotalAmount
        )

        const shippingRateListSorted = parsedShippingRateList.map(
          currentShippingRate => {
            currentShippingRate = sortBy(currentShippingRate, 'totalAmount')
            return currentShippingRate
          }
        )
        setCheapestShippingOption(shippingRateListSorted[0][0])
        setShippingRateList(shippingRateListSorted)
      } catch (error) {
        setError(
          'An error ocurred while fetching shipping info data, please try again later'
        )
      }
    }
    if (makerState.isFetched && !shippingRateList && shippingInfo) {
      fetchShippingRateFees()
    }
  }, [setError, shippingInfo, makerState, shippingRateList])

  const cleanNotes = note => {
    let finalNote = note

    const cleanFromPhoneNumbers = findPhoneNumbersInText(finalNote)
    cleanFromPhoneNumbers.forEach(phoneNumber => {
      const phone = note.slice(phoneNumber.startsAt, phoneNumber.endsAt)
      finalNote = finalNote.replace(phone, '')
    })

    const linksFound = linkify.find(finalNote)
    linksFound.forEach(linkFound => {
      if (linkFound.type === 'url' || linkFound.type === 'email') {
        finalNote = finalNote.replace(linkFound.value, '')
      }
    })
    return finalNote
  }

  const disableAllOffers = () => {
    setDisableOffer(true)
  }

  const checkStatus = offer =>
    offer.status === OFFER_STATUS.ACCEPTED ||
    offer.status === OFFER_STATUS.REJECTED

  const onClickRow = listingId => event => {
    if (!disableOnClick) {
      navigate(LISTINGS_DETAILS_ENDPOINT(listingId))
    }
  }

  const displayActiveStatus = listingStatus =>
    listingStatus ? activeOfferLabelStatus(listingStatus) : ''

  const showOfferState = offer => {
    const { listing = {} } = offer
    return offersState === OFFERS_STATE.ACTIVE
      ? displayActiveStatus(listing.status)
      : offer.status
  }

  const getListingOwner = listing => {
    let owner = {}
    if (listing) {
      owner = listing.user_info
      owner.uid = listing.owned_by
    }
    return owner
  }

  const handleRejectOrder = async offer => {
    setError('')
    try {
      await updateOfferStatus(offer.id, OFFER_STATUS.REJECTED)
      await updateListingStatus(offer.listingId, LISTING_STATUS.REJECTED)
      rejectOffer(offer)
    } catch (error) {
      setError(ERROR_REJECTING_ORDER)
    }
  }

  const handleFetchOffers = async () => {
    await fetchOffers()
  }

  const getListingProp = (listing, prop, defaultValue) => {
    let listingProp = defaultValue
    if (listing) {
      listingProp = listing[prop]
    }
    return listingProp
  }

  const redirectToReviews = maker => {
    navigate(USER_PROFILE_ENDPOINT(maker), {
      state: {
        option: USER_MENU.REVIEWS,
      },
    })
  }

  const displayOfferAmount = offer => {
    const {
      design_fee: designFee,
      offer: makerFee,
      platform_fee: platformFee,
    } = offer

    let totalWithoutShippingFee = round(
      toNumber(designFee) + toNumber(makerFee) + toNumber(platformFee),
      2
    )
    if (cheapestShippingOption) {
      totalWithoutShippingFee = round(
        totalWithoutShippingFee + toNumber(cheapestShippingOption.totalAmount),
        2
      )
    }

    return totalWithoutShippingFee
  }

  const shouldShowShippingFee = offerStatus =>
    offerStatus !== OFFER_STATUS.SUBMITTED

  return (
    <TableRow key={offer.id} onClick={onClickRow(offer.listingId)}>
      <TableCell align="left">{parseDate(offer.date)}</TableCell>
      <TableCell align="left">
        <Avatar className={classes.avatar} src={offer.username.photoUrl} />
        {isMaker ? (
          `${offer.username.first_name} ${offer.username.last_name}`
        ) : (
          <>
            <CommonButton
              buttonStyle={BUTTON_STYLE.LINK}
              fullWidth={false}
              label={`${offer.username.first_name} ${offer.username.last_name}`}
              size={BUTTON_SIZE.SMALL}
              onClick={() => redirectToReviews(offer.username.uid)}
            />
            <AverageReviewTooltip makerId={offer.username.uid} />
          </>
        )}
      </TableCell>
      <TableCell align="center">
        {!cheapestShippingOption && needLoad ? (
          <CircularProgress className={classes.spinner} size={40} />
        ) : (
          <>
            ${displayOfferAmount(offer).toFixed(2)}
            <OfferTooltip
              cheapestShippingOption={cheapestShippingOption}
              designFee={`${offer.design_fee}`}
              makerOffer={`${offer.offer}`}
              platformFee={`${offer.platform_fee}`}
              shippingFee={`${offer.shipping_fee}`}
              showShippingFee={shouldShowShippingFee(offer.status)}
              total={`${offer.total_fee}`}
              offer={offer}
              shippingInfo={shippingInfo}
            />
          </>
        )}
      </TableCell>
      <TableCell align="center">{offer.production_days}</TableCell>
      <TableCell align="left">{cleanNotes(offer.notes)}</TableCell>
      <TableCell>
        {isLoading ? (
          <CircularProgress className={classes.progress} />
        ) : isMaker ? (
          <>
            {offer.status === OFFER_STATUS.SUBMITTED && offer.from_store ? (
              <>
                <AcceptOrderModal
                  estimatedMaterial={getListingProp(
                    offer.listing,
                    'estimated_material',
                    {}
                  )}
                  estimatedMaterialPerFile={
                    offer.listing.estimated_material_per_file
                  }
                  estimatedPrintTime={getListingProp(
                    offer.listing,
                    'estimated_print_time',
                    {}
                  )}
                  initialOffer={offer.offer}
                  listingId={offer.listingId}
                  listingOwner={getListingOwner(offer.listing)}
                  modelName={getListingProp(offer.listing, 'model_name', '')}
                  offerId={offer.id}
                  zipCode={getListingProp(offer.listing, 'zip_code', '')}
                  handleFetchOffers={handleFetchOffers}
                />
                <CommonButton
                  buttonStyle={BUTTON_STYLE.CANCEL}
                  label="Reject"
                  size={BUTTON_SIZE.MEDIUM}
                  type={BUTTON_TYPE.SUBMIT}
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={() => handleRejectOrder(offer)}
                />
              </>
            ) : (
              <CommonButton
                buttonStyle={BUTTON_STYLE.PRIMARY}
                fullWidth={true}
                label={showOfferState(offer)}
                size={BUTTON_SIZE.MEDIUM}
                type={BUTTON_TYPE.SUBMIT}
                variant={BUTTON_VARIANT.OUTLINED}
              />
            )}
          </>
        ) : (
          shippingInfo && (
            <OfferConfirmDialog
              disabled={disableOffer || checkStatus(offer)}
              height={shippingInfo.height}
              length={shippingInfo.length}
              material={shippingInfo.material}
              offer={offer}
              modelId={modelId}
              ownedBy={ownedBy}
              modelName={modelName}
              quantity={quantity}
              shipToCity={shippingInfo.shipToCity}
              shipToCountry={shippingInfo.shipToCountry}
              shipToState={shippingInfo.shipToState}
              shipToZipCode={shippingInfo.shipToZipCode}
              width={shippingInfo.width}
              handleAcceptOffer={onOfferAccepted}
              disableAllOffers={disableAllOffers}
            />
          )
        )}
      </TableCell>
    </TableRow>
  )
}

export default OfferRow
