import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import googleIcon from 'assets/googleIcon.png'
import { ErrorMessage } from 'components'
import { signInOrUpWithGoogle } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  googleButton: {
    backgroundColor: '#FFF',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'none',
  },
}))

const GoogleButton = ({ disabled, setDisabled, title }) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')

  const handleGoogleSignInOrUp = async () => {
    try {
      setDisabled(true)
      await signInOrUpWithGoogle()
    } catch (error) {
      setErrorMessage(error.message)
    }
    setDisabled(false)
  }

  return (
    <>
      <p className="or-text">Or</p>
      <ErrorMessage message={errorMessage} />
      <Button
        className={classes.googleButton}
        disabled={disabled}
        fullWidth
        variant="outlined"
        type="button"
        onClick={() => handleGoogleSignInOrUp()}
      >
        <img alt="Google Logo" className="google-logo" src={googleIcon} />
        {title}
      </Button>
    </>
  )
}

GoogleButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default GoogleButton
