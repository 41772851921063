import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  Grid,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { CustomDialogTitle, ErrorMessage, IframeTracking } from 'components'
import { getShipmentTracking } from 'services/ShipEngineService'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
    '& .MuiDialogTitle-root': {
      padding: '10px 0px',
    },
  },
  container: {
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      zIndex: '3',
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  customBackGround: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.white,
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      minWidth: '700px',
      padding: '20px',
    },
  },
}))

const TrackingModal = ({
  labelId,
  open,
  setOpenTrackDialog,
  carrierCode,
  shipper,
  trackingId,
}) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [value, setValue] = useState(0)

  const [trackingInformation, setTrackingInformation] = useState({})

  useEffect(() => {
    const fetchTracking = async () => {
      setIsFetching(true)
      try {
        const trackingInformation = await getShipmentTracking(labelId)
        setTrackingInformation(trackingInformation)
        setIsFetching(false)
      } catch (e) {
        setErrorMessage(
          'An error occurred while fetching the tracking information, please try again later.'
        )
      }
    }
    fetchTracking()
  }, [labelId])

  const handleClose = () => {
    setOpenTrackDialog(false)
  }

  const handleTabChange = async (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Dialog className={classes.dialog} open={open} onClose={handleClose}>
        {trackingInformation && (
          <>
            <div className={classes.boldTitle}>
              <ErrorMessage message={errorMessage} />
              <CustomDialogTitle onClose={handleClose}>
                Tracking Shipping
              </CustomDialogTitle>
            </div>
            <DialogContent>
              <Tabs
                className={classes.tab}
                onChange={handleTabChange}
                value={value}
              >
                <Tab key={0} label="Tracking by label" />
                <Tab key={1} label="Tracking Page" />
              </Tabs>
              {value === 0 && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography component="legend">
                        Tracking number
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="legend">
                        {trackingInformation.tracking_number}
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography component="legend">Status</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="legend">
                        {trackingInformation.status_code}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="legend">Description</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="legend">
                        {trackingInformation.status_description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography component="legend">Ship date</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="legend">
                        {trackingInformation.ship_date}
                      </Typography>
                    </Grid>
                  </Grid>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="listing table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.customBackGround}>
                            Date
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.customBackGround}
                          >
                            description
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.customBackGround}
                          >
                            city locality
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.customBackGround}
                          >
                            state province
                          </TableCell>
                          <TableCell className={classes.customBackGround} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!isFetching &&
                          trackingInformation &&
                          trackingInformation.events &&
                          trackingInformation.events.map(trackingEvent => (
                            <>
                              <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="listing table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        className={classes.customBackGround}
                                      >
                                        {trackingEvent.events.occurred_at}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.customBackGround}
                                      >
                                        {trackingEvent.events.description}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.customBackGround}
                                      >
                                        {trackingEvent.events.city_locality}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className={classes.customBackGround}
                                      >
                                        {trackingEvent.events.state_province}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                </Table>
                              </TableContainer>
                            </>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              {value === 1 && (
                <IframeTracking
                  carrierCode={carrierCode}
                  shipper={shipper}
                  trackingId={trackingId}
                />
              )}
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  )
}

TrackingModal.propTypes = {
  labelId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpenTrackDialog: PropTypes.func,
}
TrackingModal.defaultProps = {
  setOpenTrackDialog: () => {},
}

export default TrackingModal
