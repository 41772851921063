import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: ' calc(100vh - 140px)',
    padding: '28px',
    '@media (max-height:820px)': {
      minHeight: '650px',
    },
  },
  infoContainer: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
  },
  menuContainer: {
    height: ' calc(100vh - 540px)',
    display: 'flex',
    alignItems: 'flex-end',
    '@media (max-height:820px)': {
      minHeight: '300px',
    },
  },
  userMenu: {
    width: '100%',
  },
  fieldLoading: {
    width: '100%',
    margin: '8px',
  },
}))

const SkeletonUserProfileInfo = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.infoContainer}>
        <Skeleton animation="wave" variant="circle" width={200} height={200} />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.fieldLoading}
          height={'2rem'}
        />
        <Skeleton
          animation="wave"
          variant="rect"
          className={classes.fieldLoading}
          height={'1.5rem'}
        />
        {/* Here we can add the user description */}
      </div>
      <div className={classes.menuContainer}>
        <div className={classes.userMenu}>
          {[...Array(4)].map((_, index) => (
            <Skeleton
              animation="wave"
              className={classes.fieldLoading}
              height={'2rem'}
              key={index}
              variant="rect"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SkeletonUserProfileInfo
