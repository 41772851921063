import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { round } from 'lodash'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { OFFER_STATUS } from 'constants/offerConstants'
import { makeStyles } from '@material-ui/core/styles'
import { CommonButton, InteractiveTutorial, OfferForm } from 'components'

import { MAKE_OFFER } from 'constants/alertConstants'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import {
  MAKE_AN_OFFER,
  TUTORIAL_NAMES,
} from 'constants/interactiveTutorialConstants'
import { createAlert } from 'services/AlertService'
import { getListingShippingInfoByListingId } from 'services/ListingShippingInfoService'
import { sendNotification } from 'services/MailService'
import { createOffer } from 'services/OfferService'
import { getUserById } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      minHeight: '420px',
    },
    '& .MuiGrid-spacing-xs-2': {
      margin: '0px',
    },
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
  vertialAlign: {
    display: 'flex',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
}))

const MakeOfferModal = ({
  estimatedMaterial,
  estimatedMaterialPerFile,
  estimatedPrintTime,
  initialOffer,
  listingId,
  listingOwner,
  modelName,
  changeStatus,
  zipCode,
}) => {
  const { user: loggedUser } = useSelector(state => state.userState)
  const classes = useStyles()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState(false)
  const [shippingInfoState, setShippingInfoState] = useState({})
  const [offer, setOffer] = useState({
    designFee: 0,
    newOffer: initialOffer,
    deliveryDate: '',
    notes: '',
    platformFee: 0,
    productionDays: 0,
    shippingFee: 0,
    status: '',
  })

  const getListingShippingInfo = async listingId => {
    try {
      const shippingInfo = await getListingShippingInfoByListingId(listingId)
      setShippingInfoState(shippingInfo)
    } catch (error) {
      setError(
        'Something went wrong when trying to get the shipping information. Please try again later'
      )
    }
  }

  const handleClose = () => {
    setOpen(false)
    setOffer({ ...offer, newOffer: 0, notes: '', productionDays: 0 })
  }

  const handleOpen = async event => {
    event.stopPropagation()
    if (!update) {
      getListingShippingInfo(listingId)
      setUpdate(true)
    }
    setOpen(true)
  }

  const stop = event => {
    event.stopPropagation()
  }

  const sendNofificationToOwner = async () => {
    try {
      const listingURL = `${process.env.GATSBY_API_URL}/listings/${listingId}`

      const { email, first_name, last_name } = await getUserById(
        listingOwner.uid
      )

      const ownerName = `${first_name} ${last_name}`
      const message = 'Someone made a bid on your listing'

      await sendNotification(email, ownerName, message, modelName, listingURL)
    } catch (error) {
      setError('There was an error sending the notification.')
    }
  }

  const createNewOffer = async () => {
    setError('')
    setLoading(true)
    try {
      const newMakerFee = parseFloat(offer.newOffer)
      const shippingFee = parseFloat(offer.shippingFee)
      const productionDays = parseFloat(offer.productionDays)
      const platformFee = parseFloat(offer.platformFee)
      const designFee = parseFloat(offer.designFee)
      const newTotalFee = round(
        newMakerFee + designFee + shippingFee + platformFee,
        2
      )
      const newOffer = {
        ...offer,
        newOffer: newMakerFee,
        shippingFee,
        productionDays,
        totalFee: newTotalFee,
      }
      newOffer.status = OFFER_STATUS.SUBMITTED
      await createOffer(loggedUser, false, listingId, newOffer)
      await sendNofificationToOwner()
      const createdBy = {
        first_name: loggedUser.first_name,
        last_name: loggedUser.last_name,
        uid: loggedUser.uid,
      }
      const createdFor = {
        first_name: listingOwner.first_name,
        last_name: listingOwner.last_name,
        uid: listingOwner.uid,
      }
      const listingAlert = {
        modelName,
        uid: listingId,
      }
      await createAlert(createdBy, createdFor, MAKE_OFFER, listingAlert)
    } catch (error) {
      setError(error.message)
    }
    setLoading(false)
    handleClose()
    changeStatus()
  }

  const validateFields = () =>
    offer.newOffer !== '' &&
    offer.productionDays > 0 &&
    offer.shippingFee !== ''

  return (
    <>
      {loggedUser.isMaker && loggedUser.uid !== listingOwner.uid && (
        <CommonButton
          buttonStyle={BUTTON_STYLE.PRIMARY}
          fullWidth={false}
          label="Make Offer"
          variant={BUTTON_VARIANT.OUTLINED}
          onClick={handleOpen}
        />
      )}
      <Dialog
        className={classes.dialog}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        onClick={stop}
      >
        <DialogTitle className={classes.boldTitle}>Make an Offer</DialogTitle>
        <DialogContent className={classes.vertialAlign}>
          {error && <div>{error}</div>}
          {loading && <CircularProgress className={classes.progress} />}
          <OfferForm
            estimatedMaterial={estimatedMaterial}
            estimatedMaterialPerFile={estimatedMaterialPerFile}
            estimatedPrintTime={estimatedPrintTime}
            listingOwner={listingOwner}
            offer={offer}
            setOffer={setOffer}
            shippingInfoState={shippingInfoState}
            zipCode={zipCode}
          />
        </DialogContent>
        <DialogActions id="submit-offer">
          <CommonButton
            buttonStyle={BUTTON_STYLE.CANCEL}
            fullWidth={false}
            label="Cancel"
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={handleClose}
          />
          <CommonButton
            buttonStyle={BUTTON_STYLE.PRIMARY}
            disabled={!validateFields() || loading}
            fullWidth={false}
            label="Submit"
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={createNewOffer}
          />
        </DialogActions>
        {open &&
          loggedUser &&
          !loggedUser.isAnonymous &&
          loggedUser.tutorial_state && (
            <InteractiveTutorial
              showTutorial={!loggedUser.tutorial_state.makeOffer}
              steps={MAKE_AN_OFFER}
              tutorial={TUTORIAL_NAMES.MAKE_OFFER}
            />
          )}
      </Dialog>
    </>
  )
}

MakeOfferModal.propTypes = {
  estimatedMaterial: PropTypes.object,
  estimatedMaterialPerFile: PropTypes.array,
  estimatedPrintTime: PropTypes.object,
  initialOffer: PropTypes.number,
  listingId: PropTypes.string.isRequired,
  listingOwner: PropTypes.object.isRequired,
  modelName: PropTypes.string.isRequired,
  changeStatus: PropTypes.func,
  zipCode: PropTypes.string.isRequired,
}

MakeOfferModal.defaultProps = {
  estimatedMaterial: {},
  estimatedMaterialPerFile: [],
  estimatedPrintTime: {},
  initialOffer: 0,
  changeStatus: () => {},
}

export default MakeOfferModal
