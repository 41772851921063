import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  intersectionBy,
  intersectionWith,
  isEqual,
  isNil,
  kebabCase,
  round,
  size,
} from 'lodash'
import { navigate } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PublishIcon from '@material-ui/icons/Publish'
import {
  CommonButton,
  DesignForm,
  ErrorMessage,
  FeatureFlag,
  InteractiveTutorial,
  LinearProgressDialog,
  StoreForm,
} from 'components'

import { updateToRefreshTrue } from 'actions/algoliaAction'
import { updateCurrentUser } from 'actions/userActions'
import {
  createDesign,
  generateDesignId,
  getDesign,
  getLatestDesignByName,
  updateDesign,
  updateDesignByFields,
  updateDesignCreateSection,
} from 'services/DesignService'
import { uploadFile } from 'services/StorageService'
import { createSeveralDesignsStore } from 'services/StoreService'
import {
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'

import { MODEL_DETAILS_ENDPOINT } from 'constants/apiUrls'
import {
  INCH_TO_MM,
  INCHES_UNIT,
  MILLIMETER_UNIT,
} from 'constants/modelConstants'
import {
  UPLOAD_DESIGN,
  TUTORIAL_NAMES,
} from 'constants/interactiveTutorialConstants'
import { PLATFORM_FEE, DESIGN_FEE } from 'constants/platformFeeConstant'
import { ERROR_ADDING_STORE } from 'constants/storeConstants'
import {
  generateWeigthsByMaterial,
  FILE_TYPES_TO_UPLOAD,
  MATERIALS,
  renameFilesWithUniqueName,
} from 'constants/utils'
const useStyles = makeStyles(theme => ({
  btn: {
    width: '100%',
    height: '100%',
  },
  btnOpen: {
    width: 'auto',
    height: '100%',
    '& .MuiButtonBase-root': {
      marginBottom: '0',
      '& .MuiButton-label': {
        marginBottom: '0',
      },
    },
  },
  dialog: {
    '& .MuiDialogContent-root': {
      '@media (max-width:600px)': {
        padding: 0,
      },
    },

    '& .MuiDialog-paper': {
      margin: 0,
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'inherit',
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
    },
  },
  footer: {
    width: '50%',
    textAlign: 'center',
    padding: '20px 0',
    margin: '0 auto',
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  footerContent: {
    display: 'flex',
    width: '100%',
  },
  header: {
    margin: '0',
    paddingLeft: '24px',
    paddingTop: '20px',
    '& h2': {
      margin: 0,
      paddingLeft: '24px',
    },
  },
  left: {
    float: 'left',
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
  stepper: {
    padding: '0',
  },
  step: {
    '& .MuiStepLabel-iconContainer .MuiSvgIcon-root .MuiStepIcon-text': {
      fill: theme.palette.text.white,
    },
  },
}))
const DEFAULT_MEASUREMENT = (0.1).toFixed(3)
const defaultDesign = Object.freeze({
  additionalComponents: '',
  additionalTags: '',
  category: '',
  description: '',
  designs: [],
  estimatedMaterial: {},
  estimatedPrintTime: {},
  images: [],
  isPublic: true,
  isSupportRequired: false,
  name: '',
  printNotes: '',
  properties: {
    depth: DEFAULT_MEASUREMENT,
    height: DEFAULT_MEASUREMENT,
    material: MATERIALS.PLA,
    width: DEFAULT_MEASUREMENT,
  },
})
const MEASURES = ['width', 'height', 'depth']

const defaultStoreName = {
  populated: false,
  storeName: '',
}

const recoverIdAndFileName = file => {
  const [, , , , , fileId, fileName] = file.split('/')
  return { file_id: fileId, file_name: fileName }
}

const verifyIfHasAllEstimationsAlready = (
  prevSTLFiles,
  newSTLFiles,
  materialEstimations
) => {
  const parsedPrevSTLFiles = prevSTLFiles.map(file =>
    recoverIdAndFileName(file)
  )
  const parsedNewSTLFiles = newSTLFiles.map(file => recoverIdAndFileName(file))

  const intersectionFiles = intersectionWith(
    parsedPrevSTLFiles,
    parsedNewSTLFiles,
    isEqual
  )

  if (size(intersectionFiles) === size(parsedNewSTLFiles)) {
    const estimations = intersectionBy(
      materialEstimations,
      intersectionFiles,
      'file_name'
    )
    const newWeightsByMaterial = generateWeigthsByMaterial(estimations)
    return {
      hasAllEstimations: size(estimations) === size(parsedNewSTLFiles),
      weightsByMaterial: newWeightsByMaterial,
    }
  }

  return { hasAllEstimations: false }
}

const DesignModal = ({ prevDesign }) => {
  const {
    user: loggedUser,
    isLoading: loggedUserLoading,
    isLoggingOut: loggingOut,
  } = useSelector(state => state.userState)
  const classes = useStyles()
  const { catalog, error: catalogError } = useSelector(
    state => state.catalogState
  )
  const { categories = [], materials = [] } = catalog
  const newDesign = !isNil(prevDesign)
    ? {
        additionalComponents: prevDesign.additionalComponents
          ? prevDesign.additionalComponents
          : '',
        additionalTags: prevDesign.additionalTags
          ? prevDesign.additionalTags
          : '',
        category: prevDesign.category,
        description: prevDesign.description,
        designs: prevDesign.designs,
        estimatedMaterial: prevDesign.estimated_material,
        estimatedPrintTime: prevDesign.estimated_print_time,
        formattedName: prevDesign.formatted_name,
        imageFiles: prevDesign.images,
        isPublic: prevDesign.is_public,
        isSupportRequired: prevDesign.is_support_required,
        name: prevDesign.name,
        printNotes: prevDesign.tech_specs.print_notes,
        properties: {
          depth: prevDesign.tech_specs.depth,
          height: prevDesign.tech_specs.height,
          material: prevDesign.tech_specs.material,
          width: prevDesign.tech_specs.width,
        },
        standardName: prevDesign.standard_name,
      }
    : defaultDesign

  const initialPrices = () => {
    const prices = {}
    materials.forEach(material => {
      prices[material.name] = ''
    })
    return prices
  }

  const [activeStep, setActiveStep] = useState(0)
  const [design, setDesign] = useState({})
  const [errorMessage, setErrorMessage] = useState(catalogError)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openProgress, setOpenProgress] = useState(false)
  const [prices, setPrices] = useState(initialPrices())
  const [storeNameState, setStoreNameState] = useState(defaultStoreName)
  const [uploading, setUploading] = useState(false)
  const [unit, setUnit] = useState(MILLIMETER_UNIT)

  useEffect(() => {
    if (loggedUser && !storeNameState.populated && !loggedUserLoading) {
      const storeName =
        loggedUser.store_name || `${loggedUser.first_name}'s store`
      setStoreNameState({
        populated: true,
        storeName,
      })
    }
  }, [loggedUser, loggedUserLoading, storeNameState])

  const handleCloseProgress = () => {
    setOpenProgress(false)
  }

  const handleOpenProgress = () => {
    setOpenProgress(true)
  }

  const handleClose = () => {
    if (activeStep === 1) {
      navigate(MODEL_DETAILS_ENDPOINT(design.formattedName), {
        state: { design },
      })
    }
    setDesign(newDesign)
    setPrices(initialPrices())
    setOpen(false)
    setOpenProgress(false)
    setUploading(false)
    setActiveStep(0)
  }
  const steps = ['Upload a design', 'Add it to your store']

  const getFormattedName = sameNameDesign => {
    const lastNumberSuffix = sameNameDesign.formatted_name.split('-').pop()
    const lastNumber = Number(lastNumberSuffix)
    const numberSufix = Number.isNaN(lastNumber) ? '1' : lastNumber + 1
    return `${sameNameDesign.standard_name}-${numberSufix}`
  }

  const convertToMillimeters = design => {
    const { properties } = design
    if (unit === INCHES_UNIT) {
      setUnit(MILLIMETER_UNIT)
      for (const measure of MEASURES) {
        properties[measure] = properties[measure] * INCH_TO_MM
      }
    }

    for (const measure of MEASURES) {
      properties[measure] = parseFloat(properties[measure]).toFixed(3)
    }
  }

  const updateInformationUploadProgress = progressInformation => {
    setFiles(files => ({
      ...files,
      [progressInformation.name]: {
        progress: progressInformation.progress,
        isNew: files.isNew,
      },
    }))
  }

  const generateFileUploadProgressReport = designFiles => {
    let arrayFiles = {}
    designFiles.forEach(
      x =>
        (arrayFiles = {
          ...arrayFiles,
          [x.name]: { progress: 100, isNew: !!x.path },
        })
    )
    setFiles(arrayFiles)
  }

  const handleCreateDesign = async () => {
    let model
    setLoading(true)
    setErrorMessage('')
    try {
      let { imageFiles, designFiles, name } = design
      const formattedName = kebabCase(name)

      const designId = generateDesignId()

      design.standardName = formattedName
      convertToMillimeters(design)
      const sameNameDesign = await getLatestDesignByName(formattedName)

      if (sameNameDesign) {
        design.formattedName = getFormattedName(sameNameDesign)
      } else {
        design.formattedName = formattedName
      }
      imageFiles = renameFilesWithUniqueName(
        imageFiles,
        FILE_TYPES_TO_UPLOAD.IMAGE
      )
      // Uploading Images
      const images = await Promise.all(
        imageFiles.map(async file => {
          return uploadFile(
            `users/${loggedUser.uid || 'unknown'}/designs/${designId}/images`,
            file.formattedName,
            file
          )
        })
      )
      const thumbnail = await uploadFile(
        `users/${loggedUser.uid || 'unknown'}/designs/${designId}/thumbnail`,
        imageFiles[0].formattedName,
        imageFiles[0],
        true,
        null,
        true
      )

      design.hasEstimationForAllParts = false
      design.images = images
      design.thumbnail = thumbnail
      model = await createDesign(design, designId, loggedUser)

      designFiles = renameFilesWithUniqueName(
        designFiles,
        FILE_TYPES_TO_UPLOAD.DESIGN
      )
      // Uploading Designs
      generateFileUploadProgressReport(designFiles)

      const designs = await Promise.all(
        designFiles.map(async file => {
          const uniqueId = uuidv4()
          setOpenProgress(true)
          setUploading(true)
          return uploadFile(
            `users/${
              loggedUser.uid || 'unknown'
            }/designs/${designId}/model-files/${uniqueId}`,
            file.formattedName,
            file,
            false,
            updateInformationUploadProgress
          )
        })
      )
      setOpenProgress(false)
      design.designs = designs
      model = await updateDesignCreateSection(design, model.uid, loggedUser)
      model.submission_date = { seconds: new Date().getTime() / 1000 }

      setDesign({ ...design, uid: model.uid })
      updateToRefreshTrue()
      if (loggedUser.isMaker) {
        setActiveStep(1)
      } else {
        handleClose()
      }
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      if (loggedUser.isMaker) {
        setActiveStep(1)
      } else {
        navigate(MODEL_DETAILS_ENDPOINT(design.formattedName), {
          state: { model },
        })
      }
      setLoading(false)
    }
  }

  const handleCreateDesignsStore = modelId => {
    const storeDesigns = materials
      .filter(
        material => prices[material.name] > 0 && prices[material.name] !== ''
      )
      .map(material => {
        const platformFree = round(
          prices[material.name] * (PLATFORM_FEE / 100),
          2
        )
        return {
          ...design,
          modelId,
          material: material.name,
          design_fee: DESIGN_FEE,
          platform_fee: platformFree,
          offer: prices[material.name],
          total_fee: round(
            DESIGN_FEE + prices[material.name] + platformFree,
            2
          ),
        }
      })
    return storeDesigns
  }

  const handleOpen = () => {
    setDesign(newDesign)
    setOpen(true)
  }

  const deleteUnusedMaterialEstimation = async (modelId, designs) => {
    const { material_estimations } = await getDesign(modelId)
    const materialEstimationsUpdated = material_estimations.filter(estimation =>
      designs.some(designPath =>
        designPath.endsWith(`/${estimation.file_name}`)
      )
    )

    await updateDesignByFields(modelId, {
      material_estimations: materialEstimationsUpdated,
    })
  }

  const handleUpdateDesign = async () => {
    let model
    setLoading(true)
    setErrorMessage('')
    try {
      let {
        imageFiles,
        designFiles,
        name,
        has_estimation_for_all_parts: hasEstimationForAllParts = false,
      } = design
      const {
        uid: modelId,
        designs: prevDesigns,
        material_estimations: materialEstimations,
      } = prevDesign
      const formattedName = kebabCase(name)
      design.previousImages = prevDesign.images
      design.previousThumbnails = prevDesign.thumbnail
      design.previousDesigns = design.designs

      if (formattedName !== prevDesign.standard_name) {
        const sameNameDesign = await getLatestDesignByName(formattedName)
        if (sameNameDesign) {
          design.formattedName = getFormattedName(sameNameDesign)
        } else {
          design.formattedName = formattedName
        }
        design.standardName = formattedName
      }

      imageFiles = renameFilesWithUniqueName(
        imageFiles,
        FILE_TYPES_TO_UPLOAD.IMAGE
      )

      const images = await Promise.all(
        imageFiles.map(async file => {
          if (file.path) {
            return uploadFile(
              `users/${loggedUser.uid || 'unknown'}/designs/${modelId}/images`,
              file.formattedName,
              file
            )
          } else {
            const imageUrl = prevDesign.images.filter(image =>
              image.includes(file.name)
            )
            return imageUrl[0]
          }
        })
      )

      const thumbnail = await uploadFile(
        `users/${loggedUser.uid || 'unknown'}/designs/${modelId}/thumbnail`,
        imageFiles[0].formattedName,
        imageFiles[0],
        true,
        null,
        true
      )

      designFiles = renameFilesWithUniqueName(
        designFiles,
        FILE_TYPES_TO_UPLOAD.DESIGN
      )

      let designFilesUpdated = false

      generateFileUploadProgressReport(designFiles)

      const designs = await Promise.all(
        designFiles.map(async file => {
          if (file.path) {
            designFilesUpdated = true
            const uniqueId = uuidv4()
            setOpenProgress(true)
            setUploading(true)
            return uploadFile(
              `users/${
                loggedUser.uid || 'unknown'
              }/designs/${modelId}/model-files/${uniqueId}`,
              file.formattedName,
              file,
              false,
              updateInformationUploadProgress
            )
          } else {
            const designUrl = prevDesign.designs.filter(design =>
              design.includes(file.name)
            )
            return designUrl[0]
          }
        })
      )
      setOpenProgress(false)
      await deleteUnusedMaterialEstimation(modelId, designs)

      let newHasEstimationForAllParts =
        hasEstimationForAllParts && !designFilesUpdated

      if (size(prevDesigns) > size(designs)) {
        const {
          hasAllEstimations,
          weightsByMaterial,
        } = verifyIfHasAllEstimationsAlready(
          prevDesigns,
          designs,
          materialEstimations
        )
        newHasEstimationForAllParts = hasAllEstimations
        design.weightsByMaterial = weightsByMaterial
      }

      design.images = images
      design.thumbnail = thumbnail

      design.designs = designs
      design.hasEstimationForAllParts = newHasEstimationForAllParts
      convertToMillimeters(design)

      model = await updateDesign(design, modelId, loggedUser)
      model.ownedBy = prevDesign.ownedBy
      model.submission_date = prevDesign.submission_date
      model.images = images
      model.designs = designs
      updateToRefreshTrue()
      handleClose()
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      if (!model) {
        model = prevDesign
      }
      navigate(MODEL_DETAILS_ENDPOINT(design.formattedName), {
        state: { model },
      })
      setLoading(false)
    }
  }

  const handleCurrentUnit = currentUnit => {
    setUnit(currentUnit)
  }

  const validateFields = () => {
    const { category, description, designFiles, imageFiles, name } = design
    return (
      category &&
      description &&
      size(designFiles) > 0 &&
      size(imageFiles) > 0 &&
      name
    )
  }

  const addToStore = async () => {
    setLoading(true)
    try {
      handleUpdateStoreName()
      const storeDesigns = handleCreateDesignsStore(design.uid)
      if (size(storeDesigns) > 0) {
        await createSeveralDesignsStore(storeDesigns, loggedUser)
      }
    } catch (error) {
      setErrorMessage(ERROR_ADDING_STORE)
    } finally {
      setLoading(false)
      navigate(MODEL_DETAILS_ENDPOINT(design.formattedName), {
        state: { design },
      })
      handleClose()
    }
  }

  const updateStoreName = value => {
    setStoreNameState({
      ...storeNameState,
      storeName: value,
    })
  }

  const handleUpdateStoreName = () => {
    if (!loggedUser.store_name && size(storeNameState.storeName) > 0) {
      const params = { store_name: storeNameState.storeName }
      updateCurrentUser({ ...loggedUser, ...params }, false, params)
    }
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <DesignForm
            categories={categories}
            design={design}
            materials={materials}
            setCurrentUnit={handleCurrentUnit}
            setDesign={setDesign}
            prices={prices}
            setPrices={setPrices}
            initialPrices={initialPrices}
          />
        )
      case 1:
        return (
          <StoreForm
            currentStoreName={storeNameState.storeName}
            hasStoreName={!!loggedUser.store_name}
            materials={materials}
            prices={prices}
            updateStoreName={updateStoreName}
            setPrices={setPrices}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  const disableAddToStoreButton =
    loading || loggedUserLoading || size(storeNameState.storeName) === 0

  return (
    <>
      {!loggingOut && loggedUser && !loggedUser.isAnonymous && (
        <div className={classes.btnOpen} id="uploadDesign">
          <CommonButton
            buttonStyle={
              isNil(prevDesign) ? BUTTON_STYLE.PRIMARY : BUTTON_STYLE.ACCENT
            }
            fullWidth={isNil(prevDesign)}
            label={isNil(prevDesign) ? 'Upload Design' : 'Edit'}
            size={BUTTON_SIZE.SMALL}
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={handleOpen}
          />
        </div>
      )}
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="title"
      >
        <FeatureFlag
          activeComponent={<></>}
          inactiveComponent={
            <LinearProgressDialog
              filesToUpload={files}
              open={openProgress}
              handleCloseProgress={handleCloseProgress}
            />
          }
          flag="progress_dialog"
        />
        <DialogTitle id="title">
          <div className={classes.header}>
            <div className={classes.left}>
              {isNil(prevDesign) ? (
                <h2>Upload Design</h2>
              ) : (
                <h2>Edit Design</h2>
              )}
            </div>
            {uploading && (
              <IconButton onClick={handleOpenProgress}>
                <PublishIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <ErrorMessage message={errorMessage} />
          {loggedUser.isMaker && isNil(prevDesign) ? (
            <>
              <Stepper
                className={classes.stepper}
                activeStep={activeStep}
                alternativeLabel
              >
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel className={classes.step}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                <div>{getStepContent(activeStep)}</div>
              </div>
            </>
          ) : (
            <DesignForm
              categories={categories}
              design={design}
              materials={materials}
              setCurrentUnit={handleCurrentUnit}
              setDesign={setDesign}
            />
          )}
        </DialogContent>
        <DialogActions>
          <div className={classes.footer}>
            <div className={classes.footerContent} id="upload-design">
              <div className={classes.btn}>
                <CommonButton
                  buttonStyle={BUTTON_STYLE.CANCEL}
                  disabled={loading}
                  fullWidth={true}
                  label={activeStep === 0 ? 'Cancel' : 'Skip this Step'}
                  size={BUTTON_SIZE.LARGE}
                  type={BUTTON_TYPE.SUBMIT}
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={handleClose}
                />
              </div>
              <div className={classes.btn}>
                {activeStep === 0 ? (
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.PRIMARY}
                    disabled={!validateFields() || loading}
                    fullWidth={true}
                    label={isNil(prevDesign) ? 'Done' : 'Save'}
                    size={BUTTON_SIZE.LARGE}
                    type={BUTTON_TYPE.SUBMIT}
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={
                      isNil(prevDesign)
                        ? handleCreateDesign
                        : handleUpdateDesign
                    }
                  />
                ) : (
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.PRIMARY}
                    disabled={disableAddToStoreButton}
                    fullWidth={true}
                    label="Add to Store"
                    size={BUTTON_SIZE.LARGE}
                    type={BUTTON_TYPE.SUBMIT}
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={addToStore}
                  />
                )}
              </div>
              {loading && (
                <CircularProgress size={48} className={classes.progress} />
              )}
            </div>
          </div>
        </DialogActions>
        <div>
          {loggedUser &&
            !loggedUser.isAnonymous &&
            loggedUser.tutorial_state && (
              <InteractiveTutorial
                showTutorial={!loggedUser.tutorial_state.uploadDesign}
                steps={UPLOAD_DESIGN}
                tutorial={TUTORIAL_NAMES.UPLOAD_DESIGN}
              />
            )}
        </div>
      </Dialog>
    </>
  )
}
DesignModal.propTypes = {
  prevDesign: PropTypes.object,
}
DesignModal.defaultProps = {
  prevDesign: null,
}

export default DesignModal
