import { USPS_LINK, UPS_LINK, FEDEX_LINK } from './shippersLinksConstants.js'

export const shipperLink = (shipper, trackingId) => {
  switch (shipper) {
    case 'usps':
      return `${USPS_LINK}${trackingId}`
    case 'ups':
      return `${UPS_LINK}${trackingId}`
    case 'fedex':
      return `${FEDEX_LINK}${trackingId}`
    default:
      return ''
  }
}
