import React from 'react'
import Rating from '@material-ui/lab/Rating'
import PropTypes from 'prop-types'
import { Grid, TextField, Typography } from '@material-ui/core'

const DesignReviewForm = ({ designReview, setDesignReview }) => {
  const handleChangeDesign = prop => (event, newValue) => {
    if (prop === 'notes') {
      const {
        target: { value },
      } = event
      setDesignReview({ ...designReview, [prop]: value })
    } else {
      setDesignReview({ ...designReview, [prop]: newValue })
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6} md={6}>
        <Typography component="legend">Quality & Accuracy</Typography>
        <Rating
          name="quality-accuracy"
          value={designReview.qualityAccuracy}
          onChange={handleChangeDesign('qualityAccuracy')}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography component="legend">Material</Typography>
        <Rating
          name="material"
          value={designReview.material}
          onChange={handleChangeDesign('material')}
        />
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <Typography component="legend">Finish</Typography>
        <Rating
          name="finish"
          value={designReview.finish}
          onChange={handleChangeDesign('finish')}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="legend">Review</Typography>
        <TextField
          autoComplete="printNotes"
          fullWidth
          inputProps={{
            maxLength: 500,
          }}
          multiline
          rows={4}
          size="small"
          type="text"
          value={designReview.notes}
          variant="outlined"
          onChange={handleChangeDesign('notes')}
        />
      </Grid>
    </Grid>
  )
}

DesignReviewForm.propTypes = {
  designReview: PropTypes.instanceOf(Object).isRequired,
  setDesignReview: PropTypes.func.isRequired,
}

export default DesignReviewForm
