import React, {
  lazy,
  Suspense,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import algoliasearch from 'algoliasearch/lite'
import {
  Configure,
  connectStateResults,
  Hits,
  InstantSearch,
} from 'react-instantsearch-dom'

import {
  CustomMenu,
  CustomPagination,
  HitCard,
  SkeletonGallery,
} from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { updateToRefreshFalse } from 'actions/algoliaAction'
import { searchEvent } from 'services/GoogleAnalyticsService'

const searchStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    marginTop: '20px',
    width: '50%',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  grid: {
    '& li': {
      margin: '10px',
    },
    display: 'grid',
    '& ul': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      listStyle: 'none',
      padding: '0px',
    },
    [theme.breakpoints.down(730)]: {
      '& ul': {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
    },
    [theme.breakpoints.between(730, 'lg')]: {
      '& ul': {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& ul': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
    },
    [theme.breakpoints.up(1525)]: {
      '& ul': {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
    },
    [theme.breakpoints.up(1925)]: {
      '& ul': {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },
    [theme.breakpoints.up(2325)]: {
      '& ul': {
        gridTemplateColumns: 'repeat(6, 1fr)',
      },
    },
    [theme.breakpoints.up(2725)]: {
      '& ul': {
        gridTemplateColumns: 'repeat(7, 1fr)',
      },
    },
  },

  alignCenter: {
    display: 'flex',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '75%',
    },
  },
  relative: {
    position: 'relative',
  },
  searchContainer: {
    fontSize: theme.custom.form.checkboxFontSize,
    '& .MuiInputBase-root': {
      height: '40px',
      paddingRight: '0px',
    },
    '& .MuiGrid-root': {
      height: '40px',
    },
    '& .MuiButton-root': {
      minWidth: '32px',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0px',
    width: '100%',
  },
}))

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const searchClient = {
  search(requests) {
    requests.forEach(request => {
      const {
        params: { query },
      } = request
      searchEvent(query)
    })
    return algoliaClient.search(requests)
  },
}

const LoadingIndicator = connectStateResults(({ isSearchStalled }) =>
  isSearchStalled ? <SkeletonGallery /> : <></>
)
const MIN_DESIGN_REQUEST = 20
const getNumberDesignRequest = screenWidth => {
  let numberDesignRequest = MIN_DESIGN_REQUEST
  if (screenWidth >= 1280 && screenWidth < 1525) {
    numberDesignRequest = 21
  }
  if (screenWidth >= 2325 && screenWidth < 2725) {
    numberDesignRequest = 24
  }
  if (screenWidth >= 2725) {
    numberDesignRequest = 28
  }
  return numberDesignRequest
}

const ScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useLayoutEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window
      setScreenWidth(innerWidth)
    }

    window.addEventListener('resize', debounce(handleResize, 1000))
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return screenWidth
}

const CustomSearchBox = lazy(() => import('./CustomSearchBox/CustomSearchBox'))

const FuzzySearch = () => {
  const classes = searchStyles()
  const refreshOption = useSelector(state => state.algoliaState.refresh)
  const isBrowser = () => typeof window !== 'undefined'

  const screenWidth = isBrowser() ? ScreenWidth() : MIN_DESIGN_REQUEST
  const numberDesignRequest = getNumberDesignRequest(screenWidth)

  useEffect(() => {
    const clearCache = () => {
      algoliaClient.clearCache().then(() => {
        updateToRefreshFalse()
      })
    }
    if (refreshOption === true) {
      clearCache()
    }
  }, [refreshOption])

  const searchDelay = 0
  return (
    <div>
      <InstantSearch
        searchClient={searchClient}
        indexName={`${process.env.GATSBY_ALGOLIA_INDEX}`}
        stalledSearchDelay={searchDelay}
      >
        <Configure filters="is_public:true" hitsPerPage={numberDesignRequest} />
        <Grid container item xs={11} className={classes.searchContainer}>
          <Grid item xs={12} className={classes.alignCenter}>
            <Grid className={classes.relative} item xs={5} sm={4} md={4} lg={4}>
              <CustomMenu attribute="category" />
            </Grid>
            <Grid item xs={7} sm={8} md={8} lg={8}>
              {isBrowser() && (
                <Suspense fallback={<div />}>
                  <CustomSearchBox />
                </Suspense>
              )}
            </Grid>
          </Grid>
        </Grid>
        <LoadingIndicator />

        <Grid className={classes.root}>
          <Grid className={classes.grid}>
            <Hits hitComponent={HitCard} />
          </Grid>
        </Grid>
        <div className={classes.center}>
          <CustomPagination />
        </div>
      </InstantSearch>
    </div>
  )
}

export default FuzzySearch
