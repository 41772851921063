import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: '4px',
    marginTop: '4px',
  },
  title: {
    display: 'block',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    textAlign: 'center',
    [theme.breakpoints.down(281)]: {
      fontSize: '0.7rem',
    },
  },
}))

const MaterialEstimationsProvidedByUser = ({
  city,
  estimatedMaterial,
  estimatedPrintTime,
  materials,
  state,
  zipCode,
}) => {
  const classes = useStyles()
  return (
    <Grid
      className={classes.textSize}
      container
      id="offer-information"
      item
      xs={12}
    >
      <Grid item xs={12}>
        <Typography className={classes.title} color="primary">
          SHIPPING DESTINATION:&nbsp;
          {`${city}, ${state}, ${zipCode}`}
        </Typography>
      </Grid>
      <Grid className={classes.margin} container item xs={12}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Typography className={classes.title} color="primary">
            Estimated Print Time (Days)
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.title} color="primary">
            Estimated Material (mm<sup>3</sup>)
          </Typography>
        </Grid>
      </Grid>
      {materials.map((item, index) => (
        <Grid className={classes.margin} container key={index} item xs={12}>
          <Grid item xs={4}>
            <Typography className={classes.title} color="primary">
              {item.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.title} color="primary">
              {estimatedPrintTime[item.name]
                ? estimatedPrintTime[item.name]
                : 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.title} color="primary">
              {estimatedMaterial[item.name]
                ? estimatedMaterial[item.name]
                : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

MaterialEstimationsProvidedByUser.propTypes = {
  city: PropTypes.string,
  estimatedMaterial: PropTypes.object,
  estimatedPrintTime: PropTypes.object,
  materials: PropTypes.array,
  state: PropTypes.string,
  zipCode: PropTypes.string,
}

MaterialEstimationsProvidedByUser.defaultProps = {
  city: '',
  estimatedMaterial: {},
  estimatedPrintTime: {},
  materials: [],
  state: '',
  zipCode: '',
}

export default MaterialEstimationsProvidedByUser
