import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { GalleryCard, NoDesignsComponent } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  grid: {
    display: 'grid',
    gridGap: '20px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      overflow: 'hidden',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up(1525)]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.up(1925)]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    [theme.breakpoints.up(2325)]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
    [theme.breakpoints.up(2725)]: {
      gridTemplateColumns: 'repeat(7, 1fr)',
    },
  },
}))

const Gallery = ({
  addPrice,
  displayPrivateInfo,
  models,
  showRegisterRedirect,
  onClickShowRegisterRedirect,
}) => {
  const loggedUser = useSelector(state => state.userState.user)
  const classes = useStyles()
  return (
    <>
      {models.length > 0 || addPrice ? (
        <Grid className={classes.root}>
          <Grid className={classes.grid}>
            {models.map((model, index) => (
              <GalleryCard
                addPrice={addPrice}
                model={model}
                showModal={!displayPrivateInfo && loggedUser.onboarded}
                showRegisterRedirect={showRegisterRedirect}
                onClickShowRegisterRedirect={onClickShowRegisterRedirect}
                key={index}
              />
            ))}
          </Grid>
        </Grid>
      ) : (
        displayPrivateInfo && <NoDesignsComponent />
      )}
    </>
  )
}

Gallery.propTypes = {
  addPrice: PropTypes.bool,
  displayPrivateInfo: PropTypes.bool,
  models: PropTypes.arrayOf(PropTypes.object),
  showRegisterRedirect: PropTypes.bool,
  onClickShowRegisterRedirect: PropTypes.func,
}

Gallery.defaultProps = {
  addPrice: false,
  displayPrivateInfo: false,
  models: [],
  showRegisterRedirect: false,
  onClickShowRegisterRedirect: () => {},
}

export default Gallery
