import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Divider, Grid, MenuItem, Select, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import { MeasurementChange } from 'components'
import {
  INCH_TO_MM,
  INCHES_UNIT,
  MILLIMETER_UNIT,
} from 'constants/modelConstants'

const useStyles = makeStyles(theme => ({
  alignCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  container: {
    display: 'flex',
    height: '52px',
    padding: '0px !important',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  containerGroup: {
    display: 'flex',
  },
  dropdown: {
    height: '40px',
    width: '100%',
    '& .MuiInputBase-input': {
      fontSize: '0.8rem',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiInputBase-input': {
        fontSize: '0.625rem',
      },
    },
  },
  divider: {
    backgroundColor: 'black',
    height: '1px',
    width: '100%',
  },
  horizontalDivider: {
    backgroundColor: theme.palette.background.gray,
    height: '1px',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '8px',
    width: '12px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4px',
      width: '8px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '4px',
      width: '6px',
    },
    '@media (max-width:280px)': {
      display: 'none',
    },
  },
  icon: {
    height: '1rem',
    color: theme.palette.background.gray,
    '@media (max-width:280px)': {
      display: 'none',
    },
  },
  mainContainer: {
    padding: '12px 24px',
  },
  numberInput: {
    textAlign: 'center',
    maxWidth: '109px',
    '& .MuiInputBase-input': {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiInputBase-input': {
        fontSize: '0.625rem',
      },
    },
    '@media (max-width:375px)': {
      width: '63px',
    },
  },
  subTitle: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  verticalDivider: {
    backgroundColor: theme.palette.background.gray,
    width: '1px',
    height: '130%',
    marginBottom: '0px',
    '@media (max-width:280px)': {
      display: 'none',
    },
  },
  verticalDividerBottom: {
    height: '51%',
  },
  verticalDividerTop: {
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '26px',
    },
  },
}))
const MEASURES = ['width', 'depth', 'height']
const MINIMUM_SIZE = 0.001

const ListingTechSpecs = ({
  materialColors,
  materials,
  model,
  isThing,
  readOnly,
  setCurrentUnit,
  updateTechSpecs,
}) => {
  const classes = useStyles()
  const { category, name, tech_specs: techSpecs } = model
  const { depth = 0, height = 0, material, width = 0 } = techSpecs
  const [unit, setUnit] = useState(MILLIMETER_UNIT)
  const [changeMeasure, setChangeMeasure] = useState(false)
  const [specs, setSpecs] = useState({
    depth,
    height,
    material: '',
    materialColor: materialColors[0],
    quantity: 1,
    width,
  })

  useEffect(() => {
    if (materials.length > 0 && specs.material === '') {
      const currentMaterial = materials.find(item => item.name === material)
        ? material
        : materials[0].name
      setSpecs({
        ...specs,
        material: currentMaterial,
        materialColor: materialColors[0],
        quantity: 1,
      })
      updateTechSpecs({
        ...specs,
        material: currentMaterial,
        materialColor: materialColors[0],
        quantity: 1,
      })
    }
  }, [material, materials, materialColors, specs, updateTechSpecs])

  const updateMaterial = event => {
    const {
      target: { value },
    } = event
    setSpecs({ ...specs, material: value })
    updateTechSpecs({ ...specs, material: value })
  }

  const updateMaterialColor = event => {
    const {
      target: { value },
    } = event
    setSpecs({ ...specs, materialColor: value })
    updateTechSpecs({ ...specs, materialColor: value })
  }

  const handleChangeMeasurements = () => {
    const currentUnit = unit === MILLIMETER_UNIT ? INCHES_UNIT : MILLIMETER_UNIT
    setUnit(currentUnit)
    setCurrentUnit(currentUnit)
    const { depth, height, width, ...rest } = specs
    setChangeMeasure(true)
    const condition =
      currentUnit === MILLIMETER_UNIT ? INCH_TO_MM : 1 / INCH_TO_MM

    let newSpecs = {
      ...rest,
      depth: depth * condition,
      height: height * condition,
      width: width * condition,
    }

    if (currentUnit === MILLIMETER_UNIT) {
      if (depth * condition < MINIMUM_SIZE) {
        newSpecs.depth = MINIMUM_SIZE
      }

      if (height * condition < MINIMUM_SIZE) {
        newSpecs.height = MINIMUM_SIZE
      }

      if (width * condition < MINIMUM_SIZE) {
        newSpecs.width = MINIMUM_SIZE
      }
    }

    setSpecs(newSpecs)
    updateTechSpecs(newSpecs)
  }

  const updateSpecs = prop => event => {
    const {
      target: { value },
    } = event

    if (parseFloat(value) >= MINIMUM_SIZE) {
      setChangeMeasure(false)
      const { depth, height, width, ...rest } = specs
      let newSpecs
      if (isThing) {
        newSpecs = {
          ...specs,
          [prop]: value,
        }
        for (const measure of MEASURES) {
          if (prop !== measure) {
            newSpecs[measure] = parseFloat(newSpecs[measure]).toFixed(3)
          }
        }
        setSpecs(newSpecs)
      } else {
        newSpecs = {
          ...rest,
          depth:
            prop === 'depth'
              ? value
              : ((depth * value) / specs[prop]).toFixed(3),
          height:
            prop === 'height'
              ? value
              : ((height * value) / specs[prop]).toFixed(3),
          width:
            prop === 'width'
              ? value
              : ((width * value) / specs[prop]).toFixed(3),
        }

        if (
          newSpecs.depth >= MINIMUM_SIZE &&
          newSpecs.height >= MINIMUM_SIZE &&
          newSpecs.width >= MINIMUM_SIZE
        ) {
          setSpecs(newSpecs)
        }
      }

      if (
        newSpecs.depth >= MINIMUM_SIZE &&
        newSpecs.height >= MINIMUM_SIZE &&
        newSpecs.width >= MINIMUM_SIZE
      ) {
        updateTechSpecs(newSpecs)
      }
    }
  }

  const updateQuantity = event => {
    const {
      target: { value },
    } = event
    if (value >= 1) {
      setSpecs({ ...specs, quantity: value })
      updateTechSpecs({ ...specs, quantity: value })
    }
  }

  const handleBlur = () => {
    const { depth, height, width, ...rest } = specs
    if (
      depth >= MINIMUM_SIZE &&
      height >= MINIMUM_SIZE &&
      width >= MINIMUM_SIZE
    ) {
      setChangeMeasure(false)
      const newSpecs = {
        ...rest,
        depth: parseFloat(depth).toFixed(3),
        height: parseFloat(height).toFixed(3),
        width: parseFloat(width).toFixed(3),
      }

      setSpecs(newSpecs)
      updateTechSpecs(newSpecs)
    }
  }

  const formatValue = value =>
    changeMeasure ? parseFloat(value).toFixed(3) : value

  return (
    <Grid container className={classes.mainContainer} spacing={1}>
      <Grid container id="material-quantity-color">
        <Grid className={classes.title}>{name}</Grid>
        <Divider
          className={classes.divider}
          orientation="horizontal"
          variant="fullWidth"
        />
        <Grid className={classes.container} item xs={5} sm={5} md={6}>
          <span className={classes.alignCenter}>Category</span>
        </Grid>
        <Grid className={classes.container} item xs={7} sm={7} md={6}>
          <span className={classes.alignCenter}>{category}</span>
        </Grid>
        <Grid className={classes.container} item xs={5} sm={5} md={6}>
          <span className={classes.alignCenter}>Material</span>
        </Grid>
        <Grid className={classes.container} item xs={7} sm={7} md={6}>
          <Select
            className={clsx(classes.dropdown, classes.alignCenter)}
            disabled={readOnly}
            id="material"
            labelId="material-label"
            value={specs.material}
            variant="outlined"
            onChange={updateMaterial}
          >
            <MenuItem value="" disabled>
              - select -
            </MenuItem>
            {materials.map((item, index) => (
              <MenuItem
                className={classes.buttonStyles}
                key={index}
                value={item.name}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid className={classes.container} item xs={5} sm={5} md={6}>
          <span className={classes.alignCenter}>Color</span>
        </Grid>
        <Grid className={classes.container} item xs={7} sm={7} md={6}>
          <Select
            className={clsx(classes.dropdown, classes.alignCenter)}
            id="material"
            labelId="material-label"
            value={specs.materialColor}
            variant="outlined"
            onChange={updateMaterialColor}
          >
            <MenuItem value="" disabled>
              - select -
            </MenuItem>
            {materialColors.map((name, index) => (
              <MenuItem
                className={classes.buttonStyles}
                key={index}
                value={name}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid className={classes.container} item xs={5} sm={5} md={6}>
          <span className={classes.alignCenter}>Quantity</span>
        </Grid>

        <Grid item xs={7} sm={7} md={6}>
          <TextField
            className={clsx(classes.numberInput, classes.alignCenter)}
            disabled={readOnly}
            size="small"
            type="number"
            value={specs.quantity}
            variant="outlined"
            onChange={updateQuantity}
          />
        </Grid>
      </Grid>

      <Grid container id="switch-measurements">
        <Grid className={classes.container} item xs={12} sm={12} md={12}>
          <Grid item xs={12}>
            <div className={clsx(classes.subTitle, classes.alignCenter)}>
              Size
            </div>
            <MeasurementChange
              groupSize="small"
              unit={unit}
              onHandleChangeMeasurements={handleChangeMeasurements}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.containerGroup}>
          <Grid className={classes.container} item xs={5} sm={5} md={6}>
            <span className={classes.alignCenter}>Width</span>
          </Grid>
          <Grid className={classes.container} item xs={7} sm={7} md={6}>
            <TextField
              className={clsx(classes.numberInput, classes.alignCenter)}
              disabled={readOnly}
              size="small"
              type="number"
              value={formatValue(specs.width)}
              variant="outlined"
              inputProps={{ step: '.001' }}
              onBlur={handleBlur}
              onChange={updateSpecs('width')}
            />
            <Divider
              className={classes.horizontalDivider}
              orientation="horizontal"
            />
            <Divider
              className={clsx(
                classes.verticalDivider,
                classes.verticalDividerTop
              )}
              orientation="vertical"
            />
          </Grid>
          <Grid className={classes.container} item xs={5} sm={5} md={6}>
            <span className={classes.alignCenter}>Height</span>
          </Grid>
          <Grid className={classes.container} item xs={7} sm={7} md={6}>
            <TextField
              className={clsx(classes.numberInput, classes.alignCenter)}
              disabled={readOnly}
              size="small"
              type="number"
              value={formatValue(specs.height)}
              variant="outlined"
              inputProps={{ step: '.001' }}
              onBlur={handleBlur}
              onChange={updateSpecs('height')}
            />
            <Divider
              className={classes.horizontalDivider}
              orientation="horizontal"
            />
            <Divider
              className={classes.verticalDivider}
              orientation="vertical"
            />
            <LockIcon className={clsx(classes.alignCenter, classes.icon)} />
          </Grid>
          <Grid className={classes.container} item xs={5} sm={5} md={6}>
            <span className={classes.alignCenter}>Length</span>
          </Grid>
          <Grid className={classes.container} item xs={7} sm={7} md={6}>
            <TextField
              className={clsx(classes.numberInput, classes.alignCenter)}
              disabled={readOnly}
              size="small"
              type="number"
              value={formatValue(specs.depth)}
              variant="outlined"
              inputProps={{ step: '.001' }}
              onBlur={handleBlur}
              onChange={updateSpecs('depth')}
            />
            <Divider
              className={classes.horizontalDivider}
              orientation="horizontal"
            />
            <Divider
              className={clsx(
                classes.verticalDivider,
                classes.verticalDividerBottom
              )}
              orientation="vertical"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ListingTechSpecs.propTypes = {
  materialColors: PropTypes.array,
  materials: PropTypes.array,
  model: PropTypes.object.isRequired,
  isThing: PropTypes.bool,
  readOnly: PropTypes.bool,
  updateTechSpecs: PropTypes.func.isRequired,
}
ListingTechSpecs.defaultProops = {
  isThing: false,
  readOnly: false,
}

export default ListingTechSpecs
