import { LISTING_STATUS } from './listingConstants'
import { ORDER_MADE } from './storeConstants'

export const ERROR_FETCHING_ALERTS =
  'An error occurred while fetching the alerts, please try again later.'
export const ERROR_UPDATING_ALERT_LIST =
  'An error occurred while updating the alert, please try again later.'
export const ACCEPT_ORDER = 'ACCEPT_ORDER'
export const INTERNAL_MESSAGE = 'INTERNAL_MESSAGE'
export const MAKE_OFFER = 'MAKE_OFFER'
export const LISTING_APPROVED = 'LISTING_APPROVED'
export const LISTING_AWAITING_APPROVAL = 'LISTING_AWAITING_APPROVAL'
export const LISTING_PRODUCTION_STARTED = 'LISTING_PRODUCTION_STARTED'
export const LISTING_RECEIVED = 'LISTING_RECEIVED'
export const LISTING_REJECTED = 'LISTING_REJECTED'
export const LISTING_SHIPPED = 'LISTING_SHIPPED'
export const LISTING_SUBMITTED = 'LISTING_SUBMITTED'
export const NEW_OFFER_TO_WORK = 'NEW_OFFER_TO_WORK'
export const CHANGE_OFFER_ACCEPTED = 'CHANGE OFFER ACCEPTED'
export const CHANGE_OFFER_REJECTED = 'CHANGE OFFER REJECTED'
export const OFFER_ACCEPTED = 'OFFER_ACCEPTED'
export const OFFER_REJECTED = 'OFFER_REJECTED'
export const STRIPE_ONBOARDED_SUCCESS = 'STRIPE_ONBOARDED_SUCCESS'
export const TRANSFER_TO_MAKER_FAILED = 'TRANSFER_TO_MAKER_FAILED'
export const TRANSFER_TO_MAKER_SUCCESSFUL = 'TRANSFER_TO_MAKER_SUCCESSFUL'

export const actionLabel = alertAction => {
  switch (alertAction) {
    case ACCEPT_ORDER: {
      return {
        firstPart: ' accepted the order for your ',
        secondPart: ' listing',
      }
    }
    case INTERNAL_MESSAGE: {
      return {
        firstPart: ' left you a comment for ',
        secondPart: ' listing',
      }
    }
    case MAKE_OFFER: {
      return {
        firstPart: ' submitted an offer for your ',
        secondPart: ' listing',
      }
    }
    case LISTING_AWAITING_APPROVAL: {
      return {
        firstPart: ' sent the images for your approval for ',
        secondPart: ' listing ',
      }
    }
    case LISTING_APPROVED: {
      return {
        firstPart: ' approved the item ',
        secondPart: ' listing',
      }
    }
    case LISTING_REJECTED: {
      return {
        firstPart: ' rejected the item ',
        secondPart: ' listing',
      }
    }
    case LISTING_PRODUCTION_STARTED: {
      return {
        firstPart: ' started the production for your ',
        secondPart: ' listing',
      }
    }
    case OFFER_ACCEPTED: {
      return {
        firstPart: ' has accepted your offer for ',
        secondPart: ' listing',
      }
    }
    case OFFER_REJECTED: {
      return {
        firstPart: ' has rejected your offer for ',
        secondPart: ' listing',
      }
    }
    case LISTING_SHIPPED: {
      return {
        firstPart: ' has made the shipment for ',
        secondPart: ' listing',
      }
    }
    case LISTING_RECEIVED: {
      return {
        firstPart: ' has received the shipment ',
        secondPart: ' listing',
      }
    }
    case STRIPE_ONBOARDED_SUCCESS: {
      return {
        firstPart: '',
        secondPart:
          'You completed the Stripe onboarding flow, you are a maker now',
      }
    }
    case TRANSFER_TO_MAKER_FAILED: {
      return {
        firstPart:
          'Something went worng when trying to transfer the amount for the listing ',
        secondPart:
          ', our team is taking care of this and will contact you soon.',
      }
    }
    case TRANSFER_TO_MAKER_SUCCESSFUL: {
      return {
        firstPart: 'Congrats! The amount for the listing ',
        secondPart: ' is in the way to your Stripe account.',
      }
    }
    case ORDER_MADE: {
      return {
        firstPart: ' Made an order from your store for ',
        secondPart: '',
      }
    }
    case NEW_OFFER_TO_WORK: {
      return {
        firstPart: ' There is an exchange offer for the listing ',
        secondPart: '',
      }
    }
    case CHANGE_OFFER_ACCEPTED: {
      return {
        firstPart: ' Change of offer accepted for the listing ',
        secondPart: '',
      }
    }
    case CHANGE_OFFER_REJECTED: {
      return {
        firstPart: ' Change of offer rejected for the listing ',
        secondPart: '',
      }
    }
    default: {
      return {
        firstPart: '',
        secondPart: '',
      }
    }
  }
}

export const getAlertAction = status => {
  switch (status) {
    case LISTING_STATUS.APPROVED: {
      return LISTING_APPROVED
    }
    case LISTING_STATUS.REJECTED: {
      return LISTING_REJECTED
    }
    case LISTING_STATUS.IN_PROGRESS: {
      return LISTING_PRODUCTION_STARTED
    }
    case LISTING_STATUS.AWAITING_APPROVAL: {
      return LISTING_AWAITING_APPROVAL
    }
    case LISTING_STATUS.SHIPPED: {
      return LISTING_SHIPPED
    }
    case LISTING_STATUS.DELIVERED: {
      return LISTING_RECEIVED
    }
    default: {
      return LISTING_SUBMITTED
    }
  }
}

export const getFeedTimelineDescription = (action, user) => {
  switch (action) {
    case LISTING_SUBMITTED: {
      return 'The listing has been created'
    }
    case LISTING_APPROVED: {
      return 'The item has been approved'
    }
    case LISTING_AWAITING_APPROVAL: {
      return 'Production images have been added'
    }
    case LISTING_REJECTED: {
      return 'The item has been rejected'
    }
    case LISTING_PRODUCTION_STARTED: {
      return 'The listing production has started'
    }
    case OFFER_ACCEPTED: {
      return `The offer from ${user.first_name} ${user.last_name} has been accepted`
    }
    case LISTING_SHIPPED: {
      return 'The item has been shipped'
    }
    case LISTING_RECEIVED: {
      return 'The item has been received '
    }
    default: {
      return ''
    }
  }
}
