import { LISTING_ACTIONS, LISTING_STATUS } from './listingConstants'
import { OFFER_STATUS } from './offerConstants'

export const makerListingStatus = (listingStatus, offerStatus) => {
  if (!offerStatus) {
    return ''
  }
  if (
    listingStatus === LISTING_STATUS.SUBMITTED &&
    offerStatus === OFFER_STATUS.SUBMITTED
  ) {
    return 'OFFER PENDING'
  }
  if (offerStatus === OFFER_STATUS.ACCEPTED) {
    switch (listingStatus) {
      case LISTING_STATUS.AWAITING: {
        return 'AWAITING PAYMENT'
      }
      case LISTING_STATUS.AWAITING_APPROVAL: {
        return LISTING_STATUS.AWAITING_APPROVAL
      }
      case LISTING_STATUS.APPROVED: {
        return LISTING_STATUS.APPROVED
      }
      case LISTING_STATUS.REJECTED: {
        return LISTING_STATUS.REJECTED
      }
      case LISTING_STATUS.OFFER_ACCEPTED: {
        return 'OFFER ACCEPTED'
      }
      case LISTING_STATUS.IN_PROGRESS: {
        return 'IN PROGRESS'
      }
      case LISTING_STATUS.SHIPPED: {
        return LISTING_STATUS.SHIPPED
      }
      case LISTING_STATUS.DELIVERED: {
        return LISTING_STATUS.DELIVERED
      }
      default: {
        return ''
      }
    }
  }
  return ''
}

export const activeOfferLabelStatus = listingStatus => {
  switch (listingStatus) {
    case LISTING_STATUS.APPROVED:
      return 'SEND LISTING'
    case LISTING_STATUS.OFFER_ACCEPTED:
      return 'START PRODUCTION'
    case LISTING_STATUS.IN_PROGRESS:
      return 'UPLOAD IMAGES'
    default:
      return 'VIEW DETAILS'
  }
}

export const nextListingState = (listingStatus, action) => {
  if (
    listingStatus === LISTING_STATUS.IN_PROGRESS &&
    action === LISTING_ACTIONS.AWAITING_APPROVAL
  ) {
    return LISTING_STATUS.AWAITING_APPROVAL
  }
  if (
    listingStatus === LISTING_STATUS.AWAITING_APPROVAL &&
    action === LISTING_ACTIONS.APPROVE
  ) {
    return LISTING_STATUS.APPROVED
  }
  if (
    listingStatus === LISTING_STATUS.AWAITING_APPROVAL &&
    action === LISTING_ACTIONS.REJECT
  ) {
    return LISTING_STATUS.REJECTED
  }
  if (
    listingStatus === LISTING_STATUS.OFFER_ACCEPTED &&
    action === LISTING_ACTIONS.START_PRODUCTION
  ) {
    return LISTING_STATUS.IN_PROGRESS
  }
  if (
    listingStatus === LISTING_STATUS.APPROVED &&
    action === LISTING_ACTIONS.SEND_SHIPMENT
  ) {
    return LISTING_STATUS.SHIPPED
  }
  if (
    listingStatus === LISTING_STATUS.SHIPPED &&
    action === LISTING_ACTIONS.RECEIVED
  ) {
    return LISTING_STATUS.DELIVERED
  }
  if (
    listingStatus === LISTING_STATUS.OFFER_COMPLETED &&
    action === LISTING_ACTIONS.REJECT_OFFER
  ) {
    return LISTING_STATUS.REJECTED
  }
  if (
    listingStatus === LISTING_STATUS.OFFER_PENDING &&
    action === LISTING_ACTIONS.REJECT_OFFER
  ) {
    return LISTING_STATUS.REJECTED
  }
  return listingStatus
}
