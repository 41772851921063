import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { CircularProgress, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AsYouType } from 'libphonenumber-js'
import { size } from 'lodash'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import iso3311a2 from 'iso-3166-1-alpha-2'
import { CommonButton, ErrorMessage } from 'components'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import { getCountryState } from 'constants/utils'
import { validateAddress } from 'services/ShipEngineService'

const defaultState = {
  country: 'United States',
  firstName: '',
  lastName: '',
  city: '',
  companyName: '',
  companyAddress: '',
  state: '',
  zipCode: '',
  phoneNumber: '',
}

const useStyles = makeStyles(theme => ({
  container: {
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      '& .MuiFormLabel-root': {
        fontSize: '0.625rem',
      },
    },
  },
  customContainer: {
    height: '45px',
    width: '100%',
  },
  dropdown: {
    fontSize: '1rem',
    fontWeight: 'normal',
    fontStyle: 'inherit',
    fontFamily: 'inherit',
    borderColor: 'black',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    '&: hover': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
    },
    borderRadious: 'inherit',
    backgroundColor: theme.palette.background.main,
    borderLeftWidth: '0px',
    borderTopWidth: '0px',
    borderRightWidth: '0px',
    height: '21px',
    marginTop: '10px',
    paddingLeft: '0px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      '& .MuiFormLabel-root': {
        fontSize: '0.625rem',
      },
    },
  },
  mainContainer: {
    backgroundColor: theme.palette.background.main,
    padding: '24px 24px',
  },
  label: {
    cursor: 'default',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    padding: '0px',
    textSizeAdjust: '100%',
    top: '0px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.625rem',
    },
  },
  marginTop: {
    pointerEvents: 'none',
    '&.MuiGrid-root': {
      marginTop: '-40px',
    },
  },
  spinner: {
    position: 'relative',
    top: '50%',
    left: '50%',
    zIndex: '10000000',
  },
  styledField: {
    '& .MuiInputBase-input': {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      '& .MuiInputBase-input': {
        fontSize: '0.75rem',
      },
    },
  },
}))

const defaultValidations = {
  error: false,
  helperText: '',
}

const defaultValidationState = {
  city: defaultValidations,
  companyAddress: defaultValidations,
  country: defaultValidations,
  state: defaultValidations,
  zipCode: defaultValidations,
}

const asYouType = new AsYouType('US')

const errorState = {
  error: true,
  helperText: 'Please verify this field',
}

const ShippingInfoEditor = ({ cancel, confirm, shippingInitialState }) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')
  const [isVerifyingAddress, setIsVerifyingAddress] = useState(false)
  const [shippingInfo, setShippingInfo] = useState(defaultState)
  const [validation, setValidation] = useState(defaultValidationState)

  useEffect(() => {
    if (shippingInitialState) {
      setShippingInfo(shippingInitialState)
    }
  }, [shippingInitialState])

  const handleBlur = prop => event => {
    const temp = { ...shippingInfo }
    const {
      target: { value },
    } = event
    const trimmedValue = value.trim()
    temp[prop] = trimmedValue
    setShippingInfo(temp)
    if (prop === 'city' || prop === 'companyAddress') {
      verifyAddress()
    }
  }

  const updateShippingInfo = prop => event => {
    const temp = { ...shippingInfo }
    const {
      target: { value },
    } = event
    temp[prop] = value
    setShippingInfo(temp)
  }

  const setPhoneNumber = event => {
    const {
      target: { value },
    } = event
    const phoneLength = size(value)
    asYouType.reset()
    let formattedInput = asYouType.input(value)

    const firstChar = formattedInput.charAt(0)
    if ((firstChar === '1' && phoneLength <= 16) || phoneLength <= 14) {
      if (phoneLength === 4 || phoneLength === 6) {
        formattedInput = value
      }
      setShippingInfo({ ...shippingInfo, phoneNumber: formattedInput })
    }
  }

  const formatPhoneNumber = () => {
    if (asYouType.isValid()) {
      setShippingInfo({
        ...shippingInfo,
        phoneNumber: asYouType.getNumber().formatNational(),
      })
    }
  }

  const countryUpdate = val => {
    const countryCode = iso3311a2.getCode(val)
    setShippingInfo({ ...shippingInfo, country: val, countryCode })
  }

  const updateRegion = val => {
    setShippingInfo({ ...shippingInfo, state: val })
  }

  const updateZipCode = event => {
    const {
      target: { value },
    } = event
    const trimmedValue = value.trim()
    const regex = /^[0-9\b]+$/
    if ((size(trimmedValue) <= 5 && regex.test(trimmedValue)) || value === '') {
      setShippingInfo({ ...shippingInfo, zipCode: trimmedValue })
    }
  }

  const verifyAddress = async () => {
    setIsVerifyingAddress(true)
    let { city, companyAddress, country, state, zipCode } = shippingInfo
    if (
      city.length > 0 &&
      country.length > 0 &&
      companyAddress.length > 0 &&
      state.length > 0 &&
      zipCode.length === 5
    ) {
      try {
        setErrorMessage('')
        setValidation({
          ...validation,
          city: defaultValidations,
          companyAddress: defaultValidations,
          zipCode: defaultValidations,
        })
        const { countryCode, stateCode } = getCountryState(country, state)
        const addressValidation = await validateAddress(
          companyAddress,
          city,
          countryCode,
          stateCode,
          zipCode
        )
        const { isValid, matchedAddress, message } = addressValidation
        if (isValid) {
          let { address, city, country, state, zipCode } = matchedAddress
          const { countryName, stateName } = getCountryState(country, state)
          setShippingInfo({
            ...shippingInfo,
            city,
            companyAddress: address,
            country: countryName,
            state: stateName,
            zipCode,
          })
        } else {
          setErrorMessage(message)
          setValidation({
            ...validation,
            city: errorState,
            companyAddress: errorState,
            zipCode: errorState,
          })
        }
      } catch (error) {
        setErrorMessage(error.message)
      }
    }
    setIsVerifyingAddress(false)
  }

  const validateFields = () => {
    const phoneNumber = size(shippingInfo.phoneNumber) >= 14
    const city = size(shippingInfo.city)
    const companyAddress = size(shippingInfo.companyAddress.trim())
    const country = !!shippingInfo.country
    const firstName = size(shippingInfo.firstName.trim())
    const lastName = size(shippingInfo.lastName.trim())
    const state = !!shippingInfo.state
    const zipCode = size(shippingInfo.zipCode) === 5

    const fieldValidations =
      city &&
      country &&
      firstName &&
      lastName &&
      companyAddress &&
      state &&
      zipCode &&
      phoneNumber

    const {
      city: { error: cityError },
      companyAddress: { error: companyAddressError },
      zipCode: { error: zipCodeError },
    } = validation

    return (
      fieldValidations && !cityError && !companyAddressError && !zipCodeError
    )
  }

  const saveShippingInfo = () => {
    const isValid = validateFields()
    if (isValid) {
      confirm(shippingInfo)
      setErrorMessage('')
    } else {
      setErrorMessage(
        'Please fill all the fields and select a valid country/state'
      )
    }
  }

  return (
    <>
      {isVerifyingAddress && (
        <CircularProgress className={classes.spinner} size={40} />
      )}
      <Grid
        className={clsx(classes.mainContainer, {
          [classes.marginTop]: isVerifyingAddress,
        })}
        container
        spacing={2}
      >
        <ErrorMessage message={errorMessage} />
        <Grid className={classes.container} item xs={12} sm={6}>
          <div className={classes.customContainer}>
            <span className={classes.label}>Country *</span>
            <CountryDropdown
              className={classes.dropdown}
              disabled
              value={shippingInfo.country}
              onChange={countryUpdate}
            />
          </div>
        </Grid>
        <Grid item sm={6}></Grid>
        <Grid className={classes.container} item xs={12} sm={6}>
          <TextField
            className={classes.styledField}
            fullWidth
            label="First Name"
            required
            size="small"
            type="text"
            value={shippingInfo.firstName}
            onChange={updateShippingInfo('firstName')}
          />
        </Grid>
        <Grid className={classes.container} item xs={12} sm={6}>
          <TextField
            className={classes.styledField}
            fullWidth
            label="Last Name"
            required
            size="small"
            type="text"
            value={shippingInfo.lastName}
            onChange={updateShippingInfo('lastName')}
          />
        </Grid>
        <Grid className={classes.container} item xs={12} sm={6}>
          <TextField
            className={classes.styledField}
            fullWidth
            label="Company Name"
            size="small"
            type="text"
            value={shippingInfo.companyName}
            onBlur={handleBlur('companyName')}
            onChange={updateShippingInfo('companyName')}
          />
        </Grid>
        <Grid className={classes.container} item xs={12} sm={6}>
          <TextField
            className={classes.styledField}
            error={validation.city.error}
            fullWidth
            helperText={validation.city.helperText}
            label="City"
            required
            size="small"
            type="text"
            value={shippingInfo.city}
            onBlur={handleBlur('city')}
            onChange={updateShippingInfo('city')}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid className={classes.container} item xs={12}>
          <TextField
            className={classes.styledField}
            error={validation.companyAddress.error}
            fullWidth
            helperText={validation.companyAddress.helperText}
            label="Address"
            required
            size="small"
            type="text"
            value={shippingInfo.companyAddress}
            onBlur={handleBlur('companyAddress')}
            onChange={updateShippingInfo('companyAddress')}
          />
        </Grid>
        <Grid className={classes.container} item xs={12} sm={4}>
          <div className={classes.customContainer}>
            <span className={classes.label}>State *</span>
            <RegionDropdown
              className={classes.dropdown}
              country={shippingInfo.country}
              defaultOptionLabel="Select"
              value={shippingInfo.state}
              valueType="full"
              onBlur={verifyAddress}
              onChange={updateRegion}
            />
          </div>
        </Grid>
        <Grid className={classes.container} item xs={12} sm={4}>
          <TextField
            className={classes.styledField}
            error={validation.zipCode.error}
            fullWidth
            helperText={validation.zipCode.helperText}
            label="Zip Code"
            required
            size="small"
            type="text"
            value={shippingInfo.zipCode}
            onBlur={verifyAddress}
            onChange={updateZipCode}
          />
        </Grid>
        <Grid className={classes.container} item xs={12} sm={4}>
          <TextField
            className={classes.styledField}
            fullWidth
            id="phoneNumber"
            label="Phone number"
            placeholder="Phone number"
            required
            size="small"
            value={shippingInfo.phoneNumber}
            onBlur={formatPhoneNumber}
            onChange={setPhoneNumber}
          />
        </Grid>
        <Grid className={classes.container} item xs={12}>
          <CommonButton
            buttonStyle={BUTTON_STYLE.CANCEL}
            fullWidth={false}
            label="Cancel"
            size={BUTTON_SIZE.LARGE}
            type={BUTTON_TYPE.SUBMIT}
            variant={BUTTON_VARIANT.TEXT}
            onClick={cancel}
          />
          <CommonButton
            buttonStyle={BUTTON_STYLE.PRIMARY}
            disabled={!validateFields() || isVerifyingAddress}
            fullWidth={false}
            label="Save Changes"
            size={BUTTON_SIZE.LARGE}
            type={BUTTON_TYPE.SUBMIT}
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={saveShippingInfo}
          />
        </Grid>
      </Grid>
    </>
  )
}

ShippingInfoEditor.propTypes = {
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  shippingInitialState: PropTypes.object.isRequired,
}

export default ShippingInfoEditor
