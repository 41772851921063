import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  margin: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '8px',
    padding: '8px',
    marginTop: '24px',
    borderColor: theme.palette.border.lightgray,
  },
}))

const StripeSignup = () => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h6">Payment Information</Typography>
      <Typography className={classes.margin}>
        We use Stripe to make sure you get paid on time and to keep your
        personal bank and details secure. Click Save and continue to set up your
        payments on Stripe.
      </Typography>
    </>
  )
}

export default StripeSignup
