import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import {
  CREATE_STRIPE_ACCOUNT,
  CREATE_SIGNUP_ACCOUNT_LINK,
  GET_STRIPE_ACCOUNT,
} from 'constants/stripeEndpointsConstants'

let stripePromise

export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_API_KEY)
  }
  return stripePromise
}

export const generateStripeAccount = async userId => {
  return axios.post(CREATE_STRIPE_ACCOUNT, {
    userId,
  })
}

export const generateStripeAccountSignupLink = async (
  stripeAccountId,
  userId
) => {
  return axios.post(CREATE_SIGNUP_ACCOUNT_LINK, {
    stripeAccountId,
    userId,
  })
}

export const getStripeAccount = async accountId => {
  return axios.get(GET_STRIPE_ACCOUNT(accountId))
}
