import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, navigate } from 'gatsby'

import clsx from 'clsx'
import {
  AppBar,
  Collapse,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import HomeIcon from '@material-ui/icons/Home'
import InfoIcon from '@material-ui/icons/Info'
import MenuIcon from '@material-ui/icons/Menu'
import PersonIcon from '@material-ui/icons/Person'
import ViewListIcon from '@material-ui/icons/ViewList'

import logo from 'assets/logo.svg'
import {
  BecomeMakerModal,
  CommonButton,
  DesignModal,
  FeatureFlag,
  UserMenu,
} from 'components'
import { ONBOARDING_ENDPOINT } from 'constants/apiUrls'
import {
  BUTTON_STYLE,
  BUTTON_SIZE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'

import { ONBOARD_LINK } from 'constants/thingiverseEndpointsConstants'

const DRAWER_WIDTH = 240

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: theme.palette.background.white,
    height: theme.mixins.toolbar.minHeight,
  },
  appBarShift: {
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  becomeMakerMobile: {
    '& div': {
      width: '100%',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  designButton: {
    marginRight: '10px',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    background: theme.palette.background.default,
  },
  hide: {
    visibility: 'hidden',
  },
  hideButton: {
    display: 'none',
  },
  hideUserMenu: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logoLink: {
    padding: '0 0',
    '@media (max-width:600px)': {
      padding: '0 0.5rem',
    },
  },
  maxHeight: {
    '& div button': {
      maxHeight: '52px',
      [theme.breakpoints.down(647)]: {
        fontSize: '0.75rem',
      },
      [theme.breakpoints.down(630)]: {
        fontSize: '0.65rem',
      },
      [theme.breakpoints.down(609)]: {
        fontSize: '0.5rem',
      },
      [theme.breakpoints.down(602)]: {
        fontSize: '7.5px',
      },
    },
    '& button': {
      maxHeight: '52px',
      [theme.breakpoints.down(647)]: {
        fontSize: '0.75rem',
      },
      [theme.breakpoints.down(630)]: {
        fontSize: '0.65rem',
      },
      [theme.breakpoints.down(609)]: {
        fontSize: '0.5rem',
      },
      [theme.breakpoints.down(602)]: {
        fontSize: '7.5px',
      },
    },
  },
  menuButton: {
    color: theme.palette.text.white,
  },
  menuButtons: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  navLink: {
    border: 'none',
    display: 'inline-block',
    padding: `1.5rem 2.5rem`,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    height: theme.mixins.toolbar.minHeight,
    fontWeight: 'bolder',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      padding: `1.5rem 1rem`,
    },
  },
  navLinkActive: {
    borderBottom: '2px solid black',
  },
  root: {
    display: 'flex',
  },
  sidenavLink: {
    width: '100%',
    display: 'inline-block',
    textDecoration: 'none',
    color: theme.palette.text.white,
  },
  sidenavLinkActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  toolbar: {
    [theme.breakpoints.up(1525)]: {
      maxWidth: '1400px',
      left: 'calc(50% - 700px)',
    },
  },
}))

const Header = ({ siteTitle }) => {
  const { user: loggedUser, isLoggingOut: loggingOut } = useSelector(
    state => state.userState
  )
  const isMaker = loggedUser ? loggedUser.isMaker : false
  const [open, setOpen] = useState(false)
  const [openDesignsMenu, setOpenDesignsMenu] = useState(false)
  const classes = useStyles()

  const toggleDrawer = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setOpen(open)
  }

  const toggleDesignsMenu = e => {
    e.preventDefault()
    setOpenDesignsMenu(!openDesignsMenu)
  }

  const userLoggedState = () => {
    return loggedUser && !loggingOut && !loggedUser.isAnonymous
  }

  const thingiverseOnboard = () => {
    navigate(ONBOARD_LINK)
  }

  return (
    <div className={classes.root}>
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        elevation={2}
        position="fixed"
      >
        <Toolbar className={classes.toolbar}>
          <Hidden smUp>
            <IconButton
              color="inherit"
              aria-label="Open"
              onClick={toggleDrawer(true)}
              edge="start"
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Link className={classes.logoLink} id="smthyLogo" to="/">
            <img src={logo} alt={siteTitle} width="auto" height="48" />
          </Link>
          <div className={classes.menuButtons}>
            <Hidden xsDown>
              <Link
                to="/designs/"
                className={classes.navLink}
                activeClassName={classes.navLinkActive}
                exact={'true'}
              >
                <span id="designs">Designs</span>
              </Link>
              {!loggingOut && isMaker && (
                <Link
                  to="/listings/"
                  className={classes.navLink}
                  activeClassName={classes.navLinkActive}
                  exact={'true'}
                >
                  <span id="listings">Listings</span>
                </Link>
              )}
              <Link
                to="/about/"
                className={classes.navLink}
                activeClassName={classes.navLinkActive}
                exact={'true'}
              >
                <span id="about">About</span>
              </Link>
              {loggedUser &&
              !loggedUser.isAnonymous &&
              !loggedUser.onboarded ? (
                <div
                  className={clsx(classes.designButton, classes.maxHeight, {
                    [classes.hideButton]: !userLoggedState(),
                  })}
                >
                  {loggedUser && !loggedUser.isMaker && !loggingOut && (
                    <CommonButton
                      buttonStyle={BUTTON_STYLE.PRIMARY}
                      fullWidth={false}
                      label="Continue onboarding"
                      size={BUTTON_SIZE.SMALL}
                      variant={BUTTON_VARIANT.OUTLINED}
                      onClick={() => navigate(ONBOARDING_ENDPOINT)}
                    />
                  )}
                </div>
              ) : (
                <>
                  <div
                    className={clsx(classes.designButton, classes.maxHeight, {
                      [classes.hideButton]: !userLoggedState(),
                    })}
                  >
                    {loggedUser && !loggedUser.isMaker && !loggingOut && (
                      <BecomeMakerModal loggedUser={loggedUser} />
                    )}
                  </div>
                  <div
                    className={clsx(classes.designButton, classes.maxHeight, {
                      [classes.hideButton]: !userLoggedState(),
                    })}
                  >
                    {loggedUser &&
                      !loggedUser.integrated_with_thingiverse &&
                      !loggingOut && (
                        <FeatureFlag
                          inactiveComponent={
                            <CommonButton
                              buttonStyle={BUTTON_STYLE.PRIMARY}
                              fullWidth={false}
                              label="Link with Thingiverse"
                              size={BUTTON_SIZE.SMALL}
                              variant={BUTTON_VARIANT.OUTLINED}
                              onClick={thingiverseOnboard}
                            />
                          }
                          activeComponent={<></>}
                          flag="display_link_with_thingiverse"
                        />
                      )}
                  </div>
                  <div
                    className={clsx(classes.designButton, classes.maxHeight, {
                      [classes.hideButton]: !userLoggedState(),
                    })}
                  >
                    {loggedUser && !loggingOut && <DesignModal />}
                  </div>
                </>
              )}
            </Hidden>
            <div
              className={clsx(classes.userMenu, {
                [classes.hideUserMenu]: !userLoggedState(),
              })}
            >
              <UserMenu />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Hidden smUp>
        <SwipeableDrawer
          className={classes.drawer}
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <List>
            <Link
              to="/designs/"
              className={classes.sidenavLink}
              activeClassName={classes.sidenavLinkActive}
              exact={'true'}
            >
              <ListItem button>
                <ListItemIcon>
                  <HomeIcon className={classes.menuButton} />
                </ListItemIcon>
                <ListItemText>Designs</ListItemText>
                {loggedUser && !loggedUser.isAnonymous && loggedUser.onboarded && (
                  <div
                    className={clsx({
                      [classes.hideButton]: !userLoggedState(),
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={toggleDesignsMenu}
                    onKeyDown={toggleDesignsMenu}
                  >
                    {openDesignsMenu ? <ExpandLess /> : <ExpandMore />}
                  </div>
                )}
              </ListItem>
            </Link>
            <Collapse in={openDesignsMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  {loggedUser && !loggingOut && <DesignModal />}
                </ListItem>
              </List>
            </Collapse>

            {isMaker && (
              <Link
                to="/listings/"
                className={classes.sidenavLink}
                activeClassName={classes.sidenavLinkActive}
                exact={'true'}
              >
                <ListItem button>
                  <ListItemIcon>
                    <ViewListIcon className={classes.menuButton} />
                  </ListItemIcon>
                  <ListItemText>Listings</ListItemText>
                </ListItem>
              </Link>
            )}
            <Link
              to="/about"
              className={classes.sidenavLink}
              activeClassName={classes.sidenavLinkActive}
              exact={'true'}
            >
              <ListItem button>
                <ListItemIcon>
                  <InfoIcon className={classes.menuButton} />
                </ListItemIcon>
                <ListItemText>About</ListItemText>
              </ListItem>
            </Link>
            {loggedUser && !loggedUser.isAnonymous && loggedUser.onboarded ? (
              <>
                <ListItem
                  button
                  className={clsx({
                    [classes.hideButton]: !userLoggedState(),
                  })}
                >
                  {loggedUser &&
                    !loggedUser.integrated_with_thingiverse &&
                    !loggingOut && (
                      <FeatureFlag
                        activeComponent={<></>}
                        inactiveComponent={
                          <CommonButton
                            buttonStyle={BUTTON_STYLE.PRIMARY}
                            fullWidth={true}
                            label="Link with Thingiverse"
                            size={BUTTON_SIZE.SMALL}
                            variant={BUTTON_VARIANT.OUTLINED}
                            onClick={thingiverseOnboard}
                          />
                        }
                        flag="display_link_with_thingiverse"
                      />
                    )}
                </ListItem>

                <ListItem
                  button
                  className={clsx(classes.becomeMakerMobile, {
                    [classes.hideButton]: !userLoggedState(),
                  })}
                >
                  {loggedUser && !loggedUser.isMaker && !loggingOut && (
                    <BecomeMakerModal loggedUser={loggedUser} />
                  )}
                </ListItem>
              </>
            ) : (
              <ListItem
                button
                className={clsx({ [classes.hideButton]: !userLoggedState() })}
              >
                <CommonButton
                  buttonStyle={BUTTON_STYLE.PRIMARY}
                  fullWidth={true}
                  label="Continue onboarding"
                  size={BUTTON_SIZE.SMALL}
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={() => navigate(ONBOARDING_ENDPOINT)}
                />
              </ListItem>
            )}

            {!userLoggedState() && (
              <Link
                to="/signup"
                className={classes.sidenavLink}
                activeClassName={classes.sidenavLinkActive}
                exact={'true'}
              >
                <ListItem button>
                  <ListItemIcon>
                    <PersonIcon className={classes.menuButton} />
                  </ListItemIcon>
                  <ListItemText>Sign Up/Login</ListItemText>
                </ListItem>
              </Link>
            )}
          </List>
        </SwipeableDrawer>
      </Hidden>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
