export const TUTORIAL_NAMES = {
  DESIGN_PAGE: 'designPage',
  PAGE_HEADER: 'pageHeader',
  UPLOAD_DESIGN: 'uploadDesign',
  CREATE_LISTING: 'createListing',
  MAKE_OFFER: 'makeOffer',
  LISTING_PAGE: 'listingPage',
  MANAGE_STORE: 'manageStore',
}

export const PAGE_HEADER = [
  {
    target: '#smthyLogo',
    title: 'Application tutorial',
    content:
      'Open your door to the world of 3d printing. This marketplace connects makers, designers, and consumers together. Browse designs from a community of artists, and purchase the ones that fit your needs. You can also share your designs with others on Smthy. Help someone create something unique or even prototype or sell your own designs from Smthy.',
    disableBeacon: true,
  },
  {
    target: '#designs',
    content:
      'This is where  you can find all public facing designs that are on the SMTHY platform that you can find a Maker to print for you.',
  },
  {
    target: '#listings',
    content:
      'The listing section showcases all the orders that should be printed.',
  },
  {
    target: '#about',
    content: 'You can learn all about us on the about page.',
  },
  {
    target: '#uploadDesign',
    content:
      'Smithy allows you to upload both public and private designs. When designs are set to public, you allow other SMTHY users to print those designs for their own enjoyment! When designs are set to private, these designs can only be accessed by you and the maker you choose to work with to get it printed. You can always convert a private design to a public design and vice versa!',
  },
  {
    target: '#applyToBeMaker',
    content:
      'If you have a 3d printer, you can sign up here and start bidding on listings, offering your private designs in your store, and getting paid!',
    disableBeacon: true,
  },
  {
    target: '#menuAvatar',
    content:
      'The profile section allows you to manage your designs, listings, and offers easily.',
  },
]

export const UPLOAD_DESIGN = [
  {
    target: '#design-name',
    content:
      'The design name should be how you would refer to the item being produced by the maker.',
    disableBeacon: true,
  },
  {
    target: '#design-category',
    content:
      'Each design will belong to a category to help people find what they are looking for',
  },
  {
    target: '#model-description',
    content:
      'The description is where you communicate to the user what the end product is and what it is used for if applicable',
  },
  {
    target: '#preferred-material',
    content:
      'If you have a preference for a given type of material select it here.',
  },
  {
    target: '#measurement-change',
    content:
      'The physical dimensions in millimeters allows the end user to know the size of the item, and allows the maker to know if their equipment can create the item.',
  },
  {
    target: '#upload-design-image',
    content: 'Images of the item, cad diagrams, etc. can all be uploaded here.',
  },
  {
    target: '#upload-design-model',
    content: 'Up to 10 STL files can be uploaded per design.',
  },
  {
    target: '#estimated-info',
    content:
      'This section is an optional “Best Guess” estimate for the time and amount of material it will take to print the object. This is temporary and we are working on automating these estimates.',
  },
  {
    target: '#print-settings',
    content:
      'This section is used to communicate technical notes for the Maker when they go to print the item. Things like supports, infill, etc. go here.',
  },
  {
    target: '#additional-components-needed',
    content:
      'This section is used to communicate if there are non-printable components, for example screws, a motor, etc.',
  },
  {
    target: '#additional-tags',
    content:
      'This section allows you to tag your design with search terms that match the product. ie. A Mask may have tags of mask, personal, protective, equipment, fashion, etc.',
  },
  {
    target: '#update-public',
    content:
      'A public design will be available to anyone on the platform. A private design can only be accessed by you and the maker you choose to build your item.',
    disableBeacon: true,
  },
  {
    target: '#upload-design',
    content:
      'Once the form is complete, save the design and it will appear in your designs section.',
  },
]

export const DESIGN_PAGE = [
  {
    target: '#design-info',
    content: "The name of the design and the owner's name.",
    disableBeacon: true,
  },
  {
    target: '#design-dimensions',
    content: 'Here you can see the actual design dimensions.',
  },
  {
    target: '#build-design',
    content:
      'If you press this button a modal is displayed to build the current design.',
    disableBeacon: true,
  },
  {
    target: '#design-images',
    content: 'Here you can see the image of the current design.',
  },
]

export const CREATE_LISTING = [
  {
    target: '#image-viewer',
    content:
      'This viewer allows you to cycle through the available images for this design.',
    disableBeacon: true,
    placement: 'right',
    offset: -300,
  },
  {
    target: '#material-quantity-color',
    content:
      'Select the type of material, the desired color, and the quantity you’d like.',
    placement: 'left',
    offset: -50,
  },
  {
    target: '#switch-measurements',
    content:
      'This displays the physical dimensions of the item to be created as defined by the designer. You can switch between metric and imperial measurements.',
    placement: 'top',
    offset: -100,
  },
  {
    target: '#design-shipping',
    content:
      'This will default to your registered address, but you can update it to have the item shipped anywhere!.',
    placement: 'left',
    offset: -130,
    disableBeacon: true,
  },
  {
    target: '#design-create',
    content:
      'Once the form is filled out, you can submit this design as a listing in the marketplace for Makers to bid on',
  },
]

export const MAKE_AN_OFFER = [
  {
    target: '#offer-information',
    content:
      'This section is designed to give a rough estimate of print time and material. Use this data to estimate a price.',
    disableBeacon: true,
  },
  {
    target: '#offer-estimate',
    content:
      'Input your estimate for the print, include an estimated time it will take you to complete the project, and leave any notes you need for the user. Shipping is automatically calculated.',
    disableBeacon: true,
  },
  {
    target: '#submit-offer',
    content:
      'Once you’ve filled out your bid, submit it for the user to review!',
  },
]

export const LISTING_PAGE = [
  {
    target: '#view-listing-0',
    content: 'View listings posted by users looking to have their items built.',
    disableBeacon: true,
  },
  {
    target: '#listing-information-0',
    content: 'These are the high level technical details of the print.',
  },
]

export const MANAGE_STORE = [
  {
    target: '#manage-store',
    content:
      'The store is where you as a Maker can directly sell products that you have uploaded designs for and are able to print',
    disableBeacon: true,
    placement: 'top',
  },
  {
    target: '#alert-table',
    content:
      'The alerts section notifies you of when anything needs your attention. This is the same in all of the profile pages.',
    disableBeacon: true,
  },
  {
    target: '#my-designs',
    content:
      'The designs in this section are available for direct sale. Users who navigate to your profile will be able to see these items as available for purchase from you. Both public and private designs are eligable for the store section.',
  },
]
