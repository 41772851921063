import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { CardMedia, Fade, Grid, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100px',
    height: '100px',
    overflow: 'hidden',
  },
  currentButton: {
    color: theme.palette.text.gray,
  },
  closeFullScreen: {
    color: theme.palette.text.white,
    top: '0px !important',
  },
  display: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    right: '0px',
    fontSize: '36px',
  },
  fullScreenImage: {
    objectFit: 'contain',
    height: '100%',
    width: '99%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px',
    height: '90vh',
    width: '100vw',
    minHeight: '300px',
  },
  media: {
    alignItems: 'center',
    display: 'flex',
    width: '100px',
    height: '100px',
    '& .MuiCardMedia-img': {
      cursor: 'pointer',
      width: '70%',
    },
  },
}))

const ImageMedia = ({ image }) => {
  const classes = useStyles()
  const [openFullScreen, setOpenFullScreen] = useState(false)

  const handleOpen = () => {
    setOpenFullScreen(true)
  }

  const handleClose = () => {
    setOpenFullScreen(false)
  }

  return (
    <Grid item xs={6} className={classes.container}>
      <div className={classes.media}>
        <CardMedia component="img" image={image} onClick={handleOpen} />
      </div>
      <Modal
        className={classes.modal}
        open={openFullScreen}
        onClose={handleClose}
      >
        <Fade in={openFullScreen}>
          <div className={classes.paper}>
            <img alt="media" src={image} className={classes.fullScreenImage} />
            <ClearIcon
              className={clsx(classes.closeFullScreen, classes.display)}
              onClick={handleClose}
            />
          </div>
        </Fade>
      </Modal>
    </Grid>
  )
}

ImageMedia.propTypes = {
  image: PropTypes.string.isRequired,
}

export default ImageMedia
