import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Backdrop, Fade, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles(theme => ({
  closeFullScreen: {
    color: theme.palette.text.white,
    top: '0px !important',
  },
  display: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    right: '0px',
    fontSize: '36px',
  },
  fullScreenImage: {
    objectFit: 'contain',
    height: '100%',
    width: '99%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px',
    height: '90vh',
    width: '100vw',
  },
}))

const CarouselViewerModal = ({
  imageSelected,
  openFullScreen,
  setOpenFullScreen,
}) => {
  const carouselClasses = useStyles()

  const handleClose = () => {
    setOpenFullScreen(false)
  }

  return (
    <>
      <Modal
        className={carouselClasses.modal}
        open={openFullScreen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFullScreen}>
          <div className={carouselClasses.paper}>
            <img
              alt="Selected"
              src={imageSelected.props.content}
              className={carouselClasses.fullScreenImage}
            />
            <ClearIcon
              className={clsx(
                carouselClasses.closeFullScreen,
                carouselClasses.display
              )}
              onClick={handleClose}
            />
          </div>
        </Fade>
      </Modal>
    </>
  )
}

CarouselViewerModal.propTypes = {
  imageSelected: PropTypes.object.isRequired,
  openFullScreen: PropTypes.bool,
  setOpenFullScreen: PropTypes.func,
}

CarouselViewerModal.defaultProps = {
  openFullScreen: false,
}

export default CarouselViewerModal
