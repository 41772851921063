import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { formStyle } from 'components'

const initialState = {
  manufacturer: '',
  model: '',
  primaryMaterial: '',
  printTechnology: '',
  xyResolution: '',
  zResolution: '',
}

const initialOtherOption = {
  printTechnology: false,
  material: false,
  manufacturer: false,
}

const MakerForm = ({ manufacturers, materials, printTechnology }) => {
  const classes = formStyle()
  const [otherOption, setOtherOption] = useState(initialOtherOption)
  const [userState, setUserState] = useState(initialState)

  const setNumber = prop => event => {
    const {
      target: { value },
    } = event
    if (/^[0-9]*$/.test(value)) {
      setUserState({ ...userState, [prop]: value })
    }
  }

  const updateFieldWithPrinter = prop => event => {
    const {
      target: { value },
    } = event
    if (prop !== 'model' && value === 'Other') {
      setOtherOption({ ...otherOption, [prop]: true })
      setUserState({ ...userState, [prop]: '' })
    } else {
      setUserState({ ...userState, [prop]: value })
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          className={classes.styledField}
          fullWidth
          id="manufacturer"
          label="Manufacturer"
          placeholder="Manufacturer"
          required={true}
          select={!otherOption.manufacturer}
          size="small"
          value={userState.manufacturer}
          variant="outlined"
          onChange={updateFieldWithPrinter('manufacturer')}
        >
          {manufacturers.map(manufacture => (
            <MenuItem key={manufacture.name} value={manufacture.name}>
              {manufacture.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.styledField}
          fullWidth
          id="model"
          label="Model"
          placeholder="Model"
          required={true}
          size="small"
          value={userState.model}
          variant="outlined"
          onChange={updateFieldWithPrinter('model')}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.styledField}
          fullWidth
          id="printTechnology"
          label="Print Technology"
          placeholder="Print Technology"
          required={true}
          select={!otherOption.printTechnology}
          size="small"
          value={userState.printTechnology}
          variant="outlined"
          onChange={updateFieldWithPrinter('printTechnology')}
        >
          {printTechnology.map(technology => (
            <MenuItem key={technology.name} value={technology.name}>
              {technology.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          className={classes.styledField}
          fullWidth
          id="primaryMaterial"
          label="Primary Material"
          placeholder="Primary Material"
          required={true}
          select={!otherOption.primaryMaterial}
          size="small"
          value={userState.primaryMaterial}
          variant="outlined"
          onChange={updateFieldWithPrinter('primaryMaterial')}
        >
          {materials.map(material => (
            <MenuItem key={material.name} value={material.name}>
              {material.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={12} xs={12}>
        <Typography variant="h6">Resolution</Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          className={classes.styledField}
          fullWidth
          id="xyResolution"
          label="XY Resolution"
          placeholder="XY Resolution"
          size="small"
          value={userState.xyResolution}
          variant="outlined"
          onChange={setNumber('xyResolution')}
          InputProps={{
            endAdornment: <InputAdornment>Inches</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          className={classes.styledField}
          fullWidth
          id="zResolution"
          label="Z Resolution"
          placeholder="Z Resolution"
          size="small"
          value={userState.zResolution}
          variant="outlined"
          onChange={setNumber('zResolution')}
          InputProps={{
            endAdornment: <InputAdornment>Inches</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  )
}

MakerForm.propTypes = {
  manufacturers: PropTypes.arrayOf(PropTypes.object),
  materials: PropTypes.arrayOf(PropTypes.object),
  printTechnology: PropTypes.arrayOf(PropTypes.object),
}

MakerForm.defaultProps = {
  manufacturers: [],
  materials: [],
  printTechnology: [],
}

export default MakerForm
