import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { get, keyBy, size } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import {
  Carousel,
  CommonButton,
  ErrorMessage,
  InteractiveTutorial,
  ListingTechSpecs,
  ShippingInfo,
} from 'components'
import { updateCurrentUser } from 'actions/userActions'
import { LISTING_SUBMITTED } from 'constants/alertConstants'
import {
  CREATE_LISTING,
  TUTORIAL_NAMES,
} from 'constants/interactiveTutorialConstants'
import {
  INCH_TO_MM,
  INCHES_UNIT,
  MILLIMETER_UNIT,
} from 'constants/modelConstants'
import {
  ONBOARDING_ENDPOINT,
  SIGNUP_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from 'constants/apiUrls'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import {
  ERROR_SAVING_LISTING,
  LISTING_STATUS,
} from 'constants/listingConstants'
import { OFFER_STATUS } from 'constants/offerConstants'
import { ORDER_MADE } from 'constants/storeConstants'
import { USER_MENU } from 'constants/userConstants'
import { NOT_AVAILABLE_LABEL, UNITS } from 'constants/utils'
import { createAlert } from 'services/AlertService'
import { getDesign } from 'services/DesignService'
import { addShippingInfoEvent } from 'services/GoogleAnalyticsService'
import { createListing } from 'services/ListingService'
import { createListingShippingInfo } from 'services/ListingShippingInfoService'
import { sendMailNofification } from 'services/MailService'
import { createOffer } from 'services/OfferService'
import { getUserById } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  alignCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrow: {
    height: '18px',
    marginRight: '10px',
  },
  btnReturn: {
    marginLeft: '60%',
  },
  button: {
    width: '100%',
    margin: '0 20px',
    minWidth: '207px',
  },
  center: {
    margin: 'auto',
  },
  dialog: {
    width: '100%',
    '& .MuiDialogContent-root': {
      maxWidth: '1328px',
      '@media (max-width:600px)': {
        padding: 0,
      },
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'inherit',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
    },
  },
  footer: {
    width: '100%',
    textAlign: 'center',
    padding: '20px 0',
  },
  header: {
    margin: '0',
    paddingLeft: '24px',
    paddingTop: '20px',
    '& h2': {
      margin: 0,
      paddingLeft: '24px',
    },
  },
  layout: {
    margin: '20px',
    paddingTop: '20px',
  },
  margin: {
    marginTop: '15px',
  },
  title: {
    height: '144px',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.white,
    fontSize: '1.875rem',
    fontWeight: 'bold',
  },
}))
const MEASURES = ['width', 'height', 'depth']
const MINIMUM_SIZE = 0.001

const getFileName = design => design.split('/').pop()

const ESTIMATION_NOT_PROCESSED_BY_SLICER = {
  dimensions: {
    height: NOT_AVAILABLE_LABEL,
    length: NOT_AVAILABLE_LABEL,
    width: NOT_AVAILABLE_LABEL,
  },
  material_estimation: NOT_AVAILABLE_LABEL,
  print_time: {
    unit: UNITS.SECONDS,
    value: NOT_AVAILABLE_LABEL,
  },
  successfully_processed: false,
  unit: UNITS.CUBIC_MILIMITERS,
}

const generateMaterialPerFile = (designs, materialEstimations) => {
  const fileNames = designs.map(design => getFileName(design))
  const materialPerFile = []
  const estimations = keyBy(materialEstimations, 'file_name')
  fileNames.forEach(fileName => {
    const material = get(estimations, fileName, {
      ...ESTIMATION_NOT_PROCESSED_BY_SLICER,
      file_name: fileName,
    })
    materialPerFile.push(material)
  })
  return materialPerFile
}

const calculateWeightBasedOnSlicerEstimations = (weights, material) => {
  const weightFromSlicer = weights.find(item => item.material === material)

  if (weightFromSlicer && weightFromSlicer.weight) {
    return weightFromSlicer.weight
  }

  return 0
}

const sendMakerStoreMail = async (makerInfo, listingId) => {
  try {
    const { uid } = makerInfo
    const listingURL = `${process.env.GATSBY_API_URL}/listings/${listingId}`
    const maker = await getUserById(uid)
    const { email, first_name: firstName, last_name: lastName } = maker
    const userName = `${firstName} ${lastName}`
    return sendMailNofification(email, userName, true, listingURL)
  } catch (error) {
    throw error
  }
}

const CreateListing = ({
  model,
  open,
  storeListing,
  handleClose,
  handleOpen,
}) => {
  const loggedUser = useSelector(state => state.userState.user)
  const { catalog, error: catalogError } = useSelector(
    state => state.catalogState
  )
  const { material_colors: materialColors = [], materials = [] } = catalog
  const classes = useStyles()
  const [canCreate, setCanCreate] = useState(false)
  const [errorMessage, setErrorMessage] = useState(catalogError)
  const [initState, setInitState] = useState(false)
  const [isCreatingListing, setIsCreatingListing] = useState(false)
  const [shippingInfo, setShippingInfo] = useState({})
  const [unit, setUnit] = useState(MILLIMETER_UNIT)
  const [techSpecs, setTechSpecs] = useState({})
  const {
    category,
    description,
    material_estimations: estimatedMaterialPerFile = [],
    estimated_material: estimatedMaterial = {},
    estimated_print_time: estimatedPrintTime = {},
    images,
    thumbnail,
    name,
    ownedBy,
    id,
    is_support_required: isSupportRequired,
    tech_specs: { print_notes: printNotes = '' },
  } = model

  const validateShippingInfo = newShippingInfo => {
    const {
      city,
      companyAddress,
      country,
      firstName,
      lastName,
      phoneNumber,
      state,
      zipCode,
    } = newShippingInfo
    return (
      size(city) &&
      size(companyAddress) &&
      size(country) &&
      size(firstName) &&
      size(lastName) &&
      size(phoneNumber) &&
      size(state) &&
      size(zipCode)
    )
  }

  const validateTechSpecs = newTechSpecs => {
    const {
      width,
      height,
      depth,
      quantity,
      materialColor,
      material,
    } = newTechSpecs
    return (
      width > 0 &&
      height > 0 &&
      depth > 0 &&
      quantity > 0 &&
      size(material) &&
      size(materialColor)
    )
  }

  const updateTechSpecs = useCallback(
    newTechSpecs => {
      const isValid =
        validateTechSpecs(newTechSpecs) && validateShippingInfo(shippingInfo)
      setTechSpecs(newTechSpecs)
      setCanCreate(isValid)
    },
    [shippingInfo]
  )

  const updateShippingInfo = useCallback(
    newShippingInfo => {
      const isValid =
        validateShippingInfo(newShippingInfo) && validateTechSpecs(techSpecs)
      setShippingInfo(newShippingInfo)
      setCanCreate(isValid)
    },
    [techSpecs]
  )

  useEffect(() => {
    const retrieveUser = async () => {
      const {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        shipping_info: initialShippingInfo = {},
      } = loggedUser
      updateShippingInfo({
        city: initialShippingInfo.city,
        companyAddress: initialShippingInfo.company_address,
        companyName: initialShippingInfo.company_name,
        country: initialShippingInfo.country,
        firstName,
        lastName,
        phoneNumber,
        state: initialShippingInfo.state,
        zipCode: initialShippingInfo.zip_code,
      })
      const { tech_specs: initialTechSpecs } = model
      updateTechSpecs({ ...techSpecs, ...initialTechSpecs })
      setInitState(true)
    }

    if (!initState && loggedUser) {
      retrieveUser()
    }
  }, [
    initState,
    loggedUser,
    model,
    techSpecs,
    updateShippingInfo,
    updateTechSpecs,
  ])

  const handleCurrentUnit = unit => {
    setUnit(unit)
  }

  const saveListing = async () => {
    const { country, zipCode } = shippingInfo
    const { material } = techSpecs
    const modelDescription = {
      ...techSpecs,
      category,
      description,
      estimatedMaterial,
      estimatedMaterialPerFile,
      estimatedPrintTime,
      images,
      thumbnail,
      isSupportRequired,
      modelId: id,
      name,
      printNotes,
    }
    try {
      setIsCreatingListing(true)
      modelDescription.depth = parseFloat(modelDescription.depth).toFixed(3)
      modelDescription.height = parseFloat(modelDescription.height).toFixed(3)
      modelDescription.width = parseFloat(modelDescription.width).toFixed(3)

      if (unit === INCHES_UNIT) {
        for (const measure of MEASURES) {
          modelDescription[measure] = modelDescription[measure] * INCH_TO_MM
          if (modelDescription[measure] < MINIMUM_SIZE) {
            modelDescription[measure] = MINIMUM_SIZE
          }
        }
      }

      const lastestStateDesign = await getDesign(id)
      const {
        has_estimation_for_all_parts: hasEstimationForAllParts = false,
        weight_estimation_per_material: weights,
        material_estimations: materialEstimations,
        designs,
      } = lastestStateDesign
      const newEstimatedMaterialPerFile = generateMaterialPerFile(
        designs,
        materialEstimations
      )

      let weightFromSlicer

      if (hasEstimationForAllParts) {
        weightFromSlicer = calculateWeightBasedOnSlicerEstimations(
          weights,
          material
        )
      }

      modelDescription['estimatedMaterialPerFile'] = newEstimatedMaterialPerFile

      const listingId = await createListing(
        loggedUser,
        ownedBy,
        modelDescription,
        zipCode,
        country,
        LISTING_STATUS.SUBMITTED,
        hasEstimationForAllParts,
        weightFromSlicer
      )

      await updateUserShippingInfo(listingId, id, name, techSpecs.quantity)

      const createdBy = {
        first_name: loggedUser.first_name,
        last_name: loggedUser.last_name,
        uid: loggedUser.uid,
      }
      const listing = {
        uid: listingId,
        modelName: name,
      }
      await createAlert(createdBy, null, LISTING_SUBMITTED, listing)
      handleClose()
      navigate(USER_PROFILE_ENDPOINT(loggedUser.uid), {
        state: {
          collapsedListing: listingId,
          mylistings: true,
          option: USER_MENU.LIST,
        },
      })
    } catch (err) {
      console.log(err)
      setErrorMessage(ERROR_SAVING_LISTING)
    } finally {
      setIsCreatingListing(false)
    }
  }

  const saveStoreListing = async () => {
    const { modelId } = model
    const currentModel = await getDesign(modelId)
    const { designs, material_estimations: materialEstimations } = currentModel
    const { country, zipCode } = shippingInfo
    const modelTechSpecs = {
      ...model.tech_specs,
      material: model.material,
      printNotes: model.tech_specs.print_notes,
    }

    const modelDescription = {
      ...modelTechSpecs,
      category,
      description,
      estimatedMaterial,
      estimatedPrintTime,
      images,
      thumbnail,
      isSupportRequired,
      modelId: modelId,
      name,
      materialColor: techSpecs.materialColor,
      quantity: 1,
      storeId: model.uid,
    }
    const { material } = techSpecs
    const {
      has_estimation_for_all_parts: hasEstimationForAllParts = false,
      weight_estimation_per_material: weights,
    } = model

    try {
      setIsCreatingListing(true)
      let weightFromSlicer

      if (hasEstimationForAllParts) {
        weightFromSlicer = calculateWeightBasedOnSlicerEstimations(
          weights,
          material
        )
      }

      const newEstimatedMaterialPerFile = generateMaterialPerFile(
        designs,
        materialEstimations
      )
      modelDescription['estimatedMaterialPerFile'] = newEstimatedMaterialPerFile
      const listingId = await createListing(
        loggedUser,
        ownedBy,
        modelDescription,
        zipCode,
        country,
        LISTING_STATUS.OFFER_PENDING,
        hasEstimationForAllParts,
        weightFromSlicer
      )
      await createOffer(model.ownedBy, true, listingId, {
        deliveryDate: '',
        designFee: model.design_fee,
        notes: '',
        newOffer: model.offer,
        platformFee: model.platform_fee,
        status: OFFER_STATUS.SUBMITTED,
        totalFee: model.total_fee,
        shippingFee: 0,
        productionDays: 0,
      })
      await sendMakerStoreMail(model.ownedBy, listingId)
      await updateUserShippingInfo(listingId, model.uid, model.name, 1)
      const listingInfo = {
        modelName: model.name,
        uid: listingId,
      }
      await createAlert(loggedUser, model.ownedBy, ORDER_MADE, listingInfo)
      await createAlert(loggedUser, null, LISTING_SUBMITTED, listingInfo)
      handleClose()
      navigate(USER_PROFILE_ENDPOINT(loggedUser.uid), {
        state: {
          collapsedListing: listingId,
          mylistings: true,
          option: USER_MENU.LIST,
        },
      })
    } catch (err) {
      setErrorMessage(ERROR_SAVING_LISTING)
    } finally {
      setIsCreatingListing(false)
    }
  }

  const updateUserShippingInfo = async (
    listingId,
    modelId,
    modelName,
    quantity
  ) => {
    let updateUserInfo = false
    let newUser = { ...loggedUser }
    const { email } = loggedUser
    const {
      city,
      companyAddress,
      companyName,
      country,
      phoneNumber,
      state,
      zipCode,
    } = shippingInfo
    try {
      addShippingInfoEvent(listingId, modelId, modelName, quantity)
      await createListingShippingInfo(listingId, { ...shippingInfo, email })
      let shippingInfoProvided = {
        shipping_info: {
          city,
          company_address: companyAddress,
          company_name: companyName,
          country,
          state,
          zip_code: zipCode,
        },
      }
      if (!loggedUser.shipping_info) {
        updateUserInfo = true
        newUser = {
          ...newUser,
          ...shippingInfoProvided,
        }
      }
      if (!loggedUser.phone_number) {
        updateUserInfo = true
        newUser = {
          ...newUser,
          phone_number: phoneNumber,
        }
      }
      if (loggedUser.shipping_info && !loggedUser.shipping_info.city) {
        updateUserInfo = true
        newUser = {
          ...newUser,
          shipping_info: {
            ...newUser.shipping_info,
            city,
          },
        }
      }
      if (loggedUser.shipping_info && !loggedUser.shipping_info.company_name) {
        updateUserInfo = true
        newUser = {
          ...newUser,
          shipping_info: {
            ...newUser.shipping_info,
            company_name: companyName,
          },
        }
      }
      if (updateUserInfo) {
        await updateCurrentUser(newUser)
      }
    } catch (error) {
      setErrorMessage(ERROR_SAVING_LISTING)
    }
  }

  return (
    <>
      {loggedUser &&
        !loggedUser.isAnonymous &&
        !storeListing &&
        loggedUser.onboarded && (
          <CommonButton
            buttonStyle={BUTTON_STYLE.PRIMARY}
            fullWidth={true}
            label="Build this design"
            size={BUTTON_SIZE.MEDIUM}
            type={BUTTON_TYPE.SUBMIT}
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={handleOpen}
          />
        )}
      {loggedUser &&
        !loggedUser.isAnonymous &&
        !storeListing &&
        !loggedUser.onboarded && (
          <CommonButton
            buttonStyle={BUTTON_STYLE.PRIMARY}
            fullWidth={true}
            label="Please complete the onboarding process"
            size={BUTTON_SIZE.MEDIUM}
            type={BUTTON_TYPE.SUBMIT}
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={() => navigate(ONBOARDING_ENDPOINT)}
          />
        )}
      {loggedUser && loggedUser.isAnonymous && !storeListing && (
        <CommonButton
          buttonStyle={BUTTON_STYLE.PRIMARY}
          fullWidth={true}
          label="Want to build it? Please login/sign up"
          size={BUTTON_SIZE.MEDIUM}
          type={BUTTON_TYPE.SUBMIT}
          variant={BUTTON_VARIANT.OUTLINED}
          onClick={() => navigate(SIGNUP_ENDPOINT)}
        />
      )}
      <Dialog
        className={classes.dialog}
        open={open && loggedUser && !loggedUser.isAnonymous}
        onClose={handleClose}
        aria-labelledby="title"
      >
        <DialogTitle id="title">
          <div className={classes.header}>
            <h2>{storeListing ? 'Buy this Item' : 'Create Listing'}</h2>
          </div>
        </DialogTitle>
        <DialogContent>
          <ErrorMessage message={errorMessage} />
          <Grid container>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              id="image-viewer"
            >
              <Carousel slides={images} thumbnails={false} />
            </Grid>
            <Grid container item xs={12} sm={12} md={5} lg={5}>
              <ListingTechSpecs
                isThing={!!model.thingiverse_id}
                model={model}
                materials={materials}
                materialColors={materialColors}
                readOnly={storeListing}
                setCurrentUnit={handleCurrentUnit}
                updateTechSpecs={updateTechSpecs}
              />
            </Grid>
          </Grid>
          <Grid className={classes.margin} container>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              id="design-shipping"
            >
              <ShippingInfo
                loggedInUser={loggedUser}
                updateShippingInfo={updateShippingInfo}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container className={classes.footer} id="design-create">
            <Grid container item xs={12} sm={6} md={7} lg={7}>
              <Grid container item md={4} lg={5} />
              <Grid container item xs={12} sm={12} md={6} lg={5}>
                <div className={classes.button}>
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.CANCEL}
                    disabled={isCreatingListing}
                    fullWidth={true}
                    label="Cancel"
                    size={BUTTON_SIZE.LARGE}
                    type={BUTTON_TYPE.SUBMIT}
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={handleClose}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} md={5} lg={5}>
              <Grid container item xs={12} sm={12} md={7} lg={5}>
                <div className={classes.button}>
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.PRIMARY}
                    disabled={!canCreate || isCreatingListing}
                    fullWidth={true}
                    label="Create"
                    size={BUTTON_SIZE.LARGE}
                    type={BUTTON_TYPE.SUBMIT}
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={storeListing ? saveStoreListing : saveListing}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
        {open &&
          loggedUser &&
          !loggedUser.isAnonymous &&
          loggedUser.tutorial_state && (
            <InteractiveTutorial
              showTutorial={!loggedUser.tutorial_state.createListing}
              steps={CREATE_LISTING}
              tutorial={TUTORIAL_NAMES.CREATE_LISTING}
            />
          )}
      </Dialog>
    </>
  )
}

CreateListing.propTypes = {
  model: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  storeListing: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func,
}

CreateListing.defaultProps = {
  storeListing: false,
  handleOpen: () => {},
}

export default CreateListing
