import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import {
  Dialog,
  Grid,
  DialogContent,
  Typography,
  Link,
  Avatar,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { CustomDialogTitle, ErrorMessage } from 'components'
import { USER_PROFILE_ENDPOINT } from 'constants/apiUrls'
import { getUserById } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
    '& .MuiDialogTitle-root': {
      padding: '10px 0px',
    },
  },
  center: {
    width: '50%',
    margin: '0 auto',
    height: '100%',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      width: '700px',
      padding: '20px',
    },
  },
  subTitle: {
    fontWeight: 'bold',
  },
}))

const UserProfileDialog = ({ open, userId, setOpenUserProfileDialog }) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')
  const [user, setUser] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = await getUserById(userId)
      setUser(currentUser)
    }
    if (userId) {
      fetchData()
    }
  }, [userId])

  const handleClose = () => {
    setOpenUserProfileDialog(false)
    setErrorMessage('')
  }

  const goToProfile = uid => {
    navigate(USER_PROFILE_ENDPOINT(uid))
  }

  return (
    <>
      <Dialog className={classes.dialog} open={open} onClose={handleClose}>
        <div>
          <ErrorMessage message={errorMessage} />
          <div className={classes.boldTitle}>
            <CustomDialogTitle onClose={handleClose}>
              User Profile
            </CustomDialogTitle>
          </div>
          <DialogContent>
            {user && (
              <>
                <Grid className={classes.boldTitle} container spacing={1}>
                  <Grid item xs={4}>
                    <Avatar
                      alt="Profile"
                      src={user.photo_url}
                      className={clsx(classes.avatar, classes.center)}
                    >
                      {user.first_name[0]}
                    </Avatar>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography component="legend">
                      {user.first_name} {user.last_name}{' '}
                      <Link href="#" onClick={() => goToProfile(user.uid)}>
                        (profile)
                      </Link>
                    </Typography>

                    <Typography component="legend">
                      Email: {user.email}
                    </Typography>
                    <Typography component="legend">
                      Phone: {user.phone_number}
                    </Typography>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        Maker
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">
                        {user.isMaker ? 'true' : 'false'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        Operator
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">
                        {user.isOperator ? 'true' : 'false'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        On board
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">
                        {user.onboarded ? 'true' : 'false'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">{user.status}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        City
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">
                        {user.shipping_info.city}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        Company Address
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">
                        {user.shipping_info.company_address}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        Company
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">
                        {user.shipping_info.company_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        Country
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">
                        {user.shipping_info.country}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        State
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">
                        {user.shipping_info.state}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        className={classes.subTitle}
                        component="legend"
                      >
                        Zip
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography component="legend">
                        {user.shipping_info.zip_code}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
        </div>
      </Dialog>
    </>
  )
}

UserProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  setOpenUserProfileDialog: PropTypes.func.isRequired,
}

UserProfileDialog.defaultProps = {
  setOpenUserProfileDialog: () => {},
}

export default UserProfileDialog
