import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import CropFreeIcon from '@material-ui/icons/CropFree'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

const useStyles = makeStyles(theme => ({
  arrowButton: {
    backgroundColor: '#494949',
    color: 'white',
    cursor: 'pointer',
    fontSize: '30px',
    margin: '0 10px',
    maxHeight: '50px',
    opacity: '0.5',
    position: 'absolute',
    top: 'calc(50% - 20px)',
    transition: '200ms',
    '&:hover': {
      backgroundColor: 'black',
      opacity: '0.5',
    },
  },
  buttons: {
    cursor: 'pointer',
    height: '16px',
    width: '16px',
    color: '#C3C3C3',
  },
  currentButton: {
    color: theme.palette.text.gray,
  },
  display: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    right: '0px',
    fontSize: '36px',
  },
  hoverButton: {
    opacity: '0',
  },
  nextButton: {
    right: 0,
  },
  overlay: {
    position: 'absolute',
    height: 'fit-content',
    top: '90%',
    left: '50%',
    bottom: '0px',
    margin: 'auto',
    width: 'auto',
    transform: 'translate(-50%, -50%)',
  },
  previousButton: {
    left: 0,
  },
}))

const rightEffect = {
  slideIn: {
    slideIn: true,
    slideDirection: 'right',
  },
  slideOut: {
    slideIn: false,
    slideDirection: 'left',
  },
}

const CarouselControl = ({
  currentSlide,
  handleOpen,
  hoverNavButtons,
  indicators,
  leftEffect,
  slides,
  slidesState,
  thumbnails,
  updateCarouselImage,
}) => {
  const carouselClasses = useStyles()

  const jumpToCarouselImage = value => {
    const newIndex = parseInt(value)
    const { index } = currentSlide
    const transitionEffect = newIndex - index > 0 ? leftEffect : rightEffect
    if (index !== newIndex) {
      updateCarouselImage(newIndex, transitionEffect)
    }
  }

  return (
    <>
      {slidesState && slidesState.length > 1 && (
        <>
          <IconButton
            className={clsx(
              carouselClasses.arrowButton,
              carouselClasses.previousButton,
              {
                [carouselClasses.hoverButton]: hoverNavButtons,
              }
            )}
            aria-label="Previous"
            onClick={() => {
              currentSlide.index === 0
                ? jumpToCarouselImage(slidesState.length - 1)
                : jumpToCarouselImage(currentSlide.index - 1)
            }}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            className={clsx(
              carouselClasses.arrowButton,
              carouselClasses.nextButton,
              {
                [carouselClasses.hoverButton]: hoverNavButtons,
              }
            )}
            aria-label="Previous"
            onClick={() => {
              currentSlide.index === slidesState.length - 1
                ? jumpToCarouselImage(0)
                : jumpToCarouselImage(currentSlide.index + 1)
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </>
      )}
      {indicators && (
        <div className={carouselClasses.overlay}>
          {slides.map((value, index) => (
            <FiberManualRecordIcon
              key={uniqueId(`${value.title}_`)}
              className={clsx(carouselClasses.buttons, {
                [carouselClasses.currentButton]: index === currentSlide.index,
              })}
              onClick={() => jumpToCarouselImage(index)}
            />
          ))}
        </div>
      )}
      {thumbnails && (
        <CropFreeIcon
          className={carouselClasses.display}
          onClick={handleOpen}
        />
      )}
    </>
  )
}

CarouselControl.propTypes = {
  currentSlide: PropTypes.object,
  handleOpen: PropTypes.func,
  hoverNavButtons: PropTypes.bool,
  indicators: PropTypes.bool,
  leftEffect: PropTypes.object,
  slides: PropTypes.instanceOf(Array).isRequired,
  slidesState: PropTypes.instanceOf(Array).isRequired,
  thumbnails: PropTypes.bool,
  updateCarouselImage: PropTypes.func,
}

CarouselControl.defaultProps = {
  hoverNavButtons: false,
  indicators: true,
  thumbnails: false,
}

export default CarouselControl
