import React from 'react'
import clsx from 'clsx'
import { Button } from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import { connectPagination } from 'react-instantsearch-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  customBtn: {
    width: '30px',
    minWidth: '30px',
    margin: '3px',
  },
  notSelectedBtn: {
    color: 'unset',
  },
  selectedBtn: {
    color: 'white',
  },
}))

const CustomPagination = connectPagination(
  ({ padding = 2, refine, currentRefinement, nbPages }) => {
    const classes = useStyles()

    const paginationRange = (start, end) =>
      Array.from(
        { length: end - start + 1 },
        (_, pageIndex) => start + pageIndex
      )

    const startPage = Math.max(1, currentRefinement - padding)
    const endPage = Math.min(nbPages, currentRefinement + padding)

    return (
      <div>
        <Button
          className={classes.customBtn}
          color="secondary"
          disabled={currentRefinement <= 1 || nbPages <= 0}
          size="small"
          variant="contained"
          onClick={() => refine(1)}
        >
          <FirstPageIcon />
        </Button>
        {paginationRange(startPage, endPage).map(page => (
          <Button
            className={clsx(
              classes.customBtn,
              currentRefinement === page
                ? classes.selectedBtn
                : classes.notSelectedBtn
            )}
            color={currentRefinement === page ? 'primary' : 'default'}
            disabled={nbPages === 0}
            key={page}
            size="small"
            variant="contained"
            onClick={() => refine(page)}
          >
            {page}
          </Button>
        ))}
        <Button
          className={classes.customBtn}
          color="secondary"
          disabled={currentRefinement >= nbPages || nbPages <= 0}
          size="small"
          variant="contained"
          onClick={() => refine(nbPages)}
        >
          <LastPageIcon />
        </Button>
      </div>
    )
  }
)

export default CustomPagination
