import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab'
import {
  Avatar,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import { USER_PROFILE_ENDPOINT } from 'constants/apiUrls'
import { removeContactInformationFromMessage } from 'constants/utils'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: 'auto',
    width: '32px',
    height: '32px',
    backgroundColor: theme.palette.background.white,
  },
  cancelIcon: {
    color: theme.palette.background.red,
  },
  date: {
    color: theme.palette.text.gray,
    opacity: '0.5',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  description: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
  disableFeedTimeLine: {
    pointerEvents: 'none',
    marginTop: '-40px',
  },
  fullWidth: {
    width: '100%',
  },
  iconsContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '4px',
  },
  nameLabel: {
    color: theme.palette.text.link,
    cursor: 'pointer',
    fontSize: '0.5rem',
  },
  noOppositeContent: {
    '& .MuiTimelineItem-missingOppositeContent:before': {
      flex: 'unset',
    },
  },
  paper: {
    padding: '6px 16px',
  },
  saveIcon: {
    color: theme.palette.background.green,
  },
  secondaryTail: {
    backgroundColor: theme.palette.background.white,
  },
  spinner: {
    position: 'relative',
    top: '50%',
    left: '50%',
    zIndex: '10000000',
  },
  timelineDot: {
    height: '44px',
    width: '44px',
    backgroundColor: theme.palette.background.white,
    display: 'flex',
  },
  timelineItem: {
    minHeight: '83px',
  },
}))

const defaultEditionState = {
  text: '',
  isEditing: false,
  itemId: '',
}

const FeedTimeline = ({
  currentUser,
  feedList,
  loading,
  newItemPreview,
  onDelete,
  onEdit,
}) => {
  const classes = useStyles()
  const lastFeedItem =
    newItemPreview && newItemPreview.description.length > 0
      ? feedList.length
      : feedList.length - 1

  const [editionState, setEditionState] = useState(defaultEditionState)

  const goToProfile = feedItem => {
    navigate(USER_PROFILE_ENDPOINT(feedItem.uid))
  }

  const handleDelete = feedItemId => async event => {
    await onDelete(feedItemId)
  }

  const handleEditText = event => {
    const {
      target: { value },
    } = event
    const text = removeContactInformationFromMessage(value)
    setEditionState({
      ...editionState,
      text,
    })
  }

  const completeEdition = async () => {
    const { itemId, text } = editionState
    await onEdit(itemId, text)
    setEditionState(defaultEditionState)
  }

  const startEdition = (id, textToEdit) => event => {
    setEditionState({
      isEditing: true,
      itemId: id,
      text: textToEdit,
    })
  }

  return (
    <>
      {loading && <CircularProgress className={classes.spinner} size={40} />}
      <div
        className={clsx(
          {
            [classes.disableFeedTimeLine]: loading,
          },
          classes.fullWidth
        )}
      >
        <Timeline className={classes.noOppositeContent} align="left">
          {feedList.map((feedItem, i) => (
            <TimelineItem
              className={clsx({ [classes.timelineItem]: i !== lastFeedItem })}
              key={i}
            >
              <TimelineSeparator>
                <TimelineDot className={classes.timelineDot}>
                  <Avatar className={classes.avatar} src={feedItem.photo_url} />
                </TimelineDot>
                <Typography
                  className={classes.nameLabel}
                  onClick={() => goToProfile(feedItem)}
                >
                  {`${feedItem.first_name} ${feedItem.last_name.charAt(0)}.`}
                </Typography>
                {i !== lastFeedItem && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Grid container>
                    <Grid container item xs={12}>
                      <Grid container item xs={9} sm={10} md={11}>
                        <Typography className={classes.date} variant="caption">
                          {feedItem.date}
                        </Typography>
                      </Grid>
                      {currentUser &&
                        currentUser.uid === feedItem.uid &&
                        feedItem.action === 'INTERNAL_MESSAGE' && (
                          <Grid container item xs={3} sm={2} md={1}>
                            {editionState.isEditing &&
                            editionState.itemId === feedItem.id ? (
                              <>
                                <IconButton
                                  aria-label="save"
                                  className={clsx(
                                    classes.iconsContainer,
                                    classes.saveIcon
                                  )}
                                  onClick={completeEdition}
                                >
                                  <SaveIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  aria-label="cancel"
                                  className={clsx(
                                    classes.iconsContainer,
                                    classes.cancelIcon
                                  )}
                                  onClick={() =>
                                    setEditionState(defaultEditionState)
                                  }
                                >
                                  <CancelIcon fontSize="small" />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  aria-label="edit"
                                  className={classes.iconsContainer}
                                  onClick={startEdition(
                                    feedItem.id,
                                    feedItem.description
                                  )}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  className={classes.iconsContainer}
                                  onClick={handleDelete(feedItem.id)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </Grid>
                        )}
                    </Grid>
                    <Grid container item xs={12}>
                      {editionState.isEditing &&
                      editionState.itemId === feedItem.id ? (
                        <TextField
                          fullWidth={true}
                          value={editionState.text}
                          variant="outlined"
                          onChange={handleEditText}
                        />
                      ) : (
                        <Typography className={classes.description}>
                          {feedItem.description}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
          {newItemPreview && newItemPreview.description.length > 0 && (
            <TimelineItem key={lastFeedItem + 1}>
              <TimelineSeparator>
                <TimelineDot className={classes.timelineDot}>
                  <Avatar
                    className={classes.avatar}
                    src={newItemPreview.photo_url}
                  />
                </TimelineDot>
                <Typography
                  className={classes.nameLabel}
                  onClick={() => goToProfile(newItemPreview)}
                >
                  {`${
                    newItemPreview.first_name
                  } ${newItemPreview.last_name.charAt(0)}.`}
                </Typography>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography className={classes.date}>
                    {newItemPreview.date}
                  </Typography>
                  <Typography className={classes.description}>
                    {newItemPreview.description}
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
      </div>
    </>
  )
}

FeedTimeline.propTypes = {
  currentUser: PropTypes.object.isRequired,
  feedList: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  newItemPreview: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

FeedTimeline.defaultProps = {
  feedList: [],
  loading: false,
  newItemPreview: {
    description: '',
  },
}

export default FeedTimeline
