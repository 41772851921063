export const DEFAULT_LIMITS = [10, 25, 50]
export const ERROR_ACCEPTING_OFFERS =
  'An error occurred accepting this offer, please try again later.'
export const ERROR_FETCHING_OFFERS =
  'An error occurred while fetching the offers, please try again later.'
export const ERROR_SRIPE_OFFER = 'An error occurred, please try again.'
export const ERROR_STRIPE_PAYMENT = 'Payment failed, please try again.'
export const OFFER_ACTIONS = {
  SUBMITTED: 'SUBMITTED',
  ACCEPTED: 'START PRODUCTION',
  IN_PROGRESS: 'COMPLETE',
  COMPLETED: 'UPLOAD PICTURE',
  FULFILLED: 'FULFILLED',
}
export const OFFER_STATUS = {
  ACCEPTED: 'ACCEPTED',
  COMPLETED: 'COMPLETED',
  EXPIRED: 'EXPIRED',
  IN_PROGRESS: 'IN_PROGRESS',
  LISTING_DELIVERED: 'LISTING_DELIVERED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED',
}
export const SLANT_3D_STATUS = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
}
export const OFFERS_STATE = { ACTIVE: 'ACTIVE', CLOSED: 'CLOSED' }
