import React, { useState } from 'react'
import { size } from 'lodash'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { CommonButton, OffersTable } from 'components'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import { DEFAULT_LIMITS, ERROR_FETCHING_OFFERS } from 'constants/offerConstants'

import { getOffers } from 'services/OfferService'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
  offersButton: {
    textAlign: 'right',
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
}))

const OffersModal = ({
  listingId,
  modelId,
  modelName,
  quantity,
  onOfferAccepted,
}) => {
  const classes = useStyles()
  const [error, setError] = useState('')
  const [hasNext, setHasNext] = useState(false)
  const [latestElements, setLatestElements] = useState([])
  const [open, setOpen] = useState(false)
  const [offersList, setOffersList] = useState([])
  const [loading, setLoading] = useState(false)

  const getInitialOffers = async () => {
    setLoading(true)
    const limit = DEFAULT_LIMITS[2]
    setError('')
    try {
      const { lastElement, offers } = await getOffers(
        listingId,
        DEFAULT_LIMITS[2]
      )
      setHasNext(size(offers) === limit)
      setOffersList(offers)
      setLatestElements([lastElement])
    } catch (error) {
      setError(ERROR_FETCHING_OFFERS)
    }
    setLoading(false)
  }

  const nextPage = async (page, limit) => {
    const lastElement = latestElements[page - 1]
    return fetchOffers(limit, lastElement)
  }

  const prevPage = async (page, limit) => {
    const lastElement = page > 0 ? latestElements[page - 1] : null
    latestElements.pop()
    latestElements.pop()
    setLatestElements(latestElements)
    fetchOffers(limit, lastElement)
  }

  const fetchOffers = async (limit = DEFAULT_LIMITS[2], lastElement) => {
    setLoading(true)
    let updated = false
    setError('')
    try {
      const { lastElement: newLastElement, offers } = await getOffers(
        listingId,
        limit,
        lastElement
      )
      if (size(offers) > 0) {
        updated = true
        setHasNext(size(offers) === limit)
        setOffersList(offers)
        if (lastElement) {
          setLatestElements([...latestElements, newLastElement])
        } else {
          setLatestElements([newLastElement])
        }
      } else {
        setHasNext(false)
      }
    } catch (error) {
      setError(ERROR_FETCHING_OFFERS)
    }
    setLoading(false)
    return updated
  }

  const handleClose = () => {
    setOpen(false)
    setOffersList([])
  }

  const handleOpen = async () => {
    setOpen(true)
    await getInitialOffers()
  }

  return (
    <>
      <div className={classes.offersButton}>
        <CommonButton
          buttonStyle={BUTTON_STYLE.PRIMARY}
          fullWidth={false}
          label="Show Offers"
          variant={BUTTON_VARIANT.OUTLINED}
          onClick={handleOpen}
        />
      </div>
      <Dialog maxWidth={'lg'} fullWidth open={open} onClose={handleClose}>
        <DialogTitle className={classes.boldTitle}>Offers</DialogTitle>
        <DialogContent>
          {error && <div>{error}</div>}
          {loading && <CircularProgress className={classes.progress} />}
          <OffersTable
            disableOnClick={true}
            fetchOffers={fetchOffers}
            hasNext={hasNext}
            initRowsPerPage={DEFAULT_LIMITS[2]}
            listingId={listingId}
            modelId={modelId}
            modelName={modelName}
            offersList={offersList}
            quantity={quantity}
            onOfferAccepted={onOfferAccepted}
            needLoad={true}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
OffersModal.propTypes = {
  listingId: PropTypes.string,
  modelId: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  onOfferAccepted: PropTypes.func,
}
OffersModal.defaultProps = {
  listingId: '',
  onOfferAccepted: () => {},
}

export default OffersModal
