import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  Carousel,
  ErrorMessage,
  InteractiveTutorial,
  MeasurementChange,
  ProductStatusTracker,
} from 'components'
import { USER_PROFILE_ENDPOINT } from 'constants/apiUrls'
import {
  LISTING_PAGE,
  TUTORIAL_NAMES,
} from 'constants/interactiveTutorialConstants'
import {
  INCH_TO_MM,
  INCHES_UNIT,
  MILLIMETER_UNIT,
} from 'constants/modelConstants'
import { isATraceableStatus } from 'constants/utils'
import { getAcceptedOfferByListingId } from 'services/OfferService'

const useRowStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  borderBottom: {
    borderBottomColor: theme.palette.border.lightgray,
    borderBottomStyle: 'solid',
    borderBottomWidth: '3px',
  },
  carouselCell: {
    width: '25%',
    '& > *': {
      maxHeight: '250px',
    },
    '& .MuiCardMedia-root': {
      height: 'auto',
    },
    '& .MuiGrid-root': {
      height: '250px',
      paddingBottom: '0px',
      paddingTop: '0px',
      '& .CarouselSlide': {
        height: 'inherit',
        '& > *': {
          transform: 'unset',
          height: 'inherit',
          '& > *': {
            height: 'inherit',
            '& > *': {
              height: 'inherit',
            },
          },
        },
      },
    },
    '& .overlay': {
      backgroundColor: 'unset',
    },
    borderBottom: 'unset',
  },
  category: {
    '&.MuiTypography-root.MuiTypography-body1': {
      '@media (max-width:1110px)': {
        fontSize: '0.6rem',
      },
    },
  },
  centeredText: {
    textAlign: 'center',
    fontSize: '0.625rem',
    '& .MuiTypography-root': {
      fontSize: '0.625rem',
      margin: '4px',
    },
  },
  customMargin: {
    marginTop: '4px',
    paddingBottom: '4px',
  },
  infoCell: {
    width: '75%',
    borderBottom: 'unset',
  },
  innerCell: {
    textAlign: 'center',
  },
  link: {
    fontWeight: 'bold',
    color: theme.palette.text.link,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.text.linkHover,
    },
  },
  mainLabel: {
    fontWeight: 'bold',
    '&.MuiTypography-root': {
      fontSize: '0.875rem',
    },
  },
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  sideBorders: {
    borderLeftWidth: '3px',
    borderLeftColor: theme.palette.border.lightgray,
    borderLeftStyle: 'solid',
    borderRightWidth: '3px',
    borderRightColor: theme.palette.border.lightgray,
    borderRightStyle: 'solid',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  title: {
    color: theme.palette.text.gray,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}))

const showProductStatus = (status, belongsToCurrentUser) => {
  return belongsToCurrentUser && isATraceableStatus(status)
}

const CollapseContent = ({
  acknowledgeReceipt,
  currentUser,
  index,
  listing,
}) => {
  const classes = useRowStyles()
  const [errorMessage, setErrorMessage] = useState('')
  const [acceptedOffer, setAcceptedOffer] = useState(null)
  const [unit, setUnit] = useState(MILLIMETER_UNIT)
  const loggedUser = useSelector(state => state.userState.user)

  useEffect(() => {
    const getAcceptedOffer = async listingId => {
      setErrorMessage('')
      try {
        const offer = await getAcceptedOfferByListingId(listingId)
        setAcceptedOffer(offer)
      } catch (error) {
        setErrorMessage(error.message)
      }
    }
    if (
      currentUser.uid === listing.owned_by &&
      isATraceableStatus(listing.status) &&
      !acceptedOffer
    ) {
      getAcceptedOffer(listing.id)
    }
  }, [acceptedOffer, currentUser, listing, setAcceptedOffer])

  const { uid: userId } = currentUser
  const {
    category,
    country,
    description,
    designer_info: designerInfo = {},
    id,
    images = [],
    model_name: modelName,
    owned_by: ownedBy,
    tech_specs: techSpecs = {},
    shipping_tracking: shippingTracking = {},
    status,
    zip_code: zipCode,
  } = listing
  const {
    color,
    height,
    length,
    width,
    material,
    print_notes,
    quantity,
  } = techSpecs
  const [lengthMeasure, setLengthMeasure] = useState(length)
  const [heightMeasure, setHeightMeasure] = useState(height)
  const [widthMeasure, setWidthMeasure] = useState(width)
  const {
    first_name: firstName,
    last_name: lastName,
    uid: designerUid,
  } = designerInfo

  const handleChangeMeasurements = () => {
    const currentUnit = unit === MILLIMETER_UNIT ? INCHES_UNIT : MILLIMETER_UNIT
    setUnit(currentUnit)
    const condition =
      currentUnit === MILLIMETER_UNIT ? INCH_TO_MM : 1 / INCH_TO_MM

    setLengthMeasure(lengthMeasure * condition)
    setHeightMeasure(heightMeasure * condition)
    setWidthMeasure(widthMeasure * condition)
  }

  const formatMeasure = measure => parseFloat(measure).toFixed(3)

  const goToProfile = () => {
    if (designerUid) {
      navigate(USER_PROFILE_ENDPOINT(designerUid))
    }
  }

  return (
    <>
      <ErrorMessage message={errorMessage} />
      {showProductStatus(status, userId === ownedBy) && (
        <ProductStatusTracker
          acknowledgeReceipt={acknowledgeReceipt}
          offerDetails={{ ...acceptedOffer, id, status, shippingTracking }}
        />
      )}
      <Box className={classes.root} margin={1}>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.carouselCell} size="small">
                <Carousel
                  hoverNavButtons={true}
                  indicators={false}
                  slides={images}
                  hideOverlay={true}
                />
              </TableCell>
              <TableCell className={classes.infoCell}>
                <Grid container item xs={12} spacing={1}>
                  <Grid
                    container
                    id={`view-listing-${index}`}
                    item
                    xs={12}
                    spacing={1}
                  >
                    <Grid className={classes.title} item xs={12}>
                      Listing
                    </Grid>
                    <Grid className={classes.subtitle} item xs={12}>
                      {modelName}
                    </Grid>
                    <Grid className={classes.borderBottom} item xs={12}>
                      {description}
                    </Grid>
                  </Grid>
                  <Grid
                    className={clsx(
                      classes.borderBottom,
                      classes.centeredText,
                      classes.customMargin
                    )}
                    container
                    id={`listing-information-${index}`}
                    item
                    xs={12}
                  >
                    <Grid item xs={2}>
                      <Typography>Category</Typography>
                      <Typography
                        className={clsx(classes.mainLabel, classes.category)}
                      >
                        {category}
                      </Typography>
                    </Grid>
                    <Grid className={classes.sideBorders} item xs={2}>
                      <Typography>Material</Typography>
                      <Typography className={classes.mainLabel}>
                        {material}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>Color</Typography>
                      <Typography className={classes.mainLabel}>
                        {color}
                      </Typography>
                    </Grid>
                    <Grid className={classes.sideBorders} container item xs={4}>
                      <Grid item xs={12}>
                        <Typography>Size</Typography>
                        <MeasurementChange
                          groupSize="small"
                          unit={unit}
                          onHandleChangeMeasurements={handleChangeMeasurements}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Width</Typography>
                        <Typography className={classes.bold}>
                          {formatMeasure(widthMeasure)} {unit}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Length</Typography>
                        <Typography className={classes.bold}>
                          {formatMeasure(lengthMeasure)} {unit}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>Heigth</Typography>
                        <Typography className={classes.bold}>
                          {formatMeasure(heightMeasure)} {unit}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>Quantity</Typography>
                      <Typography className={classes.mainLabel}>
                        {quantity}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid className={classes.centeredText} item xs={4}>
                    <Typography className={classes.bold}>
                      Print Notes
                    </Typography>
                    <Typography>{print_notes}</Typography>
                  </Grid>
                  <Grid className={classes.centeredText} item xs={4}>
                    <Typography>Designer Name</Typography>
                    <Typography className={classes.link} onClick={goToProfile}>
                      {firstName} {lastName}
                    </Typography>
                  </Grid>
                  <Grid className={classes.centeredText} item xs={4}>
                    <Typography>Location</Typography>
                    <Typography className={classes.mainLabel}>
                      {country}
                    </Typography>
                    <Typography>Zip Code: {zipCode}</Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      {loggedUser &&
        !loggedUser.isAnonymous &&
        loggedUser.tutorial_state &&
        loggedUser.tutorial_state.manageStore && (
          <InteractiveTutorial
            showTutorial={!loggedUser.tutorial_state.listingPage}
            steps={LISTING_PAGE}
            tutorial={TUTORIAL_NAMES.LISTING_PAGE}
          />
        )}
    </>
  )
}

CollapseContent.propTypes = {
  acknowledgeReceipt: PropTypes.func,
  currentUser: PropTypes.object,
  index: PropTypes.number,
  listing: PropTypes.object.isRequired,
}

CollapseContent.defaultProps = {
  acknowledgeReceipt: () => {},
  currentUser: {},
}

export default CollapseContent
