import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { size } from 'lodash'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'

import {
  CommonButton,
  ConfirmationDialog,
  ErrorMessage,
  SkeletonListing,
  UserProfileDialog,
} from 'components'
import { MODEL_DETAILS_ENDPOINT } from 'constants/apiUrls'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import { DEFAULT_DESIGNS_LIMITS } from 'constants/modelConstants'
import { updateDesignByFields } from 'services/DesignService'
const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '460px',
    height: '64vh',
  },
  customBackGround: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.white,
  },
  fullHeight: {
    height: '100%',
  },
  link: {
    cursor: 'pointer',
    fontSize: '0.95rem',
    textDecoration: 'underline',
  },
  title: {
    marginLeft: '12px',
    marginTop: '12px',
    fontWeight: 'bold',
  },
  boldText: {
    '& .MuiToolbar-root .MuiTypography-root, .MuiInputBase-root .MuiSelect-root': {
      fontWeight: 'bold',
    },
  },
}))

const parseDate = timestamp => {
  let date = ''
  if (timestamp) {
    const getDate = timestamp.toDate().toISOString().split('T')[0]
    date = getDate.replace('-', '/').replace('-', '/')
  }
  return date
}

const DesignDashboardTable = ({
  currentPage,
  designs,
  fetchDesign,
  hasNext,
  initRowsPerPage,
  isFetching,
  menuOption,
  nextPage,
  prevPage,
}) => {
  const classes = useStyles()
  const [currentDesign, setCurrentDesign] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [page, setPage] = useState(currentPage)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openUserProfileDialog, setOpenUserProfileDialog] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage)
  const [userIdSelected, setUserIdSelected] = useState('')

  const handleOpenUserProfileDialog = uid => {
    setUserIdSelected(uid)
    setOpenUserProfileDialog(true)
  }

  const handleChangePage = async (event, newPage) => {
    let response = true

    if (newPage > page) {
      response = await nextPage(newPage, rowsPerPage, fetchDesign)
    } else {
      await prevPage(newPage, rowsPerPage, fetchDesign)
    }
    if (response) {
      setPage(newPage)
    }
  }

  const goToModel = name => {
    navigate(MODEL_DETAILS_ENDPOINT(name))
  }

  const handleChangeRowsPerPage = async event => {
    const {
      target: { value: newLimit },
    } = event
    setRowsPerPage(newLimit)
    setPage(0)
    await fetchDesign(menuOption, newLimit)
  }

  const openStatusDesignDialog = design => {
    setOpenDeleteDialog(true)
    setCurrentDesign(design)
  }

  const handleUpdateStatus = async () => {
    try {
      const { is_public } = currentDesign
      await updateDesignByFields(currentDesign.uid, { is_public: !is_public })
      fetchDesign({})
    } catch (error) {
      setErrorMessage(
        'An error occurred while attempting to update the model, please try again later'
      )
    }
  }

  const handleCancel = () => {
    setOpenDeleteDialog(false)
  }

  return (
    <Paper className={classes.fullHeight}>
      <UserProfileDialog
        userId={userIdSelected}
        open={openUserProfileDialog}
        setOpenUserProfileDialog={setOpenUserProfileDialog}
      />
      <Typography className={classes.title} variant="h6" component="div">
        Designs
      </Typography>
      <ErrorMessage message={errorMessage} />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="Designs table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.customBackGround}>ID</TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Name
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Created At
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Designer Name
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Designer Contact
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Number of STL files
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Successfully Processed
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Category
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Make Design Private
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isFetching ? (
              <>
                {designs.map((design, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Typography variant="caption">{design.uid}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        className={classes.link}
                        variant="caption"
                        onClick={() => goToModel(design.formatted_name)}
                      >
                        {design.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">
                        {parseDate(design.submission_date)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {design.ownedBy && (
                        <Typography
                          className={classes.link}
                          variant="caption"
                          onClick={() =>
                            handleOpenUserProfileDialog(design.designer.uid)
                          }
                        >
                          {design.ownedBy.first_name} {design.ownedBy.last_name}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">
                        {design.designer ? design.designer.email : ''}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">
                        {size(design.designs)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">
                        {design.has_estimation_for_all_parts ? 'True' : 'False'}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">
                        {design.category}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <CommonButton
                        buttonStyle={BUTTON_STYLE.PRIMARY}
                        disabled={false}
                        fullWidth={false}
                        label={
                          design.is_public ? 'Make Private' : 'Make Public'
                        }
                        variant={BUTTON_VARIANT.OUTLINED}
                        onClick={() => {
                          openStatusDesignDialog(design)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <SkeletonListing columns={9} />
            )}
          </TableBody>
        </Table>
        <ConfirmationDialog
          cancelLabel="No"
          confirmLabel="Yes"
          open={openDeleteDialog}
          title="Are you sure to change the design status?"
          onAccept={handleUpdateStatus}
          onCancel={handleCancel}
        />
      </TableContainer>
      <TablePagination
        className={classes.boldText}
        component="div"
        count={-1}
        nextIconButtonProps={{ disabled: !hasNext }}
        labelDisplayedRows={({ from, to }) => `${from}-${to}`}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={DEFAULT_DESIGNS_LIMITS}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

DesignDashboardTable.propTypes = {
  currentPage: PropTypes.number,
  designs: PropTypes.array.isRequired,
  fetchDesign: PropTypes.func.isRequired,
  hasNext: PropTypes.bool.isRequired,
  initRowsPerPage: PropTypes.number,
  menuOption: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
}

DesignDashboardTable.defaultProps = {
  currentPage: 0,
  initRowsPerPage: DEFAULT_DESIGNS_LIMITS[0],
}

export default DesignDashboardTable
