import {
  LOGIN,
  SIGNUP,
  UPDATE_OPTION_LOGIN,
  UPDATE_OPTION_SIGNUP,
} from 'constants/signupConstants'
import { store } from '../store'
const { dispatch } = store

export const updateToLoginOption = () => {
  return dispatch({
    type: UPDATE_OPTION_LOGIN,
    option: LOGIN,
  })
}

export const updateToSignupOption = () => {
  return dispatch({
    type: UPDATE_OPTION_SIGNUP,
    option: SIGNUP,
  })
}
