import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  menuContent: {
    maxHeight: '300px',
    width: '100%',
    overflowY: 'auto',
    zIndex: '1',
    '@media (max-width:414px)': {
      width: '200px',
    },
  },
  buttonStyles: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.white,
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 'bold',
    height: '100%',
    opacity: '0.8',
    textTransform: 'none',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      opacity: 1,
    },
    '@media (max-width:783px)': {
      fontSize: '0.75rem',
    },
    '@media (max-width:690px)': {
      fontSize: '0.6rem',
    },
  },
  buttonLabel: {
    '@media (max-width:414px)': {
      fontSize: '0.6rem',
    },
    '@media (max-width:281px)': {
      fontSize: '0.4rem',
    },
  },
  menu: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.white,
    fontWeight: 'bold',
  },
  menuItem: {
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.background.gray,
      opacity: 0.6,
    },
    '@media (max-width:783px)': {
      fontSize: '0.8rem',
    },
    '@media (max-width:660px)': {
      fontSize: '0.7rem',
    },
  },
  itemSelected: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.text.gray,
    opacity: 0.6,
    '&:hover': {
      color: theme.palette.text.gray,
      backgroundColor: theme.palette.background.main,
      opacity: 0.8,
    },
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  root: {
    display: 'flex',
    height: 'inherit',
    width: '100%',
  },
}))

const DropdownMenu = ({ initialLabel, menuOptions, onSelectOption }) => {
  const anchorRef = useRef(null)
  const classes = useStyles()
  const [label, setLabel] = useState(initialLabel)
  const [open, setOpen] = useState(false)
  const prevOpen = useRef(open)

  const handleClose = newValue => event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    if (newValue) {
      setLabel(newValue)
      onSelectOption(newValue)
    }
    setOpen(false)
  }

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root}>
      <Button
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        className={classes.buttonStyles}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        fullWidth
        ref={anchorRef}
        onClick={handleToggle}
      >
        <span className={classes.buttonLabel}>{label}</span>
      </Button>
      <Popper
        placement="top-start"
        className={classes.menuContent}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose()}>
            <MenuList
              className={classes.menu}
              autoFocusItem={open}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
            >
              {menuOptions.map(item => (
                <MenuItem
                  className={clsx(
                    classes.menuItem,
                    label === item ? classes.itemSelected : ''
                  )}
                  key={item}
                  onClick={handleClose(item)}
                >
                  {item}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  )
}

DropdownMenu.propTypes = {
  initialLabel: PropTypes.string,
  menuOptions: PropTypes.arrayOf(PropTypes.string),
  onSelectOption: PropTypes.func,
}

DropdownMenu.defaultProps = {
  initialLabel: 'All Categories',
  menuOptions: [],
  onSelectOption: () => {},
}

export default DropdownMenu
