import * as Firebase from 'firebase'
import getFirebase from './firebase'

import {
  INTERNAL_MESSAGE,
  LISTING_APPROVED,
  LISTING_AWAITING_APPROVAL,
  LISTING_PRODUCTION_STARTED,
  LISTING_RECEIVED,
  LISTING_REJECTED,
  LISTING_SHIPPED,
  LISTING_SUBMITTED,
  OFFER_ACCEPTED,
} from 'constants/alertConstants'

const firebase = getFirebase()

export const createAlert = (
  createdBy,
  createdFor,
  action,
  listing,
  message
) => {
  return new Promise((resolve, reject) => {
    if (firebase) {
      let newAlert = {
        created_at: Firebase.firestore.FieldValue.serverTimestamp(),
        action,
        read: false,
      }

      if (createdBy) {
        newAlert = {
          ...newAlert,
          generated_by: {
            first_name: createdBy.first_name,
            last_name: createdBy.last_name,
            uid: createdBy.uid,
          },
        }
      }

      if (createdFor) {
        newAlert = {
          ...newAlert,
          generated_for: {
            first_name: createdFor.first_name,
            last_name: createdFor.last_name,
            uid: createdFor.uid,
          },
        }
      }

      if (listing) {
        newAlert = {
          ...newAlert,
          listing: {
            model_name: listing.modelName,
            uid: listing.uid,
          },
        }
      }

      if (message) {
        newAlert = {
          ...newAlert,
          message,
        }
      }

      firebase
        .firestore()
        .collection('alerts')
        .add(newAlert)
        .then(docRef => {
          resolve(docRef.id)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const deleteAlert = alertId => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('alerts')
      .doc(alertId)
      .delete()
      .then(() => {
        resolve({})
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const fetchAlerts = (createdForId, startAfter) => {
  return new Promise((resolve, reject) => {
    let query = firebase
      .firestore()
      .collection('alerts')
      .where('generated_for.uid', '==', createdForId)
      .where('read', '==', false)
      .orderBy('created_at', 'desc')
      .limit(25)
    if (startAfter) {
      query = query.startAfter(startAfter)
    }

    query
      .get()
      .then(querySnapshot => {
        const lastAlert = querySnapshot.docs[querySnapshot.docs.length - 1]
        const alerts = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          uid: doc.id,
        }))
        resolve({ alerts, lastAlert })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const getFeedAlertsByListingId = listingId => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('alerts')
      .where('listing.uid', '==', listingId)
      .where('action', 'in', [
        INTERNAL_MESSAGE,
        LISTING_APPROVED,
        LISTING_AWAITING_APPROVAL,
        LISTING_PRODUCTION_STARTED,
        LISTING_RECEIVED,
        LISTING_REJECTED,
        LISTING_SHIPPED,
        LISTING_SUBMITTED,
        OFFER_ACCEPTED,
      ])
      .orderBy('created_at', 'asc')
      .get()
      .then(querySnapshot => {
        const alerts = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          uid: doc.id,
        }))
        resolve(alerts)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const markAlertAsRead = alertId => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('alerts')
      .doc(alertId)
      .update({
        read: true,
      })
      .then(() => {
        resolve(alertId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateAlert = (alertId, fields) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('alerts')
      .doc(alertId)
      .update({
        ...fields,
      })
      .then(() => {
        resolve(alertId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}
