import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import {
  Avatar,
  CircularProgress,
  Hidden,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined'
import PersonIcon from '@material-ui/icons/Person'
import { logout } from 'services/UserService'
import {
  updateToLoginOption,
  updateToSignupOption,
} from 'actions/signupActions'

import { CommonButton } from 'components'
import {
  DASHBOARD_ENDPOINT,
  SIGNUP_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from 'constants/apiUrls'
import {
  BUTTON_STYLE,
  BUTTON_SIZE,
  BUTTON_TYPE,
} from 'constants/buttonConstants'

const useStyles = makeStyles(theme => ({
  helpButton: {
    marginLeft: '3rem',
  },
  orOption: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
  backgroundColor: { backgroundColor: theme.palette.primary.main },
  clickable: {
    cursor: 'pointer',
  },
  padding: { padding: 0 },
  signUpButton: {
    marginRight: '12px',
    width: '150px',
  },
  styledMenuItem: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.white,
    fontSize: '0.875rem',
    '& .MuiListItemIcon-root': {
      color: theme.palette.text.white,
      minWidth: '30px',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  styledProgress: {
    height: '40px',
    color: theme.palette.secondary.main,
  },
}))

const defaultUserMenuState = {
  avatarURL: null,
  loggedIn: false,
}

const UserMenu = () => {
  const { user: loggedUser, isLoggingOut: loggingOut } = useSelector(
    state => state.userState
  )
  const classes = useStyles()
  const isMounted = useRef(true)
  const [loading, setLoading] = useState(true)
  const [userMenu, setUserMenu] = useState(defaultUserMenuState)
  const [menu, openMenu] = useState(null)

  const handleOpen = event => openMenu(event.currentTarget)
  const handleClose = () => openMenu(null)

  useEffect(() => {
    const setAvatar = () => {
      const { isAnonymous = false, photo_url } = loggedUser
      setUserMenu({
        avatarURL: photo_url,
        loggedIn: !isAnonymous,
      })
      isMounted.current && setLoading(false)
    }

    if (loggedUser) {
      setAvatar()
    }
  }, [loggedUser])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return (
    <>
      {loading ? (
        <CircularProgress className={classes.styledProgress} />
      ) : (
        <>
          {!loggingOut && userMenu.loggedIn ? (
            <>
              <div id="menuAvatar">
                <Avatar
                  src={userMenu.avatarURL}
                  className={classes.clickable}
                  onClick={handleOpen}
                />
              </div>
              <Menu
                anchorEl={menu}
                keepMounted
                open={Boolean(menu)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                MenuListProps={{
                  classes: { padding: classes.padding },
                }}
              >
                <MenuItem
                  onClick={() =>
                    navigate(USER_PROFILE_ENDPOINT(loggedUser.uid))
                  }
                  className={classes.styledMenuItem}
                >
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                {loggedUser.isOperator && (
                  <MenuItem
                    onClick={() => navigate(DASHBOARD_ENDPOINT)}
                    className={classes.styledMenuItem}
                  >
                    <ListItemIcon>
                      <DashboardIcon fontSize="small" />
                    </ListItemIcon>
                    Dashboard
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => logout()}
                  className={classes.styledMenuItem}
                >
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <span className={classes.signUpButton}>
                <CommonButton
                  buttonStyle={BUTTON_STYLE.ACCENT}
                  fullWidth={false}
                  label="Sign up now!"
                  size={BUTTON_SIZE.SMALL}
                  type={BUTTON_TYPE.SUBMIT}
                  onClick={() => {
                    updateToSignupOption()
                    navigate(SIGNUP_ENDPOINT)
                  }}
                />
              </span>

              <span className={classes.orOption}>Or</span>
              <CommonButton
                buttonStyle={BUTTON_STYLE.LINK}
                label="Login"
                fullWidth={false}
                onClick={() => {
                  updateToLoginOption()
                  navigate(SIGNUP_ENDPOINT)
                }}
              />
              <Hidden smDown>
                <IconButton className={classes.helpButton}>
                  <LiveHelpOutlinedIcon />
                </IconButton>
              </Hidden>
            </>
          )}
        </>
      )}
    </>
  )
}

export default UserMenu
