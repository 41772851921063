import * as Firebase from 'firebase'
import getFirebase from './firebase'
import sw from 'stopword'

const firebase = getFirebase()

export const generateKeywords = (
  name = '',
  description = '',
  additionalTags = ''
) => {
  const tags = additionalTags
    .split(',')
    .map(item => item.trim())
    .filter(item => item.length > 0)
  const nameWords = name.split(' ').filter(item => item.length > 2)
  const descriptionWords = description
    .split(' ')
    .filter(item => item.length > 2 && !nameWords.includes(item))
  return sw.removeStopwords([...nameWords, ...descriptionWords, ...tags])
}

export const createDesign = (design, designId, loggedInUser) => {
  return new Promise((resolve, reject) => {
    if (firebase) {
      const {
        additionalComponents,
        additionalTags,
        category,
        description,
        designs,
        estimatedMaterial,
        estimatedPrintTime,
        formattedName,
        hasEstimationForAllParts = false,
        images,
        thumbnail,
        isPublic,
        isSupportRequired,
        name,
        printNotes,
        properties,
        standardName,
      } = design

      if (!loggedInUser.first_name) {
        loggedInUser.first_name = ''
        loggedInUser.last_name = ''
      }

      const newDesign = {
        additionalComponents,
        additionalTags,
        category,
        description,
        designs,
        estimated_material: estimatedMaterial,
        estimated_print_time: estimatedPrintTime,
        formatted_name: formattedName,
        images,
        thumbnail,
        has_estimation_for_all_parts: hasEstimationForAllParts,
        is_deleted: false,
        is_public: isPublic,
        is_support_required: isSupportRequired,
        keywords: generateKeywords(name, description, additionalTags),
        name,
        standard_name: standardName,
        ownedBy: {
          first_name: loggedInUser.first_name,
          last_name: loggedInUser.last_name,
          photo_url: loggedInUser.photo_url,
          uid: loggedInUser.uid,
        },
        submission_date: Firebase.firestore.FieldValue.serverTimestamp(),
        tech_specs: { ...properties, print_notes: printNotes },
        update_date: Firebase.firestore.FieldValue.serverTimestamp(),
      }

      firebase
        .firestore()
        .collection('models')
        .doc(designId)
        .set(newDesign)
        .then(() => {
          resolve({ ...newDesign, uid: designId })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const fetchDesigns = (
  userUid,
  limit = 15,
  startAfter,
  includePrivateDesigns = true
) => {
  return new Promise((resolve, reject) => {
    let query = firebase
      .firestore()
      .collection('models')
      .where('is_deleted', '==', false)
      .orderBy('submission_date', 'desc')
      .limit(limit)
    if (startAfter) {
      query = query.startAfter(startAfter)
    }
    if (userUid) {
      query = query.where('ownedBy.uid', '==', userUid)
    }
    if (!includePrivateDesigns) {
      query = query.where('is_public', '==', true)
    }
    query
      .get()
      .then(querySnapshot => {
        const lastElement = querySnapshot.docs[querySnapshot.docs.length - 1]
        const designs = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          uid: doc.id,
        }))
        resolve({ designs, lastElement })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const getDesign = uid => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('models')
      .doc(uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          resolve({ id: doc.id, ...doc.data() })
        } else {
          reject({})
        }
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const getLatestDesignByName = name => {
  return new Promise((resolve, reject) => {
    let query = firebase
      .firestore()
      .collection('models')
      .where('standard_name', '==', name)
      .orderBy('update_date', 'desc')
      .limit(1)
    if (firebase) {
      query
        .get()
        .then(querySnapshot => {
          resolve(querySnapshot.docs.map(doc => doc.data())[0])
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const generateDesignId = () => {
  const designRef = firebase.firestore().collection('models').doc()
  return designRef.id
}

export const markAsDeleted = designId => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('models')
      .doc(designId)
      .update({
        is_deleted: true,
      })
      .then(() => {
        resolve(designId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateDesignCreateSection = (design, designId, loggedUser) => {
  return new Promise((resolve, reject) => {
    const { hasEstimationForAllParts = false } = design
    if (firebase) {
      let newDesign = {
        additionalComponents: design.additionalComponents,
        category: design.category,
        description: design.description,
        designs: design.designs,
        formatted_name: design.formattedName,
        has_estimation_for_all_parts: hasEstimationForAllParts,
        is_public: design.isPublic,
        is_support_required: design.isSupportRequired,
        images: design.images,
        keywords: generateKeywords(
          design.name,
          design.description,
          design.additionalTags
        ),
        name: design.name,
        standard_name: design.standardName,
        update_date: Firebase.firestore.FieldValue.serverTimestamp(),
        tech_specs: { ...design.properties, print_notes: design.printNotes },
      }

      if (design.estimatedMaterial) {
        newDesign = {
          ...newDesign,
          estimated_material: design.estimatedMaterial,
        }
      }
      if (design.estimatedPrintTime) {
        newDesign = {
          ...newDesign,
          estimated_print_time: design.estimatedPrintTime,
        }
      }
      if (design.weightsByMaterial) {
        newDesign['weight_estimation_per_material'] = design.weightsByMaterial
      }

      firebase
        .firestore()
        .collection('models')
        .doc(designId)
        .update(newDesign)
        .then(() => {
          resolve({ ...newDesign, uid: designId })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const updateDesign = (design, designId, loggedUser) => {
  return new Promise((resolve, reject) => {
    const { hasEstimationForAllParts = false } = design
    if (firebase) {
      let newDesign = {
        additionalComponents: design.additionalComponents,
        additionalTags: design.additionalTags,
        category: design.category,
        description: design.description,
        designs: Firebase.firestore.FieldValue.arrayRemove(
          ...design.previousDesigns
        ),
        formatted_name: design.formattedName,
        has_estimation_for_all_parts: hasEstimationForAllParts,
        is_public: design.isPublic,
        is_support_required: design.isSupportRequired,
        images: Firebase.firestore.FieldValue.arrayRemove(
          ...design.previousImages
        ),
        keywords: generateKeywords(
          design.name,
          design.description,
          design.additionalTags
        ),
        name: design.name,
        standard_name: design.standardName,
        update_date: Firebase.firestore.FieldValue.serverTimestamp(),
        tech_specs: { ...design.properties, print_notes: design.printNotes },
        thumbnail: design.thumbnail,
      }

      if (design.estimatedMaterial) {
        newDesign = {
          ...newDesign,
          estimated_material: design.estimatedMaterial,
        }
      }

      if (design.images) {
        newDesign = {
          ...newDesign,
          images: design.images,
        }
      }

      if (design.thumbnail) {
        newDesign = {
          ...newDesign,
          thumbnail: design.thumbnail,
        }
      }

      if (design.designs) {
        newDesign = {
          ...newDesign,
          designs: design.designs,
        }
      }

      if (design.estimatedPrintTime) {
        newDesign = {
          ...newDesign,
          estimated_print_time: design.estimatedPrintTime,
        }
      }

      if (design.weightsByMaterial) {
        newDesign['weight_estimation_per_material'] = design.weightsByMaterial
      }

      firebase
        .firestore()
        .collection('models')
        .doc(designId)
        .update(newDesign)
        .then(() => {
          resolve({ ...newDesign, uid: designId })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}
export const updateDesignByFields = (modelId, fieldsToUpdate) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('models')
      .doc(modelId)
      .update({
        ...fieldsToUpdate,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(modelId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const addDesignReview = (
  designId,
  listingId,
  makerId,
  designReview,
  reviewer
) => {
  return new Promise((resolve, reject) => {
    const { qualityAccuracy, material, finish, notes } = designReview
    const { firstName, lastName, photoUrl, uid } = reviewer
    return firebase
      .firestore()
      .collection('models')
      .doc(designId)
      .collection('reviews')
      .add({
        comment_review: notes,
        finish,
        listing_id: listingId,
        makerId,
        material,
        quality_accuracy: qualityAccuracy,
        reviewer_info: {
          uid: uid,
          first_name: firstName,
          last_name: lastName,
          photo_url: photoUrl,
        },
        created_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(docRef => {
        resolve(docRef.id)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const fetchDesignReviews = (designId, limit, startAfter) => {
  return new Promise((resolve, reject) => {
    let query = firebase
      .firestore()
      .collection('models')
      .doc(designId)
      .collection('reviews')
      .orderBy('created_at', 'desc')
      .limit(limit)
    if (startAfter) {
      query = query.startAfter(startAfter)
    }
    query
      .get()
      .then(querySnapshot => {
        const lastElement = querySnapshot.docs[querySnapshot.docs.length - 1]
        const reviews = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          uid: doc.id,
        }))
        resolve({ reviews, lastElement })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}
