import React from 'react'
import { Typography } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { makeStyles } from '@material-ui/core/styles'
import { DesignModal } from 'components'

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: 100,
  },
  upperCase: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  mainMessage: {
    textAlign: 'center',
    marginTop: '25vh',
  },
  button: {
    width: '200px',
    marginLeft: '50%',
    marginTop: '10px',
    transform: 'translateX(-60%)',
  },
}))

const NoDesignsComponent = () => {
  const classes = useStyles()
  return (
    <div className={classes.mainMessage}>
      <CloudUploadIcon className={classes.icon} />
      <Typography className={classes.upperCase} variant="subtitle2">
        you don't have designs at the moment
      </Typography>
      <div className={classes.button}>
        <DesignModal />
      </div>
    </div>
  )
}

export default NoDesignsComponent
