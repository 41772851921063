import axios from 'axios'
import {
  COPY_THINGS_TO_MODELS,
  GET_FILES,
  GET_THING,
  VALIDATE_CODE,
} from 'constants/thingiverseEndpointsConstants'

export const validateCode = async (userId, code) => {
  try {
    const response = await axios.post(VALIDATE_CODE, {
      userId,
      code,
    })
    const {
      data: { message },
    } = response
    return message
  } catch (error) {
    throw error
  }
}

export const importThingsToDesigns = async (userId, publicModels) => {
  try {
    const response = await axios.post(COPY_THINGS_TO_MODELS, {
      userId,
      publicModels,
    })
    const {
      data: { message },
    } = response
    return message
  } catch (error) {
    throw error
  }
}

export const getThingiverseDesign = async thingId => {
  try {
    const response = await axios.get(GET_THING(thingId))
    return response
  } catch (error) {
    console.log(error.message)
    throw error
  }
}

export const getFiles = async thingId => {
  try {
    const response = await axios.get(GET_FILES(thingId))
    return response
  } catch (error) {
    console.log(error.message)
    throw error
  }
}
