import React from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import clsx from 'clsx'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import logoWhite from 'assets/logo_white.svg'

import {
  ABOUT_US_ENDPOINT,
  CONTACT_US_ENDPOINT,
  EAP_ENDPOINT,
  HOME_ENDPOINT,
  LISTINGS_ENDPOINT,
  TERMS_OF_SERVICE_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from 'constants/apiUrls'
import { USER_MENU } from 'constants/userConstants'

const useStyles = makeStyles(theme => ({
  withPointer: {
    cursor: 'pointer',
  },
  root: {
    flexGrow: 1,
    color: theme.palette.text.white,
    fontSize: '0.625rem',
    '& .MuiGrid-root': {
      padding: '4px',
    },
    overflow: 'hidden',
  },
  borders: {
    borderLeftColor: theme.palette.background.white,
    borderLeftStyle: 'solid',
    borderLeftWidth: '1px',
    borderRightColor: theme.palette.background.white,
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
  },
  container: {
    background: theme.palette.background.green,
    borderTopRightRadius: '50%',
    borderTopLeftRadius: '50%',
    marginLeft: '-100px',
    marginRight: '-100px',
    overflow: 'hidden',
    width: 'calc(100% + 200px)',
    margin: '0',
    '@media (max-width:598px)': {
      marginLeft: '-200px',
      marginRight: '-200px',
      width: 'calc(100% + 400px)',
    },
  },
  innerContainer: {
    marginLeft: '100px',
    marginRight: '100px',
    maxWidth: '1500px',
    '@media (max-width:598px)': {
      marginLeft: '200px',
      marginRight: '200px',
    },
  },
  alignCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  darkBackground: {
    backgroundColor: theme.palette.background.darkblue,
  },
  displayFlex: {
    display: 'flex',
  },
  fontSize: {
    fontSize: '0.625rem',
  },
  bold: {
    fontSize: '0.625rem',
    fontWeight: 'bold',
  },
  boldText: {
    fontWeight: 'bold',
  },
  marginLeft: {
    '& .MuiTypography-root': {
      paddingLeft: '8px',
    },
  },
  nonMarginTop: {
    marginTop: '0px !important',
  },
  textAlignCenter: {
    textAlign: 'center',
    '& .label': {
      color: theme.palette.text.pearl,
      cursor: 'pointer',
      marginLeft: '8px',
      marginRight: '8px',
    },
  },
  verticalAlign: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

const Footer = () => {
  const { user: loggedUser } = useSelector(state => state.userState)
  const classes = useStyles()

  return (
    <footer>
      <Grid container className={classes.root}>
        <Grid
          container
          className={classes.container}
          justify="center"
          spacing={4}
        >
          <Grid className={classes.innerContainer} container item>
            <Grid className={classes.displayFlex} item xs={12}>
              <img
                className={classes.alignCenter}
                src={logoWhite}
                alt={''}
                width="auto"
                height="48"
              />
            </Grid>
            <Grid container item xs={3}>
              <Grid
                className={classes.verticalAlign}
                item
                xs={12}
                sm={1}
              ></Grid>
              <Grid
                className={clsx(classes.nonMarginTop, classes.verticalAlign)}
                item
                xs={12}
                sm={5}
              >
                <Typography align="left" className={classes.bold} variant="h4">
                  NAVIGATION
                </Typography>
                <Typography
                  align="left"
                  className={clsx(classes.fontSize, classes.withPointer)}
                  variant="body2"
                  onClick={() => navigate(HOME_ENDPOINT)}
                >
                  Browse designs
                </Typography>
                {loggedUser && (loggedUser.isMaker || loggedUser.isOperator) && (
                  <Typography
                    align="left"
                    className={clsx(classes.fontSize, classes.withPointer)}
                    variant="body2"
                    onClick={() => navigate(LISTINGS_ENDPOINT)}
                  >
                    View Listings
                  </Typography>
                )}
                <Typography
                  align="left"
                  className={clsx(
                    classes.fontSize,
                    classes.verticalAlign,
                    classes.withPointer
                  )}
                  variant="body2"
                  onClick={() => navigate(EAP_ENDPOINT)}
                >
                  Early Adopter
                </Typography>
              </Grid>
              {loggedUser && !loggedUser.isAnonymous && (
                <Grid className={classes.verticalAlign} item xs={12} sm={6}>
                  <Typography
                    align="left"
                    className={classes.bold}
                    variant="h4"
                  >
                    YOUR ACCOUNT
                  </Typography>
                  <Typography
                    align="left"
                    className={clsx(classes.fontSize, classes.withPointer)}
                    variant="body2"
                    onClick={() =>
                      navigate(USER_PROFILE_ENDPOINT(loggedUser.uid), {
                        state: { option: USER_MENU.DESIGNS },
                      })
                    }
                  >
                    View profile
                  </Typography>
                  <Typography
                    align="left"
                    className={clsx(classes.fontSize, classes.withPointer)}
                    variant="body2"
                    onClick={() =>
                      navigate(USER_PROFILE_ENDPOINT(loggedUser.uid), {
                        state: { option: USER_MENU.LIST },
                      })
                    }
                  >
                    Your listings
                  </Typography>
                  <Typography
                    align="left"
                    className={clsx(classes.fontSize, classes.withPointer)}
                    variant="body2"
                    onClick={() =>
                      navigate(USER_PROFILE_ENDPOINT(loggedUser.uid), {
                        state: { option: USER_MENU.OFFERS },
                      })
                    }
                  >
                    Your offers
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              className={clsx(
                classes.borders,
                classes.displayFlex,
                classes.textAlignCenter
              )}
              item
              xs={6}
            >
              <Typography
                className={clsx(
                  classes.fontSize,
                  classes.verticalAlign,
                  classes.alignCenter
                )}
                variant="body2"
              >
                Powered by&nbsp;
                <span className={classes.boldText}>
                  &copy;{new Date().getFullYear()} - SMTHY Enterprises LLC
                </span>
              </Typography>
            </Grid>
            <Grid
              className={clsx(classes.marginLeft, classes.nonMarginTop)}
              item
              xs={3}
            >
              <Typography
                align="left"
                className={clsx(classes.bold, classes.verticalAlign)}
                variant="body2"
              >
                US
              </Typography>
              <Typography
                align="left"
                className={clsx(
                  classes.fontSize,
                  classes.verticalAlign,
                  classes.withPointer
                )}
                variant="body2"
                onClick={() => navigate(ABOUT_US_ENDPOINT)}
              >
                About smthy
              </Typography>
              <Typography
                align="left"
                className={clsx(
                  classes.fontSize,
                  classes.verticalAlign,
                  classes.withPointer
                )}
                variant="body2"
                onClick={() => navigate(TERMS_OF_SERVICE_ENDPOINT)}
              >
                Privacy Policy
              </Typography>
              <Typography
                align="left"
                className={clsx(
                  classes.fontSize,
                  classes.verticalAlign,
                  classes.withPointer
                )}
                variant="body2"
                onClick={() => navigate(CONTACT_US_ENDPOINT)}
              >
                Contact us
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer
