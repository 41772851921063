import JsZip from 'jszip'
import passwordValidator from 'password-validator'
import { saveAs } from 'file-saver'
import { findPhoneNumbersInText } from 'libphonenumber-js'
import * as linkify from 'linkifyjs'
import { isEmpty, isNaN, round, size, sortBy, toNumber } from 'lodash'
import { CountryRegionData } from 'react-country-region-selector'
import { LISTING_STATUS } from './listingConstants'
import { getDesign } from 'services/DesignService'
import { INVALID_PASSWORD, MISMATCHED } from 'constants/userConstants'
import { downloadFile, downloadThingFile } from 'services/StorageService'
import { getFiles } from 'services/ThingiverseService'

export const FILE_TYPES_TO_UPLOAD = { IMAGE: 'IMAGE', DESIGN: 'DESIGN' }
const REGEX_STORED_FILE = /.*images%2F(.*)/
const traceableStatus = [
  LISTING_STATUS.APPROVED,
  LISTING_STATUS.AWAITING,
  LISTING_STATUS.AWAITING_APPROVAL,
  LISTING_STATUS.IN_PROGRESS,
  LISTING_STATUS.OFFER_ACCEPTED,
  LISTING_STATUS.SHIPPED,
]

export const NOT_AVAILABLE_LABEL = 'N/A'

export const UNITS = {
  CENTIMETER: 'centimeter',
  CUBIC_MILIMITERS: 'CUBIC_MILIMITERS',
  GRAM: 'gram',
  INCH: 'inch',
  KILOGRAM: 'kilogram',
  OUNCE: 'ounce',
  POUND: 'pound',
  SECONDS: 'seconds',
}
export const MATERIALS = {
  ABS: 'ABS',
  PETG: 'PETG',
  PLA: 'PLA',
  RESIN: 'Resin',
  NYLON: 'Nylon',
}
const MATERIAL_DENSITY_GRAM_CM3 = {
  ABS: 1.04,
  PETG: 1.38,
  PLA: 1.24,
  RESIN: 1.2,
  NYLON: 1.15,
}

const MINIMUM_VALUE_DIMENSIONS_MILLIMETERS = 152.4

const FILAMENT_DIAMETER = 1.75

export const calculateWeightByDimensions = (material, depth, height, width) => {
  const parsedDepth = Math.max(depth, MINIMUM_VALUE_DIMENSIONS_MILLIMETERS)
  const parsedHeight = Math.max(height, MINIMUM_VALUE_DIMENSIONS_MILLIMETERS)
  const parsedWidth = Math.max(width, MINIMUM_VALUE_DIMENSIONS_MILLIMETERS)
  const volume =
    (parsedDepth / 10) * (parsedHeight / 10) * (parsedWidth / 10) * 0.4
  const radius = FILAMENT_DIAMETER / 2
  const length = volume / (Math.PI * Math.pow(radius, 2))
  const materialUpperCase = material.toUpperCase()
  return parseFloat(
    (MATERIAL_DENSITY_GRAM_CM3[materialUpperCase] * length).toFixed(2)
  )
}

export const generateWeigthsByMaterial = materialEstimations => {
  const { ABS, PETG, PLA, RESIN, NYLON } = MATERIAL_DENSITY_GRAM_CM3
  const unit = UNITS.GRAM
  let totalMaterial = 0
  materialEstimations.forEach(estimation => {
    const { material_estimation: materialEstimation = 0 } = estimation
    if (toNumber(materialEstimation)) {
      totalMaterial = totalMaterial + toNumber(materialEstimation)
    }
  })

  const absWeight = round(ABS * (totalMaterial / 1000), 3)
  const petgWeight = round(PETG * (totalMaterial / 1000), 3)
  const plaWeight = round(PLA * (totalMaterial / 1000), 3)
  const resinWeight = round(RESIN * (totalMaterial / 1000), 3)
  const nylonWeight = round(NYLON * (totalMaterial / 1000), 3)

  return [
    {
      material: 'ABS',
      weight: absWeight,
      unit,
    },
    {
      material: 'PETG',
      weight: petgWeight,
      unit,
    },
    {
      material: 'PLA',
      weight: plaWeight,
      unit,
    },
    {
      material: 'Resin',
      weight: resinWeight,
      unit,
    },
    {
      material: 'Nylon',
      weight: nylonWeight,
      unit,
    },
  ]
}

export const formatToDateString = timestamp => {
  let date = new Date(timestamp.seconds * 1000).toUTCString()
  date = date.split(' ')
  return `${date[2]} ${date[3]}`
}

export const isATraceableStatus = status => {
  return traceableStatus.includes(status)
}

export const parseDateToString = timestamp => {
  try {
    const date = timestamp.toDate().toISOString().split('T')[0]
    return date.replace('-', '/').replace('-', '/')
  } catch {
    return ''
  }
}

export const parseDateToLiteralString = timestamp => {
  try {
    const splittedDate = timestamp.toDate().toString().split(' ')
    return `${splittedDate[1]} ${splittedDate[2]}, ${splittedDate[3]}`
  } catch (error) {
    return ''
  }
}

export const parseDateToDateString = date => {
  try {
    const splittedDate = date.toString().split(' ')
    return `${splittedDate[1]} ${splittedDate[2]}, ${splittedDate[3]}`
  } catch (error) {
    return ''
  }
}

export const sanitizeName = name => {
  let formattedName = name.replace(/\s+/g, '-').toLowerCase()
  return encodeURIComponent(formattedName)
}

export const downloadDesignFiles = async modelId => {
  const model = await getDesign(modelId)
  if (model.thingiverse_id) {
    let zip = JsZip()
    try {
      const files = await getFiles(model.thingiverse_id)
      await Promise.all(
        files.data.files.map(async (design, index) => {
          const url = design.public_url
          const thingFile = await downloadThingFile(url)
          zip.folder(`${model.name}`).file(`design ${index}.stl`, thingFile)
        })
      )
      zip.generateAsync({ type: 'blob' }).then(function (blob) {
        saveAs(blob, 'designs.zip')
      })
    } catch (error) {
      console.log(error.message)
    }
  } else {
    const { designs: files = [], name } = model
    let zip = JsZip()
    try {
      await Promise.all(
        files.map(async (design, index) => {
          const url = await downloadFile(design)
          zip.folder(`${name}`).file(`design ${index}.stl`, url)
        })
      )
      zip.generateAsync({ type: 'blob' }).then(function (blob) {
        saveAs(blob, 'designs.zip')
      })
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const getCountryState = (country, state) => {
  const [countryName, countryCode, countryStates] = CountryRegionData.find(
    item => item[0] === country || item[1] === country
  )
  const stateList = countryStates.split('|')
  const stateInfo = stateList.find(item => item.includes(state))
  const [stateName, stateCode] = stateInfo.split('~')
  return { countryName, countryCode, stateName, stateCode }
}

export const removeContactInformationFromMessage = message => {
  let finalMesssage = message

  const cleanFromPhoneNumbers = findPhoneNumbersInText(finalMesssage, 'US')
  cleanFromPhoneNumbers.forEach(phoneNumber => {
    const phone = message.slice(phoneNumber.startsAt, phoneNumber.endsAt)
    finalMesssage = finalMesssage.replace(phone, '')
  })

  const linksFound = linkify.find(finalMesssage)
  linksFound.forEach(linkFound => {
    if (linkFound.type === 'url' || linkFound.type === 'email') {
      finalMesssage = finalMesssage.replace(linkFound.value, '')
    }
  })
  return finalMesssage
}

const formattingFilesWithAttributes = (file, name) => {
  const indexLastDash = name.lastIndexOf('-')
  const indexLastDot = name.lastIndexOf('.')

  let nameWithoutExtension = name.substring(0, indexLastDot)
  if (indexLastDash > -1) {
    nameWithoutExtension = name.substring(0, indexLastDash)
  }

  const index = parseInt(name.substring(indexLastDash + 1, indexLastDot))

  file.index = isNaN(index) ? 0 : index
  file.nameWithoutExtension = nameWithoutExtension
  return file
}

const addAttributesToFiles = files =>
  files.map(file => formattingFilesWithAttributes(file, file.name))

const addAttributesToStoredFiles = storedFiles =>
  storedFiles.map(file => {
    const nameFoundByRegex = file.name.match(REGEX_STORED_FILE)
    if (nameFoundByRegex && nameFoundByRegex[1]) {
      const fileName = nameFoundByRegex[1]
      return formattingFilesWithAttributes(file, fileName)
    } else {
      return file
    }
  })

const replaceNameSpacesWith20 = name => name.replace(/ /g, '%20')

const addNewFileWithUniqueName = (storedFiles, newFile) => {
  const { name, nameWithoutExtension, path } = newFile
  let index = 0
  let formattedName = name
  const filesFound = storedFiles.filter(
    file =>
      path &&
      replaceNameSpacesWith20(nameWithoutExtension) ===
        replaceNameSpacesWith20(file.nameWithoutExtension)
  )
  const filesFoundSize = size(filesFound)
  if (filesFoundSize > 0) {
    const extension = path.split('.').pop()
    index = filesFound[filesFoundSize - 1].index + 1
    formattedName = `${nameWithoutExtension}-${index}.${extension}`
  }

  newFile.index = index
  newFile.formattedName = formattedName
  return newFile
}
export const isBrowser = () => typeof window !== 'undefined'
export const renameFilesWithUniqueName = (files, fileType) => {
  let storedFiles = files.filter(file => !file.path)
  let filesToUpload = files.filter(file => file.path)

  if (fileType === FILE_TYPES_TO_UPLOAD.IMAGE) {
    storedFiles = addAttributesToStoredFiles(storedFiles)
    filesToUpload = addAttributesToFiles(filesToUpload)
  }
  if (fileType === FILE_TYPES_TO_UPLOAD.DESIGN) {
    storedFiles = addAttributesToFiles(storedFiles)
    filesToUpload = addAttributesToFiles(filesToUpload)
  }

  let sortStoredFiles = sortBy(storedFiles, ['index'], ['asc'])

  for (const file of filesToUpload) {
    const newStoredFile = addNewFileWithUniqueName(sortStoredFiles, file)
    storedFiles = [...storedFiles, newStoredFile]
    sortStoredFiles = [...sortStoredFiles, newStoredFile]
  }

  return storedFiles
}

export const defaultValidations = {
  error: false,
  helperText: '',
}
export const validatePassword = value => {
  const schema = new passwordValidator()

  schema
    .is()
    .min(8)
    .is()
    .max(15)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols()
    .has()
    .not()
    .spaces()
  if (!isEmpty(value) && !schema.validate(value)) {
    return {
      error: true,
      helperText: INVALID_PASSWORD,
    }
  }
  return defaultValidations
}

export const validateRepeatPasswords = (password, value) => {
  if (!isEmpty(value) && !isEmpty(password) && password !== value) {
    return {
      error: true,
      helperText: MISMATCHED,
    }
  }
  return defaultValidations
}
