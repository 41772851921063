import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Avatar, Card, Grid, Typography } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import { makeStyles } from '@material-ui/core/styles'
import { USER_PROFILE_ENDPOINT } from 'constants/apiUrls'
import { parseDateToLiteralString } from 'constants/utils'

const useRowStyles = makeStyles(theme => ({
  avatar: {
    display: 'inline-flex',
  },
  label: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: theme.palette.text.black,
  },
  owner: {
    cursor: 'pointer',
    display: 'inline',
    padding: '0px 3px',
    textDecoration: 'underline',
    fontSize: '0.85rem',
    fontWeight: 'bold',
    margin: '0px 8px',
  },
  review: {
    padding: '12px',
    boxShadow: 'none',
  },
  reviewNotes: {
    marginTop: '16px',
  },
  reviewResult: {
    marginLeft: '50px',
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
  user: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4px',
    },
  },
}))

const ReviewRow = ({ review }) => {
  const {
    comment_review: comment,
    created_at: createdAt,
    price,
    profesionalism,
    promptness,
    quality_accuracy: quality,
    reviewer_info: reviewerInfo,
  } = review
  const classes = useRowStyles()

  return (
    <Card className={classes.review}>
      <Grid container>
        <Grid item xs={12} className={classes.user}>
          <Avatar className={classes.avatar} src={reviewerInfo.photo_url} />
          <Typography
            className={classes.owner}
            variant="caption"
            onClick={() => navigate(USER_PROFILE_ENDPOINT(reviewerInfo.uid))}
          >
            {reviewerInfo.first_name} {reviewerInfo.last_name}
          </Typography>
          <Typography variant="caption">
            {parseDateToLiteralString(createdAt)}
          </Typography>
        </Grid>
        <Grid container className={classes.reviewResult}>
          {quality > 0 && (
            <Grid container item xs={12} sm={6}>
              <Grid className={classes.rating} item xs={12} md={6}>
                <Typography className={classes.label} display="inline">
                  Quality & Accuracy{' '}
                </Typography>
              </Grid>
              <Grid className={classes.rating} item xs={12} md={6}>
                <Rating name="current-rating" readOnly value={quality} />
              </Grid>
            </Grid>
          )}
          {promptness > 0 && (
            <Grid container item xs={12} sm={6}>
              <Grid className={classes.rating} item xs={12} md={6}>
                <Typography className={classes.label} display="inline">
                  Promptness
                </Typography>
              </Grid>
              <Grid className={classes.rating} item xs={12} md={6}>
                <Rating name="current-rating" readOnly value={promptness} />
              </Grid>
            </Grid>
          )}
          {profesionalism > 0 && (
            <Grid container item xs={12} sm={6}>
              <Grid className={classes.rating} item xs={12} md={6}>
                <Typography className={classes.label} display="inline">
                  Profesionalism
                </Typography>
              </Grid>
              <Grid className={classes.rating} item xs={12} md={6}>
                <Rating name="current-rating" readOnly value={profesionalism} />
              </Grid>
            </Grid>
          )}
          {price > 0 && (
            <Grid container item xs={12} sm={6}>
              <Grid className={classes.rating} item xs={12} md={6}>
                <Typography className={classes.label} display="inline">
                  Price
                </Typography>
              </Grid>
              <Grid className={classes.rating} item xs={12} md={6}>
                <Rating name="current-rating" readOnly value={price} />
              </Grid>
            </Grid>
          )}
          <Grid className={classes.reviewNotes} item xs={12}>
            <Typography display="block">{comment}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

ReviewRow.propTypes = {
  review: PropTypes.object.isRequired,
}

export default ReviewRow
