import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
    '& span.MuiTypography-root': {
      width: '100%',
    },
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    marginBottom: '8px',
    marginTop: '8px',
  },
  text: {
    fontSize: '0.875rem',
  },
  verticalAlign: {
    display: 'flex',
    alignItems: 'center',
  },
})

const ShippingRateList = ({
  fetchingShippingRate,
  shippingRateList,
  onSelect,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState('')

  const handleChange = event => {
    const {
      target: { value },
    } = event
    const [carrierId, rateId] = value.split('-')
    setValue(value)
    onSelect(shippingRateList[carrierId][rateId])
  }

  return (
    <FormControl className={classes.fullWidth} component="fieldset">
      {fetchingShippingRate && (
        <CircularProgress className={classes.progress} />
      )}
      {shippingRateList && !fetchingShippingRate && (
        <RadioGroup
          aria-label="Shipping Rate"
          className={clsx(classes.fullWidth, classes.subTitle)}
          name="Shipping Rate"
          value={value}
          onChange={handleChange}
        >
          {shippingRateList.map((rates, rateIndex) => (
            <>
              <FormLabel
                className={clsx(classes.fullWidth, classes.title)}
                component="legend"
                key={rateIndex}
              >
                {rates[0].carrierFriendlyName}
              </FormLabel>
              <Grid className={classes.fullWidth} container>
                <Grid className={classes.verticalAlign} item xs={7}>
                  Service
                </Grid>
                <Grid className={classes.verticalAlign} item xs={2}>
                  Delivery Days
                </Grid>
                <Grid className={classes.verticalAlign} item xs={3}>
                  Cost
                </Grid>
              </Grid>
              {rates.map((item, itemIndex) => (
                <>
                  <FormControlLabel
                    className={classes.fullWidth}
                    control={<Radio />}
                    key={`${rateIndex}-${itemIndex}`}
                    label={
                      <Grid className={classes.fullWidth} container>
                        <Grid className={classes.text} item xs={7}>
                          {item.serviceType}
                        </Grid>
                        <Grid className={classes.text} item xs={2}>
                          {item.deliveryDays}
                        </Grid>
                        <Grid className={classes.text} item xs={3}>
                          {item.shippingAmount.amount}
                        </Grid>
                      </Grid>
                    }
                    value={`${rateIndex}-${itemIndex}`}
                  />
                </>
              ))}
            </>
          ))}
        </RadioGroup>
      )}
    </FormControl>
  )
}

ShippingRateList.propTypes = {
  fetchingShippingRate: PropTypes.bool,
  shippingRateList: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
}

ShippingRateList.defaultProps = {
  fetchingShippingRate: false,
}

export default ShippingRateList
