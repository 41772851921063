import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { toNumber } from 'lodash'
import {
  Avatar,
  Card,
  CardMedia,
  Collapse,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import {
  CollapseContent,
  CommonButton,
  ConfirmationDialog,
  ErrorMessage,
  FeatureFlag,
  MakeOfferModal,
  OffersModal,
} from 'components'
import { LISTINGS_DETAILS_ENDPOINT } from 'constants/apiUrls'
import { parseDateToString } from 'constants/utils'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import { LISTING_STATUS } from 'constants/listingConstants'
import { updateListing } from 'services/ListingService'
const useRowStyles = makeStyles({
  buttonContainer: {
    '& .MuiGrid-root': {
      display: 'flex',
    },
    '& .MuiButtonBase-root.MuiButton-root': {
      height: 'unset',
      marginBottom: '12px',
      marginLeft: 'auto',
      marginRight: '12px',
    },
  },
  centerHorizontal: {
    width: '50%',
    margin: '0 auto',
  },
  expandStyles: {
    verticalAlign: 'middle',
    marginTop: '20%',
  },
  flex: {
    display: 'flex',
  },
  image: {
    maxHeight: '100%',
    margin: 'auto',
    maxWidth: '100%',
    width: 'unset',
  },
  media: {
    display: 'flex',
    height: '42px',
    minWidth: '42px',
    maxWidth: '42px',
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  modelName: {
    marginBottom: 'auto',
    marginTop: 'auto',
    marginLeft: '8px',
  },
  tableCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  userName: {
    fontWeight: 'bold',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '12px',
  },
  verticalAlign: {
    marginBottom: 'auto',
    marginTop: 'auto',
    height: '42px',
    minWidth: '42px',
    maxWidth: '42px',
  },
})

const CollapseRow = ({
  acknowledgeReceipt,
  collapsedListing,
  currentUser,
  displayMakeOfferButton,
  displayModelImage,
  index,
  listing,
  offerStatus,
  handleDeleteButton,
  loadNewOffers,
  refreshCurrentListingsPage,
  showDetails,
  showOffersModal,
  updateCollapsedListing,
}) => {
  const classes = useRowStyles()
  const {
    id,
    thumbnail,
    model_name: modelName,
    model: modelId,
    submission_date: submissionDate,
    tech_specs: techSpecs,
    user_info: userInfo = {},
    owned_by: ownedBy,
  } = listing
  const [errorMessage, setErrorMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [status, setStatus] = useState(offerStatus)

  useEffect(() => {
    setOpen(id === collapsedListing)
  }, [collapsedListing, id])

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    photo_url: photoUrl = '',
  } = userInfo

  const changeStatus = () => {
    setStatus(true)
    loadNewOffers()
  }

  const handleOpenDialog = e => {
    e.stopPropagation()
    handleDeleteButton(true)
    setOpenConfirmationDialog(true)
  }

  const handleDeleteListing = async () => {
    try {
      await updateListing(listing.id, { is_deleted: true })
      refreshCurrentListingsPage()
    } catch (e) {
      setErrorMessage(
        'there was an error while deleting a listing , please try again later.'
      )
    }
  }

  const handleDialogCancel = async () => {
    setOpenConfirmationDialog(false)
  }

  const displayExpandedColumnArrow = !displayMakeOfferButton && open
  const displayColumnArrowToExpand = !displayMakeOfferButton && !open
  const showDeleteButton =
    currentUser.uid === ownedBy && LISTING_STATUS.SUBMITTED === listing.status

  return (
    <Fragment>
      <TableRow
        className={classes.root}
        onClick={() => updateCollapsedListing(id)}
      >
        <TableCell component="th" scope="row">
          {parseDateToString(submissionDate)}
        </TableCell>
        <TableCell align="center">
          <div className={classes.flex}>
            <Avatar src={photoUrl} />
            <span
              className={classes.userName}
            >{`${firstName} ${lastName}`}</span>
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.flex}>
            {displayModelImage && (
              <Card className={classes.media}>
                {thumbnail && (
                  <CardMedia
                    className={classes.image}
                    component="img"
                    image={thumbnail}
                  />
                )}
              </Card>
            )}
            <Typography variant="body2" className={classes.modelName}>
              {modelName}
            </Typography>
          </div>
        </TableCell>
        <TableCell align="left">{techSpecs.quantity}</TableCell>
        <TableCell align="left">{techSpecs.print_notes}</TableCell>
        <TableCell>
          {displayMakeOfferButton && (
            <>
              {status ? (
                <CommonButton
                  buttonStyle={BUTTON_STYLE.PRIMARY}
                  disabled={true}
                  fullWidth={false}
                  label={'Offer pending'}
                  size={BUTTON_SIZE.MEDIUM}
                  type={BUTTON_TYPE.SUBMIT}
                  variant={BUTTON_VARIANT.OUTLINED}
                />
              ) : (
                <MakeOfferModal
                  estimatedMaterial={listing.estimated_material}
                  estimatedMaterialPerFile={listing.estimated_material_per_file}
                  estimatedPrintTime={listing.estimated_print_time}
                  listingId={listing.id}
                  listingOwner={{ ...userInfo, uid: ownedBy }}
                  modelName={modelName}
                  changeStatus={changeStatus}
                  zipCode={listing.zip_code}
                />
              )}
            </>
          )}
          <div className={classes.flex}>
            {displayExpandedColumnArrow && (
              <ExpandMoreIcon className={classes.expandStyles} />
            )}
            {displayColumnArrowToExpand && (
              <ChevronRightIcon className={classes.expandStyles} />
            )}
            {showDeleteButton && (
              <>
                <ErrorMessage message={errorMessage} />
                <FeatureFlag
                  activeComponent={<></>}
                  inactiveComponent={
                    <IconButton
                      aria-label="delete listing"
                      className={classes.centerHorizontal}
                      color="primary"
                      component="span"
                      onClick={e => handleOpenDialog(e)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  flag="delete_listing"
                />
                <ConfirmationDialog
                  open={openConfirmationDialog}
                  title="Are you sure you want to delete this Listing?"
                  onAccept={handleDeleteListing}
                  onCancel={handleDialogCancel}
                />
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.tableCell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && (
              <CollapseContent
                acknowledgeReceipt={acknowledgeReceipt}
                currentUser={currentUser}
                index={index}
                listing={listing}
              />
            )}
            <Grid className={classes.buttonContainer} container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                {showDetails && (
                  <CommonButton
                    buttonStyle={BUTTON_STYLE.PRIMARY}
                    fullWidth={false}
                    label="View Details"
                    variant={BUTTON_VARIANT.OUTLINED}
                    onClick={() => {
                      navigate(LISTINGS_DETAILS_ENDPOINT(listing.id))
                    }}
                  />
                )}
                {showOffersModal &&
                  listing.status === LISTING_STATUS.SUBMITTED && (
                    <OffersModal
                      listingId={listing.id}
                      modelName={modelName}
                      modelId={modelId}
                      quantity={toNumber(techSpecs.quantity)}
                    />
                  )}
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

CollapseRow.propTypes = {
  acknowledgeReceipt: PropTypes.func,
  collapsedListing: PropTypes.string,
  currentUser: PropTypes.object,
  displayMakeOfferButton: PropTypes.bool,
  displayModelImage: PropTypes.bool,
  index: PropTypes.number,
  listing: PropTypes.object.isRequired,
  offerStatus: PropTypes.bool,
  loadNewOffers: PropTypes.func,
  showDetails: PropTypes.bool,
  showOffersModal: PropTypes.bool,
  updateCollapsedListing: PropTypes.func,
}

CollapseRow.defaultProps = {
  acknowledgeReceipt: () => {},
  collapsedListing: '',
  currentUser: {},
  displayMakeOfferButton: false,
  displayModelImage: false,
  showDetails: false,
  showOffersModal: false,
  loadNewOffers: () => {},
  updateCollapsedListing: () => {},
}

export default CollapseRow
