import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import BrushOutlinedIcon from '@material-ui/icons/BrushOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import StarIcon from '@material-ui/icons/Star'
import StorefrontIcon from '@material-ui/icons/Storefront'

import { Badges, FeatureFlag } from 'components'
import { USER_MENU } from 'constants/userConstants'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: ' calc(100vh - 140px)',
    padding: '28px',
    '@media (max-height:820px)': {
      minHeight: '700px',
    },
  },
  infoContainer: {
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
  },
  lastName: {
    width: '100%',
    fontSize: '1.5rem',
  },
  menu: {
    fontWeight: 'bold',
    '&.Mui-selected': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.white,
      },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.white,
      '& .MuiListItemIcon-root': {
        color: theme.palette.text.white,
      },
    },
  },
  menuContainer: {
    height: ' calc(100vh - 540px)',
    display: 'flex',
    '@media (max-height:820px)': {
      minHeight: '300px',
    },
  },
  userAvatar: {
    height: '200px',
    width: '200px',
  },
  userName: {
    width: '100%',
    fontSize: '2rem',
  },
  userMenu: {
    width: '100%',
  },
}))

const UserProfileInfo = ({
  initialOption,
  isMaker,
  isPublic,
  user,
  onSelectMenu,
}) => {
  const classes = useStyles()

  const { first_name: firstName, last_name: lastName, badges } = user
  const handleSelect = option => {
    onSelectMenu(option)
  }

  return (
    <div className={classes.container}>
      <div className={classes.infoContainer}>
        <Avatar className={classes.userAvatar} src={user.photo_url} />
        <span className={classes.userName}>{firstName}</span>
        <span className={classes.lastName}>{lastName}</span>
        {/* Here we can add the user description */}
      </div>
      <div className={classes.menuContainer}>
        <List
          className={classes.userMenu}
          component="nav"
          aria-label="main mailbox folders"
        >
          {isMaker && (
            <ListItem
              button
              className={classes.menu}
              id="manage-store"
              selected={initialOption.store}
              onClick={() => handleSelect(USER_MENU.STORE)}
            >
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText primary="MY STORE" />
            </ListItem>
          )}
          <ListItem
            button
            className={classes.menu}
            selected={initialOption.designs}
            onClick={() => handleSelect(USER_MENU.DESIGNS)}
          >
            <ListItemIcon>
              <BrushOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="MY DESIGNS" />
          </ListItem>
          {isMaker && (
            <ListItem
              button
              className={classes.menu}
              selected={initialOption.reviews}
              onClick={() => handleSelect(USER_MENU.REVIEWS)}
            >
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary="MY REVIEWS" />
            </ListItem>
          )}
          {!isPublic && (
            <ListItem
              button
              className={classes.menu}
              selected={initialOption.mylistings}
              onClick={() => handleSelect(USER_MENU.LIST)}
            >
              <ListItemIcon>
                <GavelOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="MY LISTS" />
            </ListItem>
          )}
          {isMaker && (
            <ListItem
              button
              className={classes.menu}
              selected={initialOption.offers}
              onClick={() => handleSelect(USER_MENU.OFFERS)}
            >
              <ListItemIcon>
                <LocalOfferOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="OFFERS" />
            </ListItem>
          )}
          {!isPublic && (
            <>
              <FeatureFlag
                activeComponent={<></>}
                inactiveComponent={
                  <ListItem
                    button
                    className={classes.menu}
                    selected={initialOption.settings}
                    onClick={() => handleSelect(USER_MENU.SETTINGS)}
                  >
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="SETTINGS" />
                  </ListItem>
                }
                flag="settings_option"
              />
              <ListItem
                button
                className={classes.menu}
                onClick={() => handleSelect(USER_MENU.LOGOUT)}
              >
                <ListItemIcon>
                  <ExitToAppOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="LOG OUT" />
              </ListItem>
              <Badges badges={badges} />
            </>
          )}
        </List>
      </div>
    </div>
  )
}

UserProfileInfo.propTypes = {
  isMaker: PropTypes.bool,
  isPublic: PropTypes.bool,
  user: PropTypes.object,
  onSelectMenu: PropTypes.func.isRequired,
}

UserProfileInfo.defaultProps = {
  isMaker: false,
  isPublic: false,
  user: {},
  initialOption: USER_MENU.DESIGNS,
}

export default UserProfileInfo
