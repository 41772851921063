import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardActionArea, CardMedia, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: 'transparent',
  },
  media: {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
    },
    [theme.breakpoints.down('md')]: {
      height: '80px',
    },
    [theme.breakpoints.up('md')]: {
      height: '120px',
    },
    position: 'relative',
    objectFit: 'contain',
  },
  root: {
    backgroundColor: 'rgba(73, 53, 72, 0.9)',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0px',
  },
}))

const Thumbnail = ({ thumbnails, jumpToCarouselImage }) => {
  const classes = useStyles()

  return (
    <Grid container item xs={12} className={classes.root} spacing={3}>
      {thumbnails.map((thumbnail, index) => (
        <Grid item xs={4} key={index}>
          <Card className={classes.card}>
            <CardActionArea onClick={() => jumpToCarouselImage(index)}>
              <CardMedia
                className={classes.media}
                component="img"
                image={thumbnail}
              />
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

Thumbnail.propTypes = {
  thumbnails: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Thumbnail
