import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core'
import { CommonButton, DesignReviewForm, MakerReviewForm } from 'components'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import { ERROR_FETCHING_REVIEWS } from 'constants/reviewsConstants'
import { addDesignReview } from 'services/DesignService'
import {
  addMakerReview,
  getUserById,
  updateMakerReviews,
} from 'services/UserService'

const useStyles = makeStyles(theme => ({
  btn: {
    margin: '0px 8px',
  },
  btnActions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
  step: {
    '& .MuiStepIcon-text': {
      fill: theme.palette.text.white,
    },
  },
  stepper: {
    paddingTop: '0',
  },
}))

const ReviewsStepper = ({
  designId,
  listingId,
  makerId,
  modelName,
  loggedUser,
  open,
  handleClose,
}) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [prevReviews, setPrevReviews] = useState({ isFetched: false })
  const [makerReview, setMakerReview] = useState({
    qualityAccuracy: 0,
    profesionalism: 0,
    price: 0,
    promptness: 0,
    notes: '',
  })
  const [designReview, setDesignReview] = useState({
    qualityAccuracy: 0,
    material: 0,
    finish: 0,
    notes: '',
  })
  const steps = ['Review for maker', 'Review for design']

  useEffect(() => {
    const getMaker = async () => {
      try {
        const newMaker = await getUserById(makerId)
        if (newMaker.reviews) {
          setPrevReviews({
            isFetched: true,
            qualityAccuracy: newMaker.reviews.quality_accuracy,
            qualityCounter: newMaker.reviews.quality_counter,
            price: newMaker.reviews.price,
            priceCounter: newMaker.reviews.price_counter,
            profesionalism: newMaker.reviews.profesionalism,
            profesionalismCounter: newMaker.reviews.profesionalism_counter,
            promptness: newMaker.reviews.promptness,
            promptnessCounter: newMaker.reviews.promptness_counter,
          })
        } else {
          setPrevReviews({
            isFetched: true,
            qualityAccuracy: 0,
            qualityCounter: 0,
            price: 0,
            priceCounter: 0,
            profesionalism: 0,
            profesionalismCounter: 0,
            promptness: 0,
            promptnessCounter: 0,
          })
        }
      } catch (error) {
        setError(ERROR_FETCHING_REVIEWS)
      }
    }
    if (!prevReviews.isFetched && makerId && open) {
      getMaker()
    }
  }, [makerId, prevReviews.isFetched, open])

  const validateDesignReview = () => {
    return (
      designReview.qualityAccuracy > 0 ||
      designReview.material > 0 ||
      designReview.finish > 0 ||
      designReview.notes !== ''
    )
  }

  const validateMakerReview = () =>
    makerReview.qualityAccuracy > 0 ||
    makerReview.profesionalism > 0 ||
    makerReview.price > 0 ||
    makerReview.promptness > 0 ||
    makerReview.notes !== ''

  const labelButton = () =>
    activeStep === 0 ? validateMakerReview() : validateDesignReview()

  const saveReview = async () => {
    const {
      qualityAccuracy,
      profesionalism,
      price,
      promptness,
      notes,
    } = makerReview
    switch (activeStep) {
      case 0:
        if (
          qualityAccuracy > 0 ||
          profesionalism > 0 ||
          price > 0 ||
          promptness > 0 ||
          notes !== ''
        ) {
          await saveMakerReview()
        }
        nextStep()
        break
      case 1:
        if (validateDesignReview()) {
          await saveDesignReview()
        }
        handleClose()
        break
      default:
        handleClose()
        break
    }
  }

  const nextStep = () => {
    if (activeStep === 0) {
      setActiveStep(activeStep + 1)
    } else {
      handleClose()
    }
  }

  const backStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const saveMakerReview = async () => {
    setError('')
    setLoading(true)
    try {
      const reviewer = {
        firstName: loggedUser.first_name,
        lastName: loggedUser.last_name,
        uid: loggedUser.uid,
        photoUrl: loggedUser.photo_url,
      }
      await addMakerReview(
        designId,
        listingId,
        makerId,
        makerReview,
        modelName,
        reviewer
      )
      const qualityAverage = getAverageReview(
        'qualityAccuracy',
        'qualityCounter'
      )
      const priceAverage = getAverageReview('price', 'priceCounter')
      const profesionalismAverage = getAverageReview(
        'profesionalism',
        'profesionalismCounter'
      )
      const promptnessAverage = getAverageReview(
        'promptness',
        'promptnessCounter'
      )
      const newReview = {
        qualityAccuracy: qualityAverage.current,
        qualityCounter: qualityAverage.counter,
        price: priceAverage.current,
        priceCounter: priceAverage.counter,
        profesionalism: profesionalismAverage.current,
        profesionalismCounter: profesionalismAverage.counter,
        promptness: promptnessAverage.current,
        promptnessCounter: promptnessAverage.counter,
      }
      await updateMakerReviews(makerId, newReview)
    } catch (error) {
      setError('Error saving the review, please try again')
    }
    setLoading(false)
  }

  const getAverageReview = (value, counterName) => {
    let average = { current: 0, counter: 0 }
    if (prevReviews[value] === 0 && makerReview[value] > 0) {
      average.current = makerReview[value]
      average.counter = 1
    }
    if (prevReviews[value] > 0 && makerReview[value] === 0) {
      average.current = prevReviews[value]
      average.counter = prevReviews[counterName]
    }
    if (prevReviews[value] > 0 && makerReview[value] > 0) {
      average.current = prevReviews[value] + makerReview[value]
      average.counter = prevReviews[counterName] + 1
    }
    return average
  }
  const saveDesignReview = async () => {
    setError('')
    setLoading(true)
    try {
      const reviewer = {
        firstName: loggedUser.first_name,
        lastName: loggedUser.last_name,
        uid: loggedUser.uid,
        photoUrl: loggedUser.photo_url,
      }
      await addDesignReview(
        designId,
        listingId,
        makerId,
        designReview,
        reviewer
      )
    } catch (error) {
      setError('Error saving the review, please try again')
    }
    setLoading(false)
  }

  const handleCloseReviews = () => {
    handleClose()
    setActiveStep(0)
  }

  const getStepContent = stepIndex => {
    let stepComponent
    if (stepIndex === 0) {
      stepComponent = (
        <MakerReviewForm
          makerReview={makerReview}
          setMakerReview={setMakerReview}
        />
      )
    } else {
      stepComponent = (
        <DesignReviewForm
          designReview={designReview}
          setDesignReview={setDesignReview}
        />
      )
    }
    return stepComponent
  }

  return (
    <div>
      <Dialog open={open} onClose={handleCloseReviews}>
        <DialogTitle>Add a Review</DialogTitle>
        <DialogContent>
          {error && <div>{error}</div>}
          {loading && <CircularProgress className={classes.progress} />}
          <Stepper
            className={classes.stepper}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel className={classes.step}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <div>{getStepContent(activeStep)}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnActions}>
            <div className={classes.btn}>
              <CommonButton
                buttonStyle={BUTTON_STYLE.CANCEL}
                disabled={loading}
                fullWidth={false}
                label="Skip"
                variant={BUTTON_VARIANT.OUTLINED}
                onClick={nextStep}
              />
            </div>
            {activeStep > 0 && (
              <div className={classes.btn}>
                <CommonButton
                  buttonStyle={BUTTON_STYLE.CANCEL}
                  disabled={loading}
                  fullWidth={false}
                  label="Back"
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={backStep}
                />
              </div>
            )}
            <div className={classes.btn}>
              <CommonButton
                buttonStyle={BUTTON_STYLE.PRIMARY}
                disabled={loading || !labelButton()}
                fullWidth={false}
                label="Submit"
                variant={BUTTON_VARIANT.OUTLINED}
                onClick={saveReview}
              />
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ReviewsStepper
