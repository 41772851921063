import React, { useEffect, useState } from 'react'
import { ErrorMessage } from 'components'
import { getTrackingInformationPage } from 'services/ShipEngineService'

const IframeTracking = ({ carrierCode, shipper, trackingId }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [webpage, setWebPage] = useState('')

  useEffect(() => {
    const fetchingTrackingPage = async () => {
      try {
        const { url } = await getTrackingInformationPage(
          carrierCode,
          shipper,
          trackingId
        )

        setWebPage(url)
      } catch (error) {
        setErrorMessage('Error while getting tracking information page.')
      }
    }
    fetchingTrackingPage()
  }, [carrierCode, shipper, trackingId])

  return (
    <>
      <ErrorMessage message={errorMessage} />
      <iframe
        src={webpage}
        title="Smthy tracking"
        height="1000px"
        width="100%"
        scrolling="no"
      />
    </>
  )
}

export default IframeTracking
