import React from 'react'
import PropTypes from 'prop-types'
import { round, toNumber, truncate } from 'lodash'
import { Grid, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  margin: {
    marginBottom: '4px',
    marginTop: '4px',
  },
  printListContainer: {
    maxHeight: '196px',
    overflowY: 'auto',
  },
  title: {
    display: 'block',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    [theme.breakpoints.down(281)]: {
      fontSize: '0.7rem',
    },
  },
}))
const MIN_LENGTH_LABEL = { length: 9 }
const MAX_LENGTH_LABEL = { length: 9 }

const MaterialEstimationsProvidedBySlicer = ({
  estimatedMaterialPerFile,
  showEstimatedMaterial,
}) => {
  const classes = useStyles()

  const calculatePrintTime = printTime =>
    printTime && toNumber(printTime.value)
      ? round(printTime.value / 3600, 2)
      : 'N/A'

  const calculateTotalPrintTime = () => {
    let totalTime = 0

    estimatedMaterialPerFile.forEach(estimation => {
      const { print_time: printTime = { value: 0 } } = estimation
      if (!toNumber(printTime.value)) {
        printTime['value'] = 0
      }
      totalTime = round(totalTime, 2) + round(printTime.value, 2)
    })

    const timeInHours = round(totalTime / 3600, 2)
    return timeInHours > 0 ? timeInHours : 'N/A'
  }

  const truncateCharacters = !showEstimatedMaterial
    ? MAX_LENGTH_LABEL
    : MIN_LENGTH_LABEL

  return (
    <Grid
      className={classes.textSize}
      container
      id="offer-information"
      item
      xs={12}
    >
      <Grid className={classes.formControl} container item xs={12}>
        <Typography className={classes.title} color="primary">
          Estimates are made using 25% infill, resolution of 0.2 and a print
          speed of 35 mm/s
        </Typography>
      </Grid>
      <Grid className={classes.margin} container item xs={12}>
        <Grid item xs={2}>
          <Typography className={classes.title} color="primary">
            File Name
          </Typography>
        </Grid>
        <Grid container item xs={2}>
          <Typography className={classes.title} color="primary">
            Est Time
          </Typography>
        </Grid>
        <Grid container item xs={2}>
          <Typography className={classes.title} color="primary">
            Est Material
          </Typography>
        </Grid>
        <Grid container item xs={2}>
          <Typography className={classes.title} color="primary">
            Width
          </Typography>
        </Grid>
        <Grid container item xs={2}>
          <Typography className={classes.title} color="primary">
            Height
          </Typography>
        </Grid>
        <Grid container item xs={2}>
          <Typography className={classes.title} color="primary">
            Depth
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.printListContainer} container item xs={12}>
        {estimatedMaterialPerFile.map((item, index) => (
          <Grid className={classes.margin} container key={index} item xs={12}>
            <Grid item xs={2}>
              <Tooltip title={item.file_name}>
                <Typography className={classes.title} color="primary">
                  {truncate(item.file_name, truncateCharacters)}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.title} color="primary">
                {calculatePrintTime(item.print_time)} hr
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.title} color="primary">
                {item.material_estimation} (mm<sup>3</sup>)
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.title} color="primary">
                {typeof item.dimensions?.width == 'number' ? item.dimensions.width.toFixed(2) + " mm" : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.title} color="primary">
                {typeof item.dimensions?.height == 'number' ? item.dimensions.height.toFixed(2) + " mm" : "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className={classes.title} color="primary">
                {typeof item.dimensions?.length == 'number' ? item.dimensions.length.toFixed(2) + " mm" : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid className={classes.margin} container item xs={12}>
        <Grid item xs={2}>
          <Typography className={classes.title} color="primary">
            Total Print Time:
          </Typography>
        </Grid>
        <Grid className={classes.title} item xs={2}>
          <Typography className={classes.title} color="primary">
            {calculateTotalPrintTime()} hrs
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

MaterialEstimationsProvidedBySlicer.propTypes = {
  estimatedMaterialPerFile: PropTypes.array,
  showEstimatedMaterial: PropTypes.bool,
}

MaterialEstimationsProvidedBySlicer.defaultProps = {
  estimatedMaterialPerFile: [],
  showEstimatedMaterial: true,
}

export default MaterialEstimationsProvidedBySlicer
