import React, { lazy, Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Slide } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CarouselItem } from 'components'
import { isBrowser } from 'constants/utils'

const CAROUSEL_REFRESH_RATE = 10000
const CAROUSEL_TIME_TRANSITION = 250

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px 0px',
    width: '100%',
    overflow: 'hidden',
  },
}))

const leftEffect = {
  slideIn: {
    slideIn: true,
    slideDirection: 'left',
  },
  slideOut: {
    slideIn: false,
    slideDirection: 'right',
  },
}

const { slideIn } = leftEffect

const CarouselControl = lazy(() => import('./CarouselControl'))
const CarouselViewerModal = lazy(() => import('./CarouselViewerModal'))

const Carousel = ({
  animation,
  hoverNavButtons,
  indicators,
  slides,
  thumbnails,
}) => {
  const carouselClasses = useStyles()
  const [currentSlide, setCurrentSlide] = useState(null)
  const [imageSelected, setImageSelected] = useState(null)
  const [openFullScreen, setOpenFullScreen] = useState(false)
  slideIn.slideIn = animation
  const [slideComponent, setSlideComponent] = useState(leftEffect.slideIn)
  const [slidesState, setSlidesState] = useState([])

  useEffect(() => {
    const createCarouselItems = () => {
      return slides.map((item, index) => {
        return <CarouselItem content={item} key={index} />
      })
    }

    if (slides.length > 0) {
      const response = createCarouselItems()
      setCurrentSlide({
        index: 0,
        slide: response[0],
      })
      setImageSelected(response[0])
      setSlidesState(response)
    }
  }, [slides])

  const updateCarouselImage = (newIndex, transitionEffect) => {
    const { slideIn, slideOut } = transitionEffect
    setCurrentSlide({
      index: newIndex,
      slide: slidesState[newIndex],
    })
    setSlideComponent(slideOut)

    setTimeout(() => {
      setSlideComponent(slideIn)
    }, CAROUSEL_TIME_TRANSITION)
  }

  const nextCarouselImage = () => {
    const { index } = currentSlide
    const { length: numSlides } = slidesState
    const newIndex = (index + 1) % numSlides
    updateCarouselImage(newIndex, leftEffect)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (slidesState.length > 0) {
        nextCarouselImage()
      }
    }, CAROUSEL_REFRESH_RATE)
    return () => clearTimeout(timer)
  })

  const handleOpen = () => {
    setImageSelected(currentSlide.slide)
    setOpenFullScreen(true)
  }

  return (
    <Grid item xs={11} className={carouselClasses.container}>
      {slidesState.length > 0 && (
        <div className="CarouselSlide">
          <Slide
            in={slideComponent.slideIn}
            direction={slideComponent.slideDirection}
          >
            <div>{currentSlide.slide}</div>
          </Slide>
          {isBrowser() && (
            <>
              <Suspense fallback={<div />}>
                <CarouselControl
                  currentSlide={currentSlide}
                  handleOpen={handleOpen}
                  hoverNavButtons={hoverNavButtons}
                  indicators={indicators}
                  leftEffect={leftEffect}
                  slides={slides}
                  slidesState={slidesState}
                  thumbnails={thumbnails}
                  updateCarouselImage={updateCarouselImage}
                />
              </Suspense>
              <Suspense fallback={<div />}>
                <CarouselViewerModal
                  imageSelected={imageSelected}
                  openFullScreen={openFullScreen}
                  setOpenFullScreen={setOpenFullScreen}
                />
              </Suspense>
            </>
          )}
        </div>
      )}
    </Grid>
  )
}

Carousel.propTypes = {
  animation: PropTypes.bool,
  hoverNavButtons: PropTypes.bool,
  indicators: PropTypes.bool,
  slides: PropTypes.instanceOf(Array).isRequired,
  thumbnails: PropTypes.bool,
}

Carousel.defaultProps = {
  animation: true,
  hoverNavButtons: false,
  indicators: true,
  thumbnails: false,
}

export default Carousel
