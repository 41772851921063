import React from 'react'
import { Card, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'

const useRowStyles = makeStyles(theme => ({
  avatar: {
    display: 'inline-flex',
  },
  owner: {
    width: '120px',
    display: 'inline',
    padding: '0px 3px',
    margin: '0px 8px',
  },
  review: {
    padding: '12px',
    boxShadow: 'none',
  },
  reviewNotes: {
    marginTop: '16px',
  },
  user: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4px',
    },
  },
}))

const ReviewsSkeleton = () => {
  const classes = useRowStyles()

  const SkeletonReview = index => (
    <Card className={classes.review} key={index}>
      <Grid container>
        <Grid item xs={12} className={classes.user}>
          <Skeleton
            className={classes.avatar}
            variant="circle"
            width={30}
            height={30}
          />
          <Skeleton className={classes.owner} variant="text" />
        </Grid>
        <Grid className={classes.reviewNotes} item xs={12}>
          <Skeleton variant="rect" width="100%" height={98} />
        </Grid>
      </Grid>
    </Card>
  )

  const reviews = []
  for (let i = 0; i < 4; i++) {
    reviews.push(SkeletonReview)
  }

  return <div>{reviews.map((review, index) => review(index))}</div>
}

export default ReviewsSkeleton
