import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { Elements } from '@stripe/react-stripe-js'

import { CommonButton, StripeCheckoutForm } from 'components'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import { getStripe } from 'services/StripeService'

const useStyles = makeStyles({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      minWidth: '460px',
      minHeight: '180px',
    },
  },
})

const StripeModal = ({
  listingId,
  listingTotalPrice,
  modelName,
  offerId,
  userId,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CommonButton
        buttonStyle={BUTTON_STYLE.PRIMARY}
        fullWidth={false}
        label="Make Payment"
        variant={BUTTON_VARIANT.OUTLINED}
        onClick={handleOpen}
      />
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="title"
      >
        <DialogTitle className={classes.boldTitle}>Make Payment</DialogTitle>
        <DialogContent>
          <Elements stripe={getStripe()}>
            <StripeCheckoutForm
              handleClose={handleClose}
              listingId={listingId}
              listingTotalPrice={listingTotalPrice}
              modelName={modelName}
              offerId={offerId}
              userId={userId}
            />
          </Elements>
        </DialogContent>
      </Dialog>
    </>
  )
}

StripeModal.propTypes = {
  isOpen: PropTypes.bool,
  listingId: PropTypes.string.isRequired,
  listingTotalPrice: PropTypes.number.isRequired,
  modelName: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
}

StripeModal.defaultProps = {
  isOpen: false,
}

export default StripeModal
