import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { OfferRow } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import { DEFAULT_LIMITS } from 'constants/offerConstants'

import { getListingById } from 'services/ListingService'
import { getListingShippingInfoByListingId } from 'services/ListingShippingInfoService'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'inline-flex',
    marginRight: '8px',
  },
  boldTitle: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.white,
  },
  boldText: {
    '& .MuiToolbar-root .MuiTypography-root, .MuiInputBase-root .MuiSelect-root': {
      fontWeight: 'bold',
    },
  },
  container: {
    height: '76vh',
    minHeight: '501px',
  },
  fullHeight: {
    height: '100%',
  },
  notesCell: {
    width: '200px',
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.white,
  },
  progress: {
    top: '46%',
    left: '47%',
  },
}))

const MINIMUM_VALUE_DIMENSIONS_MILLIMETERS = '152.4'
const OffersTable = ({
  disableOnClick,
  fetchOffers,
  hasNext,
  initRowsPerPage,
  isMaker,
  listingId,
  modelId,
  modelName,
  needLoad,
  nextPage,
  offersList,
  offersState,
  prevPage,
  quantity,
  onOfferAccepted,
  rejectOffer,
}) => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage)
  const [shippingInfo, setShippingInfo] = useState(null)
  const [parsedOffersList, setParsedOffersList] = useState(null)
  const slant3dOffersFlag = useSelector(
    state => state.featureFlagState.flags['display_slant3d_offers']
  )

  useEffect(() => {
    const parseOfferList = () => {
      return offersList.filter(item => !item.is_from_slant3d)
    }
    if (!slant3dOffersFlag) {
      const newParsedOfferList = parseOfferList()
      setParsedOffersList(newParsedOfferList)
    } else {
      setParsedOffersList(offersList)
    }
  }, [offersList, slant3dOffersFlag])

  useEffect(() => {
    const fetchShippingInfo = async () => {
      try {
        let listing = await getListingById(listingId)
        const listingShippingInfo = await getListingShippingInfoByListingId(
          listingId
        )
        const { tech_specs } = listing
        let { height, length, width } = tech_specs
        if (height < MINIMUM_VALUE_DIMENSIONS_MILLIMETERS) {
          height = MINIMUM_VALUE_DIMENSIONS_MILLIMETERS
        }
        if (length < MINIMUM_VALUE_DIMENSIONS_MILLIMETERS) {
          length = MINIMUM_VALUE_DIMENSIONS_MILLIMETERS
        }
        if (width < MINIMUM_VALUE_DIMENSIONS_MILLIMETERS) {
          width = MINIMUM_VALUE_DIMENSIONS_MILLIMETERS
        }

        setShippingInfo({
          height,
          length,
          material: listing.tech_specs.material,
          shipToCity: listingShippingInfo.city,
          shipToCountry: listing.country,
          shipToState: listingShippingInfo.state,
          shipToZipCode: listing.zip_code,
          width,
        })
      } catch (error) {
        setError(
          'An error occurred while trying to fetch the shipping info, please try again later'
        )
      }
    }
    if (listingId && !shippingInfo) {
      fetchShippingInfo()
    }
  }, [listingId, shippingInfo])

  const handleChangePage = async (event, newPage) => {
    let response = true
    try {
      setIsLoading(true)
      if (newPage > page) {
        response = await nextPage(newPage, rowsPerPage)
      } else {
        await prevPage(newPage, rowsPerPage)
      }
      if (response) {
        setPage(newPage)
      }
      setIsLoading(false)
    } catch (error) {
      setError('There was an error to load the page, please try again later.')
    }
  }

  const handleChangeRowsPerPage = async event => {
    const {
      target: { value: newLimit },
    } = event
    setRowsPerPage(newLimit)
    setPage(0)
    await fetchOffers(newLimit)
  }

  return (
    <Paper className={classes.fullHeight}>
      <TableContainer className={classes.container}>
        {error && <div>{error}</div>}
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.boldTitle}>
                Submission Date
              </TableCell>
              <TableCell align="left" className={classes.boldTitle}>
                User
              </TableCell>
              <TableCell align="left" className={classes.boldTitle}>
                Price
              </TableCell>
              <TableCell align="left" className={classes.boldTitle}>
                Production Days
              </TableCell>
              <TableCell align="left" className={classes.notesCell}>
                Notes
              </TableCell>
              <TableCell align="center" className={classes.boldTitle}>
                {' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parsedOffersList &&
              parsedOffersList.map(offer => (
                <OfferRow
                  disableOnClick={disableOnClick}
                  fetchOffers={fetchOffers}
                  isLoading={isLoading}
                  isMaker={isMaker}
                  listingId={listingId}
                  needLoad={needLoad}
                  modelId={modelId}
                  modelName={modelName}
                  offer={offer}
                  offersList={offersList}
                  offersState={offersState}
                  quantity={quantity}
                  onOfferAccepted={onOfferAccepted}
                  rejectOffer={rejectOffer}
                  setError={setError}
                  setIsLoading={setIsLoading}
                  setParsedOffersList={setParsedOffersList}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.boldText}
        component="div"
        count={-1}
        nextIconButtonProps={{ disabled: !hasNext }}
        labelDisplayedRows={({ from, to }) => `${from}-${to}`}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={DEFAULT_LIMITS}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

OffersTable.propTypes = {
  disableOnClick: PropTypes.bool,
  hasNext: PropTypes.bool.isRequired,
  initRowsPerPage: PropTypes.number,
  isMaker: PropTypes.bool,
  listingId: PropTypes.string,
  modelId: PropTypes.string,
  modelName: PropTypes.string,
  nextPage: PropTypes.func.isRequired,
  offersList: PropTypes.array.isRequired,
  offersState: PropTypes.string,
  prevPage: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  rejectOffer: PropTypes.func,
}

OffersTable.defaultProps = {
  disableOnClick: false,
  initRowsPerPage: DEFAULT_LIMITS[0],
  isMaker: false,
  listingId: '',
  modelId: '',
  modelName: '',
  quantity: 0,
  onOfferAccepted: () => {},
  rejectOffer: () => {},
}

export default OffersTable
