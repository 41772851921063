import axios from 'axios'
import { MAIL_INFO, SEND_MAIL } from 'constants/mailEndpointConstant'
import { getUserById } from 'services/UserService'

export const sendMailToMaker = async (email, name) => {
  return axios.post(SEND_MAIL, {
    options: {
      from: MAIL_INFO,
      to: email,
      subject: 'Welcome to Smthy!',
    },
    template: {
      name: 'welcomeMaker',
      data: {
        recipientName: name,
      },
    },
  })
}

export const sendMailNofification = async (
  email,
  userName,
  isMaker,
  listingLink
) => {
  return axios.post(SEND_MAIL, {
    options: {
      from: MAIL_INFO,
      to: email,
      subject: 'Smthy notify',
    },
    template: {
      name: 'userNotification',
      data: {
        email,
        userName,
        isMaker,
        listingLink,
      },
    },
  })
}

export const sendMailToReportIssue = async (description, issue, subject) => {
  try {
    return axios.post(SEND_MAIL, {
      options: {
        from: MAIL_INFO,
        to: process.env.GATSBY_EMAILS_TO_REPORT,
        subject: `Report Issue, ${issue}`,
        text: `${description} \n${subject}`,
      },
    })
  } catch (error) {
    console.error(error)
  }
}

export const sendNotification = async (
  email,
  userName,
  message,
  modelName,
  listingLink,
  ccEmails
) => {
  try {
    return axios.post(SEND_MAIL, {
      options: {
        from: MAIL_INFO,
        to: email,
        subject: 'Smthy notification',
        cc: ccEmails,
      },
      template: {
        name: 'listingNotification',
        data: {
          userName,
          message,
          modelName,
          listingLink,
        },
      },
    })
  } catch (error) {
    console.error(error)
  }
}

export const sendNofificationMail = async (
  userId,
  listing,
  message,
  isFromSlant3d,
  ccEmails
) => {
  const user = await getUserById(userId)
  /* It is necessary to create this variable process.env.GATSBY_EMAIL_SLANT3D 
  with different emails in dev (support@smthy.app) and prod (general@slant3d.com) to supply this email */
  let email = 'support@smthy.app'

  if (!isFromSlant3d) {
    email = user.email
  }
  const { id, model_name } = listing
  try {
    const listingURL = `${process.env.GATSBY_API_URL}/listings/${id}`
    const { first_name, last_name } = user
    const userName = `${first_name} ${last_name}`
    await sendNotification(
      email,
      userName,
      message,
      model_name,
      listingURL,
      ccEmails
    )
  } catch (error) {
    console.error(error)
  }
}
