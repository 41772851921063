import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { AsYouType } from 'libphonenumber-js'
import { isEmpty, size } from 'lodash'
import { DropzoneDialog } from 'material-ui-dropzone'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import iso3311a2 from 'iso-3166-1-alpha-2'
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from '@material-ui/core'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined'
import MarkunreadMailboxOutlinedIcon from '@material-ui/icons/MarkunreadMailboxOutlined'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { ErrorMessage, formStyle } from 'components'
import { HOME_ENDPOINT } from 'constants/apiUrls'
import { getCountryState } from 'constants/utils'
import {
  AVATAR_FOLDER_PATH,
  ERROR_ADDING_PERSONAL_INFO,
  ERROR_EMAIL_UNVERIFIED,
  INVALID_FIELD,
} from 'constants/userConstants'
import { validateAddress } from 'services/ShipEngineService'
import { uploadFile } from 'services/StorageService'
import { updateUserPersonalInfo } from 'services/UserService'

const hasExtraWhiteSpaces = val => {
  const index = val.indexOf('  ')
  return index >= 0
}

let initialState = {
  emailVerified: false,
  firstName: '',
  isMaker: false,
  lastName: '',
  manufacturer: '',
  model: '',
  phoneNumber: '',
  photoUrl: '',
  primaryMaterial: '',
  printTechnology: '',
  printer: 'without3D',
  tutorialState: { pageHeader: false },
  xyResolution: '',
  zResolution: '',
}

const defaultValidations = {
  error: false,
  helperText: '',
}

const defaultValidationState = {
  city: defaultValidations,
  companyAddress: defaultValidations,
  country: defaultValidations,
  firstName: defaultValidations,
  lastName: defaultValidations,
  phoneNumber: defaultValidations,
  state: defaultValidations,
  zipCode: defaultValidations,
}

const onBoardStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  autoMargin: {
    margin: 'auto',
  },
  removeRightPadding: {
    paddingRight: 0,
  },
  container: {
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      '& .MuiFormLabel-root': {
        fontSize: '0.625rem',
      },
    },
    width: '100%',
  },
  customContainer: {
    height: '45px',
    width: '100%',
  },
  dropdown: {
    color: '#cacaca',
    fontSize: '1rem',
    fontWeight: 'normal',
    fontStyle: 'inherit',
    fontFamily: 'inherit',
    borderColor: '#c4c4c4',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    '&:hover': {
      border: '1px solid',
      borderColor: 'black',
    },
    '&:focus': {
      border: '2px solid',
      borderColor: theme.palette.background.green,
    },
    borderRadius: '4px',
    height: '40px',
    paddingLeft: '8px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      '& .MuiFormLabel-root': {
        fontSize: '0.625rem',
      },
    },
  },
  label: {
    cursor: 'default',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    padding: '0px',
    textSizeAdjust: '100%',
    top: '0px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.625rem',
    },
  },
  marginTop: {
    pointerEvents: 'none',
    '&.MuiGrid-root': {
      marginTop: '-40px',
    },
  },
  selectedItem: {
    color: 'black',
  },
  shippingContainer: {
    margin: 'unset',
    '&.MuiGrid-root': {
      padding: '0px',
    },
  },
  spinner: {
    position: 'relative',
    top: '50%',
    left: '50%',
    zIndex: '10000000',
  },
}))

const asYouType = new AsYouType('US')

let defaultShippingInfo = {
  city: '',
  companyAddress: '',
  companyName: '',
  country: 'United States',
  state: '',
  zipCode: '',
}

const errorState = {
  error: true,
  helperText: 'Please verify this field',
}

const OnBoard = ({ editing }) => {
  const classes = formStyle()
  const onBoardClasses = onBoardStyles()
  const loggedUser = useSelector(state => state.userState.user)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isVerifyingAddress, setIsVerifyingAddress] = useState(false)
  const [openDropzone, setOpenDropzone] = useState(false)

  if (loggedUser && loggedUser.shipping_info) {
    const { first_name, last_name, phone_number, shipping_info } = loggedUser
    initialState.firstName = first_name
    initialState.lastName = last_name
    initialState.phoneNumber = phone_number

    const {
      city,
      company_address,
      company_name,
      country,
      state,
      zip_code,
    } = shipping_info

    defaultShippingInfo = {
      city: city,
      companyAddress: company_address,
      companyName: company_name,
      country: country,
      state: state,
      zipCode: zip_code,
    }
  }

  const [shippingInfo, setShippingInfo] = useState(defaultShippingInfo)
  const [userState, setUserState] = useState(initialState)
  const [validation, setValidation] = useState(defaultValidationState)

  useEffect(() => {
    const initUserState = () => {
      const { emailVerified, uid, photo_url } = loggedUser
      setUserState({
        ...userState,
        emailVerified,
        uid,
        photoUrl: photo_url,
      })
    }
    if (!userState.uid && loggedUser) {
      initUserState()
    }
  }, [userState, loggedUser])

  const validateInput = prop => event => {
    const {
      target: { value },
    } = event
    const trimmedValue = value.trim()
    setValidation({ ...validation, [prop]: defaultValidations })
    switch (prop) {
      case 'firstName': {
        if (
          !isEmpty(value) &&
          (hasExtraWhiteSpaces(value) || size(trimmedValue) === 0)
        ) {
          setValidation({
            ...validation,
            firstName: {
              error: true,
              helperText: INVALID_FIELD('first name'),
            },
          })
        }
        break
      }
      case 'lastName': {
        if (
          !isEmpty(value) &&
          (hasExtraWhiteSpaces(value) || size(trimmedValue) === 0)
        ) {
          setValidation({
            ...validation,
            lastName: {
              error: true,
              helperText: INVALID_FIELD('last name'),
            },
          })
        }
        break
      }
      case 'city': {
        if (
          !isEmpty(value) &&
          (hasExtraWhiteSpaces(value) || size(trimmedValue) === 0)
        ) {
          setValidation({
            ...validation,
            city: {
              error: true,
              helperText: INVALID_FIELD('city'),
            },
          })
        } else {
          verifyAddress()
        }
        break
      }
      case 'companyAddress': {
        if (
          !isEmpty(value) &&
          (hasExtraWhiteSpaces(value) || size(trimmedValue) === 0)
        ) {
          setValidation({
            ...validation,
            companyAddress: {
              error: true,
              helperText: INVALID_FIELD('company address'),
            },
          })
        } else {
          verifyAddress()
        }
        break
      }
      case 'zipCode': {
        if (
          !isEmpty(value) &&
          (hasExtraWhiteSpaces(value) ||
            size(trimmedValue) === 0 ||
            size(trimmedValue) < 5)
        ) {
          setValidation({
            ...validation,
            zipCode: {
              error: true,
              helperText: INVALID_FIELD('zip code'),
            },
          })
        } else {
          verifyAddress()
        }
        break
      }
      default: {
        const numberLength = size(value)
        if (numberLength > 0 && !asYouType.isValid()) {
          setValidation({
            ...validation,
            phoneNumber: {
              error: true,
              helperText: INVALID_FIELD('phone number'),
            },
          })
        }
        if (asYouType.isValid()) {
          setUserState({
            ...userState,
            phoneNumber: asYouType.getNumber().formatNational(),
          })
        }
        break
      }
    }
  }

  const updateField = prop => event => {
    const {
      target: { value },
    } = event
    const parsedValue = value.replace(/[^a-z\s]/gi, '')
    setUserState({ ...userState, [prop]: parsedValue })
  }

  const setPhoneNumber = event => {
    const {
      target: { value },
    } = event
    const phoneLength = size(value)
    asYouType.reset()
    let formattedInput = asYouType.input(value)

    const firstChar = formattedInput.charAt(0)
    if ((firstChar === '1' && phoneLength <= 16) || phoneLength <= 14) {
      if (phoneLength === 4 || phoneLength === 6) {
        formattedInput = value
      }
      setUserState({ ...userState, phoneNumber: formattedInput })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      setIsLoading(true)
      const previousValidation =
        !validation.firstName.error &&
        !validation.lastName.error &&
        !validation.phoneNumber.error

      if (
        previousValidation &&
        !validation.city.error &&
        !validation.companyAddress.error &&
        !validation.zipCode.error
      ) {
        await updateUserPersonalInfo({
          ...userState,
          shippingInfo,
        })

        if (loggedUser) {
          const { onboarded } = loggedUser
          if (!onboarded) {
            navigate(HOME_ENDPOINT)
          }
        }
      }
    } catch (error) {
      if (error.message !== ERROR_EMAIL_UNVERIFIED) {
        error.message = ERROR_ADDING_PERSONAL_INFO
      }
      setErrorMessage(error.message)
    }
    setIsLoading(false)
  }

  const closeDropzone = () => {
    setOpenDropzone(false)
  }

  const saveFile = async files => {
    const file = files[0]
    closeDropzone()
    setIsLoading(true)
    try {
      const url = await uploadFile(AVATAR_FOLDER_PATH, userState.uid, file)
      setUserState({ ...userState, photoUrl: url })
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onClick = () => {
    setOpenDropzone(true)
  }

  const countryUpdate = val => {
    const countryCode = iso3311a2.getCode(val)
    setShippingInfo({ ...shippingInfo, country: val, countryCode })
  }

  const updateRegion = val => {
    setShippingInfo({ ...shippingInfo, state: val })
  }

  const updateZipCode = event => {
    const {
      target: { value },
    } = event
    const regex = /^[0-9\b]+$/
    if ((size(value) <= 5 && regex.test(value)) || value === '') {
      setShippingInfo({ ...shippingInfo, zipCode: value })
    }
  }

  const updateShippingInfo = prop => event => {
    const temp = { ...shippingInfo }
    const {
      target: { value },
    } = event
    temp[prop] = value
    setShippingInfo(temp)
  }

  const verifyAddress = async () => {
    setIsVerifyingAddress(true)
    if (
      !validation.city.error &&
      !validation.companyAddress.error &&
      !validation.zipCode.error &&
      shippingInfo.state !== 'State *' &&
      shippingInfo.country !== 'Country *' &&
      shippingInfo.city.length > 0 &&
      shippingInfo.zipCode.length === 5 &&
      shippingInfo.companyAddress.length > 0
    ) {
      setErrorMessage('')
      setValidation({
        ...validation,
        city: defaultValidations,
        companyAddress: defaultValidations,
        zipCode: defaultValidations,
      })
      try {
        let { city, companyAddress, country, state, zipCode } = shippingInfo
        const { countryCode, stateCode } = getCountryState(country, state)
        const addressValidation = await validateAddress(
          companyAddress,
          city,
          countryCode,
          stateCode,
          zipCode
        )
        const { isValid, matchedAddress, message } = addressValidation
        if (isValid) {
          let { address, city, country, state, zipCode } = matchedAddress
          const { countryName, stateName } = getCountryState(country, state)
          setShippingInfo({
            city,
            companyAddress: address,
            companyName: '',
            country: countryName,
            state: stateName,
            zipCode,
          })
        } else {
          setErrorMessage(message)
          setValidation({
            ...validation,
            city: errorState,
            companyAddress: errorState,
            zipCode: errorState,
          })
        }
      } catch (error) {
        setErrorMessage(error.message)
      }
    }
    setIsVerifyingAddress(false)
  }

  return (
    <Container className={classes.root} component="main" maxWidth="xs">
      <DropzoneDialog
        acceptedFiles={['image/jpeg', 'image/png']}
        filesLimit={1}
        maxFileSize={500000}
        open={openDropzone}
        showPreviewsInDropzone={false}
        showPreviews={true}
        onClose={closeDropzone}
        onSave={saveFile}
      />
      <form className="signup-form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorMessage message={errorMessage} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className={onBoardClasses.flex}>
              {isLoading ? (
                <CircularProgress className={onBoardClasses.autoMargin} />
              ) : (
                <Avatar
                  className={classes.avatarIcon}
                  src={userState.photoUrl}
                  onClick={onClick}
                >
                  <PersonOutlineIcon className={classes.medium} />
                </Avatar>
              )}
            </Grid>
            <Grid
              item
              xs={9}
              container
              spacing={1}
              className={onBoardStyles.removeRightPadding}
            >
              <Grid item xs={12}>
                <TextField
                  className={classes.styledField}
                  error={validation.firstName.error}
                  fullWidth
                  helperText={validation.firstName.helperText}
                  id="firstName"
                  label="First Name"
                  placeholder="First Name"
                  required
                  size="small"
                  type="text"
                  value={userState.firstName}
                  variant="outlined"
                  onBlur={validateInput('firstName')}
                  onChange={updateField('firstName')}
                  InputProps={{
                    endAdornment: (
                      <PersonOutlineOutlinedIcon className="field-icons" />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={classes.styledField}
                  error={validation.lastName.error}
                  fullWidth
                  helperText={validation.lastName.helperText}
                  id="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  required
                  size="small"
                  type="text"
                  value={userState.lastName}
                  variant="outlined"
                  onBlur={validateInput('lastName')}
                  onChange={updateField('lastName')}
                  InputProps={{
                    endAdornment: (
                      <PersonOutlineOutlinedIcon className="field-icons" />
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.styledField}
              error={validation.phoneNumber.error}
              fullWidth
              id="phoneNumber"
              helperText={validation.phoneNumber.helperText}
              label="Phone number (Optional)"
              placeholder="Phone number (Optional)"
              size="small"
              variant="outlined"
              value={userState.phoneNumber}
              onBlur={validateInput('phoneNumber')}
              onChange={setPhoneNumber}
              InputProps={{
                endAdornment: <PhoneOutlinedIcon className="field-icons" />,
              }}
            />
          </Grid>
          <Grid
            className={clsx(onBoardClasses.shippingContainer, {
              [onBoardClasses.marginTop]: isVerifyingAddress,
            })}
            container
            spacing={2}
          >
            {isVerifyingAddress && (
              <CircularProgress className={onBoardClasses.spinner} size={40} />
            )}
            <Grid className={onBoardClasses.container} item xs={12}>
              <div className={onBoardClasses.customContainer}>
                <CountryDropdown
                  className={clsx(onBoardClasses.dropdown, {
                    [onBoardClasses.selectedItem]:
                      shippingInfo.country !== 'Country *',
                  })}
                  disabled
                  defaultOptionLabel="Country *"
                  value={shippingInfo.country}
                  onChange={countryUpdate}
                />
              </div>
            </Grid>
            <Grid className={onBoardClasses.container} item xs={12}>
              <TextField
                className={classes.styledField}
                error={validation.companyAddress.error}
                fullWidth
                helperText={validation.companyAddress.helperText}
                id="companyAddress"
                label="Address"
                required
                size="small"
                type="text"
                value={shippingInfo.companyAddress}
                variant="outlined"
                onBlur={validateInput('companyAddress')}
                onChange={updateShippingInfo('companyAddress')}
                InputProps={{
                  endAdornment: <HomeOutlinedIcon className="field-icons" />,
                }}
              />
            </Grid>
            <Grid className={onBoardClasses.container} item xs={12}>
              <TextField
                className={classes.styledField}
                error={validation.city.error}
                fullWidth
                helperText={validation.city.helperText}
                id="city"
                label="City"
                required
                size="small"
                type="text"
                value={shippingInfo.city}
                variant="outlined"
                onBlur={validateInput('city')}
                onChange={updateShippingInfo('city')}
                InputProps={{
                  endAdornment: (
                    <LocationCityOutlinedIcon className="field-icons" />
                  ),
                }}
              />
            </Grid>
            <Grid className={onBoardClasses.container} item xs={12} sm={6}>
              <div className={onBoardClasses.customContainer}>
                <RegionDropdown
                  className={clsx(onBoardClasses.dropdown, {
                    [onBoardClasses.selectedItem]: shippingInfo.state !== '',
                  })}
                  country={shippingInfo.country}
                  defaultOptionLabel="State *"
                  value={shippingInfo.state}
                  valueType="full"
                  onChange={updateRegion}
                  onBlur={verifyAddress}
                />
              </div>
            </Grid>
            <Grid className={onBoardClasses.container} item xs={12} sm={6}>
              <TextField
                className={classes.styledField}
                error={validation.zipCode.error}
                fullWidth
                helperText={validation.zipCode.helperText}
                id="zipCode"
                label="Zip Code"
                required
                size="small"
                type="text"
                variant="outlined"
                value={shippingInfo.zipCode}
                onBlur={validateInput('zipCode')}
                onChange={updateZipCode}
                InputProps={{
                  endAdornment: (
                    <MarkunreadMailboxOutlinedIcon className="field-icons" />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.continueBtnContainer} item xs={12}>
          <Button
            className={classes.continueButton}
            disabled={isVerifyingAddress || isLoading}
            fullWidth
            variant="outlined"
            type="submit"
            xs={12}
          >
            {editing ? 'Save' : 'Continue to Site'}
          </Button>
        </Grid>
      </form>
    </Container>
  )
}
OnBoard.propTypes = {
  editing: PropTypes.bool,
}

OnBoard.defaultProps = {
  editing: false,
}

export default OnBoard
