import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  CommonButton,
  CustomDialogTitle,
  ErrorMessage,
  HelperText,
} from 'components'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'

import { sendMailToReportIssue } from 'services/MailService'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
    '& .MuiDialogTitle-root': {
      padding: '10px 0px',
    },
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      width: '500px',
      padding: '20px',
    },
  },
  dialogActions: {
    '& .MuiDialogActions-root': {
      padding: '8px 0px',
    },
  },
  container: {
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      zIndex: '100',
    },
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const DESCRIPTION_MIN_LENGTH = 20
const DESCRIPTION_MAX_LENGTH = 500

const IssueModal = ({
  listingId,
  modelId,
  open,
  ownedBy,
  handleCancelIssueModal,
}) => {
  const classes = useStyles()
  const [counter, setCounter] = useState(0)
  const [description, setDescription] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [issue, setIssue] = useState('')
  const [loading, setLoading] = useState(false)

  const issueCategories = [
    { issue: 'Transaction Issue' },
    { issue: 'Quality Issue' },
    { issue: 'Platform Issue' },
    { issue: 'Other' },
  ]

  const initialStatus = () => {
    setIssue('')
    setDescription('')
    setCounter(0)
  }

  const handleClose = () => {
    initialStatus()
    handleCancelIssueModal()
    setErrorMessage('')
  }

  const handleChangeDescription = event => {
    event.preventDefault()
    const {
      target: { value },
    } = event
    setErrorMessage('')
    if (value.length <= DESCRIPTION_MAX_LENGTH) {
      setDescription(value)
      setCounter(value.length)
    }
  }

  const handleIssue = event => {
    const {
      target: { value },
    } = event
    setErrorMessage('')
    setIssue(value)
  }

  const disableSendButton = () => {
    return (
      !description ||
      !issue ||
      description.length < DESCRIPTION_MIN_LENGTH ||
      description.length > DESCRIPTION_MAX_LENGTH
    )
  }

  const handleSend = async () => {
    try {
      setLoading(true)
      const subject = `LISTING: ${listingId} \nDESIGN: ${modelId} \nOWNER: ${ownedBy}`
      await sendMailToReportIssue(description, issue, subject)
      initialStatus()
      setLoading(false)
    } catch (error) {
      setErrorMessage(
        'There was an error when trying to send the email, please try again later.'
      )
    } finally {
      initialStatus()
      setLoading(false)
    }
  }

  return (
    <>
      <Dialog className={classes.dialog} open={open} onClose={handleClose}>
        <div>
          <div className={classes.boldTitle}>
            <ErrorMessage message={errorMessage} />
            <CustomDialogTitle onClose={handleClose}>
              Report issue
            </CustomDialogTitle>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel id="issue-label">Issue</InputLabel>
                <Select
                  className={classes.select}
                  id="issue"
                  fullWidth
                  labelId="issue-label"
                  label="Report Issue"
                  value={issue}
                  onChange={handleIssue}
                >
                  <MenuItem value="" disabled>
                    Please select
                  </MenuItem>
                  {issueCategories.map((item, index) => (
                    <MenuItem
                      className={classes.buttonStyles}
                      key={index}
                      value={item.issue}
                    >
                      {item.issue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                multiline
                placeholder="Tell us whats wrong"
                rows={6}
                value={description}
                variant="outlined"
                onChange={handleChangeDescription}
              />
              <HelperText
                counter={counter}
                maxLength={DESCRIPTION_MAX_LENGTH}
                minLength={DESCRIPTION_MIN_LENGTH}
                text={description}
              />
            </Grid>
          </Grid>
          <div className={classes.dialogActions}>
            <DialogActions>
              <CommonButton
                buttonStyle={BUTTON_STYLE.CANCEL}
                fullWidth={false}
                label="Cancel"
                variant={BUTTON_VARIANT.OUTLINED}
                onClick={handleClose}
              />
              <div className={classes.container}>
                {loading && <CircularProgress />}
                <CommonButton
                  buttonStyle={BUTTON_STYLE.PRIMARY}
                  disabled={disableSendButton()}
                  fullWidth={false}
                  label="Submit"
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={handleSend}
                />
              </div>
            </DialogActions>
          </div>
        </div>
      </Dialog>
    </>
  )
}

IssueModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancelIssueModal: PropTypes.func.isRequired,
}

export default IssueModal
