import getFirebase from './firebase'
import Resizer from 'react-image-file-resizer'
const firebaseApp = getFirebase()

export const resizeFile = async file => {
  return await new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      150,
      150,
      file.type,
      25,
      0,
      uri => {
        resolve(uri)
      },
      'file'
    )
  })
}

export const uploadFile = (
  folderPath,
  fileName,
  file,
  generateDownloadURL = true,
  updateInformationUploadProgress,
  isThumbnail = false
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const storageRef = firebaseApp.storage().ref(`${folderPath}/${fileName}`)
      let uploadTask
      if (!isThumbnail) {
        uploadTask = storageRef.put(file)
      } else {
        let thumbnailToUpload = await resizeFile(file)
        if (file.size < thumbnailToUpload.size) {
          thumbnailToUpload = file
        }
        uploadTask = storageRef.put(thumbnailToUpload)
      }

      uploadTask.on(
        'state_changed',
        snapshot => {
          if (updateInformationUploadProgress) {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            updateInformationUploadProgress({
              name: fileName,
              progress: progress,
            })
          }
        },
        error => {
          console.log('upload error: ', error.message)
          reject(error)
        },
        () => {
          if (generateDownloadURL) {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(url => {
                resolve(url)
              })
              .catch(error => {
                console.log('url error: ', error.message)
                reject(error)
              })
          } else {
            resolve(uploadTask.snapshot.metadata.fullPath)
          }
        }
      )
    } catch (error) {
      reject(error)
    }
  })
}

export const downloadFile = path => {
  return new Promise((resolve, reject) => {
    try {
      const storageRef = firebaseApp.storage().ref(path)
      storageRef
        .getDownloadURL()
        .then(url => {
          let xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = () => {
            let blob = xhr.response
            resolve(blob)
          }
          xhr.open('GET', url)
          xhr.send()
        })
        .catch(error => {
          console.log('download error: ', error.message)
          reject(error)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export const downloadThingFile = url => {
  return new Promise((resolve, reject) => {
    try {
      let xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = () => {
        let blob = xhr.response
        resolve(blob)
      }
      xhr.open('GET', url)
      xhr.send()
    } catch (error) {
      console.log(error.message)
      reject(error)
    }
  })
}
