import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const FeatureFlag = ({ activeComponent, inactiveComponent, flag }) => {
  const enabled = useSelector(state => state.featureFlagState.flags[flag])
  return enabled ? inactiveComponent : activeComponent
}

FeatureFlag.propTypes = {
  activeComponent: PropTypes.node,
  inactiveComponent: PropTypes.node,
  flag: PropTypes.string.isRequired,
}

FeatureFlag.defaultProps = {
  activeComponent: null,
  inactiveComponent: null,
}

export default FeatureFlag
