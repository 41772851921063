import React, { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { isEmpty, size } from 'lodash'
import { DropzoneArea } from 'material-ui-dropzone'
import {
  Checkbox,
  Container,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { CustomTextFieldNumber, MeasurementChange } from 'components'
import {
  EMPTY_FIELD,
  INCH_TO_MM,
  INCHES_UNIT,
  INVALID_FIELD,
  MILLIMETER_UNIT,
} from 'constants/modelConstants'
import { removeContactInformationFromMessage } from 'constants/utils'

const useStyles = makeStyles(theme => ({
  radioButtonRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '4px',
    marginBottom: '4px',
  },
  root: {
    '& .MuiDropzoneArea-root': {
      minHeight: 'auto',
    },
    '& .MuiDropzoneArea-text': {
      margin: '8px 0',
      fontSize: '0.875rem',
    },

    '& .MuiDropzoneArea-icon': {
      height: '20px',
    },
  },
  alignCenter: {
    margin: 'auto',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiIconButton-root': {
      padding: 0,
    },
    [theme.breakpoints.down(600)]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  bodySection: {
    border: '1px solid #C3C3C3',
    borderRadius: '4px',
    padding: '0px 0',
    margin: '20px 0',
  },
  flex: {
    display: 'flex',
  },
  label: {
    color: '#000',
  },
  modelFile: {
    marginTop: '35px',
  },
  previewChip: {
    maxWidth: 350,
    marginTop: '5px',
  },
  properties: {
    marginTop: '15px',
  },
  propertiesLabel: {
    color: '#000',
    paddingLeft: '12px',
  },
  radioButton: {
    color: '#000',
  },
  radioGroup: {
    display: 'block',
  },
  removePaddingMargin: {
    padding: '0px',
    margin: '0px',
  },
  row: {
    margin: 0,
    padding: 0,
    justifyContent: 'center',
  },
  section: {
    paddingTop: '20px',
  },
  styledField: {
    color: '#000',
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  styleSwitch: {
    padding: '0px',
    marginLeft: '40px',
  },
  tableBody: {
    maxWidth: '350px',
    width: '100%',
    margin: 'auto',
  },
  measurementMargin: {
    marginBottom: '4px',
    marginTop: '4px',
    [theme.breakpoints.down(600)]: {
      minWidth: '100%',
    },
  },
  cellPadding: {
    padding: '2px',
  },
  fileName: {
    '& .MuiDropzonePreviewList-imageContainer > .MuiChip-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '550px',
      '@media (max-width:1200px)': {
        maxWidth: '400px',
      },
      '@media (max-width:960px)': {
        maxWidth: '300px',
      },
      '@media (max-width:750px)': {
        maxWidth: '200px',
      },
    },
  },
}))

const defaultValidations = {
  error: false,
  helperText: '',
}

const defaultValidationState = {
  additionalComponents: defaultValidations,
  additionalTags: defaultValidations,
  category: defaultValidations,
  description: defaultValidations,
  designFiles: defaultValidations,
  imageFiles: defaultValidations,
  material: defaultValidations,
  name: defaultValidations,
  printNotes: defaultValidations,
}

const ARROWS_STATE = { ADD: 'ADD', SUBTRACT: 'SUBTRACT' }
const MEASURES = ['width', 'height', 'depth']
const MINIMUM_SIZE = 0.001

const MAX_DESIGNS_LIMIT = 25

const DesignForm = ({
  categories,
  design,
  materials,
  setCurrentUnit,
  setDesign,
}) => {
  const classes = useStyles()

  const [validation, setValidation] = useState(defaultValidationState)
  const [unit, setUnit] = useState(MILLIMETER_UNIT)
  const [controller, setController] = useState({ label: '', moveTo: '' })
  const [changeValue, setChangeValue] = useState(false)
  const [editValue, setEditValue] = useState({ typeMeasure: '' })

  const handlMouseDown = (label, moveTo) => {
    setController({ label, moveTo })
    setChangeValue(true)
  }

  const handleMouseUp = () => {
    setChangeValue(false)
  }

  const handleAdd = useCallback(
    prop => {
      const { properties } = design
      properties[prop] = (parseFloat(properties[prop]) + MINIMUM_SIZE).toFixed(
        3
      )
      setDesign({ ...design, properties })
    },
    [design, setDesign]
  )

  const handleDesignChange = designFiles => {
    setDesign({ ...design, designFiles })
  }

  const handleImageChange = imageFiles => {
    setDesign({ ...design, imageFiles })
  }

  const handleSubtract = useCallback(
    prop => {
      const { properties } = design
      if (properties[prop] > MINIMUM_SIZE) {
        properties[prop] = (
          parseFloat(properties[prop]) - MINIMUM_SIZE
        ).toFixed(3)
        setDesign({ ...design, properties })
      }
    },
    [design, setDesign]
  )

  useEffect(() => {
    if (changeValue) {
      const { label, moveTo } = controller
      const interval = setInterval(() => {
        if (moveTo === ARROWS_STATE.ADD) {
          handleAdd(label)
        }
        if (moveTo === ARROWS_STATE.SUBTRACT) {
          handleSubtract(label)
        }
      }, 100)
      return () => clearInterval(interval)
    }
  }, [changeValue, controller, handleAdd, handleSubtract])

  const hasExtraWhiteSpaces = val => {
    const index = val.indexOf('  ')
    return index >= 0
  }

  const addMaximumCharactersMessage = (prop, value) => {
    const defaultCharacters = 150
    const nameCharacters = 20
    let leftCharacters = defaultCharacters - value.length
    if (prop === 'name') {
      leftCharacters = nameCharacters - value.length
    }
    if (leftCharacters >= 0) {
      const message = removeContactInformationFromMessage(value)
      setDesign({ ...design, [prop]: message })
      setValidation({
        ...validation,
        [prop]: {
          error: false,
          helperText: `Maximum Characters Left: ${leftCharacters}`,
        },
      })
    }
  }

  const updateField = prop => event => {
    const {
      target: { value },
    } = event

    addMaximumCharactersMessage(prop, value)
  }

  const updatePublic = () => {
    const { isPublic } = design
    setDesign({
      ...design,
      isPublic: !isPublic,
    })
  }

  const updateSupportRequired = () => {
    const { isSupportRequired } = design
    setDesign({
      ...design,
      isSupportRequired: !isSupportRequired,
    })
  }

  const updateProperties = prop => event => {
    const { properties } = design

    properties[prop] = event.target.value

    setDesign({ ...design, properties })
  }

  const validateInput = prop => event => {
    const {
      target: { value },
    } = event
    const trimmedValue = value.trim()
    setValidation({ ...validation, [prop]: defaultValidations })
    if (prop === 'name' || prop === 'description') {
      if (size(trimmedValue) === 0) {
        setValidation({
          ...validation,
          [prop]: {
            error: true,
            helperText: EMPTY_FIELD,
          },
        })
      } else {
        addMaximumCharactersMessage(prop, value)
      }

      if (!isEmpty(value) && hasExtraWhiteSpaces(value)) {
        setValidation({
          ...validation,
          [prop]: {
            error: true,
            helperText: INVALID_FIELD,
          },
        })
      }
    }
  }

  const handleChangeMeasurements = () => {
    const currentUnit = unit === MILLIMETER_UNIT ? INCHES_UNIT : MILLIMETER_UNIT
    setUnit(currentUnit)
    setEditValue({ typeMeasure: '' })
    setCurrentUnit(currentUnit)
    const { properties } = design
    for (const currentMeasure of MEASURES) {
      const property = properties[currentMeasure]
      const condition =
        currentUnit === MILLIMETER_UNIT ? INCH_TO_MM : 1 / INCH_TO_MM
      properties[currentMeasure] = property * condition
    }
    setDesign({ ...design, properties })
  }

  const handleChangeValueOnBlur = (typeMeasure, value) => {
    const { properties } = design
    if (parseFloat(value) === 0) {
      properties[typeMeasure] = parseFloat(MINIMUM_SIZE).toFixed(3)
    } else {
      properties[typeMeasure] = parseFloat(value).toFixed(3)
    }
    setDesign({ ...design, properties })
  }

  const handleChangeValueOnChange = (typeMeasure, value) => {
    const { properties } = design
    setEditValue({ typeMeasure })
    properties[typeMeasure] = value
    if (isEmpty(value)) {
      properties[typeMeasure] = parseFloat(MINIMUM_SIZE).toFixed(3)
    }
    setDesign({ ...design, properties })
  }

  const formatValue = typeMeasure => {
    const { properties } = design
    return editValue.typeMeasure === typeMeasure
      ? properties[typeMeasure]
      : parseFloat(properties[typeMeasure]).toFixed(3)
  }

  return (
    <Container component="main" className={classes.root}>
      <form className="design-form">
        <Grid container>
          <Grid container className={classes.section}>
            <Grid container className={classes.row} spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth id="design-name">
                  <TextField
                    autoComplete="name"
                    className={classes.styledField}
                    error={validation.name.error}
                    fullWidth
                    helperText={validation.name.helperText}
                    id="name"
                    label="Design Name"
                    required
                    size="small"
                    type="text"
                    value={design.name}
                    variant="outlined"
                    placeholder="Name"
                    onBlur={validateInput('name')}
                    onChange={updateField('name')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  id="design-category"
                  size="small"
                  variant="outlined"
                >
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                    defaultValue=""
                    labelId="category-label"
                    id="category"
                    value={design.category}
                    label="Category"
                    onChange={updateField('category')}
                  >
                    <MenuItem value="" disabled>
                      Please select
                    </MenuItem>
                    {categories.map((item, index) => (
                      <MenuItem
                        className={classes.buttonStyles}
                        key={index}
                        value={item.category}
                      >
                        {item.category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.bodySection}>
            <Grid container className={classes.row} spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    className={classes.styledField}
                    error={validation.description.error}
                    fullWidth
                    helperText={validation.description.helperText}
                    id="model-description"
                    label="Description"
                    multiline
                    placeholder="Enter design description"
                    rows={4}
                    required
                    size="small"
                    type="text"
                    value={design.description}
                    variant="outlined"
                    onBlur={validateInput('description')}
                    onChange={updateField('description')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container direction="column">
                  <Grid item xs={12}>
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      id="preferred-material"
                      size="small"
                      variant="outlined"
                    >
                      <InputLabel id="material-label">
                        Preferred Material
                      </InputLabel>
                      <Select
                        defaultValue=""
                        id="material"
                        labelId="material-label"
                        label="Preferred Material"
                        value={design.properties.material}
                        onChange={updateProperties('material')}
                      >
                        <MenuItem value="" disabled>
                          Please select
                        </MenuItem>
                        {materials.map((item, index) => (
                          <MenuItem
                            className={classes.buttonStyles}
                            key={index}
                            value={item.name}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className={classes.properties}>
                    <Grid
                      container
                      className={classes.row}
                      id="measurement-change"
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={classes.radioButtonRoot}>
                          <MeasurementChange
                            groupSize="medium"
                            unit={unit}
                            onHandleChangeMeasurements={
                              handleChangeMeasurements
                            }
                          />
                        </div>
                      </Grid>
                      <Grid
                        className={classes.measurementMargin}
                        item
                        xs={4}
                        sm={6}
                        md={3}
                      >
                        <FormControl fullWidth className={classes.buttons}>
                          <FormLabel
                            component="legend"
                            className={classes.label}
                          >
                            Width
                          </FormLabel>
                          <div className={classes.buttons}>
                            <IconButton
                              disabled={design.properties.width <= MINIMUM_SIZE}
                              onClick={() => handleSubtract('width')}
                              onMouseDown={() =>
                                handlMouseDown('width', ARROWS_STATE.SUBTRACT)
                              }
                              onMouseUp={handleMouseUp}
                            >
                              <RemoveIcon />
                            </IconButton>
                            <CustomTextFieldNumber
                              fieldName="width"
                              number={formatValue('width')}
                              onBlur={handleChangeValueOnBlur}
                              onChange={handleChangeValueOnChange}
                            />

                            <IconButton
                              onClick={() => handleAdd('width')}
                              onMouseDown={() =>
                                handlMouseDown('width', ARROWS_STATE.ADD)
                              }
                              onMouseUp={handleMouseUp}
                            >
                              <AddIcon />
                            </IconButton>
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid
                        className={classes.measurementMargin}
                        item
                        xs={4}
                        sm={6}
                        md={3}
                      >
                        <FormControl fullWidth className={classes.buttons}>
                          <FormLabel
                            component="legend"
                            className={classes.label}
                          >
                            Height
                          </FormLabel>
                          <div className={classes.buttons}>
                            <IconButton
                              disabled={
                                design.properties.height <= MINIMUM_SIZE
                              }
                              onClick={() => handleSubtract('height')}
                              onMouseDown={() =>
                                handlMouseDown('height', ARROWS_STATE.SUBTRACT)
                              }
                              onMouseUp={handleMouseUp}
                            >
                              <RemoveIcon />
                            </IconButton>
                            <CustomTextFieldNumber
                              fieldName="height"
                              number={formatValue('height')}
                              onBlur={handleChangeValueOnBlur}
                              onChange={handleChangeValueOnChange}
                            />

                            <IconButton
                              onClick={() => handleAdd('height')}
                              onMouseDown={() =>
                                handlMouseDown('height', ARROWS_STATE.ADD)
                              }
                              onMouseUp={handleMouseUp}
                            >
                              <AddIcon />
                            </IconButton>
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid
                        className={classes.measurementMargin}
                        item
                        xs={4}
                        sm={6}
                        md={3}
                      >
                        <FormControl fullWidth className={classes.buttons}>
                          <FormLabel
                            component="legend"
                            className={classes.label}
                          >
                            Depth
                          </FormLabel>
                          <div className={classes.buttons}>
                            <IconButton
                              disabled={design.properties.depth <= MINIMUM_SIZE}
                              onClick={() => handleSubtract('depth')}
                              onMouseDown={() =>
                                handlMouseDown('depth', ARROWS_STATE.SUBTRACT)
                              }
                              onMouseUp={handleMouseUp}
                            >
                              <RemoveIcon />
                            </IconButton>
                            <CustomTextFieldNumber
                              fieldName="depth"
                              number={formatValue('depth')}
                              onBlur={handleChangeValueOnBlur}
                              onChange={handleChangeValueOnChange}
                            />

                            <IconButton
                              onClick={() => handleAdd('depth')}
                              onMouseDown={() =>
                                handlMouseDown('depth', ARROWS_STATE.ADD)
                              }
                              onMouseUp={handleMouseUp}
                            >
                              <AddIcon />
                            </IconButton>
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={classes.row} spacing={3}>
              <Grid className={classes.fileName} item xs={12} sm={12}>
                <FormControl fullWidth id="upload-design-image">
                  <FormLabel className={classes.label} component="legend">
                    Image
                  </FormLabel>
                  <DropzoneArea
                    acceptedFiles={['image/jpeg', 'image/png']}
                    alertSnackbarProps={{
                      anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    }}
                    filesLimit={10}
                    initialFiles={design.imageFiles}
                    showPreviews={true}
                    showPreviewsInDropzone={false}
                    previewChipProps={{
                      classes: { root: classes.previewChip },
                    }}
                    previewGridProps={{
                      container: { spacing: 1, direction: 'row' },
                    }}
                    previewText=""
                    useChipsForPreview
                    onChange={handleImageChange}
                  />
                </FormControl>
                <FormControl id="upload-design-model" fullWidth>
                  <FormLabel className={classes.label} component="legend">
                    Model File
                  </FormLabel>
                  <DropzoneArea
                    acceptedFiles={[
                      '.stl',
                      'model/*',
                      'application/sla',
                      'application/vnd.ms-pki.stl',
                    ]}
                    alertSnackbarProps={{
                      anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    }}
                    filesLimit={MAX_DESIGNS_LIMIT}
                    initialFiles={design.designs}
                    maxFileSize={10000000000}
                    showPreviews={true}
                    dropzoneText={'Drag and drop a STL file here or click'}
                    showPreviewsInDropzone={false}
                    previewChipProps={{
                      classes: { root: classes.previewChip },
                    }}
                    previewGridProps={{
                      container: { spacing: 1, direction: 'row' },
                    }}
                    previewText=""
                    useChipsForPreview
                    onChange={handleDesignChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container className={classes.row} spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="printNotes"
                    className={classes.styledField}
                    fullWidth
                    helperText={validation.printNotes.helperText}
                    id="print-settings"
                    label="Print Notes"
                    multiline
                    placeholder="Please describe some"
                    rows={4}
                    size="small"
                    type="text"
                    value={design.printNotes}
                    variant="outlined"
                    onBlur={validateInput('printNotes')}
                    onChange={updateField('printNotes')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="additionalComponents"
                    className={classes.styledField}
                    fullWidth
                    helperText={validation.additionalComponents.helperText}
                    id="additional-components-needed"
                    label="Additional components needed"
                    multiline
                    rows={4}
                    size="small"
                    type="text"
                    value={design.additionalComponents}
                    variant="outlined"
                    onBlur={validateInput('additionalComponents')}
                    onChange={updateField('additionalComponents')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    autoComplete="additionalTags"
                    className={classes.styledField}
                    fullWidth
                    helperText={validation.additionalTags.helperText}
                    id="additional-tags"
                    label="Additional Tags"
                    placeholder="Please add some(separated by a comma) to find it easier. Ex: a,b,c"
                    size="small"
                    type="text"
                    value={design.additionalTags}
                    variant="outlined"
                    onBlur={validateInput('additionalTags')}
                    onChange={updateField('additionalTags')}
                  />
                </FormControl>
              </Grid>
              <Grid container item xs={12} sm={6}>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.removePaddingMargin}
                    id="support-additional-required"
                    control={
                      <Checkbox
                        className={classes.removePaddingMargin}
                        checked={design.isSupportRequired}
                        onChange={updateSupportRequired}
                        name="isPublic"
                      />
                    }
                    label="Supports Required"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.removePaddingMargin}
                    id="update-public"
                    control={
                      <Checkbox
                        className={classes.removePaddingMargin}
                        checked={design.isPublic}
                        onChange={updatePublic}
                        name="isPublic"
                      />
                    }
                    label="Make my design public"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

DesignForm.propTypes = {
  categories: PropTypes.instanceOf(Array),
  design: PropTypes.instanceOf(Object),
  materials: PropTypes.instanceOf(Array),
  setCurrentUnit: PropTypes.func,
  setDesign: PropTypes.func,
}

DesignForm.defaultProps = {
  categories: [],
  design: { properties: {} },
  materials: [],
  setCurrentUnit: () => {},
  setDesign: () => {},
}

export default DesignForm
