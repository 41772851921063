import React from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AlertRow } from 'components'

const useStyles = makeStyles(theme => ({
  badge: {
    backgroundColor: theme.palette.background.lightgray,
    borderRadius: '50%',
    padding: '4px',
    paddingLeft: '8px',
    paddingRight: '8px',
    marginLeft: '8px',
  },
  background: {
    '& .MuiTableRow-root .MuiTableCell-head.MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.background.white,
    },
  },
  container: {
    maxHeight: '168px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#e0e0e0',
  },
  root: {
    width: '100%',
    height: '100%',
  },
  tittle: {
    '& .MuiTypography-root.MuiTypography-caption': {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },
  },
}))

const AlertTable = ({ alertList, markAlertAsRead, onRequestAlerts }) => {
  const classes = useStyles()

  const handleScroll = async event => {
    const { scrollHeight, scrollTop, clientHeight } = event.target
    const scrollPosition = scrollHeight - scrollTop - clientHeight
    if (scrollPosition <= 0) {
      onRequestAlerts()
    }
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container} onScroll={handleScroll}>
        <Table stickyHeader>
          <TableHead className={classes.background}>
            <TableRow>
              <TableCell className={classes.tittle}>
                <Typography variant="caption">Alerts</Typography>
                <Typography className={classes.badge} variant="caption">
                  {alertList.length}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alertList.map((alert, i) => (
              <AlertRow
                key={i}
                alert={alert}
                markAlertAsRead={markAlertAsRead}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

AlertTable.prototype = {
  alertList: PropTypes.arrayOf(PropTypes.object),
  markAlertAsRead: PropTypes.func,
  onRequestAlerts: PropTypes.func,
}

AlertTable.defaultProps = {
  alertList: [],
  markAlertAsRead: () => {},
  onRequestAlerts: () => {},
}

export default AlertTable
