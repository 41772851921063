import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  body: {
    padding: '16px',
  },
  container: {
    padding: '16px',
  },
  content: {
    fontWeight: 'bold',
  },
  header: {
    borderBottomColor: theme.palette.border.lightgray,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    color: theme.palette.text.gray,
    fontWeight: 'bold',
    paddingBottom: '8px',
    paddingTop: '8px',
  },
  innerContainer: {
    marginBottom: '16px',
    marginTop: '16px',
  },
  tittle: {
    color: theme.palette.text.gray,
    fontWeight: 'bold',
    opacity: '0.7',
    marginBottom: '4px',
    marginTop: '4px',
  },
}))

const ShippingDetails = ({ shippingDetails, showShippingAddress }) => {
  const classes = useStyles()
  const {
    country,
    printNotes,
    quantity,
    shippingAddress,
    state,
    zipCode,
  } = shippingDetails

  return (
    <Grid className={classes.container} container>
      <Grid className={classes.header} container item xs={12}>
        Shipping Details
      </Grid>
      <Grid className={classes.body} container item xs={12}>
        <Grid className={classes.innerContainer} container item xs={12}>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.tittle}>Shipping Address</Typography>
            {showShippingAddress && (
              <Typography className={classes.content}>
                {shippingAddress}
              </Typography>
            )}
            <Typography className={classes.content}>
              {`${state}, ${zipCode} ${country}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography className={classes.tittle}>Quantity</Typography>
            <Typography className={classes.content}>{quantity}</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.innerContainer} item xs={12}>
          <Typography className={classes.tittle}>Print Notes</Typography>
          <Typography className={classes.content}>{printNotes}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

ShippingDetails.propTypes = {
  shippingDetails: PropTypes.object.isRequired,
  showShippingAddress: PropTypes.bool,
}

ShippingDetails.defaultProps = {
  showShippingAddress: false,
}

export default ShippingDetails
