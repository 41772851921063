import * as Firebase from 'firebase'
import getFirebase from './firebase'
const firebase = getFirebase()

export const addTransfer = (listingId, offerId, makerFee, userId) => {
  return new Promise((resolve, reject) => {
    if (firebase) {
      firebase
        .firestore()
        .collection('transfers')
        .add({
          created_at: Firebase.firestore.FieldValue.serverTimestamp(),
          listingId,
          maker_fee: makerFee,
          offerId,
          status: 'PENDING',
          user_id: userId,
        })
        .then(transfer => {
          resolve(transfer)
        })
        .catch(error => {
          reject(error)
        })
    }
  })
}
