import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { size } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, TablePagination } from '@material-ui/core'
import {
  ErrorMessage,
  ReviewDesignRow,
  ReviewRow,
  ReviewsSkeleton,
} from 'components'
import {
  ERROR_FETCHING_REVIEWS,
  REVIEWS_LIMIT,
} from 'constants/reviewsConstants'
import { fetchDesignReviews } from 'services/DesignService'
import { fetchMakerReviews } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    position: 'relative',
  },
  customBackGround: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.white,
  },
  footerStyles: {
    bottom: 0,
    right: 0,
    '& .MuiToolbar-root .MuiTypography-root, .MuiInputBase-root .MuiSelect-root': {
      fontWeight: 'bold',
    },
    '& .MuiToolbar-root .MuiTypography-root': {
      pointerEvents: 'none',
      '&focused': {
        backgroundColor: theme.palette.background.white,
      },
    },
    '& .MuiToolbar-root .MuiInputBase-root': {
      pointerEvents: 'none',
      '&focused': {
        backgroundColor: theme.palette.background.white,
      },
    },
    '& .MuiToolbar-root .MuiInputBase-root .MuiSvgIcon-root': {
      display: 'none',
    },
  },
  fullHeight: {
    height: '82vh',
    minHeight: '480px',
    overflowY: 'auto',
  },
  root: {
    width: '100%',
  },
  title: {
    marginLeft: '12px',
    marginTop: '12px',
    fontWeight: 'bold',
  },
  '& .MuiDialog-paperScrollPaper': {
    maxHeight: '100%',
  },
  boldText: {
    '& .MuiToolbar-root .MuiTypography-root, .MuiInputBase-root .MuiSelect-root': {
      fontWeight: 'bold',
    },
  },
}))

const defaultReviews = {
  reviews: [],
  isFetched: false,
}

const ReviewsTable = ({ designId, makerId }) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState('')
  const [latestReviews, setLatestReviews] = useState([])
  const [loading, setLoading] = useState(true)
  const [hasNext, setHasNext] = useState(false)
  const [page, setPage] = useState(0)
  const [reviewsState, setReviewsState] = useState(defaultReviews)

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true)
      try {
        let listReviews
        if (!designId) {
          listReviews = await fetchMakerReviews(makerId, REVIEWS_LIMIT, null)
        } else {
          listReviews = await fetchDesignReviews(designId, REVIEWS_LIMIT, null)
        }
        const { lastElement, reviews } = listReviews
        setReviewsState({ fetched: true, reviews: reviews })
        setLatestReviews([lastElement])
        setHasNext(size(reviews) === REVIEWS_LIMIT)
      } catch (error) {
        setErrorMessage(ERROR_FETCHING_REVIEWS)
      }
      setLoading(false)
    }
    if (!reviewsState.isFetched) {
      fetchReviews()
    }
  }, [makerId, reviewsState.isFetched, designId])

  const fetchReviews = async lastElement => {
    let hasUpdated = false
    setErrorMessage('')
    setLoading(true)
    try {
      let listReviews
      if (!designId) {
        listReviews = await fetchMakerReviews(
          makerId,
          REVIEWS_LIMIT,
          lastElement
        )
      } else {
        listReviews = await fetchDesignReviews(
          designId,
          REVIEWS_LIMIT,
          lastElement
        )
      }
      const { lastElement: newLastElement, reviews } = listReviews
      if (size(reviews) > 0) {
        hasUpdated = true
        setHasNext(size(reviews) === REVIEWS_LIMIT)
        setReviewsState({
          reviews: reviews,
          fetched: true,
        })
        if (lastElement) {
          setLatestReviews([...latestReviews, newLastElement])
        } else {
          setLatestReviews([newLastElement])
        }
      } else {
        setHasNext(false)
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_REVIEWS)
    }
    setLoading(false)
    return hasUpdated
  }

  const handleChangePage = async (event, newPage) => {
    let response = true
    if (newPage > page) {
      response = await nextReviews(newPage)
    } else {
      await prevReviews(newPage)
    }
    if (response) {
      setPage(newPage)
    }
  }

  const nextReviews = async page => {
    const lastElement = latestReviews[page - 1]
    return fetchReviews(lastElement)
  }

  const prevReviews = async page => {
    const lastElement = page > 0 ? latestReviews[page - 1] : null
    latestReviews.pop()
    latestReviews.pop()
    setLatestReviews(latestReviews)
    fetchReviews(lastElement)
  }

  return designId ? (
    size(reviewsState.reviews) > 0 && (
      <Paper className={classes.container} elevation={0}>
        <div className={classes.fullHeight}>
          <ErrorMessage message={errorMessage} />
          {loading && <ReviewsSkeleton />}
          {reviewsState.reviews.map((review, index) => (
            <ReviewDesignRow key={index} review={review} />
          ))}
        </div>
        <TablePagination
          className={classes.footerStyles}
          component="div"
          count={-1}
          labelDisplayedRows={({ from, to }) => `${from}-${to}`}
          labelRowsPerPage="Elements to display per page:"
          nextIconButtonProps={{ disabled: !hasNext }}
          page={page}
          rowsPerPage={REVIEWS_LIMIT}
          rowsPerPageOptions={[REVIEWS_LIMIT]}
          onChangePage={handleChangePage}
        />
      </Paper>
    )
  ) : (
    <Paper className={classes.container} elevation={0}>
      <div className={classes.fullHeight}>
        <ErrorMessage message={errorMessage} />
        {loading && <ReviewsSkeleton />}
        {reviewsState.reviews.map((review, index) => (
          <ReviewRow key={index} review={review} />
        ))}
      </div>
      <TablePagination
        className={classes.footerStyles}
        component="div"
        count={-1}
        labelDisplayedRows={({ from, to }) => `${from}-${to}`}
        labelRowsPerPage="Elements to display per page:"
        nextIconButtonProps={{ disabled: !hasNext }}
        page={page}
        rowsPerPage={REVIEWS_LIMIT}
        rowsPerPageOptions={[REVIEWS_LIMIT]}
        onChangePage={handleChangePage}
      />
    </Paper>
  )
}

ReviewsTable.propTypes = {
  designId: PropTypes.string,
  makerId: PropTypes.string,
}
ReviewsTable.defaultProps = {
  designId: '',
  makerId: '',
}
export default ReviewsTable
