import {
  UPDATE_REFRESH_TRUE,
  UPDATE_REFRESH_FALSE,
} from 'constants/algoliaConstants'
import { store } from '../store'
const { dispatch } = store

export const updateToRefreshTrue = () => {
  return dispatch({
    type: UPDATE_REFRESH_TRUE,
  })
}

export const updateToRefreshFalse = () => {
  return dispatch({
    type: UPDATE_REFRESH_FALSE,
  })
}
