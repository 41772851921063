import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Grid,
  Hidden,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(theme => ({
  center: {
    margin: 'auto',
    maxWidth: '50%',
  },
  label: {
    cursor: 'default',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    paddingLeft: '12px',
    textSizeAdjust: '100%',
    top: '0px',
  },
  priceRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    '& .MuiDropzoneArea-root': {
      minHeight: 'auto',
    },
    '& .MuiDropzoneArea-text': {
      margin: '8px 0',
      fontSize: '0.875rem',
    },

    '& .MuiDropzoneArea-icon': {
      height: '20px',
    },
  },
  row: {
    marginTop: '12px',
    padding: 0,
    justifyContent: 'center',
  },
  storeName: {
    width: '70%',
  },
  storeNameContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  styledField: {
    color: '#000',
    width: '100%',
  },
}))

const StoreForm = ({
  currentStoreName,
  hasStoreName,
  materials,
  prices,
  storeItemsSwitch,
  visibleColumns,
  setPrices,
  setStoreItemsSwitch,
  updateStoreName,
}) => {
  const classes = useStyles()
  const [storeName, setStoreName] = useState(currentStoreName)

  const handleChangePrice = prop => event => {
    const {
      target: { value },
    } = event
    const newValue = Number.parseFloat(value)
    const regex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/
    if ((newValue > 0 && regex.test(newValue)) || value === '') {
      setPrices({ ...prices, [prop]: value === '' ? '' : newValue })
      if (storeItemsSwitch && storeItemsSwitch[prop]) {
        setStoreItemsSwitch({
          ...storeItemsSwitch,
          [prop]: {
            isDeleted: storeItemsSwitch[prop].isDeleted,
            stored: storeItemsSwitch[prop].stored,
            updated: true,
          },
        })
      }
    }
  }

  const handleStoreNameChange = event => {
    const {
      target: { value },
    } = event
    setStoreName(value)
  }

  const handleOnBlur = event => {
    const {
      target: { value },
    } = event
    const trimmedValue = value.trim()
    setStoreName(trimmedValue)
    updateStoreName(trimmedValue)
  }

  const handleChangeSwitchValue = material => {
    if (prices[material] > 0) {
      const updatedSwitch = {
        ...storeItemsSwitch,
        [material]: {
          isDeleted: !storeItemsSwitch[material].isDeleted,
          stored: storeItemsSwitch[material].stored,
          updated: true,
        },
      }
      setStoreItemsSwitch(updatedSwitch)
    }
  }

  const showDeleteLabel = column =>
    !isEmpty(storeItemsSwitch) && visibleColumns[column]

  const isStoredItem = material =>
    storeItemsSwitch &&
    storeItemsSwitch[material.name] &&
    storeItemsSwitch[material.name].stored

  return (
    <Container component="main" className={classes.root}>
      <form className="design-form">
        <Grid container className={classes.row} spacing={1}>
          <Grid className={classes.storeNameContainer} item xs={12}>
            {!hasStoreName ? (
              <TextField
                className={classes.storeName}
                id="storeName"
                label="Store Name"
                placeholder="Store Name"
                required
                size="small"
                type="text"
                value={storeName}
                variant="outlined"
                onBlur={handleOnBlur}
                onChange={handleStoreNameChange}
              />
            ) : (
              <Typography variant="h5">{storeName}</Typography>
            )}
          </Grid>
          <Hidden xsDown>
            <Grid item sm={2} xs={4} />
            <Grid
              className={classes.priceRow}
              container
              item
              justify="center"
              sm={2}
              xs={4}
            >
              Price($)
            </Grid>
            <Grid
              className={classes.priceRow}
              container
              item
              justify="center"
              sm={2}
              xs={4}
            >
              {showDeleteLabel('first') && 'Delete'}
            </Grid>
          </Hidden>
          <Grid item sm={2} xs={4} />
          <Grid
            className={classes.priceRow}
            container
            justify="center"
            item
            sm={2}
            xs={4}
          >
            Price($)
          </Grid>
          <Grid
            className={classes.priceRow}
            container
            justify="center"
            item
            sm={2}
            xs={4}
          >
            {showDeleteLabel('second') && 'Delete'}
          </Grid>
          {materials.map((item, index) => (
            <Grid container key={index} item sm={6} xs={12}>
              <Grid className={classes.priceRow} item xs={2} />
              <Grid className={classes.priceRow} item xs={2}>
                <Typography className={classes.label} display="inline">
                  {item.name}
                </Typography>
              </Grid>
              <Grid className={classes.priceRow} item xs={8}>
                <Grid item xs={6}>
                  <TextField
                    className={classes.styledField}
                    label={item.name}
                    placeholder="Add price"
                    size="small"
                    type="number"
                    value={prices[item.name]}
                    variant="outlined"
                    onChange={handleChangePrice(item.name)}
                    InputProps={{
                      inputProps: { min: '0' },
                    }}
                  />
                </Grid>
                <Grid className={classes.center} item xs={4}>
                  {isStoredItem(item) && (
                    <div className={classes.center}>
                      <Switch
                        checked={storeItemsSwitch[item.name].isDeleted}
                        onChange={() => handleChangeSwitchValue(item.name)}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </form>
    </Container>
  )
}

StoreForm.propTypes = {
  currentStoreName: PropTypes.string,
  materials: PropTypes.instanceOf(Array),
  prices: PropTypes.instanceOf(Object),
  storeItemsSwitch: PropTypes.object,
  visibleColumns: PropTypes.object,
  setPrices: PropTypes.func,
  setStoreItemsSwitch: PropTypes.func,
  updateStoreName: PropTypes.func,
}

StoreForm.defaultProps = {
  currentStoreName: '',
  materials: [],
  prices: {},
  storeItemsSwitch: {},
  visibleColumns: {},
  setPrices: () => {},
  setStoreItemsSwitch: () => {},
  updateStoreName: () => {},
}

export default StoreForm
