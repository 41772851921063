import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ShippingInfoEditor } from 'components'

const defaultShippingInfo = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  city: '',
  companyName: '',
  companyAddress: '',
  country: 'United States',
  state: '',
  zipCode: '',
}

const useStyles = makeStyles(theme => ({
  button: {
    fontWeight: 'bold',
    color: theme.palette.text.link,
    '&:hover': {
      backgroundColor: theme.palette.background.main,
      color: theme.palette.text.linkHover,
    },
    fontSize: '1rem',
    paddingTop: '4px',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  container: {
    backgroundColor: theme.palette.background.main,
    fontSize: '1rem',
    padding: '24px 16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  tittle: {
    fontWeight: 'bold',
  },
}))

const ShippingInfo = ({ loggedInUser, updateShippingInfo }) => {
  const classes = useStyles()
  const [showEditor, setShowEditor] = useState(false)
  const [shippingInfo, setShippingInfo] = useState(defaultShippingInfo)

  useEffect(() => {
    if (loggedInUser && shippingInfo.firstName === '') {
      const {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        shipping_info: userShippingInfo = {},
      } = loggedInUser
      const {
        city = '',
        company_name: companyName = '',
        company_address: companyAddress = '',
        country = 'United States',
        state = '',
        zip_code: zipCode = '',
      } = userShippingInfo
      setShippingInfo({
        city,
        companyName,
        companyAddress,
        country,
        firstName,
        lastName,
        phoneNumber,
        state,
        zipCode,
      })
    }
  }, [loggedInUser, shippingInfo])

  const displayEditor = () => {
    setShowEditor(true)
  }

  const closeEditor = () => {
    setShowEditor(false)
  }

  const saveShippingInfo = newShippingInfo => {
    setShippingInfo(newShippingInfo)
    updateShippingInfo(newShippingInfo)
    closeEditor()
  }

  return !showEditor ? (
    <Grid container className={classes.container}>
      <span className={classes.tittle}>Ship to</span>
      <Grid container>
        <Grid item xs={5}>
          {`${shippingInfo.firstName} ${shippingInfo.lastName}`}
        </Grid>
        <Grid item xs={7}>
          {shippingInfo.companyName}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {shippingInfo.companyAddress}
      </Grid>
      <Grid item xs={12}>
        {`${shippingInfo.city} ${shippingInfo.state} ${shippingInfo.zipCode}`}
      </Grid>
      <Grid item xs={12}>
        {shippingInfo.country}
      </Grid>
      <Grid item xs={12}>
        {shippingInfo.phoneNumber}
      </Grid>
      <Grid item xs={12}>
        <Link className={classes.button} href="#" onClick={displayEditor}>
          Edit
        </Link>
      </Grid>
    </Grid>
  ) : (
    <ShippingInfoEditor
      cancel={closeEditor}
      confirm={saveShippingInfo}
      shippingInitialState={shippingInfo}
    />
  )
}

ShippingInfo.propTypes = {
  loggedInUser: PropTypes.object,
  updateShippingInfo: PropTypes.func.isRequired,
}

ShippingInfo.defaultProps = {
  loggedInUser: {},
}

export default ShippingInfo
