import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { INCHES_UNIT, MILLIMETER_UNIT } from 'constants/modelConstants'

const useStyles = makeStyles(theme => ({
  smallBtn: {
    textTransform: 'none',
    width: '20px',
    height: '20px',
  },
  mediumBtn: {
    textTransform: 'none',
    width: '35px',
    height: '35px',
  },
  notSelectedBtn: {
    color: 'unset',
  },
  padding: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  selectedBtn: {
    color: 'white',
  },
}))

const TYPE_BUTTON = { small: 'small', medium: 'medium' }
const MeasurementChange = ({ unit, groupSize, onHandleChangeMeasurements }) => {
  const classes = useStyles()

  return (
    <ButtonGroup color="primary" disableElevation variant="contained">
      <Button
        className={clsx(
          groupSize === TYPE_BUTTON.small
            ? classes.smallBtn
            : classes.mediumBtn,
          unit === MILLIMETER_UNIT
            ? classes.selectedBtn
            : classes.notSelectedBtn,
          classes.padding
        )}
        color={unit === MILLIMETER_UNIT ? 'primary' : 'default'}
        onClick={onHandleChangeMeasurements}
      >
        <Typography>{MILLIMETER_UNIT}</Typography>
      </Button>
      <Button
        className={clsx(
          groupSize === TYPE_BUTTON.small
            ? classes.smallBtn
            : classes.mediumBtn,
          unit === INCHES_UNIT ? classes.selectedBtn : classes.notSelectedBtn,
          classes.padding
        )}
        color={unit === INCHES_UNIT ? 'primary' : 'default'}
        onClick={onHandleChangeMeasurements}
      >
        <Typography>{INCHES_UNIT}</Typography>
      </Button>
    </ButtonGroup>
  )
}

MeasurementChange.propTypes = {
  unit: PropTypes.string.isRequired,
  groupSize: PropTypes.string,
  onHandleChangeMeasurements: PropTypes.func.isRequired,
}

MeasurementChange.defaultProps = {
  groupSize: 'small',
}

export default MeasurementChange
