import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { find } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { CollapseRow, SkeletonListing } from 'components'
import { DEFAULT_LISTING_LIMITS } from 'constants/listingConstants'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '466px',
    height: '77vh',
  },
  customBackGround: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.white,
  },
  fullHeight: {
    height: '100%',
  },
  root: {
    width: '100%',
  },
  title: {
    marginLeft: '12px',
    fontWeight: 'bold',
  },
  boldText: {
    '& .MuiToolbar-root .MuiTypography-root, .MuiInputBase-root .MuiSelect-root': {
      fontWeight: 'bold',
    },
  },
}))
const MINIMUM_ROWS = 10
const ListingTable = ({
  acknowledgeReceipt,
  collapsedListing,
  currentPage,
  currentUser,
  displayMakeOfferButton,
  displayModelImage,
  fetchListings,
  hasNext,
  initRowsPerPage,
  isFetching,
  listings,
  offers,
  loadNewOffers,
  nextPage,
  prevPage,
  refreshCurrentListingsPage,
  showDetails,
  showOffersModal,
}) => {
  const classes = useStyles()
  const [clickDeleteButton, setClickDeleteButton] = useState(false)
  const [page, setPage] = useState(currentPage)
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage)
  const [visibleRows, setVisibleRows] = useState(MINIMUM_ROWS)
  const defaultRows = listings.slice(0, MINIMUM_ROWS)
  const [necessaryListings, setNecessaryListings] = useState(defaultRows)
  const [currentCollapsedListing, setCurrentCollapsedListing] = useState(
    collapsedListing
  )

  useEffect(() => {
    setPage(currentPage)
  }, [currentPage])

  const handleScroll = async event => {
    const { scrollHeight, scrollTop, clientHeight } = event.target
    const scrollPosition = scrollHeight - scrollTop - clientHeight
    if (scrollPosition <= 0 && visibleRows < rowsPerPage) {
      setNecessaryListings(listings.slice(0, visibleRows + MINIMUM_ROWS))
      setVisibleRows(visibleRows + MINIMUM_ROWS)
    }
  }

  const handleChangePage = async (event, newPage) => {
    let response = true
    if (newPage > page) {
      response = await nextPage(newPage, rowsPerPage)
    } else {
      await prevPage(newPage, rowsPerPage)
    }
    if (response) {
      setPage(newPage)
    }
  }

  const handleChangeRowsPerPage = async event => {
    const {
      target: { value: newLimit },
    } = event
    setRowsPerPage(newLimit)
    setPage(0)
    await fetchListings(newLimit)
  }

  const updateCollapsedListing = listingId => {
    if (!clickDeleteButton) {
      const newListingId =
        listingId === currentCollapsedListing ? '' : listingId
      setCurrentCollapsedListing(newListingId)
    }
    setClickDeleteButton(false)
  }

  const offerStatus = listingId => {
    const offer = find(offers, ['listingId', listingId])
    return offer ? true : false
  }

  return (
    <Paper className={classes.fullHeight}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Models
      </Typography>
      <TableContainer className={classes.container} onScroll={handleScroll}>
        <Table stickyHeader aria-label="listing table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.customBackGround}>
                Submision Date
              </TableCell>
              <TableCell align="left" className={classes.customBackGround}>
                User
              </TableCell>
              <TableCell align="left" className={classes.customBackGround}>
                Model Name
              </TableCell>
              <TableCell align="left" className={classes.customBackGround}>
                Quantity
              </TableCell>
              <TableCell align="left" className={classes.customBackGround}>
                Notes
              </TableCell>
              <TableCell className={classes.customBackGround} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!isFetching ? (
              necessaryListings.map((listing, index) => (
                <CollapseRow
                  acknowledgeReceipt={acknowledgeReceipt}
                  collapsedListing={currentCollapsedListing}
                  currentUser={currentUser}
                  displayMakeOfferButton={displayMakeOfferButton}
                  displayModelImage={displayModelImage}
                  index={index}
                  key={index}
                  listing={listing}
                  offerStatus={offerStatus(listing.id)}
                  handleDeleteButton={setClickDeleteButton}
                  loadNewOffers={loadNewOffers}
                  refreshCurrentListingsPage={refreshCurrentListingsPage}
                  showDetails={showDetails}
                  showOffersModal={showOffersModal}
                  updateCollapsedListing={updateCollapsedListing}
                />
              ))
            ) : (
              <SkeletonListing />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.boldText}
        component="div"
        count={-1}
        nextIconButtonProps={{ disabled: !hasNext }}
        labelDisplayedRows={({ from, to }) => `${from}-${to}`}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={DEFAULT_LISTING_LIMITS}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

ListingTable.propTypes = {
  acknowledgeReceipt: PropTypes.func,
  collapsedListing: PropTypes.string,
  currentPage: PropTypes.number,
  currentUser: PropTypes.object,
  displayMakeOfferButton: PropTypes.bool,
  displayModelImage: PropTypes.bool,
  hasNext: PropTypes.bool.isRequired,
  initRowsPerPage: PropTypes.number,
  isFetching: PropTypes.bool.isRequired,
  listings: PropTypes.array.isRequired,
  offers: PropTypes.array,
  loadNewOffers: PropTypes.func,
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
  showDetails: PropTypes.bool,
  showOffersModal: PropTypes.bool,
}

ListingTable.defaultProps = {
  acknowledgeReceipt: () => {},
  collapsedListing: '',
  currentPage: 0,
  currentUser: {},
  displayMakeOfferButton: false,
  displayModelImage: false,
  initRowsPerPage: DEFAULT_LISTING_LIMITS[0],
  loadNewOffers: () => {},
  offers: [],
  showDetails: false,
  showOffersModal: false,
}

export default ListingTable
