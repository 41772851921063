import React, { useState } from 'react'
import { Container, Grid, TextField } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { CommonButton, ErrorMessage, formStyle } from 'components'

import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import {
  defaultValidations,
  validatePassword,
  validateRepeatPasswords,
} from 'constants/utils'
import { updatePassword } from 'services/UserService'

const UpdatePasswordForm = () => {
  const classes = formStyle()

  const initialState = {
    currentPassword: '',
    password: '',
    repeatedPassword: '',
  }

  const [errorMessage, setErrorMessage] = useState('')
  const [fields, setFields] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [passwordValidation, setPasswordValidation] = useState(
    defaultValidations
  )
  const [repeatedPasswordValidation, setRepeatedPasswordValidation] = useState(
    defaultValidations
  )

  const checkPassword = value => {
    const validatedPassword = validatePassword(value)
    setPasswordValidation(validatedPassword)
  }

  const checkRepeatedPassword = (password, value) => {
    const validatedPasswords = validateRepeatPasswords(password, value)
    setRepeatedPasswordValidation(validatedPasswords)
  }

  const handleChange = prop => event => {
    const { value } = event.target
    checkPassword(value)
    checkRepeatedPassword(fields.password, fields.repeatedPassword)
  }

  const handleUpdatePassword = async e => {
    e.preventDefault()
    setErrorMessage('')
    setLoading(true)
    try {
      if (
        fields.currentPassword &&
        !passwordValidation.error &&
        !repeatedPasswordValidation.error
      ) {
        await updatePassword(fields.currentPassword, fields.password)
      }
    } catch (error) {
      setErrorMessage(
        'The current password is invalid or this account does not have a password, try again please'
      )
    }
    setLoading(false)
    setFields(initialState)
  }

  const updateField = prop => event => {
    setFields({ ...fields, [prop]: event.target.value })
  }

  return (
    <Container component="main" maxWidth="xs">
      {
        <form className="signup-form" onSubmit={handleUpdatePassword}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ErrorMessage message={errorMessage} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.styledField}
                disabled={loading}
                fullWidth
                label="Current Password"
                required
                size="small"
                type="password"
                value={fields.currentPassword}
                variant="outlined"
                onBlur={handleChange('currentPassword')}
                onChange={updateField('currentPassword')}
                InputProps={{
                  endAdornment: <LockOutlinedIcon className="field-icons" />,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.styledField}
                error={passwordValidation.error}
                disabled={loading}
                fullWidth
                helperText={passwordValidation.helperText}
                label="New Password"
                required
                size="small"
                type="password"
                value={fields.password}
                variant="outlined"
                onBlur={handleChange('password')}
                onChange={updateField('password')}
                InputProps={{
                  endAdornment: <LockOutlinedIcon className="field-icons" />,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.styledField}
                error={repeatedPasswordValidation.error}
                disabled={loading}
                fullWidth
                helperText={repeatedPasswordValidation.helperText}
                label="Repeat Password"
                required
                size="small"
                type="password"
                value={fields.repeatedPassword}
                variant="outlined"
                onBlur={handleChange('repeatedPassword')}
                onChange={updateField('repeatedPassword')}
                InputProps={{
                  endAdornment: <LockOutlinedIcon className="field-icons" />,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CommonButton
                buttonStyle={BUTTON_STYLE.PRIMARY}
                disabled={loading}
                fullWidth={true}
                label={'Update password'}
                size={BUTTON_SIZE.MEDIUM}
                type={BUTTON_TYPE.SUBMIT}
                variant={BUTTON_VARIANT.OUTLINED}
              />
            </Grid>
          </Grid>
        </form>
      }
    </Container>
  )
}

export default UpdatePasswordForm
