// material ui button size large: 42 px, medium: 36px, small: 30px
export const BUTTON_SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
}

// accent is purple button
// cancel is outlined gray
// primary is green button
// huge buttons have height: 60px
export const BUTTON_STYLE = {
  ACCENT: 'accent',
  CANCEL: 'cancel',
  LINK: 'link',
  PRIMARY: 'primary',
  HUGE_ACCENT: 'hugeAccent',
  HUGE_PRIMARY: 'hugePrimary',
}

export const BUTTON_TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit',
}

export const BUTTON_VARIANT = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
}
