import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { round } from 'lodash'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { OFFER_STATUS } from 'constants/offerConstants'
import { makeStyles } from '@material-ui/core/styles'
import { CommonButton, OfferForm } from 'components'

import { ACCEPT_ORDER } from 'constants/alertConstants'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import {
  ERROR_FETCHING_SHIP_INFO,
  LISTING_STATUS,
} from 'constants/listingConstants'
import { PLATFORM_FEE } from 'constants/platformFeeConstant'

import { createAlert } from 'services/AlertService'
import { updateListingStatus } from 'services/ListingService'
import { getListingShippingInfoByListingId } from 'services/ListingShippingInfoService'
import { updateOffer } from 'services/OfferService'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      minHeight: '420px',
    },
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
}))

const AcceptOrderModal = ({
  estimatedMaterial,
  estimatedPrintTime,
  estimatedMaterialPerFile,
  initialOffer,
  listingId,
  listingOwner,
  modelName,
  offerId,
  zipCode,
  handleFetchOffers,
}) => {
  const { user: loggedUser } = useSelector(state => state.userState)
  const classes = useStyles()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [shippingInfo, setShippingInfo] = useState()
  const [offer, setOffer] = useState({
    designFee: 0,
    newOffer: initialOffer,
    deliveryDate: '',
    notes: '',
    platformFee: 0,
    productionDays: 0,
    shippingFee: 0,
    status: '',
  })

  useEffect(() => {
    const getListingShippingInfo = async listingId => {
      try {
        const shippingInfo = await getListingShippingInfoByListingId(listingId)
        setShippingInfo(shippingInfo)
      } catch (error) {
        setError(ERROR_FETCHING_SHIP_INFO)
      }
    }
    if (!shippingInfo && listingId) {
      getListingShippingInfo(listingId)
    }
  }, [shippingInfo, listingId])

  const handleClose = () => {
    setOpen(false)
    setOffer({
      ...offer,
      newOffer: initialOffer,
      notes: '',
      productionDays: 0,
    })
  }

  const handleOpen = async event => {
    event.stopPropagation()
    setOpen(true)
  }

  const stop = event => {
    event.stopPropagation()
  }

  const acceptNewOrder = async () => {
    setError('')
    setLoading(true)
    try {
      const newOffer = offer
      const platformFee = round(initialOffer * (PLATFORM_FEE / 100), 2)
      newOffer.totalFee = round(
        offer.newOffer + offer.designFee + offer.shippingFee + platformFee,
        2
      )
      await updateOffer(offerId, newOffer, OFFER_STATUS.PENDING)
      await updateListingStatus(listingId, LISTING_STATUS.OFFER_COMPLETED)
      const createdBy = {
        first_name: loggedUser.first_name,
        last_name: loggedUser.last_name,
        uid: loggedUser.uid,
      }
      const createdFor = {
        first_name: listingOwner.first_name,
        last_name: listingOwner.last_name,
        uid: listingOwner.uid,
      }
      const listing = {
        modelName,
        uid: listingId,
      }
      await createAlert(createdBy, createdFor, ACCEPT_ORDER, listing)
      await handleFetchOffers()
    } catch (error) {
      setError(error.message)
    }
    setLoading(false)
    handleClose()
  }

  const validateFields = () =>
    offer.newOffer !== '' &&
    offer.productionDays > 0 &&
    offer.shippingFee !== ''

  return (
    <>
      <CommonButton
        buttonStyle={BUTTON_STYLE.PRIMARY}
        fullWidth={false}
        label="Accept"
        variant={BUTTON_VARIANT.OUTLINED}
        onClick={handleOpen}
      />
      <Dialog
        className={classes.dialog}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        onClick={stop}
      >
        <DialogTitle className={classes.boldTitle}>Accept an Order</DialogTitle>
        <DialogContent>
          {error && <div>{error}</div>}
          {loading && <CircularProgress className={classes.progress} />}
          <OfferForm
            estimatedMaterialPerFile={estimatedMaterialPerFile}
            listingOwner={listingOwner}
            isOrder={true}
            offer={offer}
            setOffer={setOffer}
          />
        </DialogContent>
        <DialogActions>
          <CommonButton
            buttonStyle={BUTTON_STYLE.CANCEL}
            fullWidth={false}
            label="Cancel"
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={handleClose}
          />
          <CommonButton
            buttonStyle={BUTTON_STYLE.PRIMARY}
            disabled={!validateFields() || loading}
            fullWidth={false}
            label="Submit"
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={acceptNewOrder}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

AcceptOrderModal.propTypes = {
  estimatedMaterial: PropTypes.object,
  estimatedMaterialPerFile: PropTypes.array,
  estimatedPrintTime: PropTypes.object,
  initialOffer: PropTypes.number.isRequired,
  listingId: PropTypes.string.isRequired,
  listingOwner: PropTypes.object.isRequired,
  modelName: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  handleFetchOffers: PropTypes.func.isRequired,
}

AcceptOrderModal.defaultProps = {
  estimatedMaterial: {},
  estimatedMaterialPerFile: [],
  estimatedPrintTime: {},
}

export default AcceptOrderModal
