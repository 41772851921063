import { makeStyles } from '@material-ui/core/styles'

const formStyle = makeStyles(theme => ({
  root: {
    minHeight: '410px',
  },
  appbar: {
    boxShadow: 'none',
  },
  avatarIcon: {
    color: theme.palette.text.white,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  checkboxContainer: {
    fontSize: theme.custom.form.checkboxFontSize,
    '& span': {
      padding: '12px 0',
    },
  },
  progress: {
    position: 'absolute',
    left: 'calc(50% - 15px)',
    top: '50%',
  },
  progressRecovery: {
    position: 'absolute',
    left: 'calc(50% - 15px)',
    top: '30%',
    zIndex: 3,
  },
  continueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    fontWeight: 'bold',
    textTransform: 'none',
    marginTop: '16px',
  },
  continueBtnContainer: {
    '& .MuiButton-root:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.text.white,
    },
  },
  createAccountBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  createAccountBtnContainer: {
    '& .MuiButton-root:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.text.white,
    },
  },
  errorClass: {
    color: theme.palette.error.main,
  },
  formContainer: {
    flexShrink: 0,
  },
  formContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 129px)',
  },
  medium: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  radioButton: {
    color: theme.palette.text.primary,
  },
  right: {
    float: 'right',
    fontSize: theme.custom.form.checkboxFontSize,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  signUpFormContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 129px)',
    '@media (max-height:640px)': {
      height: '100% !important',
    },
  },
  styledField: {
    color: theme.custom.form.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    '& label': {
      color: theme.custom.form.text.secondary,
    },
  },
  tab: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.white,
    '& .MuiTab-textColorInherit.Mui-selected': {
      backgroundColor: theme.palette.tertiary.main,
      padding: '0px 12px',
    },
    '& .MuiTab-wrapper': {
      fontSize: '1rem',
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  fullFormContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-height:900px)': {
      height: '100% !important',
    },
  },
}))

export { formStyle }
