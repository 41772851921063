import React, { useState } from 'react'
import clsx from 'clsx'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CreateListing, OfferTooltip } from 'components'
import {
  MODEL_DETAILS_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from 'constants/apiUrls'

const useStyles = makeStyles(theme => ({
  card: {
    height: '256px',
    width: '100%',
    maxWidth: '335px',
    minWidth: '288px',
  },
  cardActions: {
    padding: '0',
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'inherit !important',
    },
  },
  cardFooter: {
    display: 'grid',
  },
  cardFooterContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.background.default,
  },
  hoverDescription: {
    background: 'rgba(63, 48, 44, 0.1)',
    fontWeight: 'bold',
    height: '100%',
    lineHeight: '1.7',
    padding: '24px',
    position: 'absolute',
    textAlign: 'left',
    width: '100%',
  },
  hoverImage: {
    height: '192px',
    opacity: '0.2',
    pointerEvents: 'none',
    position: 'relative',
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    padding: 0,
    textTransform: 'none',
  },
  media: {
    height: '192px',
    pointerEvents: 'none',
    objectFit: 'contain',
  },
  price: {
    fontWeight: 'bold',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const GalleryCard = ({
  addPrice,
  model,
  showModal,
  showRegisterRedirect,
  onClickShowRegisterRedirect,
}) => {
  const classes = useStyles()
  const [hover, setHover] = useState(false)
  const [open, setOpen] = useState(false)

  const { description, formatted_name, images, name, ownedBy } = model
  let thumbnail = images[0]
  if (model.thumbnail) {
    thumbnail = model.thumbnail
  }
  const {
    first_name: firstName,
    last_name: lastName,
    photo_url: photoUrl,
    uid: userId,
  } = ownedBy

  const redirectToModel = () => {
    hover &&
      !addPrice &&
      navigate(MODEL_DETAILS_ENDPOINT(formatted_name), { state: { model } })

    hover && addPrice && showModal && setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleOnClick = () => {
    if (showRegisterRedirect) {
      onClickShowRegisterRedirect()
    } else {
      redirectToModel()
    }
  }

  return (
    <Card className={classes.card}>
      <CardActionArea
        onClick={handleOnClick}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {hover ? (
          <CardContent className={clsx(classes.description, classes.media)}>
            <img className={classes.hoverImage} src={thumbnail} alt="" />
            <span className={classes.hoverDescription}>
              {description.slice(0, 150)}
              {description.length > 150 && <span>...</span>}
            </span>
          </CardContent>
        ) : (
          <CardMedia
            className={classes.media}
            component="img"
            image={thumbnail}
            title={name}
          />
        )}
      </CardActionArea>
      <CardActions>
        <Box onClick={() => navigate(USER_PROFILE_ENDPOINT(userId))}>
          <IconButton>
            <Avatar src={photoUrl} />
          </IconButton>
        </Box>
        <div className={classes.cardFooterContainer}>
          <div className={classes.cardFooter}>
            <div
              className={clsx(classes.label, classes.title)}
              size="small"
              underline="none"
            >
              {name}
            </div>
            <Link
              className={classes.label}
              href="#"
              onClick={() => navigate(USER_PROFILE_ENDPOINT(userId))}
              size="small"
              underline="none"
            >
              {firstName} {lastName}
            </Link>
            {addPrice && (
              <div
                className={clsx(classes.label, classes.title)}
                size="small"
                underline="none"
              >
                Material: {model.material}
              </div>
            )}
          </div>
          {addPrice && (
            <>
              <div className={classes.price}>
                ${model.total_fee.toFixed(2)}
                <OfferTooltip
                  designFee={`${model.design_fee}`}
                  showShippingFee={false}
                  makerOffer={`${model.offer}`}
                  platformFee={`${model.platform_fee}`}
                  shippingFee={0}
                  total={`${model.total_fee}`}
                />
              </div>
              <CreateListing
                model={model}
                open={open}
                storeListing={true}
                handleClose={handleCancel}
              />
            </>
          )}
        </div>
      </CardActions>
    </Card>
  )
}

GalleryCard.propTypes = {
  model: PropTypes.object.isRequired,
  showRegisterRedirect: PropTypes.bool,
  onClickShowRegisterRedirect: PropTypes.func,
}

GalleryCard.defaultProps = {
  showRegisterRedirect: false,
  onClickShowRegisterRedirect: () => {},
}

export default GalleryCard
