import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Container, Tab, Tabs } from '@material-ui/core'
import { TabPanel, formStyle } from 'components'

const FormContainer = ({ forms, tabOption, updateOption }) => {
  const classes = formStyle()
  const [value, setValue] = useState(0)

  useEffect(() => {
    if (tabOption) {
      setValue(tabOption === 'login' ? 1 : 0)
    }
  }, [tabOption])

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
    if (tabOption !== '') {
      updateOption(newValue)
    }
  }

  return (
    <Container className={classes.root} component="main" maxWidth="sm">
      <AppBar className={classes.appbar} position="static">
        <Tabs
          className={classes.tab}
          centered
          onChange={handleTabChange}
          value={value}
        >
          {forms.map((item, index) => (
            <Tab key={index} label={item.label} />
          ))}
        </Tabs>
      </AppBar>
      {forms.map((item, index) => (
        <TabPanel index={index} key={index} value={value}>
          {item.component}
        </TabPanel>
      ))}
    </Container>
  )
}

FormContainer.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.object),
  tabOption: PropTypes.object,
  updateOption: PropTypes.func,
}

FormContainer.defaultProps = {
  forms: [],
  tabOption: null,
  updateOption: () => {},
}

export default FormContainer
