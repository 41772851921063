import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { CircularProgress, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CommonButton } from 'components'

import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import { LISTING_ACTIONS } from 'constants/listingConstants'

import { updateShippingTracking } from 'services/ListingService'
import { printLabel } from 'services/ShipEngineService'
import { addTransfer } from 'services/TransferService'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
  },
  circularContainer: {
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      zIndex: '3',
    },
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      minHeight: '300px',
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  label: {
    cursor: 'default',
    display: 'block',
    fontFamily: 'inherit',
    fontSize: '0.875rem',
    lineHeight: '0.875rem',
    padding: '0px',
    textSizeAdjust: '100%',
    top: '0px',
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
  spacing: {
    display: 'inline-flex',
    '& div a, button': {
      marginRight: '4px',
    },
  },
  withoutUnderline: {
    '&  .MuiLink-underlineHover': {
      textDecoration: 'none',
    },
  },
}))

const ShipmentTrackingModal = ({
  listingId,
  makerFee,
  offerId,
  shippingTracking,
  userId,
  updateStatus,
}) => {
  const [href, setHref] = useState('')
  const [printing, setPrinting] = useState(false)
  const classes = useStyles()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (shippingTracking && shippingTracking.shipping_label_link) {
      setHref(shippingTracking.shipping_label_link)
    }
  }, [shippingTracking])

  const submitShipmentInformation = async () => {
    setError('')
    try {
      setLoading(true)
      await updateStatus(LISTING_ACTIONS.SEND_SHIPMENT, true)
      await addTransfer(listingId, offerId, makerFee, userId)
    } catch (error) {
      setError(
        'Something went wrong while trying to submit the shipping information, please try again later.'
      )
    }
    setLoading(false)
  }
  const printShippingLabel = async () => {
    try {
      setPrinting(true)
      if (shippingTracking && shippingTracking.shipping_label_link) {
        setHref(shippingTracking.shipping_label_link)
      } else {
        const {
          carrier_code,
          carrier_id,
          label_download: { href },
          label_id,
          packages,
          service_code,
          status,
          shipment_id,
          tracking_number,
          tracking_status,
        } = await printLabel(listingId, userId)
        setHref(href)
        const newShipment = {
          carrierCode: carrier_code,
          carrierId: carrier_id,
          labelId: label_id,
          packageId: packages[0].package_id,
          shipmentId: shipment_id,
          shipper: service_code,
          shippingLabelLink: href,
          status,
          trackingId: tracking_number,
          trackingStatus: tracking_status,
        }
        await updateShippingTracking(listingId, newShipment)
      }
      setPrinting(false)
    } catch (error) {
      setError(
        'Something went wrong while trying to print the Label, please try again later.'
      )
    }
  }

  const downloadLinkToLabel = href || shippingTracking.shipping_label_link

  return (
    <>
      {error && <div className={classes.errorMessage}>{error}</div>}
      {!downloadLinkToLabel ? (
        <div className={classes.circularContainer}>
          {printing && <CircularProgress />}
          <CommonButton
            buttonStyle={BUTTON_STYLE.PRIMARY}
            disabled={printing}
            fullWidth={false}
            label="Print Shipping label"
            variant={BUTTON_VARIANT.OUTLINED}
            onClick={printShippingLabel}
          />
        </div>
      ) : (
        <div className={clsx(classes.spacing, classes.withoutUnderline)}>
          <Link href={href} target="_blank" rel="noopener">
            <CommonButton
              buttonStyle={BUTTON_STYLE.HUGE_ACCENT}
              disabled={loading}
              fullWidth={false}
              label="Download Label"
              variant={BUTTON_VARIANT.OUTLINED}
            />
          </Link>
          <span className={classes.circularContainer}>
            {loading && <CircularProgress />}
            <CommonButton
              buttonStyle={BUTTON_STYLE.PRIMARY}
              disabled={loading}
              fullWidth={false}
              label="Ship Item"
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={submitShipmentInformation}
            />
          </span>
        </div>
      )}
    </>
  )
}

ShipmentTrackingModal.propTypes = {
  listingId: PropTypes.string.isRequired,
  offerId: PropTypes.string.isRequired,
  shippingTracking: PropTypes.object,
  updateStatus: PropTypes.func,
}

ShipmentTrackingModal.defaultProps = {
  shippingTracking: {},
  updateStatus: () => {},
}

export default ShipmentTrackingModal
