import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'

const buttonStyles = makeStyles(theme => ({
  accentButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.white,
    fontWeight: 'bold',
    height: '100%',
    textAlign: 'center',
    textTransform: 'capitalize',
    opacity: 0.8,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      opacity: 1,
    },
  },
  cancelButton: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    color: theme.palette.text.gray,
    '&:hover': {
      color: theme.palette.text.white,
      backgroundColor: theme.palette.background.default,
      opacity: 1,
    },
    '&:disabled': {
      opacity: 0.3,
      color: theme.palette.text.white,
    },
  },
  hugeButton: {
    height: '100px',
  },
  primaryButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    fontWeight: 'bold',
    height: '100%',
    textAlign: 'center',
    textTransform: 'capitalize',
    opacity: 0.8,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
    '&:disabled': {
      opacity: 0.3,
      color: theme.palette.text.white,
    },
  },
  linkButton: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
}))

const CommonButton = ({
  buttonStyle,
  disabled,
  fullWidth,
  label,
  size,
  type,
  variant,
  onClick,
}) => {
  const classes = buttonStyles()

  return (
    <Button
      className={clsx({
        [classes.accentButton]: buttonStyle === BUTTON_STYLE.ACCENT,
        [classes.cancelButton]: buttonStyle === BUTTON_STYLE.CANCEL,
        [classes.hugeAccentButton]: buttonStyle === BUTTON_STYLE.HUGE_ACCENT,
        [classes.linkButton]: buttonStyle === BUTTON_STYLE.LINK,
        [classes.primaryButton]: buttonStyle === BUTTON_STYLE.PRIMARY,
        [`${classes.hugeButton} ${classes.accentButton}`]:
          buttonStyle === BUTTON_STYLE.HUGE_ACCENT,
        [`${classes.hugeButton} ${classes.primaryButton}`]:
          buttonStyle === BUTTON_STYLE.HUGE_PRIMARY,
      })}
      disabled={disabled}
      fullWidth={fullWidth}
      type={type}
      size={size}
      variant={variant}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}

CommonButton.propTypes = {
  buttonStyle: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
}

CommonButton.defaultProps = {
  buttonStyle: BUTTON_STYLE.PRIMARY,
  disabled: false,
  fullWidth: false,
  label: '',
  size: BUTTON_SIZE.MEDIUM,
  type: BUTTON_TYPE.BUTTON,
  variant: BUTTON_VARIANT.TEXT,
  onClick: () => {},
}

export default CommonButton
