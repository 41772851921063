import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { TableCell, TableRow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { actionLabel } from 'constants/alertConstants'
import {
  LISTINGS_DETAILS_ENDPOINT,
  USER_PROFILE_ENDPOINT,
} from 'constants/apiUrls'

const useStyles = makeStyles(theme => ({
  alertRow: {
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: '8px',
      paddingLeft: '16px',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    verticalAlign: 'middle',
    width: '16px',
    height: '16px',
    marginRight: '8px',
  },
  link: {
    color: theme.palette.text.link,
    cursor: 'pointer',
    fontSize: '1rem',
  },
}))

const formatName = username => {
  const { first_name: firstName = '', last_name: lastName = '' } = username
  const firstLastNameLetter = lastName.charAt(0) ? lastName.charAt(0) : ''
  return `${firstName} ${firstLastNameLetter}.`
}

const AlertRow = ({ alert, markAlertAsRead }) => {
  const classes = useStyles()
  const messageToDisplay = actionLabel(alert.action)

  const goToListing = () => {
    navigate(LISTINGS_DETAILS_ENDPOINT(alert.listing.uid))
  }

  const goToProfile = () => {
    navigate(USER_PROFILE_ENDPOINT(alert.generated_by.uid))
  }

  return (
    <TableRow className={classes.alertRow}>
      <TableCell>
        {alert.generated_by && (
          <Typography
            className={classes.link}
            variant="caption"
            onClick={() => goToProfile()}
          >
            {formatName(alert.generated_by)}
          </Typography>
        )}
        {messageToDisplay.firstPart}
        {alert.listing && (
          <Typography
            className={classes.link}
            variant="caption"
            onClick={() => goToListing()}
          >
            {alert.listing.model_name}
          </Typography>
        )}
        {messageToDisplay.secondPart}
      </TableCell>
      <TableCell align="right">
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => markAlertAsRead(alert.uid)}
        />
      </TableCell>
    </TableRow>
  )
}

AlertRow.propTypes = {
  alert: PropTypes.object.isRequired,
  markAlertAsRead: PropTypes.func,
}

AlertRow.defaultProps = {
  markAlertAsRead: () => {},
}

export default AlertRow
