import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  default: {
    color: theme.palette.text.default,
  },
  allowed: {
    color: theme.palette.text.allowed,
  },
  alert: {
    color: theme.palette.text.alert,
  },
  danger: {
    color: theme.palette.error.main,
  },
  helperText: {
    fontSize: '11px',
    float: 'right',
  },
}))

const HelperText = ({ counter, maxLength, minLength, text }) => {
  const classes = useStyles()
  const [classColor, setClassColor] = useState(classes.default)
  const [helper, setHelper] = useState(
    `Write at least ${minLength} characters.`
  )

  useEffect(() => {
    const reloadHelperText = () => {
      if (text.length === 0) {
        setClassColor(classes.default)
        setHelper(`Write at least ${minLength} characters.`)
      }
      if (text.length > 0 && text.length <= minLength) {
        setClassColor(classes.default)
        setHelper(
          `You have to write ${minLength - counter} characters to send.`
        )
      }
      if (text.length > minLength && text.length < maxLength / 2 - minLength) {
        setClassColor(classes.allowed)
        setHelper(
          `You can send or write ${maxLength - counter} characters more.`
        )
      }
      if (text.length > maxLength / 2 - minLength) {
        setClassColor(classes.alert)
        setHelper(
          `You can send or write ${maxLength - counter} characters more.`
        )
      }
      if (text.length > maxLength) {
        setClassColor(classes.danger)
        setHelper(
          `Your description is too long to send by ${Math.abs(
            maxLength - counter
          )} characters.`
        )
      }
      if (text.length > maxLength / 2 - minLength) {
        setClassColor(classes.alert)
        setHelper(
          `You can send or write ${maxLength - counter} characters more.`
        )
      }
      if (text.length === maxLength) {
        setClassColor(classes.danger)
        setHelper(`You reached the limit (500 characters).`)
      }
    }
    reloadHelperText()
  }, [classes, counter, maxLength, minLength, text])

  return (
    <div className={classes.helperText}>
      <span className={classColor}>{helper}</span>
    </div>
  )
}

HelperText.propTypes = {
  counter: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  minLength: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
}

export default HelperText
