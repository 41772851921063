import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { size } from 'lodash'
import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'

import {
  ConfirmationDialog,
  ErrorMessage,
  FeatureFlag,
  OffersModal,
  SkeletonListing,
  UserProfileDialog,
} from 'components'
import { NEW_OFFER_TO_WORK } from 'constants/alertConstants'
import {
  MODEL_DETAILS_ENDPOINT,
  LISTINGS_DETAILS_ENDPOINT,
} from 'constants/apiUrls'
import {
  DEFAULT_LISTING_LIMITS,
  LISTING_INTERMEDIATE_STATUS,
} from 'constants/listingConstants'
import { OFFER_STATUS } from 'constants/offerConstants'
import { createAlert } from 'services/AlertService'
import { getDesign } from 'services/DesignService'
import { sendNofificationMail } from 'services/MailService'
import { getOffers } from 'services/OfferService'
import { updateListing } from 'services/ListingService'
const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '460px',
    height: '64vh',
  },
  customBackGround: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.background.white,
  },
  fullHeight: {
    height: '100%',
  },
  link: {
    cursor: 'pointer',
    fontSize: '0.95rem',
    textDecoration: 'underline',
  },
  select: {
    width: '150px',
    maxWidth: '150px',
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'none',
      border: 'none',
    },
  },
  title: {
    marginLeft: '12px',
    marginTop: '12px',
    fontWeight: 'bold',
  },
  boldText: {
    '& .MuiToolbar-root .MuiTypography-root, .MuiInputBase-root .MuiSelect-root': {
      fontWeight: 'bold',
    },
  },
}))

const Selector = ({
  currentOfferSelected,
  listing,
  refreshRow,
  loggedUserId,
}) => {
  const classes = useStyles()
  const [currentOffers, setCurrentOffers] = useState([currentOfferSelected])
  const [openDialogToChangeMaker, setOpenDialogToChangeMaker] = useState(false)
  const [newOfferAccepted, setOfferAccepted] = useState(null)
  const [offerSelected, setOfferSelected] = useState(null)
  const [error, setError] = useState('')

  const showMakerName = offer =>
    `${offer.username.first_name} ${offer.username.last_name}`

  const getCurrentOfferId = currentOffer => {
    return newOfferAccepted ? newOfferAccepted.id : currentOffer.id
  }

  const handleChangeOffer = async event => {
    const {
      target: { value },
    } = event
    setError('')
    setOpenDialogToChangeMaker(true)
    setOfferSelected(value)
  }

  const handleAccept = async () => {
    try {
      const newOfferSelected = currentOffers.find(
        offer => offer.id === offerSelected
      )

      let intermediateState = {
        pending_offer_id: newOfferSelected.id,
        status: LISTING_INTERMEDIATE_STATUS.AWAITING_CHANGE,
        operator_id: loggedUserId,
      }

      const offerAccepted = currentOffers.find(
        offer => offer.status === OFFER_STATUS.ACCEPTED
      )

      if (offerAccepted.id === newOfferSelected.id) {
        await updateListing(listing.id, {
          intermediate_state: {
            pending_offer_id: '',
            status: '',
            operator_id: '',
          },
        })
      }
      await updateListing(listing.id, {
        intermediate_state: intermediateState,
      })

      const createdFor = {
        first_name: listing.user_info.first_name,
        last_name: listing.user_info.last_name,
        uid: listing.owned_by,
      }

      const listingAlert = {
        modelName: listing.model_name,
        uid: listing.id,
      }

      listing.intermediate_state = intermediateState
      await createAlert(null, createdFor, NEW_OFFER_TO_WORK, listingAlert)
      const msg = 'Do you agree to accept another offer?'
      await sendNofificationMail(
        listing.owned_by,
        listing,
        msg,
        offerAccepted.is_from_slant3d
      )
      refreshRow(newOfferSelected, listing)
      setOfferAccepted(newOfferSelected)
    } catch (error) {
      setError('There was an error changing the offer, try again later please.')
    }
  }

  const handleCancel = () => {
    setOfferSelected(null)
    setOpenDialogToChangeMaker(false)
  }

  const getAllOffers = async () => {
    const { offers } = await getOffers(listing.id)
    setCurrentOffers(offers)
  }

  return (
    <>
      <ConfirmationDialog
        open={openDialogToChangeMaker}
        title="Are you sure to change the offer of this listing?"
        confirmLabel="Update"
        cancelLabel="Cancel"
        onAccept={handleAccept}
        onCancel={handleCancel}
      />
      <ErrorMessage message={error} />
      <Select
        className={classes.select}
        labelId="material-label"
        value={getCurrentOfferId(currentOfferSelected)}
        variant="outlined"
        onChange={e => handleChangeOffer(e, listing.id)}
        onOpen={getAllOffers}
      >
        <MenuItem value="" disabled>
          - select -
        </MenuItem>

        {currentOffers.map((item, index) => (
          <MenuItem
            className={classes.buttonStyles}
            key={index}
            value={item.id}
          >
            {showMakerName(item)}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

const CurrentRow = ({ listing, numberOffers, offerSelected, loggedUserId }) => {
  const classes = useStyles()
  const [currentOfferSelected, setCurrentOfferSelected] = useState(
    offerSelected
  )
  const [currentListing, setCurrentListing] = useState(listing)
  const [openUserProfileDialog, setOpenUserProfileDialog] = useState(false)
  const [pendingOfferSelected, setPendingOfferSelected] = useState({})
  const [userIdSelected, setUserIdSelected] = useState('')

  const parseDate = timestamp => {
    let date = ''
    if (timestamp) {
      const getDate = timestamp.toDate().toISOString().split('T')[0]
      date = getDate.replace('-', '/').replace('-', '/')
    }
    return date
  }

  useEffect(() => {
    const getPendingOfferSelected = async () => {
      const { offers } = await getOffers(listing.id)
      const pendingOffer = offers.find(
        offer => offer.id === currentListing.intermediate_state.pending_offer_id
      )
      setPendingOfferSelected(pendingOffer)
    }

    if (
      currentListing.intermediate_state &&
      currentListing.intermediate_state.pending_offer_id
    ) {
      getPendingOfferSelected()
    }
  }, [currentListing.intermediate_state, listing.id])

  const refreshRow = (newOffer, listing) => {
    setCurrentOfferSelected(newOffer)
    setCurrentListing(listing)
  }

  const goToDesignDetail = async modelId => {
    const model = await getDesign(modelId)
    navigate(MODEL_DETAILS_ENDPOINT(model.formatted_name), {
      state: { model },
    })
  }

  const goToListing = listingId => {
    navigate(LISTINGS_DETAILS_ENDPOINT(listingId))
  }
  const handleOpenUserProfileDialog = uid => {
    setUserIdSelected(uid)
    setOpenUserProfileDialog(true)
  }

  const showMakerName = offer =>
    `${offer.username.first_name} ${offer.username.last_name}`

  return (
    <>
      <UserProfileDialog
        userId={userIdSelected}
        open={openUserProfileDialog}
        setOpenUserProfileDialog={setOpenUserProfileDialog}
      />
      <TableRow>
        <TableCell component="th" scope="row">
          <Typography
            className={classes.link}
            variant="caption"
            onClick={() => goToListing(currentListing.id)}
          >
            {currentListing.id}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography
            className={classes.link}
            variant="caption"
            onClick={() => goToDesignDetail(currentListing.model)}
          >
            {currentListing.model_name}
          </Typography>
        </TableCell>
        <TableCell align="center">{currentListing.status}</TableCell>
        <TableCell align="center">
          {parseDate(currentListing.submission_date)}
        </TableCell>
        <TableCell align="center">
          {parseDate(currentListing.updated_at)}
        </TableCell>
        <TableCell align="center">
          {currentListing.user_info && (
            <Typography
              className={classes.link}
              variant="caption"
              onClick={() =>
                handleOpenUserProfileDialog(currentListing.owned_by)
              }
            >
              {currentListing.user_info.first_name}&nbsp;
              {currentListing.user_info.last_name}
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          {currentListing.designer_info && (
            <Typography
              className={classes.link}
              variant="caption"
              onClick={() =>
                handleOpenUserProfileDialog(currentListing.designer_info.uid)
              }
            >
              {currentListing.designer_info.first_name}&nbsp;
              {currentListing.designer_info.last_name}
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          {currentListing.intermediate_state &&
            currentListing.intermediate_state.status &&
            pendingOfferSelected.username &&
            `PENDING ${pendingOfferSelected.username.first_name} ${pendingOfferSelected.username.last_name}`}
          {numberOffers > 0 && !!offerSelected && !!offerSelected.username && (
            <>
              <FeatureFlag
                activeComponent={<>{showMakerName(currentOfferSelected)}</>}
                inactiveComponent={
                  <Selector
                    currentOfferSelected={currentOfferSelected}
                    listing={currentListing}
                    loggedUserId={loggedUserId}
                    refreshRow={refreshRow}
                  />
                }
                flag="backup_fulfillment"
              />
            </>
          )}
        </TableCell>
        <TableCell align="center">
          {numberOffers > 0 && !!offerSelected && !!offerSelected.id && (
            <>{currentOfferSelected.id}</>
          )}
        </TableCell>
        <TableCell align="center">
          <OffersModal
            listingId={currentListing.id}
            modelId={currentListing.model}
            modelName={currentListing.model_name}
            quantity={currentListing.tech_specs.quantity}
          />
        </TableCell>
      </TableRow>
    </>
  )
}

const ListingDashboardTable = ({
  currentPage,
  fetchListings,
  hasNext,
  initRowsPerPage,
  isFetching,
  listings,
  menuOption,
  offers,
  nextPage,
  prevPage,
}) => {
  const classes = useStyles()
  const loggedUser = useSelector(state => state.userState.user)
  const [page, setPage] = useState(currentPage)
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage)

  const handleChangePage = async (event, newPage) => {
    let response = true
    if (newPage > page) {
      response = await nextPage(newPage, rowsPerPage, fetchListings)
    } else {
      await prevPage(newPage, rowsPerPage, fetchListings)
    }
    if (response) {
      setPage(newPage)
    }
  }

  const handleChangeRowsPerPage = async event => {
    const {
      target: { value: newLimit },
    } = event
    setRowsPerPage(newLimit)
    setPage(0)
    await fetchListings(menuOption, newLimit)
  }

  return (
    <Paper className={classes.fullHeight}>
      <Typography className={classes.title} variant="h6" component="div">
        Listings
      </Typography>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="listing table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.customBackGround}>ID</TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Design Name
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Status
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Created at
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Updated at
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                User
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Designer
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Maker
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Accepted Bid
              </TableCell>
              <TableCell align="center" className={classes.customBackGround}>
                Offers
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isFetching ? (
              <>
                {listings.map((listing, index) => (
                  <CurrentRow
                    key={index}
                    listing={listing}
                    loggedUserId={loggedUser.uid}
                    numberOffers={size(offers)}
                    offerSelected={offers[index]}
                  />
                ))}
              </>
            ) : (
              <SkeletonListing columns={10} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.boldText}
        component="div"
        count={-1}
        nextIconButtonProps={{ disabled: !hasNext }}
        labelDisplayedRows={({ from, to }) => `${from}-${to}`}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={DEFAULT_LISTING_LIMITS}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

ListingDashboardTable.propTypes = {
  currentPage: PropTypes.number,
  fetchListings: PropTypes.func.isRequired,
  hasNext: PropTypes.bool.isRequired,
  initRowsPerPage: PropTypes.number,
  listings: PropTypes.array.isRequired,
  menuOption: PropTypes.number.isRequired,
  offers: PropTypes.array.isRequired,
  nextPage: PropTypes.func.isRequired,
  prevPage: PropTypes.func.isRequired,
}

ListingDashboardTable.defaultProps = {
  currentPage: 0,
  initRowsPerPage: DEFAULT_LISTING_LIMITS[0],
}

export default ListingDashboardTable
