import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { round, size } from 'lodash'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { updateCurrentUser } from 'actions/userActions'
import { CommonButton, ErrorMessage, StoreForm } from 'components'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import { PLATFORM_FEE, DESIGN_FEE } from 'constants/platformFeeConstant'
import {
  ERROR_ADDING_STORE,
  ERROR_FETCHING_STORE,
} from 'constants/storeConstants'
import {
  createDesignStore,
  getStoreItemsByDesignId,
  updateStoreItem,
} from 'services/StoreService'

const useStyles = makeStyles(theme => ({
  btn: {
    width: '100%',
  },
  dialog: {
    '& .MuiDialogContent-root': {
      '@media (max-width:600px)': {
        padding: 0,
      },
    },
    '& .MuiDialog-paper': {
      margin: 0,
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'inherit',
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
    },
  },
  footer: {
    width: '50%',
    textAlign: 'center',
    padding: '20px 0',
    margin: '0 auto',
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  footerContent: {
    display: 'flex',
    width: '100%',
  },
  header: {
    margin: '0',
    paddingLeft: '24px',
    float: 'left',
    paddingTop: '20px',
    '& h2': {
      margin: 0,
      paddingLeft: '24px',
    },
  },
  progress: {
    position: 'absolute',
    top: '46%',
    left: '47%',
  },
}))

const StoreSettingsModal = ({ design, loggedUser }) => {
  const classes = useStyles()
  const { isLoading: loggedUserLoading } = useSelector(state => state.userState)
  const { catalog } = useSelector(state => state.catalogState)
  const { error: catalogError } = useSelector(state => state.catalogState)
  const { materials = [] } = catalog

  const initialPrices = () => {
    const prices = {}
    materials.forEach(material => {
      prices[material.name] = ''
    })
    return prices
  }

  const [errorMessage, setErrorMessage] = useState(catalogError)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [prices, setPrices] = useState(initialPrices())
  const [storeItems, setStoreItems] = useState([])
  const [storeItemsSwitch, setStoreItemsSwitch] = useState({})
  const [storeNameState, setStoreNameState] = useState({
    populated: false,
    storeName: '',
  })
  const [visibleColumns, setVisibleColumns] = useState({
    first: false,
    second: false,
  })

  useEffect(() => {
    if (loggedUser && !storeNameState.populated && !loggedUserLoading) {
      const storeName =
        loggedUser.store_name || `${loggedUser.first_name}'s store`

      setStoreNameState({
        populated: true,
        storeName,
      })
    }
  }, [loggedUser, loggedUserLoading, storeNameState])

  const getStoreStatus = () => {
    let storeStatus = {}
    for (const [material, price] of Object.entries(prices)) {
      const storeItem = storeItems.find(
        storeItem => material === storeItem.material
      )

      if (storeItem && storeItem.uid) {
        storeStatus[material] = {
          id: storeItem.uid,
          price,
          isStored: true,
        }
      } else {
        storeStatus[material] = {
          price,
          isStored: false,
        }
      }
    }
    return storeStatus
  }

  const saveStoreItems = async () => {
    const storeStatus = getStoreStatus()
    const savedStoreItems = Object.keys(storeStatus).map(async material => {
      const { isStored, price } = storeStatus[material]
      const priceIsNotZero = price > 0
      if (priceIsNotZero && storeItemsSwitch[material].updated) {
        if (isStored) {
          const fieldsToUpdate = {
            offer: price,
            is_deleted: storeItemsSwitch[material].isDeleted,
          }
          return updateStoreItem(storeStatus[material].id, fieldsToUpdate)
        } else {
          const storeDesigns = handleCreateDesignsStore(design.id, material)
          return createDesignStore(storeDesigns, loggedUser)
        }
      }
    })
    return savedStoreItems
  }

  const addStoreItems = async () => {
    try {
      setLoading(true)
      handleUpdateStoreName()
      const savedStoreItems = await saveStoreItems()
      await Promise.all(savedStoreItems)

      handleClose()
    } catch (error) {
      setErrorMessage(ERROR_ADDING_STORE)
    }
    setLoading(false)
  }

  const handleCreateDesignsStore = (modelId, material) => {
    const currentPrice = prices[material]
    const platformFee = round(currentPrice * (PLATFORM_FEE / 100), 2)
    return {
      ...design,
      additionalTags: '',
      estimatedMaterial: design.estimated_material,
      estimatedPrintTime: design.estimated_print_time,
      formattedName: design.formatted_name,
      imageFiles: design.images,
      isPublic: design.is_public,
      isSupportRequired: design.is_support_required,
      printNotes: design.tech_specs.print_notes,
      properties: design.tech_specs,
      standardName: design.standard_name,
      design_fee: DESIGN_FEE,
      material,
      modelId,
      offer: currentPrice,
      platform_fee: platformFee,
      total_fee: round(DESIGN_FEE + currentPrice + platformFee, 2),
    }
  }

  const updateStoreName = value => {
    setStoreNameState({
      ...storeNameState,
      storeName: value,
    })
  }

  const handleUpdateStoreName = () => {
    if (!loggedUser.store_name && size(storeNameState.storeName) > 0) {
      const params = { store_name: storeNameState.storeName }
      updateCurrentUser({ ...loggedUser, ...params }, false, params)
    }
  }

  const fetchStoreItemsStored = async () => {
    try {
      const storeItemsStored = await getStoreItemsByDesignId(design.id)
      setStoreItems(storeItemsStored)
      for (const [index, material] of materials.entries()) {
        const storeItem = storeItemsStored.find(
          storeItem => storeItem.material === material.name
        )
        let isDeleted = true
        if (storeItem) {
          const { first, second } = visibleColumns
          const isEven = index % 2 === 0
          if (isEven && !first) {
            setVisibleColumns(visibleColumns => ({
              ...visibleColumns,
              first: true,
            }))
          }
          if (!isEven && !second) {
            setVisibleColumns(visibleColumns => ({
              ...visibleColumns,
              second: true,
            }))
          }

          isDeleted = storeItem.is_deleted
          setPrices(prices => ({
            ...prices,
            [material.name]: storeItem.offer,
          }))
        }
        setStoreItemsSwitch(storeItemsSwitch => ({
          ...storeItemsSwitch,
          [material.name]: {
            isDeleted,
            stored: !!storeItem,
          },
        }))
      }
    } catch (error) {
      setErrorMessage(ERROR_FETCHING_STORE)
    }
  }

  const handleOpen = async () => {
    setLoading(true)
    setPrices(initialPrices())
    await fetchStoreItemsStored()
    setOpen(true)
    setLoading(false)
  }

  const handleClose = () => {
    setErrorMessage(catalogError)
    setOpen(false)
    setLoading(false)
  }

  return (
    <>
      <CommonButton
        label="Add to Store"
        size={BUTTON_SIZE.SMALL}
        variant={BUTTON_VARIANT.OUTLINED}
        onClick={handleOpen}
      />
      <Dialog className={classes.dialog} open={open} onClose={handleClose}>
        <DialogTitle id="title">
          <div className={classes.header}>{<h2>Store Settings</h2>}</div>
        </DialogTitle>
        <DialogContent>
          <ErrorMessage message={errorMessage} />
          <StoreForm
            currentStoreName={storeNameState.storeName}
            hasStoreName={!!loggedUser.store_name}
            materials={materials}
            prices={prices}
            storeItemsSwitch={storeItemsSwitch}
            visibleColumns={visibleColumns}
            setPrices={setPrices}
            setStoreItemsSwitch={setStoreItemsSwitch}
            updateStoreName={updateStoreName}
          />
        </DialogContent>
        <DialogActions>
          <div className={classes.footer}>
            <div className={classes.footerContent}>
              <div className={classes.btn}>
                <CommonButton
                  buttonStyle={BUTTON_STYLE.CANCEL}
                  disabled={loading}
                  fullWidth={true}
                  label="Cancel"
                  size={BUTTON_SIZE.LARGE}
                  type={BUTTON_TYPE.SUBMIT}
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={handleClose}
                />
              </div>
              {loading && (
                <CircularProgress size={48} className={classes.progress} />
              )}
              <div className={classes.btn}>
                <CommonButton
                  buttonStyle={BUTTON_STYLE.PRIMARY}
                  disabled={loading}
                  fullWidth={true}
                  label="Save"
                  size={BUTTON_SIZE.LARGE}
                  type={BUTTON_TYPE.SUBMIT}
                  variant={BUTTON_VARIANT.OUTLINED}
                  onClick={addStoreItems}
                />
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}
StoreSettingsModal.propTypes = {
  design: PropTypes.object.isRequired,
  loggedUser: PropTypes.object.isRequired,
}

export default StoreSettingsModal
