import * as Firebase from 'firebase'
import getFirebase from './firebase'
import { generateKeywords } from 'services/DesignService'

const firebaseApp = getFirebase()

export const getStoreItems = (makerId, limit = 15, startAfter) => {
  return new Promise((resolve, reject) => {
    let query = firebaseApp
      .firestore()
      .collection('store')
      .orderBy('submission_date', 'desc')
      .where('ownedBy.uid', '==', makerId)
      .where('is_deleted', '==', false)
      .where('is_disabled', '==', false)
      .limit(limit)
    if (startAfter) {
      query = query.startAfter(startAfter)
    }
    query
      .get()
      .then(querySnapshot => {
        const lastElement = querySnapshot.docs[querySnapshot.docs.length - 1]
        const items = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          uid: doc.id,
        }))
        resolve({ items, lastElement })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const createDesignStore = (designStore, loggedInUser) => {
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      const {
        additionalComponents,
        additionalTags,
        category,
        description,
        designs,
        design_fee,
        formattedName,
        images,
        isPublic,
        isSupportRequired,
        material,
        modelId,
        name,
        offer,
        platform_fee,
        printNotes,
        properties,
        standardName,
        thumbnail,
        total_fee,
      } = designStore
      if (!loggedInUser.first_name) {
        loggedInUser.first_name = ''
        loggedInUser.last_name = ''
      }

      const newDesignStore = {
        additionalComponents,
        category,
        description,
        designs,
        design_fee,
        formatted_name: formattedName,
        images,
        is_deleted: false,
        is_disabled: false,
        is_public: isPublic,
        is_support_required: isSupportRequired,
        keywords: generateKeywords(name, description, additionalTags),
        material,
        modelId,
        name,
        offer,
        platform_fee,
        standard_name: standardName,
        ownedBy: {
          first_name: loggedInUser.first_name,
          last_name: loggedInUser.last_name,
          photo_url: loggedInUser.photo_url,
          uid: loggedInUser.uid,
        },
        submission_date: Firebase.firestore.FieldValue.serverTimestamp(),
        tech_specs: { ...properties, print_notes: printNotes },
        thumbnail,
        total_fee,
        update_date: Firebase.firestore.FieldValue.serverTimestamp(),
      }

      firebaseApp
        .firestore()
        .collection('store')
        .add(newDesignStore)
        .then(docRef => {
          resolve({ ...newDesignStore, uid: docRef.id })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const createSeveralDesignsStore = (listDesigns, loggedInUser) => {
  return new Promise((resolve, reject) => {
    const designStores = listDesigns.map(design => {
      return createDesignStore(design, loggedInUser)
    })
    Promise.all(designStores)
      .then(designsStore => {
        resolve({ designsStore })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const getStoreItemsByDesignId = designId => {
  return new Promise((resolve, reject) => {
    firebaseApp
      .firestore()
      .collection('store')
      .where('modelId', '==', designId)
      .get()
      .then(querySnapshot => {
        const items = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          uid: doc.id,
        }))
        resolve(items)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateStoreItem = (storeId, fieldsToUpdate) => {
  return new Promise((resolve, reject) => {
    firebaseApp
      .firestore()
      .collection('store')
      .doc(storeId)
      .update({
        ...fieldsToUpdate,
      })
      .then(() => {
        resolve(storeId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateMultipleStoreItems = (storeIds, fieldsToUpdate) => {
  return new Promise((resolve, reject) => {
    const storeItems = storeIds.map(storeId => {
      return updateStoreItem(storeId, fieldsToUpdate)
    })
    Promise.all(storeItems)
      .then(storeItemsResponse => {
        resolve({ storeItemsResponse })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}
