import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, LinearProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { CustomDialogTitle } from 'components'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    '& .MuiTypography-h6': {
      fontWeight: 'bold',
    },
    '& .MuiDialogTitle-root': {
      padding: '10px 0px',
    },
  },
  linearProgress: {
    height: '20px',
  },
  dialog: {
    '& .MuiDialog-paperScrollPaper': {
      width: '500px',
      padding: '20px',
    },
    '& .MuiDialogContent-root': {
      overflowY: 'auto',
      height: '400px',
    },
  },
}))

const LinearProgressWithLabel = props => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

const LinearProgressDialog = ({ filesToUpload, open, handleCloseProgress }) => {
  const classes = useStyles()
  const [files, setFiles] = useState(filesToUpload)

  useEffect(() => {
    setFiles(filesToUpload)
  }, [filesToUpload])

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleCloseProgress}
    >
      <div>
        <div className={classes.boldTitle}>
          <CustomDialogTitle onClose={handleCloseProgress}>
            Uploading Files
          </CustomDialogTitle>
        </div>
        <DialogContent>
          {files &&
            Object.entries(files).map(file => {
              const fileName = file[0]
              return (
                <div key={fileName} className={classes.container}>
                  <span className={classes.title}>{fileName}</span>
                  <LinearProgressWithLabel
                    className={classes.linearProgress}
                    value={file[1].progress}
                  />
                </div>
              )
            })}
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default LinearProgressDialog
