import React, { useEffect, useState } from 'react'
import Rating from '@material-ui/lab/Rating'
import PropTypes from 'prop-types'
import { IconButton, Grid, Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { getUserById } from 'services/UserService'

const useStyles = makeStyles(theme => ({
  boldTitle: {
    fontWeight: 'bold',
    margin: '8px 0px',
  },
  offerTool: {
    width: '132px',
    textAlign: 'center',
    fontSize: '0.75rem',
  },
}))

const AverageReviewTooltip = ({ makerId }) => {
  const classes = useStyles()
  const [error, setError] = useState('')
  const [review, setReview] = useState({ isFetched: false })

  useEffect(() => {
    const getMaker = async () => {
      try {
        const newMaker = await getUserById(makerId)
        if (newMaker.reviews) {
          setReview({
            isFetched: true,
            qualityAccuracy: newMaker.reviews.quality_accuracy,
            qualityCounter: newMaker.reviews.quality_counter,
            price: newMaker.reviews.price,
            priceCounter: newMaker.reviews.price_counter,
            profesionalism: newMaker.reviews.profesionalism,
            profesionalismCounter: newMaker.reviews.profesionalism_counter,
            promptness: newMaker.reviews.promptness,
            promptnessCounter: newMaker.reviews.promptness_counter,
          })
        } else {
          setReview({
            isFetched: true,
            qualityAccuracy: 0,
            qualityCounter: 0,
            price: 0,
            priceCounter: 0,
            profesionalism: 0,
            profesionalismCounter: 0,
            promptness: 0,
            promptnessCounter: 0,
          })
        }
      } catch (error) {
        setError(
          'An error occurred while deleting the alert, please try again later.'
        )
      }
    }
    if (!review.isFetched && makerId) {
      getMaker()
    }
  }, [makerId, review.isFetched])

  return (
    <Tooltip
      placement="bottom-start"
      title={
        <Grid container className={classes.offerTool}>
          <Grid className={classes.boldTitle} item xs={12}>
            Reviews
          </Grid>
          {error && <div>{error}</div>}
          <Grid item xs={12}>
            Quality&Accuracy:
          </Grid>
          <Grid item xs={12}>
            <Rating
              name="quality-review"
              precision={0.5}
              readOnly
              size="small"
              value={review.qualityAccuracy / review.qualityCounter}
            />
          </Grid>
          <Grid item xs={12}>
            Profesionalism:
          </Grid>
          <Grid item xs={12}>
            <Rating
              name="profesionalism-review"
              precision={0.5}
              readOnly
              size="small"
              value={review.profesionalism / review.profesionalismCounter}
            />
          </Grid>
          <Grid item xs={12}>
            Price:
          </Grid>
          <Grid item xs={12}>
            <Rating
              name="price-review"
              precision={0.5}
              readOnly
              size="small"
              value={review.price / review.priceCounter}
            />
          </Grid>
          <Grid item xs={12}>
            Promptness:
          </Grid>
          <Grid item xs={12}>
            <Rating
              name="promptness-review"
              precision={0.5}
              readOnly
              size="small"
              value={review.promptness / review.promptnessCounter}
            />
          </Grid>
        </Grid>
      }
    >
      <IconButton>
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}

AverageReviewTooltip.propTypes = {
  makerId: PropTypes.string.isRequired,
}

export default AverageReviewTooltip
