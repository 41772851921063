import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Hidden, TextField } from '@material-ui/core'
import { CommonButton, DropdownMenu } from 'components'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'
import {
  BUTTON_STYLE,
  BUTTON_SIZE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import { ALL_CATEGORIES } from 'constants/catalogConstants'

const searchStyles = makeStyles(theme => ({
  alignCenter: {
    display: 'flex',
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '75%',
    },
  },
  buttonStyles: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.white,
    cursor: 'pointer',
    display: 'flex',
    fontSize: theme.custom.form.checkboxFontSize,
    fontWeight: 'bold',
    height: '100%',
    opacity: 0.8,
    textTransform: 'none',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      opacity: 1,
    },
  },
  cleanSearchIcon: {
    cursor: 'pointer',
    height: '16px',
  },
  icon: {
    color: theme.palette.text.white,
  },
  inputSearch: {
    height: '100%',
  },
  relative: {
    position: 'relative',
  },
  searchContainer: {
    fontSize: theme.custom.form.checkboxFontSize,
    '& .MuiInputBase-root': {
      height: '40px',
      paddingRight: '0px',
    },
    '& .MuiGrid-root': {
      height: '40px',
    },
    '& .MuiButton-root': {
      minWidth: '32px',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '0px',
    width: '100%',
  },
  searchButton: {
    '& .MuiButtonBase-root': {
      '@media (max-width:783px)': {
        fontSize: '0.75rem',
      },
      '@media (max-width:690px)': {
        fontSize: '0.6rem',
      },
    },
  },
  searchIcon: {
    marginRight: 'auto',
    marginLeft: 'auto',
    height: '20px',
    width: '20px',
  },
  select: {
    fontSize: '0.875rem',
    paddingLeft: '10px',
  },
}))

const searchDefaultState = {
  value: '',
  showError: false,
  errorText: '',
  showCleanField: false,
  searchExecuted: false,
}

const Search = ({
  categories,
  selectedCategory,
  updateCategory,
  restoreGallery,
  onClickSearch,
}) => {
  const classes = searchStyles()
  const [searchState, setSearchState] = useState(searchDefaultState)

  const search = () => {
    const splittedSearch = searchState.value.toLowerCase().split(' ')
    const searchInput = splittedSearch.filter(item => item.length > 0)
    if (searchInput.length) {
      setSearchState({
        ...searchState,
        errorText: '',
        searchExecuted: true,
        showError: false,
      })
      onClickSearch(searchInput)
    } else {
      setSearchState({
        ...searchState,
        errorText: `The field can't be empty.`,
        showError: true,
      })
    }
  }

  const onChangeSearch = event => {
    const {
      target: { value },
    } = event
    setSearchState({
      ...searchState,
      value,
      showCleanField: true,
    })
  }

  const cleanSearch = () => {
    if (searchState.searchExecuted) {
      restoreGallery()
    }
    setSearchState(searchDefaultState)
  }

  return (
    <Grid container item xs={11} className={classes.searchContainer}>
      <Grid item xs={12} className={classes.alignCenter}>
        <Grid className={classes.relative} item xs={5} sm={4} md={4} lg={4}>
          <DropdownMenu
            initialLabel={ALL_CATEGORIES}
            menuOptions={categories.map(item => item.category)}
            onSelectOption={updateCategory}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextField
            className={classes.inputSearch}
            error={searchState.showError}
            fullWidth
            helperText={searchState.errorText}
            id="search"
            value={searchState.value}
            placeholder="What do you want to build?"
            size="small"
            variant="outlined"
            onChange={onChangeSearch}
            InputProps={{
              endAdornment: searchState.showCleanField && (
                <CancelIcon
                  className={classes.cleanSearchIcon}
                  onClick={cleanSearch}
                />
              ),
            }}
          />
        </Grid>
        <Grid className={classes.searchButton} item xs={1} sm={2} md={2} lg={2}>
          <Hidden smUp>
            <div className={classes.buttonStyles}>
              <SearchIcon className={classes.searchIcon} />
            </div>
          </Hidden>
          <Hidden xsDown>
            <CommonButton
              buttonStyle={BUTTON_STYLE.ACCENT}
              fullWidth={true}
              label="Search"
              size={BUTTON_SIZE.MEDIUM}
              type={BUTTON_TYPE.SUBMIT}
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={search}
            />
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  )
}

Search.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCategory: PropTypes.string.isRequired,
  updateCategory: PropTypes.func.isRequired,
}

export default Search
