import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    maxHeight: '542px',
  },
  media: {
    height: '542px',
    objectFit: 'contain',
  },
  overlay: {
    position: 'absolute',
    height: '20%',
    bottom: 0,
    backgroundColor: 'RGBA(0,0,0,0.35)',
    width: '100%',
  },
  styledField: {
    color: theme.palette.text.white,
    fontWeight: theme.typography.fontWeightMedium,
    '& label': {
      color: theme.palette.text.white,
    },
  },
}))

const CarouselItem = ({ content, hideOverlay = false }) => {
  const { cta, description, image_url, title } = content
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      {image_url ? (
        <>
          <CardMedia
            component="img"
            className={classes.media}
            image={image_url}
            title={title}
          />
          <CardContent className={clsx(classes.overlay, 'overlay')}>
            <Typography
              className={classes.styledField}
              gutterBottom
              variant="h6"
            >
              {title}
            </Typography>
            <Typography
              className={classes.styledField}
              component="p"
              variant="body1"
            >
              {description}
            </Typography>
            {cta && (
              <Button
                className={classes.styledField}
                size="small"
                onClick={() => navigate(cta.url)}
              >
                {cta.label}
              </Button>
            )}
          </CardContent>
        </>
      ) : (
        <CardMedia
          component="img"
          className={classes.media}
          image={content}
          title={title}
        />
      )}
    </Card>
  )
}

CarouselItem.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)])
    .isRequired,
}

export default CarouselItem
