import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'

import { CommonButton } from 'components'
import {
  BUTTON_SIZE,
  BUTTON_STYLE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
} from 'constants/buttonConstants'
import { LISTING_STATUS } from 'constants/listingConstants'
import { shipperLink } from 'constants/shipperLink'
import { parseDateToString } from 'constants/utils'

const useStyles = makeStyles(theme => ({
  alignCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  buttonsContainer: {
    '& .MuiGrid-root .MuiButtonBase-root .MuiButton-label': {
      fontSize: '0.7rem',
    },
  },
  content: {
    backgroundColor: theme.palette.background.darkblue,
    color: theme.palette.text.white,
    '& .MuiTableRow-root .MuiTableCell-root .MuiTypography-root': {
      color: theme.palette.text.white,
      fontSize: '1rem',
    },
  },
  firstColumn: {
    width: '65%',
    paddingRight: '0px',
  },
  flex: {
    display: 'flex',
  },
  icon: {
    color: theme.palette.text.white,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  secondColumn: {
    width: '35%',
    paddingLeft: '0px',
  },
}))

const ProductStatusTracker = ({ acknowledgeReceipt, offerDetails }) => {
  const {
    id: listingId,
    accepted_on: acceptedDate,
    delivery_date: expectedDate,
    username = {},
    shippingTracking,
    status,
  } = offerDetails
  const { first_name: firstName = '', last_name: lastName = '' } = username
  const makerName = `${firstName} ${lastName}`
  const classes = useStyles()
  return (
    <Box className={classes.root} margin={1}>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody className={classes.content}>
          <TableRow>
            <TableCell className={classes.firstColumn}>
              <Grid container>
                <Grid className={classes.flex} item xs={1}>
                  <InfoOutlinedIcon className={classes.icon} />
                </Grid>
                <Grid container item xs={11}>
                  <Grid container item xs={12}>
                    <Typography className={classes.bold} variant="body1">
                      Status
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Typography variant="body1">
                      You have accepted an offer from&nbsp;
                      <span className={classes.bold}>{makerName}</span>
                      &nbsp;on&nbsp;
                      <span className={classes.bold}>
                        {parseDateToString(acceptedDate)}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <Typography variant="body1">
                      Production status is:&nbsp;
                      <span className={classes.bold}>{status}</span>
                      &nbsp;with expected delivery on&nbsp;
                      <span className={classes.bold}>
                        {parseDateToString(expectedDate)}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell className={classes.secondColumn}>
              <Grid
                className={clsx(classes.alignCenter, classes.buttonsContainer)}
                container
                item
                xs={12}
              >
                {status === LISTING_STATUS.SHIPPED && (
                  <Grid item xs={12} lg={6}>
                    {shippingTracking.shipper !== 'OTHER' ? (
                      <Typography variant="body1">
                        <span className={classes.bold}>Tracking ID:</span>
                        <Link
                          href={shipperLink(
                            shippingTracking.shipper,
                            shippingTracking.trackingId
                          )}
                          target="_blank"
                          rel="noopener"
                        >
                          {shippingTracking.shipper}
                        </Link>
                      </Typography>
                    ) : (
                      <>
                        <Typography variant="body1">
                          <span className={classes.bold}>Tracking ID:</span>
                          &nbsp;{shippingTracking.shipper}
                        </Typography>
                        <Typography variant="body1">
                          {shippingTracking.trackingId}
                        </Typography>
                      </>
                    )}
                  </Grid>
                )}
                {status === LISTING_STATUS.SHIPPED && (
                  <Grid item xs={12} lg={6}>
                    <CommonButton
                      buttonStyle={BUTTON_STYLE.PRIMARY}
                      fullWidth={false}
                      label="Acknowledge receipt"
                      size={BUTTON_SIZE.LARGE}
                      type={BUTTON_TYPE.SUBMIT}
                      variant={BUTTON_VARIANT.OUTLINED}
                      onClick={() => acknowledgeReceipt(listingId)}
                    />
                  </Grid>
                )}
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

ProductStatusTracker.propTypes = {
  acknowledgeReceipt: PropTypes.func,
  offerDetails: PropTypes.object.isRequired,
}

ProductStatusTracker.defaultProps = {
  acknowledgeReceipt: () => {},
}

export default ProductStatusTracker
