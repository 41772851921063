import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { TooltipTutorial } from 'components'
import { TUTORIAL_NAMES } from 'constants/interactiveTutorialConstants'
import { updateTutorialState } from 'services/UserService'

const InteractiveTutorial = ({ showTutorial, steps, tutorial }) => {
  const INITIAL_STATE = {
    run: showTutorial,
    stepIndex: 0,
  }
  const loggedUser = useSelector(state => state.userState.user)
  const [continuous, setContinuous] = useState(true)
  const [state, setState] = useState(INITIAL_STATE)
  const [spotlightPadding, setSpotlightPadding] = useState(5)
  const [scrollOffset, setScrollOffset] = useState(100)

  const changeState = async data => {
    const {
      action,
      index,
      size,
      status,
      type,
      step: { target },
    } = data
    const { uid, tutorial_state } = loggedUser
    setSpotlightPadding(5)
    if (tutorial === TUTORIAL_NAMES.LISTING_PAGE) {
      setScrollOffset(300)
    }

    if (action === ACTIONS.SKIP || status === STATUS.FINISHED) {
      try {
        tutorial_state[tutorial] = true
        await updateTutorialState(uid, tutorial_state)
      } catch (error) {
        console.log(error.message)
      }
    }
    if (
      target === '#image-viewer' &&
      tutorial === TUTORIAL_NAMES.CREATE_LISTING
    ) {
      setSpotlightPadding(-20)
    }

    if (action === ACTIONS.CLOSE) {
      setState({ ...state, run: false })
    }
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setState({
        ...state,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      })
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setState({ ...state, run: false })
    }
    size - 1 <= index ? setContinuous(false) : setContinuous(true)
  }

  return (
    <>
      <JoyRide
        callback={changeState}
        continuous={continuous}
        run={state.run}
        scrollOffset={scrollOffset}
        scrollToFirstStep
        showSkipButton
        spotlightPadding={spotlightPadding}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        tooltipComponent={TooltipTutorial}
      />
    </>
  )
}

InteractiveTutorial.propTypes = {
  showTutorial: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  tutorial: PropTypes.string.isRequired,
}

InteractiveTutorial.defaultProps = {
  showTutorial: false,
}

export default InteractiveTutorial
