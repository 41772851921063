import * as Firebase from 'firebase'
import getFirebase from './firebase'

const firebase = getFirebase()

export const createListingShippingInfo = (listingId, shippingInfo) => {
  return new Promise((resolve, reject) => {
    if (firebase) {
      const {
        city,
        companyAddress,
        companyName,
        email,
        firstName,
        lastName,
        phoneNumber,
        state,
      } = shippingInfo

      firebase
        .firestore()
        .collection('listings_shipping_info')
        .add({
          city,
          company_address: companyAddress,
          company_name: companyName,
          email,
          first_name: firstName,
          last_name: lastName,
          listing: listingId,
          phone_number: phoneNumber,
          state,
          submission_date: Firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(docRef => {
          resolve(docRef.id)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const getListingShippingInfoByListingId = listingId => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('listings_shipping_info')
      .where('listing', '==', listingId)
      .limit(1)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0]
          if (doc.exists) {
            resolve({ id: doc.id, ...doc.data() })
          } else {
            reject(`Document doesn't exist`)
          }
        } else {
          reject(`Document doesn't exist`)
        }
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}
