import React from 'react'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import { size } from 'lodash'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { HitCard } from 'components'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiButton-root:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.text.white,
    },
  },
  buttonSize: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    fontWeight: 'bold',
    marginTop: '16px',
    textTransform: 'none',
    width: '200px',
  },
  grid: {
    display: 'grid',
    gridGap: '20px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      overflow: 'hidden',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up(1525)]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.up(1925)]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    [theme.breakpoints.up(2325)]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
    [theme.breakpoints.up(2725)]: {
      gridTemplateColumns: 'repeat(7, 1fr)',
    },
  },
}))

const CustomInfiniteHits = connectInfiniteHits(
  ({ hits, hasMore, refineNext }) => {
    const classes = useStyles()
    const publicHits = hits.filter(hit => hit.is_public)
    return (
      <div>
        <Grid className={classes.root}>
          <Grid className={classes.grid}>
            {publicHits.map((model, index) => (
              <HitCard hit={model} key={index} />
            ))}
            {size(publicHits) === 0 && <Typography>Not found</Typography>}
          </Grid>
        </Grid>
        {hasMore && (
          <div className={classes.buttonContainer}>
            <Button
              className={classes.buttonSize}
              variant="outlined"
              xs={12}
              onClick={refineNext}
            >
              Load More...
            </Button>
          </div>
        )}
      </div>
    )
  }
)

export default CustomInfiniteHits
