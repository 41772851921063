import React from 'react'
import { Card, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: '0 6px',
  },
  card: {
    height: '256px',
    maxWidth: '335px',
    minWidth: '288px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    height: '64px',
    padding: '12px',
  },
  grid: {
    display: 'grid',
    gridGap: '20px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      overflow: 'hidden',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.up(1525)]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.up(1525)]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.up(1925)]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    [theme.breakpoints.up(2325)]: {
      gridTemplateColumns: 'repeat(6, 1fr)',
    },
    [theme.breakpoints.up(2725)]: {
      gridTemplateColumns: 'repeat(7, 1fr)',
    },
  },
  media: {
    height: '192px',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',
  },
}))

const SkeletonGallery = () => {
  const classes = useStyles()

  const SkeletonCard = index => (
    <Card className={classes.card} key={index}>
      <Skeleton variant="rect" className={classes.media} />
      <div className={classes.footer}>
        <div className={classes.avatar}>
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        </div>
        <Skeleton animation="wave" variant="rect" width="100%" />
      </div>
    </Card>
  )

  const cards = []
  for (let i = 0; i < 12; i++) {
    cards.push(SkeletonCard)
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.grid}>
        {cards.map((card, index) => card(index))}
      </Grid>
    </Grid>
  )
}

export default SkeletonGallery
