import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Chip, Divider, Grid, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  body: {
    padding: '12px 0px',
  },
  center: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '110px',
    '& span': {
      maxWidth: '110px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.white,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& > *': {
      width: '100%',
      height: '194px',
      maxHeight: '194px',
    },
  },
  scrollable: {
    padding: '2px 4px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  tittle: {
    color: theme.palette.text.gray,
    fontSize: '1rem',
    fontWeight: 'bold',
    opacity: '0.8',
  },
}))

const Badge = ({ badge }) => {
  const classes = useStyles()

  return (
    <Grid className={classes.center} item xs={6}>
      <Chip className={classes.primary} label={badge} size="small" />
    </Grid>
  )
}

const Badges = ({ badges }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.scrollable}>
        <Typography className={classes.tittle} variant="caption">
          BADGES
        </Typography>
        <Divider />
        {badges && (
          <Grid className={classes.body} container spacing={1}>
            {badges.map((badge, index) => (
              <Badge key={index} badge={badge} />
            ))}
          </Grid>
        )}
      </Paper>
    </div>
  )
}

Badges.propTypes = {
  badges: PropTypes.array,
}

Badges.defaultProps = {
  badges: [],
}
export default Badges
